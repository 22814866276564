import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Loader } from "./index";

interface IProps {
  className?: string;
  linkAddressData: any;
  linkAddressLoading: any;
  linkAddressError: any;
}

const LinkAddress: React.FC<IProps> = ({
  className,
  linkAddressLoading,
  linkAddressData,
  linkAddressError,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn("w-screen max-w-sm", className)}>
      {linkAddressLoading && <Loader />}
      {linkAddressData && (
        <div className="p2 text-common_success">
          {t("Great! You have successfully linked a EVM address!")}
        </div>
      )}
      {linkAddressError && (
        <div className="p2 text-common_danger">
          {t("Oops! Something went wrong, try again later or contact our support.")}
        </div>
      )}
    </div>
  );
};

export default LinkAddress;
