import cn from "classnames";
import { FC } from "react";
import { Ido } from "sdk";
import AplicationStatus from "./application-status";
import "./project-whitelist.sass";

type Props = {
  className?: string;
  project: Ido;
  setNotification: (notification: string) => void
};

const ProjectWhitelist: FC<Props> = ({ className = "", project, setNotification }) => {
  return (
    <div className={cn("project-whitelist", className)}>
      <AplicationStatus project={project} setNotification={setNotification} />
    </div>
  );
};

export default ProjectWhitelist;

// declare var BlockpassKYCConnect: any;

// async function kycConnect(walletAddr: string) {
//   const blockpass = new BlockpassKYCConnect("gagarin_launchpad_077b3", {
//     env: "prod", // optional
//     refId: walletAddr, // кастомный ID пользователя, который нажмёт кнопку.
//     local_user_id: "",
//     elementId: "",
//     token: "",
//     mainColor: "170503", // цвет кнопок на форме Blockpass
//     email: "user@domain.com", // почта пользователя, которая автоматически вставится в форму Blockpass
//   });

//   blockpass.startKYCConnect();
// }
