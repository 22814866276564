import { useActions } from "app/hooks";
import { Path } from "app/pages";
import LogoutIcon from "assets/img/logout.svg";
import { ReactComponent as ProfileIcon } from "assets/img/profile-icon.svg";
import { ReactComponent as SmartInvestingIcon } from "assets/img/smart-investing.svg";
import cn from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useReferrersQuery } from "store/api";
import { EWalletType } from "store/slice";
import { useAccount, useDisconnect } from "wagmi";
import "./user-menu.sass";

type TProps = {
  isUserMenuOpened: boolean;
  closeUserMenu: () => void;
};

export const UserMenu: FC<TProps> = ({ isUserMenuOpened, closeUserMenu }) => {
  const { setWalletType } = useActions();
  const { t } = useTranslation();
  const { disconnect } = useDisconnect();
  const { address } = useAccount();

  const { data } = useReferrersQuery(address || "", { skip: !address });
  console.log(data);
  console.log(Path.REFERRAL_PROGRAM);

  return (
    <div className={cn("user-menu", { _opened: isUserMenuOpened })}>
      <Link
        className="user-menu__item"
        to={Path.ACCOUNT}
        onClick={() => {
          setWalletType(EWalletType.BNB);
          closeUserMenu();
        }}
      >
        <div className="user-menu__img">
          <ProfileIcon />
        </div>
        <div className="user-menu__title">{t("Account settings")}</div>
      </Link>
      {data && !!data.length && (
        <Link
          className="user-menu__item"
          to={Path.REFERRAL_PROGRAM}
          onClick={() => closeUserMenu()}
        >
          <div className="user-menu__img">
            <SmartInvestingIcon />
          </div>
          <div className="user-menu__title">{t("Referral program")}</div>
        </Link>
      )}
      <div className="user-menu__item" onClick={() => disconnect()}>
        <img src={LogoutIcon} alt="" className="user-menu__img" />
        <div className="user-menu__title-special">{t("Log out")}</div>
      </div>
    </div>
  );
};
