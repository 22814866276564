import WalletTon from "app/components/header/wallet-ton";
import { FC } from "react";

const TonAccountBlockNotConnected: FC = () => {
  return (
    <div>
      <WalletTon />
    </div>
  )
}

export default TonAccountBlockNotConnected

