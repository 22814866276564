import { getStakingContract } from "app/utils/contract-helper";
import { BigNumber } from "ethers";
import { ChainId } from "sdk";
import { useAccount, useChainId, useContractRead } from "wagmi";

export const useGetUserQuery = () => {
  const chainId = useChainId() as ChainId;
  const { address: account } = useAccount();
  const userResponse = useContractRead({
    chainId,
    ...getStakingContract(chainId),
    functionName: "getUser",
    enabled: Boolean(account),
    args: account && [account],
    watch: true,
    select(data) {
      return {
        pid: BigNumber.from(data.pid),
        stakedAmount: BigNumber.from(data.stakedAmount),
        totalReward: BigNumber.from(data.totalReward),
        stakedAt: BigNumber.from(data.stakedAt),
        lastRewardAt: BigNumber.from(data.lastRewardAt),
      };
    },
  });
  return userResponse;
};
