import { FC } from "react";
import Circle from "./circle";

type Props = {
  className?: string;
  isLoading?: boolean;
  colorType?: "default" | "darker";
  height?: number;
  width?: number;
};

const CircleLoader: FC<Props> = ({ className, isLoading, children, ...props }) => {
  return (
    <>
      {isLoading ? (
        <div className={className}>
          <Circle {...props} />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default CircleLoader;
