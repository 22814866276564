import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useReferrersQuery } from "store/api";
import { useAccount } from "wagmi";
import RocketLoader from "../loader/rocket-loader";
import ReferrerItem from "./referrer-item";
import "./referrers.list.sass";

const ReferrersList: FC = () => {
  const { t } = useTranslation();
  const { address } = useAccount()
  const { data: referrers, isLoading } = useReferrersQuery(address || '', { skip: !address });

  return (
    <div className="referrers-list">
      <div className="referrers-list__title">{t("Referral Projects")}</div>
      <RocketLoader className="referrers-list__loader" isLoading={isLoading || !referrers}>
        {referrers?.map((referrer) => (
          <ReferrerItem key={referrer.id} referrer={referrer} />
        ))}
      </RocketLoader>
    </div>
  );
};

export default ReferrersList;
