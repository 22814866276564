import { NOW_IN_SEC } from "app/constants";
import { VestingType } from "app/ton-components/components/claim-progress/claim-progress";
import { formatBigNumber } from "app/utils";
import { getVestingContract } from "app/utils/contract-helper";
import { BigNumber } from "ethers";
import { useMemo } from "react";
import { ChainId } from "sdk";
import { useAccount, useChainId, useContractRead, useContractReads } from "wagmi";

const findNextDate = (dates: BigNumber[], currentDate: number, claimed: number) => {
  let result = 0;
  for (let i = claimed; i < dates.length; i++) {
    const date = dates[i].toNumber();
    if (date > currentDate) {
      result = date;
      break;
    }
  }
  return result;
};

export type VestingData = {
  vid: number; // id вестинга
  roundTitle: string;
  nextClaim: number; // timestamp
  totalAmount: string; // уже отформаченная строка
  alreadyReceived: string; // уже отформаченная строка
  availableToClaim: string; // сколько доступно для клейма
  vestingDescription: string;
};

export const useGetDataClaimQuery = () => {
  const chainId = useChainId() as ChainId;
  const { address: account } = useAccount();
  const VIDs = [1, 2, 3] as const; // NOTE vesting IDs

  const { data: receiverInfo, ...receiverInfoRest } = useContractRead({
    chainId,
    ...getVestingContract(chainId),
    functionName: "receiverInfo",
    args: account ? [account, VIDs.map((el) => BigInt(el))] : undefined,
    enabled: Boolean(account),
    watch: true,
    select(data) {
      if ("map" in data) {
        return data.map((el) => ({
          amount: BigNumber.from(el.amount),
          released: BigNumber.from(el.released),
          claimed: el.claimed,
        }));
      }
    },
  });

  const { data: releasable, ...releasableRest } = useContractRead({
    chainId,
    ...getVestingContract(chainId),
    functionName: "releasable",
    args: account ? [account, VIDs.map((el) => BigInt(el)), BigInt(NOW_IN_SEC)] : undefined,
    enabled: Boolean(account),
    watch: true,
    select(data) {
      if ("map" in data) {
        return (data as [bigint, bigint, bigint][]).map((el) => ({
          availableToClaim: BigNumber.from(el[0]),
          reward: BigNumber.from(el[2]),
        }));
      }
    },
  });

  const { data: vestingSchedules, ...vestingSchedulesRest } = useContractReads({
    contracts: [
      {
        ...getVestingContract(chainId),
        functionName: "vestingSchedule",
        args: [BigInt(1)],
      },
      {
        ...getVestingContract(chainId),
        functionName: "vestingSchedule",
        args: [BigInt(2)],
      },
      {
        ...getVestingContract(chainId),
        functionName: "vestingSchedule",
        args: [BigInt(3)],
      },
    ] as const,
    enabled: Boolean(account),
    watch: true,
    select(data) {
      if (data.every((el) => el.result)) {
        return data.map(({ result }) => {
          return {
            dates: result!.dates.map((el) => BigNumber.from(el)),
            percent: result!.percent.map((el) => BigNumber.from(el)),
            vestedAmount: BigNumber.from(result!.vestedAmount),
            released: BigNumber.from(result!.released),
            withStaking: result!.withStaking,
          };
        });
      }
    },
  });

  const data = useMemo(() => {
    if (!(vestingSchedules && releasable && receiverInfo)) return;

    const result: VestingData[] = [];
    let totalAmount = BigNumber.from(0);
    let totalReceived = BigNumber.from(0);
    let totalAvailableToClaim = BigNumber.from(0);
    let nextDate = 0;
    for (let i = 0; i < receiverInfo.length; i++) {
      const receiver = receiverInfo[i];
      if (receiver.amount.isZero()) continue;
      const vestingScheduleId = VIDs[i];
      const vesting = vestingSchedules[i];
      const { availableToClaim, reward } = releasable[i];
      const vestingNextDate = findNextDate(vesting.dates, NOW_IN_SEC, receiver.claimed);
      const vestingAmount = receiver.amount.add(reward);
      const receiverVestingData: VestingData = {
        vid: vestingScheduleId,
        roundTitle: VestingType[vestingScheduleId],
        nextClaim: vestingNextDate && vestingNextDate * 1000,
        totalAmount: formatBigNumber(vestingAmount, 5),
        alreadyReceived: formatBigNumber(receiver.released, 5),
        availableToClaim: formatBigNumber(availableToClaim, 5),
        vestingDescription: "Description" + vestingScheduleId,
      };
      result.push(receiverVestingData);

      totalAmount = totalAmount.add(vestingAmount);
      totalReceived = totalReceived.add(receiver.released);
      totalAvailableToClaim = totalAvailableToClaim.add(availableToClaim);
      if (
        !nextDate ||
        (receiverVestingData.nextClaim && receiverVestingData.nextClaim < nextDate)
      ) {
        nextDate = receiverVestingData.nextClaim;
      }
    }
    const totalVestingData: VestingData = {
      vid: 0,
      roundTitle: "",
      nextClaim: nextDate,
      totalAmount: formatBigNumber(totalAmount, 5),
      alreadyReceived: formatBigNumber(totalReceived, 5),
      availableToClaim: formatBigNumber(totalAvailableToClaim, 5),
      vestingDescription: "",
    };

    result.push(totalVestingData);
    return result;
  }, [vestingSchedules, releasable, receiverInfo]);

  return {
    data,
    isLoading:
      vestingSchedulesRest.isLoading || releasableRest.isLoading || receiverInfoRest.isLoading,
    refetch: () => {
      vestingSchedulesRest.refetch();
      releasableRest.refetch();
      receiverInfoRest.refetch();
    },
  };
};
