import { SocialActivitiesForm, SocialActivitiesFormItem } from "app/types/social-activities-form";
import cn from "classnames";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import FileInput from "../file-input/file-input";

type Props = {
  form: SocialActivitiesForm;
  updateValue: (key: keyof SocialActivitiesForm, rawValue?: string) => void;
};

export const SocialActivitiesAdditionalInputList: FC<Props> = ({ form, updateValue }) => {
  const { t } = useTranslation();
  const additionalFieldsGroups = useMemo(() => {
    let additionalFields = Object.entries(form).filter(
      ([key]) => key !== "telegram" && key !== "twitter"
    );

    const additionalFieldsGroups: [string, SocialActivitiesFormItem][][] = [];
    const groupSize = 2;

    for (let i = 0; i < Math.ceil(additionalFields.length / groupSize); i++) {
      const start = i * groupSize;
      const end = i * groupSize + groupSize;
      additionalFieldsGroups[i] = additionalFields.slice(start, end);
    }

    return additionalFieldsGroups;
  }, [form]);

  return (
    <>
      <div className="social-activities__row-title">
        <div className="social-activities__row-title-count">4.</div>
        <div className="social-activities__row-title-text">
          {t("For this IDO we need an additional information. Fill the fields below please.")}
        </div>
      </div>
      {additionalFieldsGroups.map((additionalFields, i) => (
        <div
          key={i}
          className={cn("social-activities__form-group", {
            _margin: i + 1 !== additionalFieldsGroups.length,
          })}
        >
          {additionalFields.map(([key, { value, title }]) => {
            if (key === "file") {
              return (
                <FileInput
                  key={key}
                  title={title}
                  fileName={value}
                  setValue={(file) => updateValue(key, file)}
                  name={key}
                  className={cn("social-activities__form-input", "_file")}
                />
              );
            }

            return (
              <input
                key={key}
                className="social-activities__form-input"
                placeholder={t(title)}
                name={key}
                value={value}
                onChange={({ currentTarget: { value } }) => updateValue(key, value)}
              />
            );
          })}
        </div>
      ))}
    </>
  );
};
