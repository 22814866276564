import Referrer from "app/components/referral-program/referrer";
import { Path } from "app/pages";
import { ReactComponent as BackIcon } from "assets/img/icons/back.svg";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ReferrerPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="page">
      <div className="page__inner">
        <Link className="page__back" to={Path.REFERRAL_PROGRAM}>
          <BackIcon className="page__back-icon" />
          {t("Back")}
        </Link>
        <Referrer />
      </div>
    </div>
  );
};

export default ReferrerPage;
