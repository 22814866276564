import { Project } from "app/types";
import { ReactComponent as ArrowLinkIcon } from "assets/img/arrow-link.svg";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { isIdo } from "sdk";
import "./project-manual.sass";

type Props = {
  project: Project;
};

const ProjectManual: FC<Props> = ({ project }) => {
  const { t } = useTranslation();

  return (
    <div className="project-manual">
      {isIdo(project) && (
        <a
          href="https://gagarin-launchpad.gitbook.io/gagarin/join-idos/ido-rounds"
          target="_blank"
          className="project-manual__link"
        >
          {t("How to participate in IDO?")}
          <ArrowLinkIcon className="project-manual__link-icon" />
        </a>
      )}
      <a
        href={"https://gagarin-launchpad.gitbook.io/gagarin/join-idos/investor-tiers"}
        target="_blank"
        className="project-manual__link"
      >
        {t("tier.HowToGet")}
        <ArrowLinkIcon className="project-manual__link-icon" />
      </a>
      <a href={t("links.tgChat")} target="_blank" className="project-manual__link">
        {t("Join the telegram chat")}
        <ArrowLinkIcon className="project-manual__link-icon" />
      </a>
    </div>
  );
};

export default ProjectManual;
