import cn from "classnames";
import { ButtonHTMLAttributes, CSSProperties, FC, MouseEventHandler } from "react";
import "../button/button.sass";
import CircleLoader from "../loader/circle-loader";

type Props = {
  onClick?: MouseEventHandler;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  style?: CSSProperties;
  variant?: "_blank" | "_filled" | "_dark";
  form?: ButtonHTMLAttributes<HTMLButtonElement>["form"];
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
};

const Button: FC<Props> = ({
  onClick,
  disabled,
  className,
  children,
  isLoading,
  style,
  variant = "_filled",
  form,
  type,
}) => {
  return (
    <button
      type={type}
      form={form}
      disabled={disabled || isLoading}
      onClick={onClick}
      className={cn("button", className, variant, {
        _disabled: disabled || isLoading,
      })}
      style={style}
    >
      <div className="button__inner">
        <CircleLoader isLoading={isLoading} colorType="darker">
          {children}
        </CircleLoader>
      </div>
    </button>
  );
};

export default Button;
