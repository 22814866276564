import Button from "app/components/button/button";
import { useWidth } from "app/hooks";
import ggr from "assets/img/networks/ggr.png";
import line from "assets/img/networks/line.png";
import round from "assets/img/networks/round.png";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import OuterLink from "../outer-link";
import "./networks-coinex.sass";

const NetworksCoinex: FC = () => {
  const { t } = useTranslation();
  const { isMobileWidth } = useWidth();

  return (
    <div className="networks">
      <div className="coinex-page__block">
        <div className="coinex-page__inner">
          {!isMobileWidth && (
            <div className="networks__buy">
              <div className="networks__left">
                <div className="networks__image">
                  <img src={round} alt="" className="networks__image-round" />
                  <div className="networks__image-line">
                    <img src={line} alt="" className="networks__image-line-img" />
                    <img src={ggr} alt="" className="networks__image-ggr" />
                  </div>
                </div>
              </div>
              <div className="networks__col">
                <div className="networks__title">
                  {t("Participate in the private round of GAGARIN and get all the bonuses")}
                </div>
                <div className="networks__button">
                  <a href="http://private.gagarin.world">
                    <Button className="networks__button-btn">{t("Buy GGR")}</Button>
                  </a>
                </div>
                <OuterLink href="https://medium.com/@GAGARIN.World/gagarin-launches-investor-support-program-747d33f6afd2">
                  {t("Learn about the early investor support program")}
                </OuterLink>
              </div>
            </div>
          )}
          {isMobileWidth && (
            <div className="networks__buy">
              <div className="networks__title">
                {t("Participate in the private round of GAGARIN and get all the bonuses")}
              </div>
              <div className="networks__left">
                <div className="networks__image">
                  <img src={round} alt="" className="networks__image-round" />
                  <div className="networks__image-line">
                    <img src={line} alt="" className="networks__image-line-img" />
                    <img src={ggr} alt="" className="networks__image-ggr" />
                  </div>
                </div>
              </div>
              <div className="networks__button">
                <a href="http://private.gagarin.world">
                  <Button>{t("Buy GGR")}</Button>
                </a>
              </div>
              <OuterLink
                className="networks__link"
                href="https://medium.com/@GAGARIN.World/gagarin-launches-investor-support-program-747d33f6afd2"
              >
                {t("Learn about the early investor support program")}
              </OuterLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NetworksCoinex;
