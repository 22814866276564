import { ChainId, ROOT_ADDRESSES_MAP } from "sdk";
import apiService from "services/api.service";
import rootContract from "services/root.contract";
import { useChainId } from "wagmi";
import { useMutation } from "./use-mutation";

export const useReturnProjectTokensMutation = () => {
  const chainId = useChainId() as ChainId;
  const returnProjectTokensMutation = async ({ id, chainId }: { id: string; chainId: ChainId }) => {
    const voucher = await apiService.getReturnTokensMetaTx({ id, chainId });
    return await rootContract.returnProjectTokensPopulate({
      address: ROOT_ADDRESSES_MAP[chainId],
      voucher,
      chainId: chainId,
    });
  };
  return useMutation(returnProjectTokensMutation);
};
