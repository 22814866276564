import { BigNumber } from "ethers";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectIsProjectTimerRunning, selectProjectStage } from "store/slice";
import { useApplication } from "./use-application";
import { ApplicationStatus, useApplicationStatus } from "./use-application-status";
import { useLeftToRaiseAmount } from "./use-left-to-raise";

export enum FcfsStage {
  F1_HAS_NO_APP = 1,
  F2_HAS_APP_NOT_REDEEM,
  F3_HAS_APP_REDEEM,
}

export const useIdoFcfsStage = (ido: Ido) => {
  const { application } = useApplication(ido);
  const projectStage = useSelector(selectProjectStage);
  const isProjectTimerRunning = useSelector(selectIsProjectTimerRunning);
  const applicationStatus = useApplicationStatus(ido);
  const leftToRaiseAmount = useLeftToRaiseAmount(ido);

  return useMemo(() => {
    if (
      !ido.fcfsFree &&
      (applicationStatus === ApplicationStatus.notCreated ||
        applicationStatus === ApplicationStatus.interview ||
        applicationStatus === ApplicationStatus.rejected)
    ) {
      return FcfsStage.F1_HAS_NO_APP;
    }

    const projectFcfsMaxAmount = BigNumber.from(ido.fcfsMaxCount);
    let availableAmount = projectFcfsMaxAmount.sub(application?.fcfsAmount || 0);
    if (availableAmount.lt(0)) {
      availableAmount = BigNumber.from(0);
    }

    if (
      (ido.fcfsFree ||
        applicationStatus === ApplicationStatus.lose ||
        applicationStatus === ApplicationStatus.win ||
        applicationStatus === ApplicationStatus.approved) &&
      !availableAmount.isZero() &&
      !leftToRaiseAmount.isZero()
    ) {
      return FcfsStage.F2_HAS_APP_NOT_REDEEM;
    }

    return FcfsStage.F3_HAS_APP_REDEEM;
  }, [projectStage, applicationStatus, application, ido, isProjectTimerRunning]);
};
