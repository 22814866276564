import { useMarketingActivityContent, useProjectImage } from "app/hooks";
import { FC } from "react";
import { ImageType, MarketingActivity } from "sdk";
import "./marketing-activity-content.sass";

type Props = {
  selectedActivity: MarketingActivity;
};

const MarketingActivityContent: FC<Props> = ({ selectedActivity }) => {
  const { text, title, startDate, endDate } = useMarketingActivityContent(selectedActivity);
  const banner = useProjectImage(ImageType.BANNER, selectedActivity.id);

  return (
    <div className="marketing-activity-content">
      <div className="marketing-activity-content__dates">
        {title} {startDate} {!!selectedActivity.endDate && `- ${endDate}`}
      </div>
      {(banner || selectedActivity.banner) && (
        <img
          className="marketing-activity-content__banner"
          src={banner || selectedActivity.banner}
          alt={title}
        />
      )}
      <div className="marketing-activity-content__content">
        <div className="marketing-activity-content__title">{title}</div>
        <div
          className="marketing-activity-content__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
};

export default MarketingActivityContent;
