import { SelectItem } from "app/types/select-item";
import iEn from "assets/img/locale_en.svg";
import iRu from "assets/img/locale_ru.svg";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "sdk";
import { Selector } from "../selector/selector";

const LANGUAGE_ICON_MAP = {
  [Language.EN]: iEn,
  [Language.RU]: iRu,
};

type Props = {};

const LanguageSelector: FC<Props> = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (selected: SelectItem) => {
    i18n.changeLanguage(selected.value.toString());
  };

  const languagesList = useMemo(() => {
    return Object.values(Language)
      .sort((a) => (a === (i18n.language as Language) ? -1 : 1))
      .map((language) => ({
        value: language,
        icon: LANGUAGE_ICON_MAP[language],
      }));
  }, [i18n.language]);

  return (
    <Selector
      selected={{
        value: i18n.language,
        icon: LANGUAGE_ICON_MAP[i18n.language as Language],
      }}
      list={languagesList}
      onSelect={changeLanguage}
      showSelected
    />
  );
};

export default LanguageSelector;
