import { ProjectStage } from "app/enums";
import { Project } from "app/types";
import { getClaimCurrencies, getExchangeCurrencies } from "app/utils";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { AnyObject, ChainConfig, ChainId, RefundType, getChainsConfig } from "sdk";
import { selectProjectStage } from "store/slice";
import { useChainId, useSwitchNetwork } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import { useAuth } from "./use-auth";
import { useRedeemedByChains } from "./use-redeemed-by-chains";

const chainsConfig = getChainsConfig();
const mapChains = ({ chainId }: AnyObject & { chainId: ChainId }) => chainId;

export const useSupportedChains = (project?: Project) => {
  const chainId = useChainId();
  const isAuth = useAuth();
  const projectStage = useSelector(selectProjectStage);
  const { redeemedByChains } = useRedeemedByChains(project);

  return useMemo(() => {
    let isSupportedChain = false;
    let supportedChains: ChainConfig[] = [];
    let chains: ChainId[] = [];
    let exchangeCurrencies = getExchangeCurrencies(project);
    let claimCurrency = getClaimCurrencies(project);

    if (!exchangeCurrencies.length) {
      return {
        isSupportedChain,
        supportedChains,
      };
    }
    if (project?.refundType === RefundType.RETURN_PROJECT_TOKENS && claimCurrency?.length) {
      chains = [claimCurrency[0].chainId];
    } else if (
      (projectStage === ProjectStage.refund || projectStage === ProjectStage.refundPolicy) &&
      redeemedByChains &&
      redeemedByChains.length
    ) {
      chains = redeemedByChains.map(mapChains);
    } else {
      chains = exchangeCurrencies.map(mapChains);
    }

    supportedChains = chains.reduce((result, chainId) => {
      const foundConfig = chainsConfig.find(({ id }) => id === chainId);
      if (foundConfig) {
        return [...result, foundConfig];
      }
      return result;
    }, [] as ChainConfig[]);
    isSupportedChain = supportedChains
      .map(({ id }) => (id === chainId ? chainId : null))
      .includes(chainId);

    return {
      isSupportedChain,
      supportedChains,
    };
  }, [project, chainId, isAuth, redeemedByChains, projectStage]);
};

export const useChains = (project?: Project) => {
  const isAuth = useAuth();
  const chainId = useChainId();
  const supportedChainsResult = useSupportedChains(project);

  const { chains, ...switchNetwork } = useSwitchNetwork({ throwForSwitchChainNotSupported: true });

  return useMemo(() => {
    const chainsConfig = getChainsConfig();

    const currentChain = chains.find(({ id }) => id === chainId);

    let isCorrectChain = false;
    if (currentChain && project) {
      isCorrectChain = currentChain.id === project.network;
    }

    let isSwitchAvailable = true;
    if (!isAuth) {
      isSwitchAvailable = false;
    }

    return {
      chainId,
      isCorrectChain,
      availableChains: chains,
      chainsConfig,
      currentChain,
      isSwitchAvailable,
      ...supportedChainsResult,
      switchNetwork,
    };
  }, [chainId, project, isAuth, supportedChainsResult, switchNetwork]);
};

export const usePersonalAccountChain = ({ skip }: { skip: boolean }) => {
  const { chainId, switchNetwork } = useChains();
  const requiredChainId =
    process.env.REACT_APP_ENVIRONMENT === "production" ? bsc.id : bscTestnet.id;

  useEffect(() => {
    if (!skip) {
      if (chainId !== requiredChainId) {
        switchNetwork.switchNetwork?.(requiredChainId);
      }
    }
  }, [chainId]);

  return switchNetwork;
};
