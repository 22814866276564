import { LIVE_FILTER, UPCOMING_FILTER } from "app/constants";
import { QueryParam } from "app/enums";
import { useProjectsSwiper, useQuery, useWidth } from "app/hooks";
import { ActiveFilter } from "app/types/projects-filters";
import iFail from "assets/img/icons/fail.svg";
import cn from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Ido } from "sdk";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/scss';
import IdoItem from "../ido/ido-item";
import RocketLoader from "../loader/rocket-loader";
import Switcher from "../switcher/switcher";
import "./project.list.sass";

type Props = {
  title: string;
  projects?: Ido[]
  filters?: ActiveFilter[];
  isLoading: boolean;
  align?: "center" | "left";
  noProjectsMessage?: string;
};

const ProjectList: FC<Props> = ({
  title,
  projects,
  filters,
  isLoading,
  align = "left",
  noProjectsMessage = "",
}) => {
  const { t } = useTranslation();
  const [isFilterUpdated, setIsFilterUpdated] = useState<boolean>(false);
  const { isMobileWidth, isTabletWidth } = useWidth();
  const listRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const { slidesPerView, initialMarginLeft, isSwiper, spaceBetween } = useProjectsSwiper(
    innerRef,
    listRef,
    projects
  );
  const { queryParam, setQueryParam } = useQuery(QueryParam.PROJECT_FILTER);

  useEffect(() => {
    if (isFilterUpdated || !projects) return;

    if (queryParam === LIVE_FILTER && !projects.length) {
      setQueryParam(QueryParam.PROJECT_FILTER, UPCOMING_FILTER);
    } else {
      setIsFilterUpdated(true);
    }
  }, [queryParam, projects, isFilterUpdated]);

  return (
    <div className="project-list" id="project-list">
      <div className="page__inner" ref={innerRef}>
        <div
          className={cn("project-list__title", { "_no-margin": !filters })}
          style={{ textAlign: align }}
        >
          {t(title)}
        </div>
        {filters && (
          <div className="project-list__switcher" style={{ justifyContent: align }}>
            <Switcher filters={filters} />
          </div>
        )}
      </div>
      <div className="page__inner">
        <RocketLoader className="project-list__loader" isLoading={isLoading}>
          <div className="project-list__items" ref={listRef}>
            {!projects?.length ? (
              <div className="project-list__empty">
                <img
                  src={iFail}
                  alt="fail"
                  className="project-list__empty-icon"
                  width={40}
                  height={40}
                />
                {t(noProjectsMessage)}
              </div>
            ) : (
              <>
                {isSwiper ? (
                  <Swiper
                    spaceBetween={spaceBetween}
                    slidesPerView={slidesPerView}
                    centeredSlides={isMobileWidth}
                    className="project-list__swiper"
                  >
                    {projects!.map((project, i) => (
                      <SwiperSlide
                        key={project.id}
                        style={{
                          marginLeft: i ? 0 : initialMarginLeft,
                        }}
                      >
                        <IdoItem className="project-list__item" ido={project} />
                      </SwiperSlide>
                    ))}

                    {isTabletWidth && ( // почему-то в планшетной версии последний слайд не помещается на экран и нельзя прокрутить, это заглушка
                      <SwiperSlide>
                        <div className="project-list__item" />
                      </SwiperSlide>
                    )}
                  </Swiper>
                ) : (
                  <>
                    {projects!.map((project, i) => {
                      return <IdoItem
                        className="project-list__item"
                        key={project.id}
                        ido={project}
                        style={{
                          marginLeft: i ? 0 : initialMarginLeft,
                        }}
                      />
                    }
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </RocketLoader>
      </div>
    </div>
  );
};

export default ProjectList;
