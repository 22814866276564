import { QueryParam } from "app/enums";
import { useMarketingActivitiesList, useMarketingActivity, useQuery } from "app/hooks";
import { Project } from "app/types";
import { ReactComponent as BackIcon } from "assets/img/icons/back.svg";
import cn from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isIdo } from "sdk";
import MarketingActivityButton from "../marketing-activity/marketing-activity-button";
import MarketingActivityFeed from "../marketing-activity/marketing-activity-feed";
import Tabs from "../tabs/tabs";
import Description from "./description";
import "./description-wizard.sass";

export enum DescriptionTab {
  PROJECT_DESC = "Project description",
}

type Props = {
  className?: string;
  project: Project;
};

const DescriptionWizard: FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [selectedTab, setSelectedTab] = useState<string>(DescriptionTab.PROJECT_DESC);
  const { queryParam: queryActivityType, clearQueryParams } = useQuery(
    QueryParam.MARKETING_ACTIVITY_TYPE
  );
  const activities = useMarketingActivitiesList(project.activities);
  const { currentActivity } = useMarketingActivity(queryActivityType, project.activities);

  const onClick = () => {
    clearQueryParams();
  };

  useEffect(() => {
    if (!ref.current || !queryActivityType) return;

    ref.current.scrollIntoView({ behavior: "smooth" });
  }, [ref, queryActivityType]);

  return (
    <div className="description-wizard" ref={ref}>
      <div className="description-wizard__head">
        {!!queryActivityType && (
          <div className={cn("description-wizard__title", "_pointer")} onClick={onClick}>
            <BackIcon className="description-wizard__back" />
            {t("To description")}
          </div>
        )}
        {!queryActivityType &&
          (isIdo(project) ? (
            <div className={cn("description-wizard__title")}>{t(DescriptionTab.PROJECT_DESC)}</div>
          ) : (
            <Tabs
              list={Object.values(DescriptionTab)}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          ))}
        {!!activities.length && (
          <div className="marketing-activity-button__list">
            {activities.map(({ type, id }) => (
              <MarketingActivityButton key={id} activityType={type} activitiesList={activities} />
            ))}
          </div>
        )}
      </div>
      <MarketingActivityFeed selectedActivity={currentActivity} project={project} />
      <Description project={project} selectedTab={selectedTab} />
    </div>
  );
};

export default DescriptionWizard;
