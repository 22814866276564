import { ProjectStage } from "app/enums";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { useRefundApplicationQuery } from "store/api";
import { selectProjectStage } from "store/slice";
import { useChainId } from "wagmi";
import { useApplication } from "./use-application";
import { useAuth } from "./use-auth";

export const useRefundApplication = (project?: Ido) => {
  const isAuth = useAuth();
  const [errorFound, setErrorFound] = useState<boolean>();
  const chainId = useChainId();
  const projectStage = useSelector(selectProjectStage);
  const { application } = useApplication(project);
  const { data, isLoading, refetch, error } = useRefundApplicationQuery(
    { applicationId: application?.id || "", chainId },
    {
      pollingInterval: 10000,
      skip:
        !application ||
        !chainId ||
        errorFound ||
        (projectStage !== ProjectStage.refund && projectStage !== ProjectStage.refundPolicy),
    }
  );

  useEffect(() => {
    if (error) setErrorFound(!!error);
  }, [error]);

  return useMemo(
    () => ({
      refundApplication: data,
      isRefundApplicationLoading: isLoading,
      refetchRefundApplication: refetch,
    }),
    [data, isLoading, refetch, isAuth, project, chainId]
  );
};
