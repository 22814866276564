import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectScreenWidth } from "store/slice";
import { useActions } from "./use-actions";

export const useResize = (): number => {
  const screenWidth = useSelector(selectScreenWidth);
  const { setScreenWidth } = useActions();

  const onResize = (): void => {
    let width = window.innerWidth;
    if (window.innerWidth > window.outerWidth) {
      width = window.outerWidth;
    }
    setScreenWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize, false);

    return () => {
      window.removeEventListener("resize", onResize, false);
    };
  }, []);

  return screenWidth;
};
