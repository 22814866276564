import { ProjectStage } from "app/enums";
import { useRedeemedByChains } from "app/hooks";
import { Project } from "app/types";
import { formatBigNumber, getExchangeCurrencies } from "app/utils";
import cn from "classnames";
import { FC, Fragment, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ChainId, NETWORK_NAMES_MAP, RefundType } from "sdk";
import { selectProjectStage } from "store/slice";

enum TextType {
  DEFAULT = 1,
  NOT_REDEEMED,
  LEFT_TO_REFUND,
}

type Props = {
  project: Project;
};

export const CheckNetworkText: FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const projectStage = useSelector(selectProjectStage);
  const { redeemedNonZeroByChains, redeemedByCurrentChain } = useRedeemedByChains(project);

  const getSymbol = (chainId: ChainId) => {
    const currencies = getExchangeCurrencies(project);
    const currency = currencies.find((currency) => currency.chainId === chainId);
    if (!currency) return "TBA";

    return currency.ticket;
  };

  const textType = useMemo(() => {
    if (project.refundType === RefundType.RETURN_PROJECT_TOKENS) {
      return TextType.DEFAULT;
    }

    if (projectStage !== ProjectStage.refund && projectStage !== ProjectStage.refundPolicy) {
      return TextType.DEFAULT;
    }

    if (!redeemedByCurrentChain) {
      return TextType.NOT_REDEEMED;
    }

    if (redeemedNonZeroByChains && redeemedNonZeroByChains.length) {
      return TextType.LEFT_TO_REFUND;
    }
  }, [projectStage, redeemedNonZeroByChains, redeemedByCurrentChain]);

  return (
    <div className="check-network__text">
      {textType === TextType.DEFAULT && (
        <>
          {t("You are on an unsupported network.")} {t("Switch the network to continue.")}
        </>
      )}

      {textType === TextType.NOT_REDEEMED && (
        <>
          {t("You have not redeemed in this network.")} {t("Switch the network to continue.")}
        </>
      )}

      {textType === TextType.LEFT_TO_REFUND && (
        <>
          {t("You also have")} <br />
          {redeemedNonZeroByChains!.map(({ redeemed, chainId }, i) => {
            const component = (
              <Trans i18nKey="wrong network refund message" key={chainId}>
                {{ redeemed: formatBigNumber(redeemed) }}{" "}
                <span className={cn("check-network__text", "_white")}>
                  {{
                    symbol: getSymbol(chainId),
                  }}
                </span>{" "}
                left in {{ networkName: NETWORK_NAMES_MAP[chainId] }}
              </Trans>
            );

            if (redeemedNonZeroByChains!.length - 1 === i) return component;

            return (
              <Fragment key={chainId}>
                {component}
                <br />
              </Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};
