export enum TierType {
  NONE = "NONE",
  EARTH = "EARTH",
  ORBIT = "ORBIT",
  GALAXY = "GALAXY",
  UNIVERSE = "UNIVERSE",
}

// export enum TierSetType {
//   EARLY = 'EARLY',
//   TGE = 'TGE'
// }
