import Button from "app/components/button/button";
import { useActions } from "app/hooks";
import cn from "classnames";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDisconnect } from "wagmi";
import "./refund-confirm-modal.sass";

type Props = {
  className?: string;
};

const PendingSignLoginMessageModal: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { closeModal } = useActions();
  const onRetryClick = () => window.location.reload()
  const { disconnect } = useDisconnect()

  const onCancelClick = () => {
    disconnect()
    closeModal()
  }
  return (
    <div className={cn(className, "refund-confirm-modal")}>
      <div className="refund-confirm-modal__title">
        <Trans i18nKey="signLoginMessageModalTitle">
          <span className="_g-text-active-lighter">Wallet</span> connect
        </Trans>
      </div>
      <div className="refund-confirm-modal__desc">
        {t('signLoginMessageModalBody')}
      </div>
      <div className="refund-confirm-modal__buttons">
        <Button
          className="refund-confirm-modal__button"
          onClick={onCancelClick}
          variant="_blank"
        >
          {t("cancel")}
        </Button>
        <Button className="refund-confirm-modal__button" onClick={onRetryClick}>
          {t("retry")}
        </Button>
      </div>
    </div>
  );
};

export default PendingSignLoginMessageModal;
