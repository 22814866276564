import Button from "app/components/button/button";
import { PAYMENTS_OVER } from "app/constants";
import { ProjectStage } from "app/enums";
import {
  useBlockchainProject,
  useClaimCurrencySymbol,
  useClaimMutation,
  useIdoRedeemedTokensAmountView,
  useInvestorInfo,
  useNextClaimByProject
} from "app/hooks";
import { formatTimerNumber } from "app/utils";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectProject } from "store/slice";

type Props = {
  ido: Ido;
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
};

const IdoClaim__Button: FC<Props> = ({ ido, seconds, minutes, hours, days }) => {
  const { t } = useTranslation();
  const { projectStage, isProjectTimerRunning } = useSelector(selectProject);
  const [claimProject, { isLoading: isClaimLoading }] = useClaimMutation();

  const { investorInfo, refetchInvestorInfo, isInvestorInfoLoading } = useInvestorInfo(ido);
  const { blockchainProject } = useBlockchainProject(ido);
  const redeemedTokensAmountView = useIdoRedeemedTokensAmountView(ido);
  const claimCurrencySymbol = useClaimCurrencySymbol(ido);
  const { nextClaimView } = useNextClaimByProject(ido);

  const claim = useCallback(async () => {
    await claimProject(ido);
    refetchInvestorInfo();
  }, [ido]);

  const disabled = useMemo(() => {
    return (
      projectStage === ProjectStage.claimGap ||
      !investorInfo ||
      !investorInfo.isAvailableToClaim ||
      investorInfo.availableToClaim.isZero() ||
      !blockchainProject ||
      !blockchainProject.isActive
    );
  }, [investorInfo, projectStage, isProjectTimerRunning, blockchainProject]);

  const buttonText = useMemo(() => {
    if (nextClaimView === PAYMENTS_OVER && disabled) {
      return t(PAYMENTS_OVER);
    }

    if (!disabled) {
      return t("ClaimAction");
    }

    if (!days && !hours && (minutes || seconds)) {
      return `${t("Next")} ${t("after")} ${formatTimerNumber(minutes)}:${formatTimerNumber(
        seconds
      )}`;
    }

    return `${t("Next")} — ${nextClaimView}`;
  }, [nextClaimView, disabled, days, hours, minutes, seconds]);

  return (
    <>
      <Button
        onClick={claim}
        disabled={disabled}
        isLoading={isInvestorInfoLoading || isClaimLoading}
      >
        {buttonText}
      </Button>
      <div className="ido-claim__amount">
        {t("Total amount")}: {redeemedTokensAmountView} {claimCurrencySymbol}
      </div>
    </>
  );
};

export default IdoClaim__Button;
