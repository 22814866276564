import { BigNumber } from "ethers";
import { useMemo } from "react";
import { Ido } from "sdk";

/**
 * @returns Exchange Currency value
 */
export const getLeftToRaiseAmount = (project?: Ido): BigNumber => {
  if (!project) return BigNumber.from(1); // на всякий случай
  const totalRaise = BigNumber.from(project.totalRaise || "0");
  const invested = BigNumber.from(project.invested || "0");

  return totalRaise.sub(invested);
};

/**
 * @returns Exchange Currency value
 */
export const useLeftToRaiseAmount = (project?: Ido): BigNumber => {
  return useMemo(() => getLeftToRaiseAmount(project), [project]);
};
