import { useWidth } from "app/hooks";
import { ReactComponent as DsIcon } from "assets/img/discord-negative.svg";
import iLogoWhite from "assets/img/Gagarin-logo.svg";
import { ReactComponent as MdIcon } from "assets/img/medium-negative.svg";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
// import iPD from "assets/img/Pitchdeck.svg";
import { ReactComponent as TgDotIcon } from "assets/img/telegram-negative-n.svg";
import { ReactComponent as TgIcon } from "assets/img/telegram-negative.svg";
import { ReactComponent as TwIcon } from "assets/img/twitter-negative.svg";
import { ReactComponent as WPIcon } from "assets/img/WhitePaper.svg";
import "./footer.sass";

type Props = {};

const Footer: FC<Props> = () => {
  const { t } = useTranslation();
  const { isDesktopWidth } = useWidth();

  return (
    <div className="footer">
      <div className="footer__inner">
        <div className="footer__column">
          <a className="footer__logo" href="https://gagarin.world">
            <img className="footer__logo-icon" src={iLogoWhite} alt="gagarin" />
          </a>
          <div className="footer__copyright">
            Developed by{" "}
            <a className="footer__copyright-link" href="https://rsquad.io/" target="_blank">
              RSquad
            </a>
          </div>
        </div>
        <div className="footer__block">
          <div className="footer__menu">
            <div className="footer__column-group">
              <div className="footer__column">
                <a
                  className="footer__menu-item"
                  href="https://docs.google.com/forms/u/1/d/e/1FAIpQLSdwpbewPKWrRxxDcYjG2VjmI4S7tkAh-Ho7O18tkCuVvcahSg/viewform?usp=send_form"
                  target="_blank"
                >
                  {t("Apply for IDO")}
                </a>
                <a
                  className="footer__menu-item"
                  href="https://docs.google.com/forms/d/e/1FAIpQLScsSy_3HCxMID415WgRXbP2WFvzL_qkPX4n_w80VHENHngCLg/viewform"
                  target="_blank"
                >
                  <Trans i18nKey="Apply for Incubation">
                    Apply {!isDesktopWidth && <br />} for Incubation
                  </Trans>
                </a>
              </div>
              <div className="footer__column">
                <a
                  className="footer__menu-item"
                  href="https://gagarin.world/GGR_Terms_of_Use_v01.pdf"
                  target="_blank"
                >
                  {t("Terms of Services")}
                </a>
                <a
                  className="footer__menu-item"
                  href="https://gagarin.world/GGR_Privacy_Policy_v01.pdf"
                  target="_blank"
                >
                  {t("Privacy Policy")}
                </a>
              </div>
            </div>
            <div className="footer__column-group">
              <div className="footer__column">
                {/* <a
                  className="footer__menu-item"
                  href="https://docsend.com/view/fbjwnxhsnnkdxfa5/d/pr5ui6b85bpg3yqj"
                  target="_blank"
                >
                  <img
                    className="footer__menu-icon"
                    src={iPD}
                    alt="PitchDeck"
                    width={14}
                    height={14}
                  />
                  PitchDeck
                </a> */}
                <a className="footer__menu-item" href="https://gagarin.world/#projects">
                  {t("Projects")}
                </a>
                <a className="footer__menu-item" href={t("links.whitepaper")} target="_blank">
                  <WPIcon className="footer__menu-icon" width={14} height={14} />
                  Whitepaper
                </a>
              </div>
              <div className="footer__column">
                <a
                  className="footer__menu-item"
                  href="https://gagarin-launchpad.gitbook.io/gagarin/gagarin-overview/what-is-the-gagarin-ecosystem"
                  target="_blank"
                >
                  {t("Documentation")}
                </a>
                <a
                  className="footer__menu-item"
                  // href="https://gagarin.world/#projects"
                >
                  {/* {t("Projects")} */}
                </a>
              </div>
            </div>
          </div>
          <div className="footer__column">
            <div className="footer__social">
              <a className="footer__social-item" href={t("links.tgChat")} target="_blank">
                <TgIcon className="footer__social-item-icon" />
              </a>
              <a
                className="footer__social-item"
                href="https://t.me/gagarin_launchpad"
                target="_blank"
              >
                <TgDotIcon className="footer__social-item-icon" />
              </a>
              <a
                className="footer__social-item"
                href="https://twitter.com/GAGARIN_World"
                target="_blank"
              >
                <TwIcon className="footer__social-item-icon" />
              </a>
              <a
                className="footer__social-item"
                href="https://medium.com/@GAGARIN.World"
                target="_blank"
              >
                <MdIcon className="footer__social-item-icon" />
              </a>
              <a
                className="footer__social-item"
                href="https://discord.gg/6FPvg5RDbc"
                target="_blank"
              >
                <DsIcon className="footer__social-item-icon" />
              </a>
            </div>
            <a className="footer__email" href="mailto:yuri@gagarin.world">
              yuri@gagarin.world
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
