import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { idoApi, projectApi, referrerApi, userApi } from "./api";
import { tonApi } from "./api/ton.api";
import { kycSlice, layoutSlice, projectSlice, referrerPageSlice, walletTypeSlice } from "./slice";

export const store = configureStore({
  reducer: {
    kyc: kycSlice.reducer,
    project: projectSlice.reducer,
    layout: layoutSlice.reducer,
    referrerPage: referrerPageSlice.reducer,
    [idoApi.reducerPath]: idoApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [referrerApi.reducerPath]: referrerApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [tonApi.reducerPath]: tonApi.reducer,
    walletType: walletTypeSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(idoApi.middleware)
      .concat(userApi.middleware)
      .concat(referrerApi.middleware)
      .concat(projectApi.middleware)
      .concat(tonApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch);
