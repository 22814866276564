import CheckAuth from "app/components/check-auth";
import { HighTierFcfsStage, useIdoHighTierFcfsStage } from "app/hooks/use-high-tier-fcfs-stage";
import cn from "classnames";
import { FC } from "react";
import { Ido } from "sdk";
import { CheckNetwork } from "../../project/check-network/check-network";
import ProjectLose from "../../project/project-lose";
import ProjectSwap__Success from "../../project/project-swap/project-swap__success";
import IdoFcfs__Body from "./ido-fcfs__body";
import IdoFcfs__Buttons from "./ido-fcfs__buttons";
import IdoFcfs__Success from "./ido-fcfs__sucess";

type Props = {
  className?: string;
  ido: Ido;
};

const IdoFcfsHighTier: FC<Props> = ({ className = "", ido }) => {
  const highTierFcfsStage = useIdoHighTierFcfsStage(ido);

  return (
    <div className={cn("project-swap", className)}>
      <div className="project-swap__title">High Tier FCFS</div>
      <CheckAuth className="project-swap__connect">
        <CheckNetwork project={ido} className="project-swap__network">
          {highTierFcfsStage === HighTierFcfsStage.F1_HAS_NO_APP && <ProjectLose type="high-tier-only" />}

          {highTierFcfsStage === HighTierFcfsStage.F2_HAS_APP_NOT_REDEEM && (
            <>
              <ProjectSwap__Success project={ido} />
              <IdoFcfs__Body ido={ido} />
              <IdoFcfs__Buttons ido={ido} />
            </>
          )}

          {highTierFcfsStage === HighTierFcfsStage.F3_HAS_APP_REDEEM && <IdoFcfs__Success ido={ido} />}
        </CheckNetwork>
      </CheckAuth>
    </div>
  );
};

export default IdoFcfsHighTier;
