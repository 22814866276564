import { useAuth, useWidth } from "app/hooks";
import { Path } from "app/pages";
import iLogo from "assets/img/gagarin-logo.png";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./header.sass";
import LanguageSelector from "./language-selector";
import { Menu } from "./menu";
import Wallet from "./wallet";
import WalletTon from "./wallet-ton";

type Props = {};

const Header: FC<Props> = () => {
  const isAuth = useAuth();
  const { isDesktopWidth } = useWidth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsOpen((state) => !state);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <div className="header">
      <div className="header__inner">
        <Link to={Path.MAIN}>
          <img src={iLogo} className="header__logo" />
        </Link>
        <Menu isOpen={isOpen} closeMenu={() => setIsOpen(false)} />
        <div className="header__actions">
          <LanguageSelector />
          {/* {isAuth && <ChainSelector />} */}
          {/* <TonConnectButton /> */}

          <div className={cn("max-[1450px]:hidden", "flex items-center justify-center gap-4")}>
            <Wallet />
            <WalletTon />
          </div>

          <div className={cn("max-[1450px]:flex", "header__btn hidden")} onClick={toggleMenu}>
            <div className={cn("header__btn-row", { _opened: isOpen })} />
            <div className={cn("header__btn-row", { _opened: isOpen })} />
            <div className={cn("header__btn-row", { _opened: isOpen })} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
