import Button from "app/components/button/button";
import {
  useAllowance,
  useApplication,
  useApproveMutation,
  useBalance,
  useExchangeCurrency,
  useRedeemIdoMutation,
  useSwapAmount
} from "app/hooks";
import { BigNumber } from "ethers";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChainId, Ido, ROOT_ADDRESSES_MAP } from "sdk";
import { useSyncIdoRedeemedMutation } from "store/api";
import { useAccount, useChainId } from "wagmi";

type Props = {
  className?: string;
  project: Ido;
};

const ProjectSwap__Buttons: FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const { address: account } = useAccount()
  const chainId = useChainId() as ChainId
  const [redeem, { isLoading: isRedeemLoading }] = useRedeemIdoMutation();
  const [approve, { isLoading: isApproveLoading }] = useApproveMutation();
  const { application, refetchApplication, isApplicationLoading } = useApplication(project);
  const [syncRedeemed, { isLoading: isSyncRedeemedLoading }] = useSyncIdoRedeemedMutation();
  const { exchangeCurrencySymbol, currentExchangeCurrency } = useExchangeCurrency(project);
  const { allowance, isAllowanceLoading, refetchAllowance } = useAllowance(
    currentExchangeCurrency?.address,
    currentExchangeCurrency?.decimals
  );
  const { minSwapAmount, maxSwapAmount, swapInputAmount } = useSwapAmount(project);


  const onApproveClick = async () => {
    if (!currentExchangeCurrency) return;

    await approve({
      address: currentExchangeCurrency.address,
      spender: ROOT_ADDRESSES_MAP[chainId],
    });
    refetchAllowance();
    refetchApplication();
  };
  const onSwapClick = async () => {
    await redeem({
      id: project.id,
      amount: swapInputAmount,
      chainId,
      address: ROOT_ADDRESSES_MAP[chainId],
    });
    await syncRedeemed(project.id);
    refetchApplication();
  };

  const { balance, isBalanceLoading } = useBalance(
    currentExchangeCurrency?.address,
    currentExchangeCurrency?.decimals
  );

  const disabled = useMemo(() => {
    if (
      currentExchangeCurrency &&
      application &&
      !swapInputAmount.isZero() &&
      application.swappedAmount.lt(application.amount) &&
      allowance &&
      (allowance.gte(application.amount) || allowance.gte(swapInputAmount)) &&
      balance.gte(swapInputAmount) &&
      swapInputAmount.lte(maxSwapAmount) &&
      (!application.swappedAmount.isZero() ||
        (application.swappedAmount.isZero() && swapInputAmount.gte(minSwapAmount)))
    ) {
      return false;
    }
    return true;
  }, [
    currentExchangeCurrency,
    application,
    balance,
    account,
    allowance,
    swapInputAmount,
    maxSwapAmount,
  ]);

  const isLoading = useMemo(() => {
    if (
      isRedeemLoading ||
      isApproveLoading ||
      isApplicationLoading ||
      isAllowanceLoading ||
      isBalanceLoading ||
      isSyncRedeemedLoading
    ) {
      return true;
    }
    return false;
  }, [
    isRedeemLoading,
    isApproveLoading,
    isApplicationLoading,
    isAllowanceLoading,
    isBalanceLoading,
    isSyncRedeemedLoading,
  ]);

  useEffect(() => {
    syncRedeemed(project.id);
  }, []);

  const isApproveRequired = useMemo(() => {
    if (!allowance) return true
    if (swapInputAmount) {
      return allowance.lt(swapInputAmount)
    }
    return allowance.lte(application?.amount || BigNumber.from(0))
  }, [allowance, swapInputAmount, application?.amount])


  return (
    <div className="project-swap__buttons">
      {isApproveRequired ? (
        <Button onClick={onApproveClick} isLoading={isLoading} disabled={!currentExchangeCurrency}>
          {t("Approve")}
        </Button>
      ) : (
        <Button onClick={onSwapClick} isLoading={isLoading} disabled={disabled}>
          {balance.gte(swapInputAmount)
            ? t("swapButton")
            : t("Insufficient balance", { symbol: exchangeCurrencySymbol })}
        </Button>
      )}
    </div>
  );
};

export default ProjectSwap__Buttons;
