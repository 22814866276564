import CheckAuth from "app/components/check-auth";
import RocketLoader from "app/components/loader/rocket-loader";
import {
  RefundStage,
  useApplication,
  useInvestorInfo,
  useRedeemedByChains,
  useRefundStage
} from "app/hooks";
import cn from "classnames";
import { FC } from "react";
import { Ido } from "sdk";
import { CheckNetwork } from "../check-network/check-network";
import ProjectLose from "../project-lose";
import "./project-refund.sass";
import ProjectRefund__Body from "./project-refund__body";
import ProjectRefund__Success from "./project-refund__success";

type Props = {
  className?: string;
  project: Ido;
};

const ProjectRefund: FC<Props> = ({ className, project }) => {
  const refundStage = useRefundStage(project);
  const { isInvestorInfoLoading } = useInvestorInfo(project);
  const { isApplicationLoading } = useApplication(project);
  const { isRedeemedByChainsLoading } = useRedeemedByChains(project);

  return (
    <CheckAuth>
      <RocketLoader
        className="project-refund__loader"
        isLoading={isApplicationLoading || isInvestorInfoLoading || isRedeemedByChainsLoading}
      >
        <CheckNetwork className="project-refund__network" project={project}>
          <div className={cn("project-refund", className)}>
            {refundStage === RefundStage.R1_NO_APP && (
              <>
                <ProjectLose type="no-application" />
              </>
            )}

            {refundStage === RefundStage.R2_HAS_APP_NOT_REDEEM && (
              <>
                <ProjectLose type="not-redeemed_ido" />
              </>
            )}

            {refundStage === RefundStage.R5_REFUNDED && (
              <ProjectRefund__Success project={project} />
            )}

            {refundStage === RefundStage.R4_HAS_APP_REDEEM_SPECIAL && (
              <>
                <ProjectRefund__Success project={project} />
              </>
            )}
            {refundStage === RefundStage.R3_HAS_APP_REDEEM && (
              <ProjectRefund__Body project={project} />
            )}
            {refundStage === RefundStage.R6_AVAILABLE_TO_RETURN_TOKENS && (
              <ProjectRefund__Body project={project} />
            )}
            {refundStage === RefundStage.R7_PROJECT_TOKENS_RETURNED && (
              <ProjectRefund__Success project={project} />
            )}
            {refundStage === RefundStage.R8_RETURN_TOKENS_END_TIME && (
              <ProjectRefund__Success project={project} />
            )}
          </div>
        </CheckNetwork>
      </RocketLoader>
    </CheckAuth>
  );
};

export default ProjectRefund;
