import { BigNumber } from "ethers";
import { useMemo } from "react";
import { Ido, parseDecimals } from "sdk";
import { formatEther } from "viem";
import { formatBigNumber, getTokensAmount } from "../utils";
import { useBalance } from "./use-balance";
import { useInvestorInfo } from "./use-investor-info";

/**
 * @returns Claim Currency value
 */
export const useTotalRaiseTokensAmount = (project?: Ido): string => {
  return useMemo(() => {
    if (!project || !project.totalRaise || !project.exchangeRate) return "TBA";

    const totalRaise = BigNumber.from(project.totalRaise);
    const totalRaiseTokensAmount = getTokensAmount(project.exchangeRate, totalRaise);

    return formatBigNumber(totalRaiseTokensAmount);
  }, [project]);
};

/**
 * @returns Claim Currency value
 */
export const useInvestedTokensAmount = (project?: Ido): string => {
  return useMemo(() => {
    if (!project || !project.invested || !project.exchangeRate) return "0";

    const totalInvested = BigNumber.from(project.invested);
    const totalInvestedTokensAmount = getTokensAmount(project.exchangeRate, totalInvested);

    return formatBigNumber(totalInvestedTokensAmount);
  }, [project]);
};

/**
 * @returns Exchange rate or "TBA"
 */
export const useExchangeRate = (project?: Ido, length?: number | 'auto'): string => {
  length = length || 5
  return useMemo(() => {
    if (!project || !project.exchangeRate) return "TBA";

    if (length === 'auto') {
      return formatEther(BigInt(project.exchangeRate))
    }

    return formatBigNumber(project.exchangeRate, length);
  }, [project]);
};

/**
 * @returns Claim Currency value
 */
export const useAvailableToClaimTokens = (project: Ido): string => {
  const { investorInfo } = useInvestorInfo(project);

  return useMemo(() => {
    if (!investorInfo) return "0";

    return formatBigNumber(investorInfo.availableToClaim);
  }, [investorInfo]);
};

/**
 * @returns Claim Currency value
 */
export const useClaimedTokensAmount = (project: Ido) => {
  const { investorInfo } = useInvestorInfo(project);

  return useMemo(() => {
    if (!investorInfo)
      return {
        claimedTokensAmount: BigNumber.from(0),
        claimedTokensAmountView: "0",
      };

    return {
      claimedTokensAmount: investorInfo.claimed,
      claimedTokensAmountView: formatBigNumber(investorInfo.claimed),
    };
  }, [investorInfo]);
};

export const useRefundTokensAmount = (project: Ido) => {
  const { balance, isBalanceLoading, refetchBalance } = useBalance(
    project.claimCurrency?.[0].address,
    project.claimCurrency?.[0].decimals
  );

  const { claimedTokensAmount } = useClaimedTokensAmount(project);

  return useMemo(() => {
    if (!balance) {
      return {
        refundTokensAmount: BigNumber.from(0),
        refundTokensAmountView: "0",
        isRefundTokensAmountLoading: isBalanceLoading,
        refetchClaimTokenBalance: refetchBalance,
      };
    }

    const formattedBalance = parseDecimals(balance, project.claimCurrency?.[0].decimals);
    let refundTokensAmount = formattedBalance;
    let refundTokensAmountView = formatBigNumber(formattedBalance, 0);

    if (refundTokensAmount.gt(claimedTokensAmount)) {
      refundTokensAmount = claimedTokensAmount;
      refundTokensAmountView = formatBigNumber(claimedTokensAmount, 0);
    }

    return {
      refundTokensAmount,
      refundTokensAmountView,
      isRefundTokensAmountLoading: isBalanceLoading,
      refetchClaimTokenBalance: refetchBalance,
    };
  }, [project, balance, isBalanceLoading, claimedTokensAmount]);
};
