import cn from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ChainId } from "sdk";
import { ProjectStatusTag } from "./ido/ido-tags.list";
import "./project-tag.sass";

type Props = {
  status: ProjectStatusTag | ChainId | string;
};

const ProjectTag: FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <div className={cn("project-tag", `_${status.toString().toLowerCase()}`)}>
      <h2 className="project-tag__value">{t(`tags.${status.toString().toLowerCase()}`)}</h2>
    </div>
  );
};

export default ProjectTag;
