import { Project } from "app/types";
import { BigNumber } from "ethers";
import { useMemo } from "react";
import { Ido } from "sdk";
import { formatBigNumber } from "../utils";
import { useRedeemedByChains } from "./use-redeemed-by-chains";
import { useRefundApplication } from "./use-refund-application";

/**
 * @returns {string} Exchange Currency or "TBA"
 */
export const useTotalRaiseAmount = (project?: Ido): string => {
  return useMemo(() => {
    if (!project || !project.totalRaise) return "TBA";

    return formatBigNumber(project.totalRaise);
  }, [project]);
};

/**
 * @returns Exchange Currency value
 */
export const useInvestedAmount = (project?: Project): string => {
  return useMemo(() => {
    if (!project || !project.invested) return "0";

    return formatBigNumber(project.invested);
  }, [project]);
};

/**
 * @returns BUSD/USDT value
 */
export const useRefundedAmount = (project?: Ido) => {
  const { refundApplication } = useRefundApplication(project);
  return useMemo(() => {
    let refundedAmountView = "0";
    let refundedAmount = BigNumber.from(0);

    if (refundApplication && refundApplication.refundedAmount) {
      refundedAmountView = formatBigNumber(refundApplication.refundedAmount);
      refundedAmount = BigNumber.from(refundApplication.refundedAmount);
    }

    return { refundedAmountView, refundedAmount };
  }, [refundApplication, project]);
};

/**
 * @returns BUSD/USDT value
 */
export const useRefundAmount = (project: Ido) => {
  const { redeemedByCurrentChain } = useRedeemedByChains(project);

  return useMemo(() => {
    let refundAmount = BigNumber.from(0);
    let refundAmountView = "0";

    if (redeemedByCurrentChain) {
      refundAmount = redeemedByCurrentChain.redeemed;
      refundAmountView = formatBigNumber(refundAmount);
    }

    return {
      refundAmount,
      refundAmountView,
    };
  }, [redeemedByCurrentChain, project]);
};
