import { useMarketingActivitiesList } from "app/hooks";
import { Project } from "app/types";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ProjectItemMarketingActivity } from "./project-item-marketing-activity";

type Props = {
  project: Project;
};

export const ProjectItemMarketingActivityList: FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const activities = useMarketingActivitiesList(project.activities);

  return (
    <div className="project-item-activity__list">
      {activities.length ? (
        activities.map(({ type, id }) => (
          <ProjectItemMarketingActivity key={id} activityType={type} project={project} />
        ))
      ) : (
        <div className="project-item-activity__message">
          <p>{t("This project has no activities yet")}</p>
        </div>
      )}
    </div>
  );
};
