import cn from "classnames";
import { useTranslation } from "react-i18next";
import loader from "../../../../assets/img/common/loader.png";
type TProps = {
  className?: string;
};

const Loader: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={cn("flex flex-col items-center justify-center", className)}>
      <img className="mb-2 animate-spin" src={loader} alt="" width={24} height={24} />
      <div className="p3 text-text_primary">{t("Wait a bit...")}</div>
    </div>
  );
};

export default Loader;
