import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { KycStatus } from "app/enums";
import axios from "axios";
import { RootState } from "..";

interface KYCStatusState {
  status: KycStatus;
}

const initialState: KYCStatusState = {
  status: KycStatus.Idle,
};

export const getKycStatus = createAsyncThunk<KycStatus, string | undefined>(
  "kyc/getStatus",
  async (walletAddress: string = "0x97F88e0f01b24Ec91E468d631675D69Fa41E31d2") => {
    try {
      const { data } = await axios.get(
        "https://kyc.blockpass.org/kyc/1.0/connect/gagarin_launchpad_077b3/refId/" + walletAddress,
        {
          headers: {
            Authorization: "cbd17356a95f97d798eadbfe2345a9c1",
          },
        }
      );

      switch (data.data.status) {
        case "approved":
          return KycStatus.Success;
        case "waiting":
        case "inreview":
          return KycStatus.Pending;
        case "resubmit":
        case "blocked":
          return KycStatus.Reject;
        default:
          return KycStatus.Idle;
      }
    } catch (error) {
      console.error("getKYCStatus err: ", error);
      // return 'empty'
      return KycStatus.Idle;
    }
  }
);

export const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    setKYCStatus(state, { payload }) {
      state.status = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKycStatus.fulfilled, (state, action) => ({
      status: action.payload,
    }));
  },
});

export const selectKYCStatus = (state: RootState) => state.kyc.status;
