import { FC, useMemo } from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import { useSelector } from "react-redux";
import { selectItemsPerPage } from "store/slice";
import "./pagination.sass";

type Props = {
  itemsCount?: number;
  onPageChange: ReactPaginateProps["onPageChange"];
  className?: string;
};

const Pagination: FC<Props> = ({ itemsCount = 0, onPageChange, className }) => {
  const itemsPerPage = useSelector(selectItemsPerPage);
  const pageCount = useMemo(() => Math.ceil(itemsCount / itemsPerPage), [itemsCount, itemsPerPage]);

  return (
    <>
      {pageCount > 1 && (
        <div className={className}>
          <ReactPaginate
            pageCount={pageCount}
            containerClassName="pagination"
            pageLinkClassName="pagination__page-link"
            activeClassName="_active"
            activeLinkClassName="_active"
            breakLinkClassName="pagination__break-link"
            previousLinkClassName="pagination__arrow-link"
            nextLinkClassName="pagination__arrow-link"
            disabledClassName="_disabled"
            disabledLinkClassName="_disabled"
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            onPageChange={onPageChange}
          />
        </div>
      )}
    </>
  );
};

export default Pagination;
