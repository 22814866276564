import { Contract } from "ethers";
import { ChainId } from "sdk";

export class ContractService {
  private _contracts: Map<ChainId, { [address: string]: Contract }> = new Map();

  constructor(public abi: any) {
    Object.values(ChainId).forEach((chainId) => this._contracts.set(+chainId, {}));
  }

  protected _getContract<T = Contract>(address: string, chainId: ChainId) {
    const contract = this._contracts.get(chainId)?.[address];
    if (!contract) {
      return this._registerContract<T>(address, chainId);
    }

    return contract as unknown as T;
  }

  private _registerContract<T = Contract>(address: string, chainId: ChainId) {
    const contract = new Contract(address, this.abi);
    this._contracts.set(chainId, { [address]: contract });

    return contract as unknown as T;
  }
}
