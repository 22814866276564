import { getStakingContract } from "app/utils/contract-helper";
import { BigNumber } from "ethers";
import { ChainId } from "sdk";
import { HexString } from "sdk/src/types/hex-string";
import { useChainId, useContractRead } from "wagmi";

export const useGetPoolQuery = ({ pid }: { pid?: HexString }) => {
  const chainId = useChainId() as ChainId;
  const poolQuery = useContractRead({
    chainId,
    ...getStakingContract(chainId),
    functionName: "getPool",
    enabled: Boolean(pid),
    args: pid && +pid ? [BigInt(pid)] : undefined,
    watch: true,
    select(data) {
      return {
        pid: BigNumber.from(data.pid),
        staked: BigNumber.from(data.staked),
        paidReward: BigNumber.from(data.paidReward),
        lockPeriod: BigNumber.from(data.lockPeriod),
        alpPerGgr: BigNumber.from(data.alpPerGgr),
        emergencyWithdrawFee: BigNumber.from(data.emergencyWithdrawFee),
        apr: BigNumber.from(data.apr),
        isActive: data.isActive,
      };
    },
  });
  return poolQuery;
};
