import { useMemo } from "react";
import { ChainId, parseDecimals, ROOT_ADDRESSES_MAP } from "sdk";
import { HexString } from "sdk/src/types/hex-string";
import { useChainId } from "wagmi";
import { useAllowanceQuery } from ".";

export const useAllowance = (token: string = "", decimals?: number, spender?: string) => {
  const chainId = useChainId() as ChainId;
  const { data, isLoading, refetch } = useAllowanceQuery({
    token: token as HexString,
    spender: (spender || ROOT_ADDRESSES_MAP[chainId]) as HexString,
  });

  return useMemo(
    () => ({
      allowance: parseDecimals(data, decimals),
      isAllowanceLoading: isLoading,
      refetchAllowance: refetch,
    }),
    [data, refetch, decimals, token, spender, chainId]
  );
};
