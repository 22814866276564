export default [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
    ],
    name: "CreateProject",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "contract IERC20UpgradeableWithDecimals",
            name: "token",
            type: "address",
          },
          {
            internalType: "contract IERC20UpgradeableWithDecimals",
            name: "claimToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256[]",
            name: "claimDates",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "claimPercent",
            type: "uint256[]",
          },
        ],
        indexed: false,
        internalType: "struct RootV2.ProjectProps",
        name: "newProps",
        type: "tuple",
      },
    ],
    name: "ProjectPropsChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum VoucherV2.RedeemType",
        name: "redeemType",
        type: "uint8",
      },
    ],
    name: "RedeemIdo",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum VoucherV2.RedeemType",
        name: "redeemType",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "boxIDs",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "boxCounts",
        type: "uint256[]",
      },
    ],
    name: "RedeemIno",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "investor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "enum VoucherV2.RefundType",
        name: "refundType",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "enum VoucherV2.OfferingType",
        name: "offeringType",
        type: "uint8",
      },
    ],
    name: "Refund",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "voucherSigner",
        type: "address",
      },
    ],
    name: "__RootV1_init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "addrs",
        type: "address[]",
      },
    ],
    name: "addAdmins",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "users",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        internalType: "enum VoucherV2.RedeemType[]",
        name: "redeemTypes",
        type: "uint8[]",
      },
    ],
    name: "addIdoBalance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "investors",
        type: "address[]",
      },
    ],
    name: "airDrop",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "investorAddr",
        type: "address",
      },
    ],
    name: "cancelParticipation",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "removedInvestors",
        type: "address[]",
      },
      {
        components: [
          {
            internalType: "address",
            name: "addr",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "redeemed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "claimed",
            type: "uint256",
          },
        ],
        internalType: "struct RootV2.InvestorDataWithAddress[]",
        name: "investors",
        type: "tuple[]",
      },
    ],
    name: "changeProject",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "contract IERC20UpgradeableWithDecimals",
            name: "token",
            type: "address",
          },
          {
            internalType: "contract IERC20UpgradeableWithDecimals",
            name: "claimToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256[]",
            name: "claimDates",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "claimPercent",
            type: "uint256[]",
          },
        ],
        internalType: "struct RootV2.ProjectProps",
        name: "newProps",
        type: "tuple",
      },
    ],
    name: "changeProjectProps",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
    ],
    name: "claimProject",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20UpgradeableWithDecimals",
            name: "token",
            type: "address",
          },
          {
            internalType: "contract IERC20UpgradeableWithDecimals",
            name: "claimToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256[]",
            name: "claimDates",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "claimPercent",
            type: "uint256[]",
          },
        ],
        internalType: "struct RootV2.ProjectProps",
        name: "props",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "offchainId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "addrs",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "redeemed",
        type: "uint256[]",
      },
    ],
    name: "createProject",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20UpgradeableWithDecimals",
        name: "token",
        type: "address",
      },
      {
        internalType: "address[]",
        name: "investors",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
    ],
    name: "distribute",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getChainID",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
    ],
    name: "getCollectedFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
    ],
    name: "getIdoBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "uint256[]",
        name: "boxIDs",
        type: "uint256[]",
      },
    ],
    name: "getInoBalance",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "addrs",
        type: "address[]",
      },
    ],
    name: "getInvestorsBalancesFromIdo",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "swap",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fcfs",
            type: "uint256",
          },
        ],
        internalType: "struct RootV2.BalanceType[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "addrs",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "boxIDs",
        type: "uint256[]",
      },
    ],
    name: "getInvestorsBalancesFromIno",
    outputs: [
      {
        components: [
          {
            internalType: "uint256[]",
            name: "boxCounts",
            type: "uint256[]",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        internalType: "struct RootV2.InoBalanceReturnType[]",
        name: "",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "uint256[]",
            name: "boxCounts",
            type: "uint256[]",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        internalType: "struct RootV2.InoBalanceReturnType[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "investors",
        type: "address[]",
      },
    ],
    name: "getInvestorsFromProject",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "redeemed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "claimed",
            type: "uint256",
          },
        ],
        internalType: "struct RootV2.InvestorData[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "investorAddr",
        type: "address",
      },
    ],
    name: "getLastClaimAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "lastClaimAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "lastClaimIndex",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20UpgradeableWithDecimals",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "redeemed",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    name: "getRedeemedConverted",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getSigner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
    ],
    name: "getUserFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "users",
        type: "address[]",
      },
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
    ],
    name: "getUsersFees",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "investor",
        type: "address",
      },
    ],
    name: "investorDataFromProject",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "redeemed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "claimed",
            type: "uint256",
          },
        ],
        internalType: "struct RootV2.InvestorData",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "investor",
        type: "address",
      },
    ],
    name: "investorDataWithProject",
    outputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "contract IERC20UpgradeableWithDecimals",
            name: "token",
            type: "address",
          },
          {
            internalType: "contract IERC20UpgradeableWithDecimals",
            name: "claimToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256[]",
            name: "claimDates",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "claimPercent",
            type: "uint256[]",
          },
        ],
        internalType: "struct RootV2.ProjectProps",
        name: "props",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "isActive",
        type: "bool",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "redeemed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "claimed",
            type: "uint256",
          },
        ],
        internalType: "struct RootV2.InvestorData",
        name: "investorData",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "addr",
        type: "address",
      },
    ],
    name: "isAdmin",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
    ],
    name: "projectById",
    outputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "contract IERC20UpgradeableWithDecimals",
            name: "token",
            type: "address",
          },
          {
            internalType: "contract IERC20UpgradeableWithDecimals",
            name: "claimToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256[]",
            name: "claimDates",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "claimPercent",
            type: "uint256[]",
          },
        ],
        internalType: "struct RootV2.ProjectProps",
        name: "props",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "isActive",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
    ],
    name: "projectPauseToggle",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "projectTokensReturnedAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "projectId",
            type: "uint256",
          },
          {
            internalType: "enum VoucherV2.RedeemType",
            name: "redeemType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "address",
            name: "investorAddr",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "fee",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expireTime",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct VoucherV2.RedeemIdoVoucher",
        name: "voucher",
        type: "tuple",
      },
    ],
    name: "redeemIdo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "projectId",
            type: "uint256",
          },
          {
            internalType: "enum VoucherV2.RedeemType",
            name: "redeemType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "address",
            name: "investorAddr",
            type: "address",
          },
          {
            internalType: "uint256[]",
            name: "boxIDs",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "boxCounts",
            type: "uint256[]",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expireTime",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct VoucherV2.RedeemInoVoucher",
        name: "voucher",
        type: "tuple",
      },
    ],
    name: "redeemIno",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "projectId",
            type: "uint256",
          },
          {
            internalType: "enum VoucherV2.RefundType",
            name: "refundType",
            type: "uint8",
          },
          {
            internalType: "enum VoucherV2.OfferingType",
            name: "offeringType",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "investorAddr",
            type: "address",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expireTime",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct VoucherV2.RefundVoucher",
        name: "voucher",
        type: "tuple",
      },
    ],
    name: "refund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "addrs",
        type: "address[]",
      },
    ],
    name: "removeAdmins",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "projectId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "addrs",
        type: "address[]",
      },
      {
        internalType: "enum VoucherV2.OfferingType",
        name: "offeringType",
        type: "uint8",
      },
    ],
    name: "removeBalances",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "projectId",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "investorAddr",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expireTime",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct VoucherV2.ReturnProjectTokensVoucher",
        name: "voucher",
        type: "tuple",
      },
    ],
    name: "returnProjectTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newSigner",
        type: "address",
      },
    ],
    name: "setSigner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20UpgradeableWithDecimals",
        name: "token",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;
