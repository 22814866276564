import CircleLoader from "app/components/loader/circle-loader";
import { refundedProjects, refundedProjectsId } from "app/constants";
import {
  RefundStage,
  useClaimCurrency, useExchangeCurrencySymbol,
  useInvestorInfo,
  useRedeemedByChains,
  useRefundApplication,
  useRefundStage,
  useRefundedAmount
} from "app/hooks";
import { formatBigNumber } from "app/utils";
import iSuccess from "assets/img/status-success.svg";
import cn from "classnames";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChainId, EXPLORERS_MAP, Ido, RefundType } from "sdk";
import { useSyncRefundApplicationMutation } from "store/api";
import { useChainId } from "wagmi";
import { CheckNetworkBody } from "../check-network/check-network-body";

type Props = {
  className?: string;
  project: Ido;
};

const ProjectRefund__Success: FC<Props> = ({ className = "", project }) => {
  const { t } = useTranslation();
  const chainId = useChainId() as ChainId
  const exchangeCurrencySymbol = useExchangeCurrencySymbol(project);
  const { claimCurrencySymbol, claimCurrencyDecimals } = useClaimCurrency(project)
  const { refundApplication } = useRefundApplication(project);
  const { refundedAmount, refundedAmountView } = useRefundedAmount(project);
  const { redeemedNonZeroByChains } = useRedeemedByChains(project);
  const [syncRefundApplication] = useSyncRefundApplicationMutation();
  const { investorInfo } = useInvestorInfo(project)
  const refundStage = useRefundStage(project)

  useEffect(() => {
    syncRefundApplication({ projectId: project.id, chainId });
  }, [project, chainId]);

  const isProjectTokensReturn = useMemo(() => {
    return (!investorInfo?.returnedTokensAmount?.isZero() && refundStage !== RefundStage.R5_REFUNDED) || refundStage === RefundStage.R8_RETURN_TOKENS_END_TIME
  }, [investorInfo, refundStage])

  return (
    <div className={cn("project-refund__success", className)}>
      <img
        src={iSuccess}
        alt="success"
        className="project-refund__success-icon"
        width={40}
        height={40}
      />
      {
        isProjectTokensReturn && (
          <div className="project-refund__success-title">
            {RefundStage.R8_RETURN_TOKENS_END_TIME && investorInfo?.returnedTokensAmount?.isZero() ?
              (<>{t("The time to return the project tokens is over, wait for the fund transfer to the wallet")}</>) : // FIXME добавить текст в файлы переводов
              (<>
                <span>
                  {formatBigNumber(investorInfo?.returnedTokensAmount, 3, claimCurrencyDecimals)}&nbsp;{claimCurrencySymbol}
                </span>{" "}
                {t("successfully returned. The funds will be transferred to the wallet in the near future")}
              </>)}

          </div>
        )
      }
      {!isProjectTokensReturn && (refundedAmount.isZero() ? (
        <div className="project-refund__success-title">
          {t("Your funds successfully returned, thank you for your participation")}
        </div>
      ) : (
        <div className="project-refund__success-title">
          <span>
            {refundedAmountView}&nbsp;{exchangeCurrencySymbol}
          </span>{" "}
          {t("successfully returned, thank you for your participation")}
        </div>
      ))}
      {refundedProjectsId.includes(project.id) && (
        <div className="project-refund__links">
          {refundedProjects.map(({ id, txHashList }) =>
            id === project.id
              ? txHashList.map(({ hash, chainId }) => (
                <a
                  key={id}
                  href={`${EXPLORERS_MAP[chainId].url}tx/${hash}`}
                  target="_blank"
                  className={cn("project-refund__link", {
                    _margin: redeemedNonZeroByChains && !!redeemedNonZeroByChains.length,
                  })}
                >
                  {t("View in")} {EXPLORERS_MAP[chainId].name}
                </a>
              ))
              : null
          )}
        </div>
      )}
      {!isProjectTokensReturn && !refundedProjectsId.includes(project.id) && (
        <CircleLoader
          isLoading={
            (!refundApplication || !refundApplication.transactionHash) && !project.refundTxHash
          }
          className="project-refund__success-loader"
        >
          <a
            href={`${EXPLORERS_MAP[chainId].url}tx/${refundApplication?.transactionHash || project.refundTxHash
              }`}
            target="_blank"
            className={cn("project-refund__link", {
              _margin: redeemedNonZeroByChains && !!redeemedNonZeroByChains.length,
            })}
          >
            {t("View in")} {EXPLORERS_MAP[chainId].name}
          </a>
        </CircleLoader>
      )}
      {!isProjectTokensReturn && redeemedNonZeroByChains &&
        !!redeemedNonZeroByChains.length &&
        project.refundType !== RefundType.REFUNDED && (
          <div className="project-refund__chain-selector">
            <div className="project-refund__chain-selector-inner">
              <CheckNetworkBody project={project} />
            </div>
          </div>
        )}
    </div>
  );
};

export default ProjectRefund__Success;
