import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectStage } from "app/enums";
import { RootState } from "../";

interface InitialState {
  fcfsInputValue: string;
  swapInputValue: string;
  isProjectTimerRunning: boolean;
  refundTransactionHash: string;
  projectStage: ProjectStage;
  isRefundPolicyAvailable: boolean;
  projectBanner: string;
  projectLogo: string;
  projectTokenLogo: string;
}

const initialState: InitialState = {
  fcfsInputValue: "",
  swapInputValue: "",
  isProjectTimerRunning: true,
  refundTransactionHash: "",
  projectStage: 1, // enum падает с ошибкой "Cannot access 'ProjectStage' before initialization"
  isRefundPolicyAvailable: false,
  projectBanner: "",
  projectLogo: "",
  projectTokenLogo: "",
};

export const projectSlice = createSlice({
  initialState,
  name: "project",
  reducers: {
    setSwapInputValue: (state, { payload }: PayloadAction<string>) => {
      state.swapInputValue = payload;
    },
    setFcfsInputValue: (state, { payload }: PayloadAction<string>) => {
      state.fcfsInputValue = payload;
    },
    setIsProjectTimerRunning: (state, { payload }: PayloadAction<boolean>) => {
      state.isProjectTimerRunning = payload;
    },
    resetRefundTransaction: (state) => {
      state.refundTransactionHash = initialState.refundTransactionHash;
    },
    setProjectStage: (state, { payload }: PayloadAction<ProjectStage>) => {
      state.projectStage = payload;
    },
    setIsRefundPolicyAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.isRefundPolicyAvailable = payload;
    },
    setProjectBanner: (state, { payload }: PayloadAction<string>) => {
      state.projectBanner = payload;
    },
    setProjectLogo: (state, { payload }: PayloadAction<string>) => {
      state.projectLogo = payload;
    },
    setProjectTokenLogo: (state, { payload }: PayloadAction<string>) => {
      state.projectTokenLogo = payload;
    },
  },
});

export const selectProject = (store: RootState) => store.project;
export const selectSwapInputValue = (store: RootState) => store.project.swapInputValue;
export const selectFcfsInputValue = (store: RootState) => store.project.fcfsInputValue;
export const selectIsProjectTimerRunning = (store: RootState) =>
  store.project.isProjectTimerRunning;
export const selectRefundTransactionHash = (store: RootState) =>
  store.project.refundTransactionHash;
export const selectProjectStage = (store: RootState) => store.project.projectStage;
export const selectIsRefundPolicyAvailable = (store: RootState) =>
  store.project.isRefundPolicyAvailable;
export const selectProjectBanner = (store: RootState) => store.project.projectBanner;
export const selectProjectLogo = (store: RootState) => store.project.projectLogo;
export const selectProjectTokenLogo = (store: RootState) => store.project.projectTokenLogo;
