import Button from "app/components/button/button";
import { useActions } from "app/hooks";
import cn from "classnames";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectModalProps } from "store/slice";
import "./refund-confirm-modal.sass";

type Props = {
  className?: string;
};

const RefundConfirmModal: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { closeModal } = useActions();
  const { onConfirm, isLoading } = useSelector(selectModalProps);

  return (
    <div className={cn(className, "refund-confirm-modal")}>
      <div className="refund-confirm-modal__title">
        <Trans i18nKey="Refund confirm modal title">
          <span className="_g-text-active-lighter">Refund</span> the funds?
        </Trans>
      </div>
      <div className="refund-confirm-modal__desc">
        <Trans i18nKey="Refund confirm modal desc">
          Pay your attention that by clicking on the "Yes" button you confirm your intention to
          refuse taking part in this IDO.
          <br />
          <span>This action is irreversible.</span>
        </Trans>
      </div>
      <div className="refund-confirm-modal__buttons">
        <Button
          className="refund-confirm-modal__button"
          onClick={onConfirm || console.log}
          isLoading={isLoading}
          variant="_blank"
        >
          {t("yes")}
        </Button>
        <Button className="refund-confirm-modal__button" disabled={isLoading} onClick={closeModal}>
          {t("no")}
        </Button>
      </div>
    </div>
  );
};

export default RefundConfirmModal;
