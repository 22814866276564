import { useExchangeCurrency } from "app/hooks";
import { Project } from "app/types";
import { KeyValueItem } from "app/types/key-value-item";


import { ReactComponent as Protection } from "assets/img/protection-icon.svg";
import cn from "classnames";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Ido, RefundType } from "sdk";
import { getValueOrTBA } from "../../utils";
import IdoClaimNetworksList from "../ido/ido-claim-networks-list";
import ProjectInfoItem from "./project-info-item";
import "./project-info.sass";

type Props = {
  className?: string;
  project: Project;
  additionalItems: KeyValueItem[];
};

const ProjectInfo: FC<Props> = ({ className = "", project, additionalItems, children }) => {
  const { t } = useTranslation();
  const { exchangeCurrencySymbol, exchangeCurrencyIcon } = useExchangeCurrency(project);

  const items = useMemo(
    () => [
      {
        itemKey: "Raise Currency",
        children: (
          <>
            {exchangeCurrencyIcon && (
              <img width={22} height={22} src={exchangeCurrencyIcon} alt={exchangeCurrencySymbol} />
            )}
            {exchangeCurrencySymbol}
          </>
        ),
      },
      {
        itemKey: "Claim Type",
        children: (
          <>
            {project.claimType.toLowerCase() === "airdrop" ? (
              <>{getValueOrTBA(project.claimType)}</>
            ) : (
              <>
                {t("Claim on")} {getValueOrTBA(project.claimType)}
              </>
            )}
          </>
        ),
      },
      ...additionalItems,
      {
        itemKey: "Claim Network",
        children: (
          <IdoClaimNetworksList project={project as Ido} />
        ),

      },
    ],
    [project, exchangeCurrencyIcon, exchangeCurrencySymbol, additionalItems]
  );

  const refundPolicy = useMemo(() => {
    const isRefundPolicyAdvanced =
      project.refundType === RefundType.REFUND_POLICY_ADVANCED ||
      project.refundType === RefundType.RETURN_PROJECT_TOKENS;
    const noRefund = project.refundType === RefundType.NO_REFUND;
    let result
    if (noRefund) {
      result = {
        title: t("No Refund"),
        link: "https://gagarin-launchpad.gitbook.io/gagarin/join-idos/protection-system/basic-investors-protection-system",
      }
    } else if (isRefundPolicyAdvanced) {
      result = {
        title: t("Advanced Protection System"),
        link: "https://gagarin-launchpad.gitbook.io/gagarin/join-idos/protection-system/advanced-protection-system",
      }
    } else {
      result = {
        title: t("Protection System"),
        link: "https://gagarin-launchpad.gitbook.io/gagarin/join-idos/protection-system/basic-investors-protection-system",
      }
    }
    const icon = noRefund ? null : <Protection className={cn({ advanced: isRefundPolicyAdvanced })} />
    return { ...result, icon }
  }, [project.refundType])

  return (
    <div className={cn("project-info", className)}>
      <div className={cn("project-info__protection")}>
        {project.id === '6464c4bc2d3b5cea84d59498' ? (<></>) : (<a
          href={
            refundPolicy.link
          }
          target="_blank"
          rel="noreferrer"
        >
          <div className={cn("project-info__protection-type")}>
            {refundPolicy.icon}
            <p>
              {refundPolicy.title}
            </p>
          </div>
        </a>)}

      </div>
      <div className="project-info__list">
        {items.map(({ itemKey, children }) => (
          <ProjectInfoItem className="project-info__list" key={itemKey} itemKey={itemKey}>
            {children}
          </ProjectInfoItem>
        ))}
      </div>
      <div className="project-info__bottom">{children}</div>
    </div>
  );
};

export default ProjectInfo;
