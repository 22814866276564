export enum ImageType {
  BANNER = "banner",
  LOGO = "logo",
  TOKEN_LOGO = "tokenLogo",
}

export type Image = {
  id: string;
  path: string;
  type: ImageType;
  idoId?: string;
};
