import CheckAuth from "app/components/check-auth";
import RocketLoader from "app/components/loader/rocket-loader";
import { useClaimedTokensAmount, useCurrency, useInvestorInfo } from "app/hooks";
import iEnded from "assets/img/ended-icon.svg";
import cn from "classnames";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Ido } from "sdk";
import { CheckNetwork } from "../check-network/check-network";
import "./project-ended.sass";

type Props = {
  project: Ido;
};

const ProjectEnded: FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const { claimCurrencyIcon, claimCurrencySymbol } = useCurrency(project);
  const { claimedTokensAmountView } = useClaimedTokensAmount(project);
  const { isInvestorInfoLoading } = useInvestorInfo(project);

  return (
    <CheckAuth className="project-ended__connect">
      <RocketLoader className="project-ended__loader" isLoading={isInvestorInfoLoading}>
        <CheckNetwork project={project} exact>
          <div className="project-ended">
            <div className="project-ended__upper">
              <img src={iEnded} alt="" className="project-ended__img" />
            </div>
            <div className={cn("project-ended__text", "_g-text-active-lighter")}>
              {t("Payments are over")}
            </div>
            <div className={cn("project-ended__text", "_flex")}>
              <Trans i18nKey="projectEnded">
                You have claimed
                {claimCurrencyIcon && (
                  <img
                    className="project-ended__token-icon"
                    src={claimCurrencyIcon}
                    alt={claimCurrencySymbol}
                  />
                )}
                {claimedTokensAmountView}&nbsp;{claimCurrencySymbol}.
              </Trans>
            </div>
            <div className="project-ended__text">{t("Thanks for participating!")}</div>
          </div>
        </CheckNetwork>
      </RocketLoader>
    </CheckAuth>
  );
};

export default ProjectEnded;
