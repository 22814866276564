import { QueryParam } from "app/enums";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useQuery = (paramName?: string) => {
  const { search } = useLocation();
  const history = useHistory<History>();

  const createQueryParam = useCallback((paramName: QueryParam, value: string) => {
    const param = new URLSearchParams({
      [paramName]: value,
    });

    return param.toString();
  }, []);

  const setQueryParam = useCallback(
    (paramName: QueryParam, value: string) => {
      const search = createQueryParam(paramName, value);

      history.push({ search });
    },
    [createQueryParam]
  );

  const clearQueryParams = useCallback(() => {
    history.push({ search: "" });
  }, []);

  return useMemo(() => {
    const query = new URLSearchParams(search);
    let queryParam: string | null = "";

    if (paramName) {
      queryParam = query.get(paramName);
    }

    return { query, queryParam, setQueryParam, clearQueryParams, createQueryParam };
  }, [search, paramName, setQueryParam, clearQueryParams, createQueryParam]);
};
