import cn from "classnames";
import { useState } from "react";

type TProps = {
  className?: string;
  stakingNewChild: React.ReactChild;
  stakingOldChild: React.ReactChild;
};

const StakingTabs: React.FC<TProps> = ({ className, stakingNewChild, stakingOldChild }) => {
  const [stakingActive, setActiveStaking] = useState(1);

  return (
    <div className={cn("bg-bg_card rounded-3xl overflow-hidden transition-all", className)}>
      <div className="w-full  h-10 text-sm font-semibold flex justify-between text-slate-50">
        <div
          onClick={() => setActiveStaking(1)}
          className={cn(
            "flex items-center justify-center w-1/2 transition-all border-b border-solid  ",
            stakingActive === 1
              ? "bg-green bg-opacity-30 border-green  border-b-2"
              : "opacity-50 cursor-pointer"
          )}
        >
          GGR 2.0 (New)
        </div>
        <div
          onClick={() => setActiveStaking(2)}
          className={cn(
            "flex items-center justify-center w-1/2 py-3 border-b border-solid ",
            stakingActive === 2
              ? "bg-green  bg-opacity-30 border-green  border-b-2"
              : "opacity-50 cursor-pointer"
          )}
        >
          GGR 1.0 (Old)
        </div>
      </div>
      {stakingActive === 1 && stakingNewChild}
      {stakingActive === 2 && stakingOldChild}
    </div>
  );
};

export default StakingTabs;
