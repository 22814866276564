import { ProjectStage } from "app/enums";
import {
  useActions,
  useClaimCurrency,
  useClaimedTokensAmount,
  useRefundTokensAmount,
  useWidth,
} from "app/hooks";
import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectIsRefundPolicyAvailable } from "store/slice";
import Button from "../button/button";
import Timer from "../timer/timer";
import "./refund-policy.sass";

type Props = {
  ido: Ido;
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
};

type ButtonProps = {
  ido: Ido;
};

const RefundButton: FC<ButtonProps> = ({ ido }) => {
  const { t } = useTranslation();
  const { setProjectStage } = useActions();

  const onClick = () => {
    setProjectStage(ProjectStage.refundPolicy);
  };

  const { claimCurrencySymbol } = useClaimCurrency(ido);
  const { refundTokensAmount, isRefundTokensAmountLoading } = useRefundTokensAmount(ido);
  const { claimedTokensAmount } = useClaimedTokensAmount(ido);

  const disabled = useMemo(
    () => refundTokensAmount.lt(claimedTokensAmount),
    [refundTokensAmount, claimedTokensAmount]
  );
  return (
    <Button
      className="refund-policy__button"
      onClick={onClick}
      disabled={disabled}
      isLoading={isRefundTokensAmountLoading}
    >
      {disabled ? t("Insufficient balance", { symbol: claimCurrencySymbol }) : t("Refund Funds")}
    </Button>
  );
};

const RefundPolicy: FC<Props> = ({ ido, seconds, minutes, hours, days }) => {
  const { t } = useTranslation();
  const { isTabletWidth } = useWidth();
  const isRefundPolicyAvailable = useSelector(selectIsRefundPolicyAvailable);

  return (
    <>
      {isRefundPolicyAvailable && (
        <div className="refund-policy">
          <div className="refund-policy__row">
            <div className="refund-policy__title">{t("Refund of funds")}</div>
          </div>
          <div className="refund-policy__row">
            <div className="refund-policy__text">
              <Trans i18nKey="Refund Policy">
                Within <span className="refund-policy__text-special">two hours</span> after the
                first claim date, the refund will not be available to you. Please note that after
                the refund, this IDO will be closed to you.
              </Trans>
            </div>
            <div>
              <Timer
                className="refund-policy__timer"
                hours={hours}
                minutes={minutes}
                seconds={seconds}
                days={days}
              />
              {isTabletWidth && <RefundButton ido={ido} />}
            </div>
          </div>
          {!isTabletWidth && (
            <div className="refund-policy__row">
              <RefundButton ido={ido} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RefundPolicy;
