import { getRootContract } from "app/utils/contract-helper";
import { BigNumber } from "ethers";
import { ChainId, getHexFromId } from "sdk";
import { useAccount, useChainId, useContractRead } from "wagmi";

export const useInvestorQuery = ({ projectId }: { projectId?: string }) => {
  const chainId = useChainId() as ChainId;
  const { address: account } = useAccount();
  const response = useContractRead({
    chainId,
    ...getRootContract(chainId),
    functionName: "investorDataFromProject",
    enabled: Boolean(projectId && account),
    args:
      projectId && account
        ? [BigNumber.from(getHexFromId(projectId)).toBigInt(), account]
        : undefined,
    watch: true,
  });
  return response;
};
