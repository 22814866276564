import cn from "classnames";
import "./select-line.sass";

interface ISelectItem {
  title: string;
  value: string;
  disabled?: boolean;
}

interface IProps {
  className?: string;
  items: ISelectItem[];
  actionElement?: number;
  click: any;
}

const SelectLine: React.FC<IProps> = ({ className, items, actionElement, click }) => {
  return (
    <div className={cn("select-line", className)}>
      <div className={cn("select-line__content")}>
        {items.map((el, i) => (
          <div
            key={i}
            onClick={() => (el.disabled ? "" : click(i))}
            className={cn("select-line__item ", {
              " _bg": i === actionElement,
              " _disabled": el.disabled,
            })}
          >
            {el.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectLine;
