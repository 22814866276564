import cn from 'classnames';

export enum BadgeType {
  PRIMARY = 'PRIMARY'
}

type TProps = {
  className?: string;
  title?: string;
  type?: BadgeType;
};

const Badge: React.FC<TProps> = ({ className, title, type = BadgeType.PRIMARY }) => {
  return (
    <div
      className={cn(
        'rounded-md py-1 px-2',
        {
          'bg-bg_primary': type === BadgeType.PRIMARY
        },
        className
      )}
    >
      <div className={cn('5', { 'text-text_primary': type === BadgeType.PRIMARY })}>{title}</div>
    </div>
  );
};

export default Badge;
