import { useBalance, useExchangeCurrency } from 'app/hooks';
import { formatBigNumber } from 'app/utils';
import { BigNumber } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Ido } from 'sdk';

type Props = {
  project: Ido;
  inputValue?: string
  setInputValue?: (...args: any) => void
};

const ProjectSwap__UserBalance: FC<Props> = ({ project, inputValue, setInputValue }) => {
  const { t } = useTranslation();
  const { currentExchangeCurrency } = useExchangeCurrency(project);
  const { balance } = useBalance(
    currentExchangeCurrency?.address,
    currentExchangeCurrency?.decimals
  );

  const currentUserBalance = useMemo(() => {
    const currentBalance: BigNumber = balance || 0
    const currentDecimals: number = currentExchangeCurrency?.decimals || 18
    const formattedUserBalance = formatBigNumber(currentBalance, 3, currentDecimals)
    const rawUserBalance = balance
    const numberUserBalance = +formatUnits(balance, currentDecimals)

    return { formattedUserBalance, rawUserBalance, numberUserBalance }
  }, [balance])

  useEffect(() => {
    if (!inputValue || !setInputValue) return

    const inputValueBN = parseUnits(inputValue || '0', currentExchangeCurrency?.decimals || 0)
    if (inputValueBN.gt(balance)) {
      const formattedBalance = formatUnits(balance, currentExchangeCurrency?.decimals)
      const pointIndex = formattedBalance.indexOf('.')
      if (formattedBalance.length > pointIndex + 3) {
        setInputValue(formattedBalance.slice(0, pointIndex + 3))
      } else {
        setInputValue((+formattedBalance).toString())
      }
    }
  }, [currentUserBalance, inputValue])

  return (
    <div className="project-swap__body-balance">
      <h3 className="project-swap__body-balance-title">{t("Balance")}:</h3>
      <span className="project-swap__body-balance-value">{`${currentUserBalance.formattedUserBalance} ${currentExchangeCurrency?.ticket}`}</span>
    </div>
  )
};

export default ProjectSwap__UserBalance;
