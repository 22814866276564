import { BigNumberish } from "ethers";
import { ChainId, GagarinStaking, GagarinStakingAbi } from "sdk";
import { ContractService } from "./contract.service";

class StakingContract extends ContractService {
  chainId =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? ChainId.BINANCE_MAINNET
      : ChainId.BINANCE_TESTNET;
  address: string = process.env.REACT_APP_STAKING_CONTRACT_ADDR as string;
  constructor() {
    super(GagarinStakingAbi);
  }

  depositPopulate({
    pid,
    amount,
    address = this.address,
    chainId = this.chainId,
  }: {
    pid: BigNumberish;
    amount: BigNumberish;
    address?: string;
    chainId?: ChainId;
  }) {
    const contract = this._getContract<GagarinStaking>(address, chainId);
    return contract.populateTransaction.deposit(pid, amount);
  }

  reStakePopulate({
    pid,
    address = this.address,
    chainId = this.chainId,
  }: {
    pid: BigNumberish;
    address?: string;
    chainId?: ChainId;
  }) {
    const contract = this._getContract<GagarinStaking>(address, chainId);
    return contract.populateTransaction.reStake(pid);
  }

  releasePopulate({
    address = this.address,
    chainId = this.chainId,
  }: {
    address?: string;
    chainId?: ChainId;
  }) {
    const contract = this._getContract<GagarinStaking>(address, chainId);
    return contract.populateTransaction.release();
  }

  withdrawPopulate({
    address = this.address,
    chainId = this.chainId,
  }: {
    address?: string;
    chainId?: ChainId;
  }) {
    const contract = this._getContract<GagarinStaking>(address, chainId);
    return contract.populateTransaction.withdraw();
  }

  emergencyWithdrawPopulate({
    address = this.address,
    chainId = this.chainId,
  }: {
    address?: string;
    chainId?: ChainId;
  }) {
    const contract = this._getContract<GagarinStaking>(address, chainId);
    return contract.populateTransaction.emergencyWithdraw();
  }
}

const stakingContract = new StakingContract();

export default stakingContract;
