import { useMutation } from "app/hooks/root-contract/mutation/use-mutation";
import { BigNumberish } from "ethers";
import stakingContract from "services/staking.contract";
import { useChainId } from "wagmi";

export const useDepositMutation = () => {
  const chainId = useChainId();
  const depositMutation = async ({ pid, amount }: { pid: BigNumberish; amount: BigNumberish }) => {
    return await stakingContract.depositPopulate({
      address: process.env.REACT_APP_STAKING_CONTRACT_ADDR,
      chainId: chainId,
      pid,
      amount,
    });
  };
  return useMutation(depositMutation);
};
