import CheckAuth from "app/components/check-auth";
import RocketLoader from "app/components/loader/rocket-loader";
import { privateSwapProjects } from "app/constants";
import { SwapStage, useApplication, useSwapStage } from "app/hooks";
import cn from "classnames";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Ido } from "sdk";
import { CheckNetwork } from "../check-network/check-network";
import ProjectLose from "../project-lose";
import "./project-swap.sass";
import ProjectSwap__Body from "./project-swap__body";
import ProjectSwap__Buttons from "./project-swap__buttons";
import ProjectSwap__Desc from "./project-swap__desc";
import ProjectSwap__Success from "./project-swap__success";
import ProjectSwap__Wait from "./project-swap__wait";
import ProjectSwap__Win from "./project-swap__win";

type Props = {
  className?: string;
  project: Ido;
};

const ProjectSwap: FC<Props> = ({ className, project }) => {
  const { t } = useTranslation();
  const { isApplicationLoading } = useApplication(project);
  const swapStage = useSwapStage(project);
  const title = useMemo(() => {
    let title = "Swap";
    if (
      swapStage === SwapStage.S1_SWAP_GAP_HAS_NO_APP ||
      swapStage === SwapStage.S2_SWAP_GAP_HAS_APP
    ) {
      title = "Lottery";
    }
    if (
      swapStage >= SwapStage.S3_SWAP_GAP_WITH_RESULTS_HAS_NO_APP &&
      swapStage <= SwapStage.S5_SWAP_GAP_WITH_RESULTS_LOSE
    ) {
      title = "Lottery results";
    }

    return title;
  }, [swapStage]);

  return (
    <div className={cn("project-swap", className)}>
      {<div className="project-swap__title">{t(title)}</div>}
      <CheckAuth className="project-swap__connect">
        <RocketLoader className="project-swap__loader" isLoading={isApplicationLoading}>
          <CheckNetwork project={project} className="project-swap__network">
            {(swapStage === SwapStage.S1_SWAP_GAP_HAS_NO_APP ||
              swapStage === SwapStage.S3_SWAP_GAP_WITH_RESULTS_HAS_NO_APP ||
              swapStage === SwapStage.S6_SWAP_HAS_NO_APP) &&
              (project.whitelistFree || !privateSwapProjects.includes(project.id)) && (
                <>
                  {/* <ProjectLose type="no-application" /> */}
                  <ProjectLose type="investor-only" />
                </>
              )}
            {/* {(swapStage === SwapStage.S1_SWAP_GAP_HAS_NO_APP ||
              swapStage === SwapStage.S3_SWAP_GAP_WITH_RESULTS_HAS_NO_APP ||
              swapStage === SwapStage.S6_SWAP_HAS_NO_APP) &&
              (!project.whitelistFree || privateSwapProjects.includes(project.id)) && (
                <>
                  <ProjectLose type="investor-only" />
                </>
              )} */}
            {swapStage === SwapStage.S2_SWAP_GAP_HAS_APP && (
              <>
                <ProjectSwap__Wait />
              </>
            )}

            {swapStage === SwapStage.S4_SWAP_GAP_WITH_RESULTS_WIN && (
              <>
                <ProjectSwap__Win />
                <ProjectSwap__Body project={project} />
                <ProjectSwap__Desc />
              </>
            )}

            {(swapStage === SwapStage.S5_SWAP_GAP_WITH_RESULTS_LOSE ||
              swapStage === SwapStage.S7_SWAP_LOSE) && (
              <>
                <ProjectLose type="lose-lottery" />
              </>
            )}

            {swapStage === SwapStage.S8_SWAP_WIN_NOT_REDEEM && (
              <>
                <ProjectSwap__Success project={project} />
                <ProjectSwap__Body project={project} />
                <ProjectSwap__Buttons project={project} />
              </>
            )}

            {swapStage === SwapStage.S9_SWAP_WIN_REDEEM && (
              <ProjectSwap__Success project={project} />
            )}
          </CheckNetwork>
        </RocketLoader>
      </CheckAuth>
    </div>
  );
};

export default ProjectSwap;
