import cn from "classnames";
import { useTranslation } from "react-i18next";
import "./agree-modal.sass";

interface IProps {
  className?: string;
  onAgree: VoidFunction;
  onCancel: VoidFunction;
}

const AgreeModal: React.FC<IProps> = ({ className, onAgree, onCancel }) => {
  const { t, i18n } = useTranslation();
  const data = {
    title: t("modal.confirmationTitle"),
    button1: t("modal.yes"),
    button2: t("modal.no"),
  };
  return (
    <div className={cn("agree-modal", className)}>
      <div className="agree-modal__title">{data.title}</div>
      <div className="agree-modal__buttons">
        <button className="agree-modal__button _bg-red" onClick={onAgree}>
          {data.button1}
        </button>
        <button className="agree-modal__button _bg-green" onClick={onCancel}>
          {data.button2}
        </button>
      </div>
    </div>
  );
};

export default AgreeModal;
