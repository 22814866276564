import DocumentIcon from "assets/img/document.svg";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ProjectLink } from "sdk";

type Props = { link: ProjectLink };

const ProjectItemLinkDocs: FC<Props> = ({ link }) => {
  const { t } = useTranslation();
  return (
    <a className="project-item__link" href={link.link} target="_blank">
      <img className="project-item__link-icon" src={DocumentIcon} alt={t(link.name)} />
      <div className="project-item__link-title">{t(link.name)}</div>
    </a>
  );
};

export default ProjectItemLinkDocs;
