import { Params } from "app/pages";
import { FC } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";

const ProjectRedirect: FC = () => {
  const { idoId } = useParams<Params>();
  const { search } = useLocation();

  return <>{idoId && <Redirect to={`/ido/${idoId}${search}`} />}</>;
};

export default ProjectRedirect;
