import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Referrer, ReferrerStatistics } from "sdk";
import tokenService from "../../services/token.service";

export const referrerApi = createApi({
  reducerPath: "referrerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ROOT,
    prepareHeaders: (headers) => {
      if (tokenService.tokens) {
        headers.set("authorization", `Bearer ${tokenService.tokens.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createReferal: builder.mutation<void, { code: string; idoId: string }>({
      query: ({ code, idoId }) => ({
        url: `referrers/${code}/referrals?idoId=${idoId}`,
        method: "POST",
      }),
    }),

    referrers: builder.query<Referrer[], string>({
      query: (address) =>
        `referrers?filter=${JSON.stringify({
          include: [
            {
              relation: "ido",
              scope: { include: [{ relation: "claimCurrency" }, { relation: "payments" }] },
            },
          ],
          where: { userId: { regexp: `${address}/i` } },
        })}`,
    }),

    referrerByIdo: builder.query<Referrer, { idoId: string; address: string }>({
      query: ({ idoId, address }) =>
        `referrers?filter=${JSON.stringify({
          where: { idoId, userId: { regexp: `${address}/i` } },
          include: [{ relation: "ido", scope: { include: [{ relation: "exchangeCurrency" }] } }],
        })}`,
      transformResponse: ([referrer]: Referrer[]) => referrer,
    }),

    referralsCount: builder.query<{ count: number }, string>({
      query: (referrerId) => `referrers/${referrerId}/referrals/count`,
    }),

    referrerStatistics: builder.query<
      ReferrerStatistics,
      { referrerId: string; limit: number; skip: number }
    >({
      query: ({ referrerId, skip, limit }) =>
        `/referrers/${referrerId}/statistics?filter=${JSON.stringify({
          skip,
          limit,
        })}`,
    }),
  }),
});

export const {
  useCreateReferalMutation,
  useReferrersQuery,
  useReferralsCountQuery,
  useReferrerStatisticsQuery,
  useReferrerByIdoQuery,
} = referrerApi;
