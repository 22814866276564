import {
  Address,
  beginCell,
  Cell,
  Contract,
  contractAddress,
  ContractProvider,
  Dictionary,
  Sender,
} from "ton-core";
import jettonWalletCompiled from "../artifacts/jetton-wallet.compiled.json";

export const jettonWalletCodeCell = Cell.fromBoc(Buffer.from(jettonWalletCompiled.hex, "hex"))[0];

export async function calculateJettonWalletAddress(ownerAddr: Address, jettonMinterAddr: Address) {
  const workchain = 0;
  const stateInit = {
    code: jettonWalletCodeCell,
    data: beginCell()
      .storeCoins(0)
      .storeAddress(ownerAddr)
      .storeAddress(jettonMinterAddr)
      .storeRef(jettonWalletCodeCell)
      .endCell(),
  };
  return contractAddress(workchain, stateInit).toString();
}

export class JettonWallet implements Contract {
  readonly address: Address;

  constructor(address: Address) {
    this.address = address;
  }

  static createFromAddress(address: Address) {
    return new JettonWallet(address);
  }

  static OP_CODES = {
    TRANSFER: BigInt(0xf8a7ea5),
  };

  async sendTransfer(
    provider: ContractProvider,
    via: Sender,
    params: {
      coinAmount: bigint;
      destAddr: Address;
      tokenAmount: bigint;
      responseAddr: Address;
      customPayload: Dictionary<any, any>;
      forwardCoinAmount: bigint;
      forwardMsg: Cell | null;
    }
  ) {
    return await provider.internal(via, {
      value: params.coinAmount,
      body: beginCell()
        .storeUint(0xf8a7ea5, 32)
        .storeUint(0, 64)
        .storeCoins(params.tokenAmount)
        .storeAddress(params.destAddr)
        .storeAddress(params.responseAddr)
        .storeDict(params.customPayload)
        .storeCoins(params.forwardCoinAmount)
        .storeMaybeRef(params.forwardMsg)
        .endCell(),
    });
  }

  async getBalance(provider: ContractProvider) {
    const state = await provider.getState();
    if (state.state.type !== "active") {
      return { amount: BigInt(0) };
    }
    const { stack } = await provider.get("get_wallet_data", []);
    const [amount] = [stack.readBigNumber()];
    return { amount };
  }

  static buildSendTransferMsg(params: {
    jettonAmount: bigint;
    destAddr: Address;
    responseAddr: Address;
    customPayload: Dictionary<any, any>;
    forwardToncoinAmount: bigint;
    forwardPayload?: Cell;
  }) {
    return beginCell()
      .storeUint(JettonWallet.OP_CODES.TRANSFER, 32)
      .storeUint(0, 64)
      .storeCoins(params.jettonAmount)
      .storeAddress(params.destAddr)
      .storeAddress(params.responseAddr)
      .storeDict(params.customPayload)
      .storeCoins(params.forwardToncoinAmount)
      .storeMaybeRef(params.forwardPayload || null)
      .endCell();
  }
}
