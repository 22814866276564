import { BigNumber } from "ethers";
import { Ido } from "./ido";
import { RefundApplication } from "./refund-application";
import { User } from "./user";

export enum ApplicationStatus {
  INTERVIEW = "inreview",
  APPROVED = "approved",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  REFUND = "refund",
  ADDED_ON_FCFS = "addedOnFcfs",
}

type BaseApplication = {
  id: string;
  userId: string;
  idoId: string;
  status: ApplicationStatus;
  isGarant: boolean;
  refundApplications: RefundApplication[];
  createdAt?: number;
  updatedAt?: number;
  swappedAt?: number;
  fcfsFisrtSwapdAt?: number;
  fcfsLastSwapdAt?: number;
  ido?: Ido;
  applicationToUser?: User;
  [key: string]: any;
};

export type RawApplication = {
  amount: string;
  swappedAmount: string;
  fcfsAmount: string;
} & BaseApplication;

export type Application = {
  amount: BigNumber;
  swappedAmount: BigNumber;
  fcfsAmount: BigNumber;
} & BaseApplication;
