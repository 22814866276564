import {
  useAddCurrency,
  useAvailableToClaimTokens,
  useClaimCurrencySymbol,
  useClaimedTokensAmount,
  useIdoRedeemedTokensAmountView,
  useInvestorInfo,
  useNextClaimByProject,
} from "app/hooks";
import { isRefund } from "app/utils";
import { ReactComponent as CopyIcon } from "assets/img/copy.svg";
import cn from "classnames";
import { constants } from "ethers";
import { isAddress } from "ethers/lib/utils";
import { FC, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectProject } from "store/slice";
import metamskIcon from "../../../../assets/img/icons/metamask.png";

type Props = {
  ido: Ido;
};

const IdoClaim__Body: FC<Props> = ({ ido }) => {
  const { t } = useTranslation();
  const { investorInfo } = useInvestorInfo(ido);
  const { nextClaimView } = useNextClaimByProject(ido);
  const availableToClaimTokens = useAvailableToClaimTokens(ido);
  const { claimedTokensAmountView } = useClaimedTokensAmount(ido);
  const redeemedTokensAmount = useIdoRedeemedTokensAmountView(ido);
  const claimCurrencySymbol = useClaimCurrencySymbol(ido);
  const [isNoticeVisible, setIsNoticeVisible] = useState<boolean>(false);
  const { projectTokenLogo } = useSelector(selectProject);

  const { address } = ido.claimCurrency![0];
  const isAddressExist = isAddress(address) && constants.AddressZero !== address;

  const showNotice = (): void => {
    setIsNoticeVisible(true);
    setTimeout(() => setIsNoticeVisible(false), 1200);
  };

  const addCurrency = useAddCurrency()

  const addClaimToken = async () => {
    const { ticket, decimals } = ido.claimCurrency![0];

    await addCurrency({
      address,
      symbol: ticket,
      image: projectTokenLogo,
      decimals,
    });
  };

  return (
    <>
      <div
        className={cn("ido-claim__header", {
          _hidden: isRefund(ido.refundType),
        })}
      >
        <div className="ido-claim__title">{t("Claim progress")}</div>
        <div className="ido-claim__date">{nextClaimView}</div>
        <div
          className={cn("ido-claim__actions", {
            _hidden: !isAddressExist,
          })}
        >
          <CopyToClipboard text={address}>
            <CopyIcon className="ido-claim__icon _copy" onClick={showNotice} />
          </CopyToClipboard>
          <div
            className={cn("ido-claim__notice", {
              _visible: isNoticeVisible,
            })}
          >
            {t("Copied")}!
          </div>
          <img
            src={metamskIcon}
            alt="metamask"
            className="ido-claim__metamask"
            onClick={addClaimToken}
          />
        </div>
      </div>
      <div className="ido-claim__info">
        <div className="ido-claim__column">
          <div className="ido-claim__column-title">{t("Available For Claim")}</div>
          <div
            className={cn("ido-claim__column-value", {
              _transparent: !investorInfo || investorInfo?.availableToClaim.isZero(),
            })}
          >
            {availableToClaimTokens} {claimCurrencySymbol}
          </div>
        </div>
        <div className="ido-claim__column">
          <div className="ido-claim__column-title">{t("Already Recieved")}</div>
          <div
            className={cn("ido-claim__column-value", {
              _transparent: !investorInfo || investorInfo?.claimed.isZero(),
            })}
          >
            {claimedTokensAmountView} {claimCurrencySymbol}
          </div>
        </div>
        <div className="ido-claim__column">
          <div className="ido-claim__column-title">{t("Total amount")}</div>
          <div
            className={cn("ido-claim__column-value", {
              _transparent: !investorInfo || investorInfo.redeemedTokens.isZero(),
            })}
          >
            {redeemedTokensAmount} {claimCurrencySymbol}
          </div>
        </div>
      </div>
    </>
  );
};

export default IdoClaim__Body;
