import { useExchangeCurrencySymbol, useReferrerStatistics, useWidth } from "app/hooks";
import cn from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Referrer, ellipseAddress } from "sdk";
import { useAccount } from "wagmi";
import RocketLoader from "../loader/rocket-loader";
import "./referrer-info.sass";

type Props = {
  referrer: Referrer;
};

const ReferrerInfo: FC<Props> = ({ referrer }) => {
  const { t } = useTranslation();
  const { address } = useAccount()
  const { isDesktopWidth, isTabletWidth } = useWidth();
  const exchangeCurrencySymbol = useExchangeCurrencySymbol(referrer.ido);

  const { redeemed, refunded, award, isReferrerStatisticsLoading } = useReferrerStatistics(
    referrer.id
  );

  return (
    <div className="referrer-info">
      <RocketLoader className="referrer-info__loader" isLoading={isReferrerStatisticsLoading}>
        <div className="referrer-info__inner">
          <div className="referrer-info__address">
            <div className="referrer-info__item">
              <div className="referrer-info__key">{t("Your Address")}</div>
              <div className="referrer-info__value">
                {isDesktopWidth || isTabletWidth ? address : ellipseAddress(address)}
              </div>
            </div>
          </div>
          <div className="referrer-info__stats">
            <div className="referrer-info__item">
              <div className="referrer-info__key">{t("Total amount")}</div>
              <div className={cn("referrer-info__value", { _blank: redeemed === "0" })}>
                {redeemed} {exchangeCurrencySymbol}
              </div>
            </div>
            <div className="referrer-info__item">
              <div className="referrer-info__key">{t("Total Refund")}</div>
              <div className={cn("referrer-info__value", { _blank: redeemed === "0" })}>
                {refunded} {exchangeCurrencySymbol}
              </div>
            </div>
            <div className="referrer-info__item">
              <div className="referrer-info__key">{t("Award")}</div>
              <div className={cn("referrer-info__value", { _green: award !== "0" })}>
                {award} {exchangeCurrencySymbol}
              </div>
            </div>
            <div className="referrer-info__item">
              <div className="referrer-info__key">{t("Status")}</div>
              <div
                className={cn("referrer-info__value", {
                  _blank: !referrer.paymentTxLink,
                })}
              >
                {referrer.paymentTxLink ? (
                  <a className="referrer-info__link" href={referrer.paymentTxLink}>
                    {t("paid")}
                  </a>
                ) : (
                  t("in proccess")
                )}
              </div>
            </div>
          </div>
        </div>
      </RocketLoader>
    </div>
  );
};

export default ReferrerInfo;
