import { useGetDataClaimQuery } from "app/hooks";
import { useBalanceService } from "app/hooks/use-balance-service";
import cn from "classnames";
import { TierTypes } from "graphql/account-query";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Card from "../card/card";
import ClaimProgress from "../claim-progress/claim-progress";
import StackingView from "../stacking-view/stacking-view";

type Props = {
  address: string;
};

const BnbAccountBlockConnected: FC<Props> = ({ address }) => {
  const { t, i18n } = useTranslation();

  const {
    accountResponse: {
      data: accountData,
      loading: isAccountDataLoading,
      error: accountDataError,
      refetch: accountDataRefetch,
    },
    syncBalanceResponse: [, { error: syncAccountError, loading: syncAccountLoading }],
    syncAccountData,
  } = useBalanceService({ address });

  const { data, isLoading: isClaimDataLoading } = useGetDataClaimQuery();

  return (
    <div
      className={cn(
        "account-block__content"
        // "absolute translate-x-full opacity-0 -z-30"
      )}
    >
      {/* <div className="account-block__title ">{t("accountInfo.title")}</div> */}
      <div className="account-block__grid ">
        {/* <div className="account-block__info">
          <Card size="sm" bg={iAccountInfo}>
            <AccountInfo
              isAccountDataLoading={isAccountDataLoading}
              address={address}
              account={accountData?.account}
            />
          </Card>
          <Card size="sm">
            <MyTier
              isAccountDataLoading={isAccountDataLoading}
              account={accountData?.account}
            />
          </Card>
        </div> */}
        <div className="account-block__sacking ">
          <Card soon={false} soonText="Staking" size="sm">
            <StackingView address={address} syncAccountData={syncAccountData} />
          </Card>
        </div>
        <div className="account-block__claim ">
          <Card soon={false} soonText="Claim progress" size="sm">
            <ClaimProgress
              claimData={data}
              address={address}
              tier={accountData?.account.tier as TierTypes | undefined}
              syncAccountData={syncAccountData}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default BnbAccountBlockConnected;
