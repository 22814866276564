import cn from "classnames";
import { ReactNode } from "react";
import Loader from "./loader";

export enum ButtonType {
  DEFAULT = "DEFAULT",
  DANGER_TRANSPARENT = "DANGER_TRANSPARENT",
  TRANSPARENT = "TRANSPARENT",
  DISABLED_TRANSPARENT = "DISABLED_TRANSPARENT",
  ACCENT = "ACCENT",
  ACCENT_ALT = "ACCENT_ALT",
  BADGE = "BADGE",
}
export enum ButtonSize {
  DEFAULT = "DEFAULT",
  LG = "LG",
  SM = "SM",
}

type TProps = {
  className?: string;
  text?: string;
  icon?: ReactNode;
  type?: ButtonType;
  size?: ButtonSize;
  narrow?: boolean;
  square?: boolean;
  spin?: boolean;
  onClick?: VoidFunction;
};

const Button: React.FC<TProps> = ({
  className,
  icon,
  text,
  type = ButtonType.DEFAULT,
  size = ButtonSize.DEFAULT,
  narrow = false,
  square = false,
  spin,
  onClick = () => {},
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "flex cursor-pointer items-center justify-center gap-2 transition-all",
        className,
        {
          "px-2": narrow && type !== ButtonType.BADGE,
          "px-4": !narrow && !square && type !== ButtonType.BADGE,
          "h-9 min-w-[2.25rem]": size === ButtonSize.DEFAULT,
          "h-11 min-w-[3rem]": size === ButtonSize.LG,
          "bg-bg_secondary hover:bg-bg_secondary_active": type === ButtonType.DEFAULT,
          "border-bg_secondary_active border-solid hover:bg-bg_secondary_active border bg-transparent hover:border-transparent":
            type === ButtonType.TRANSPARENT || type === ButtonType.DISABLED_TRANSPARENT,
          "bg-bg_grad_accent_alt hover:bg-bg_grad_accent_alt_active":
            type === ButtonType.ACCENT_ALT,
          "bg-bg_primary_30 rounded py-1 px-2": type === ButtonType.BADGE,
          "rounded-xl": type !== ButtonType.BADGE,
          "pointer-events-none opacity-80": spin,
          "pointer-events-none opacity-50": type === ButtonType.DISABLED_TRANSPARENT,
          "border-crimson_red hover:bg-soft_sky_10 border bg-transparent":
            type === ButtonType.DANGER_TRANSPARENT,
        }
      )}
    >
      {spin ? (
        <Loader />
      ) : (
        <>
          {icon && icon}
          {text && (
            <div
              className={cn("", {
                p3: size === ButtonSize.DEFAULT,
                p2: size === ButtonSize.LG,
                p5: size === ButtonSize.SM,
                "text-crimson_red": type === ButtonType.DANGER_TRANSPARENT,
                "text-text_accent": type === ButtonType.BADGE,
                "text-text_primary":
                  type === ButtonType.TRANSPARENT ||
                  type === ButtonType.DISABLED_TRANSPARENT ||
                  type === ButtonType.ACCENT ||
                  type === ButtonType.DEFAULT ||
                  type === ButtonType.ACCENT_ALT,
              })}
            >
              {text}
            </div>
          )}
        </>
      )}
    </button>
  );
};

export default Button;
