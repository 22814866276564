import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TonConnectUI } from "@tonconnect/ui-react";
import { JettonMinter, JettonWallet, tonClient } from "app/ton-lk/ton-services/ton";
import { Sdlp } from "app/ton-lk/ton-services/ton/sdlp";
import { sleep } from "sdk";
import { toNano } from "ton";
import { Address, Dictionary } from "ton-core";

export const tonApi = createApi({
  reducerPath: "tonApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    tonJBalance: builder.query<bigint, { tonAddr: string; jAddr: string }>({
      queryFn: async ({ tonAddr, jAddr }) => {
        try {
          const myTonAddr = Address.parse(tonAddr);
          const srcJContract = tonClient.open(JettonMinter.createFromAddress(Address.parse(jAddr)));
          const { address: srcJwAddress } = await srcJContract.getWalletAddress(myTonAddr);

          const srcJWalletContract = tonClient.open(JettonWallet.createFromAddress(srcJwAddress));
          return {
            data: (await srcJWalletContract.getBalance()).amount || BigInt(0),
          };
        } catch (error) {
          return {
            error: {
              status: 418,
              statusText: "something went wrong",
              data: error?.toString(),
            },
          };
        }
      },
    }),
    sdlpSwap: builder.mutation<
      null,
      {
        tonConnectUI: TonConnectUI;
        amount: bigint;
      }
    >({
      async queryFn({ tonConnectUI, amount }, api, extraOptions, baseQuery) {
        try {
          const myTonAddr = Address.parse(tonConnectUI.account?.address!);

          const srcJContract = tonClient.open(
            JettonMinter.createFromAddress(Address.parse(process.env.REACT_APP_PUBLIC_TON_GGR_ADDR))
          );

          const { address: srcJwAddress } = await srcJContract.getWalletAddress(myTonAddr);

          const sdlpContract = tonClient.open(
            Sdlp.createFromAddress(Address.parse(process.env.REACT_APP_PUBLIC_TON_SDLP_ADDR))
          );

          const msgBody = JettonWallet.buildSendTransferMsg({
            destAddr: sdlpContract.address,
            jettonAmount: amount,
            responseAddr: myTonAddr,
            customPayload: Dictionary.empty(),
            forwardToncoinAmount: toNano("0.3"),
            forwardPayload: Sdlp.buildSwapPayload(),
          });

          await tonConnectUI.sendTransaction({
            validUntil: Date.now() + 1000000,
            messages: [
              {
                address: srcJwAddress.toString(),
                amount: toNano("0.5").toString(),
                payload: msgBody.toBoc().toString("base64"),
              },
            ],
          });
          await sleep(15000);

          return {
            data: null,
          };
        } catch (error) {
          return {
            error: {
              status: 418,
              statusText: "something went wrong",
              data: error?.toString(),
            },
          };
        }
      },
    }),
  }),
});

export const { useTonJBalanceQuery, useSdlpSwapMutation } = tonApi;
