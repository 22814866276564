import cn from "classnames";
import { useTranslation } from "react-i18next";
import Button from "../button/button";
import "./claim-modal.sass";

interface IProps {
  className?: string;
  onSubmit?: () => Promise<void>;
  claimSum?: string;
  click?: any;
}

const ClaimModal: React.FC<IProps> = ({ className, claimSum, click }) => {
  const { t, i18n } = useTranslation();
  const data = {
    title: t("buttons.buttonClaim"),
    available: t("modal.claimModal.title"),
    button: t("buttons.buttonClaim"),
  };
  return (
    <div className={cn("claim-modal", className)}>
      <div className="claim-modal__title">{data.title}</div>
      <div className="claim-modal__subtitle">{data.available}</div>
      <div className="claim-modal__amount">{claimSum} GGR</div>
      <Button onClick={() => click()} className="claim-modal__button" title={data.button} />
    </div>
  );
};

export default ClaimModal;
