import { useActions, useReferrerStatistics } from "app/hooks";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactPaginateProps } from "react-paginate";
import { Referrer } from "sdk";
import { useReferralsCountQuery } from "store/api";
import RocketLoader from "../loader/rocket-loader";
import Pagination from "../pagination/pagination";
import ReferralInfo from "./referral-info";
import "./referrals.list.sass";

type Props = {
  referrer?: Referrer;
};

const ReferralsList: FC<Props> = ({ referrer }) => {
  const { t } = useTranslation();
  const { setActivePage } = useActions();
  const { data } = useReferralsCountQuery(referrer?.id || "", { skip: !referrer });
  const { referrals, isReferrerStatisticsLoading } = useReferrerStatistics(referrer?.id);

  const onPageChange: ReactPaginateProps["onPageChange"] = ({ selected }) => {
    setActivePage(selected);
  };

  return (
    <>
      <div className="referrals-list">
        <div className="referrals-list__header">
          <div className="referrals-list__key">{t("Address")}</div>
          <div className="referrals-list__key">{t("Amount")}</div>
          <div className="referrals-list__key">{t("Refund")}</div>
          <div className="referrals-list__key">{t("Award")}</div>
        </div>
        {!!referrals.length && (
          <RocketLoader className="referrals-list__loader" isLoading={isReferrerStatisticsLoading}>
            <div className="referrals-list__list">
              {referrals.map((referral) => (
                <ReferralInfo key={referral.address} referral={referral} project={referrer?.ido} />
              ))}
            </div>
          </RocketLoader>
        )}
        <Pagination
          className="referrals-list__pagination"
          itemsCount={data?.count}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
};

export default ReferralsList;
