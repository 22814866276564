import { ProjectStage } from "app/enums";
import { formatTimerNumber } from "app/utils";
import cn from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectProjectStage } from "store/slice";
import "./timer.sass";

type Props = {
  className?: string;
  title?: string;
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
};

const Timer: FC<Props> = ({ className, days, hours, minutes, seconds, title }) => {
  const { t } = useTranslation();
  const projectStage = useSelector(selectProjectStage);

  return (
    <div className={cn("timer", className)}>
      {title && <div className="timer__title">{t(title)}</div>}
      {projectStage === ProjectStage.whitelistTba ? (
        <div className="timer__tba">TBA</div>
      ) : projectStage === ProjectStage.ended ? (
        <div className="timer__tba">{t("IDO is over. Thanks for participation")}!</div>
      ) : (
        <div className="timer__list">
          {typeof days === "number" && (
            <div className="timer__item">
              <div className="timer__item-value">{formatTimerNumber(days)}</div>
              <div className="timer__item-key">{t("Days")}</div>
            </div>
          )}
          {typeof hours === "number" && (
            <div className="timer__item">
              <div className="timer__item-value">{formatTimerNumber(hours)}</div>
              <div className="timer__item-key">{t("Hours")}</div>
            </div>
          )}
          {typeof minutes === "number" && (
            <div className="timer__item">
              <div className="timer__item-value">{formatTimerNumber(minutes)}</div>
              <div className="timer__item-key">{t("Minutes")}</div>
            </div>
          )}
          {typeof seconds === "number" && (
            <div className="timer__item">
              <div className="timer__item-value">{formatTimerNumber(seconds)}</div>
              <div className="timer__item-key">{t("Seconds")}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Timer;
