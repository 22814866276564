import { useTranslation } from "react-i18next";

type TProps = { className?: string };

function ProjectSwap__Desc({ className = "" }: TProps) {
  const { t } = useTranslation();
  return (
    <div className="project-swap__desc">
      {t(
        "Now you have time to prepare the funds for swap. You will be able to redeem allocation after the timer expires"
      )}
    </div>
  );
}

export default ProjectSwap__Desc;
