import IdoTitlePostfix from "app/components/ido-title-postfix";
import ProjectEnded from "app/components/project/project-ended/project-ended";
import ProjectItemLinks from "app/components/project/project-item/project-item-links";
import ProjectManual from "app/components/project/project-manuals/project-manual";
import { ProjectNotification } from "app/components/project/project-notification/project-notification";
import ProjectPause from "app/components/project/project-pause/project-pause";
import ProjectRefund from "app/components/project/project-refund/project-refund";
import ProjectRequirements from "app/components/project/project-requirements";
import ProjectSwap from "app/components/project/project-swap/project-swap";
import ProjectWhitelist from "app/components/project/project-whitelist/project-whitelist";
import { ProjectStage } from "app/enums";
import cn from "classnames";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectProject } from "store/slice";
import IdoFcfs from "../../ido/ido-fcfs/ido-fcfs";
import IdoClaim from "../ido-claim/ido-claim";
import IdoFcfsHighTier from "../ido-fcfs/ido-fcfs__high-tier";
import IdoTimer from "../ido-timer";
import "./ido-wizard-mobile.sass";

type Props = {
  className?: string;
  ido: Ido;
};

const IdoWizardMobile: FC<Props> = ({ className, ido }) => {
  const { projectStage, projectLogo } = useSelector(selectProject);
  const [notification, setNotification] = useState('')

  return (
    <div className={cn("project-wizard-mobile", className)}>
      <>
        <div className="project-wizard-mobile__row">
          <div className="project__title">
            {projectLogo && (
              <div
                className="project__logo"
                style={{
                  backgroundImage: `url(${projectLogo} )`,
                  backgroundSize: "80%",
                }}
              />
            )}
            {ido.title}{" "}<IdoTitlePostfix projectId={ido?.id} />
          </div>
          <ProjectItemLinks project={ido} />
        </div>

        {projectStage <= ProjectStage.fcfs && (
          <div className="project-wizard-mobile__row">
            <ProjectManual project={ido} />
            <IdoTimer ido={ido} />
          </div>
        )}

        {projectStage <= ProjectStage.whitelist && (
          <div className="project-wizard-mobile__row">
            <ProjectRequirements project={ido} />
            <ProjectWhitelist project={ido} setNotification={setNotification} />
          </div>
        )}

        {projectStage >= ProjectStage.swapGap && projectStage < ProjectStage.fcfs && (
          <ProjectSwap project={ido} />
        )}

        {projectStage === ProjectStage.highTierFcfs && (
          <>
            <IdoFcfsHighTier ido={ido} />
          </>
        )}

        {projectStage === ProjectStage.fcfs && (
          <>
            <IdoFcfs ido={ido} />
          </>
        )}

        {projectStage >= ProjectStage.claimGap && projectStage <= ProjectStage.claim && (
          <div className="project-wizard-mobile__row">
            <IdoClaim ido={ido} />
          </div>
        )}

        {projectStage >= ProjectStage.refund && projectStage <= ProjectStage.refundPolicy && (
          <div className="project-wizard-mobile__row">
            <ProjectRefund project={ido} />
          </div>
        )}

        {projectStage === ProjectStage.pause && (
          <>
            <ProjectPause />
          </>
        )}

        {projectStage === ProjectStage.ended && (
          <>
            <ProjectEnded project={ido} />
          </>
        )}
      </>

      <ProjectNotification project={ido} notification={notification} />
    </div>
  );
};

export default IdoWizardMobile;
