import cn from "classnames";
import { t } from "i18next";
import Button from "../button/button";
import "./claim-modal.sass";

interface IProps {
  className?: string;
  onSubmit?: () => Promise<void>;
  withdrawSum?: string;
  click?: any;
}

const WithdrawModal: React.FC<IProps> = ({ className, withdrawSum, click }) => {
  const data = {
    title: t("buttons.buttonWithdraw"),
    available: t("modal.withdrawModal.text"),
    button: t("buttons.buttonWithdraw"),
  };
  return (
    <div className={cn("withdraw-modal", className)}>
      <div className="withdraw-modal__title">{data.title}</div>
      <div className="withdraw-modal__amount">{withdrawSum} GGR</div>
      <div className="withdraw-modal__subtitle">
        {data.available}{" "}
        <span className="withdraw-modal__subtitle-span">{t("modal.withdrawModal.subText")}</span>
      </div>

      <Button onClick={click} className="withdraw-modal__button" title={data.button} />
    </div>
  );
};

export default WithdrawModal;
