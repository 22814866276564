import { NETWORK_NAMES_MAP } from "sdk";

const ru = {
  "Connect Wallet": "Подключить Кошелек",
  About: "О нас",
  Projects: "Проекты",
  Staking: "Стейкинг",
  Buy: "Купить",
  "Apply Project": "Подать Заявку",
  "Account settings": "Личный кабинет",
  "Smart Investing": "Умное Инвестирование",
  "Log out": "Выйти",
  "About GAGARIN": "О\u00A0нас",
  "Stake GGR": "Стейкинг GGR",
  "Projects on GAGARIN": "Проекты на GAGARIN",
  "Want to launch your project on the GAGARIN Launchpad?":
    "Хотите запустить ваш проект на GAGARIN?",
  "Leave an application for acceleration and launch a project together with our experts":
    "Оставьте заявку и запустите проект вместе с нашими специалистами",
  "SOLIDITY.FINANCE AUDIT PASSED": "СМАРТ-КОНТРАКТЫ ПРОВЕРЕНЫ SOLIDITY.FINANCE",
  "WE ARE AUDITED": "АУДИТ ПРОЙДЕН",
  "apply to launch": "присоединиться к запуску",
  "Apply for IDO": "Заявка на IDO",
  "Apply for Incubation": "Заявка на <1></1>Инкубацию",
  "Terms of Services": "Условия обслуживания",
  "Privacy Policy": "Политика Конфиденциальности",
  Documentation: "Документация",
  New: "Новое",
  Soon: "Скоро",
  My: "Мои",
  All: "Все",
  Live: "Сейчас",
  Closed: "Закрытые",
  Back: "Назад",
  Timeline: "Таймлайн",
  Upcoming: "Ожидается",
  Swap: "Выкуп",
  FCFS: "FCFS",
  Filled: "Заполнен",
  Claimable: "Выплаты",
  Ended: "Окончен",
  Stage: "Стадия",
  Duration: "Продолжительность",
  "Whitelist start": "Открытие Вайтлиста",
  "Whitelist end": "Закрытие Вайтлиста",
  "Swap start": "Начало Выкупа",
  "Swap end": "Конец Выкупа",
  "FCFS start": "Старт FCFS",
  "FCFS end": "Конец FCFS",
  "High tier FCFS end": "Конец FCFS Высших Тиров",
  "Next claim": "Следующая Выплата",
  "Payments are over": "Выплаты окончены",
  "Starts on": "Открывается",
  "Ends on": "Закрывается",
  "Whitelist starts on": "Вайтлист открывается",
  "Whitelist ends on": "Вайтлист закрывается",
  "Swap starts on": "Выкуп начинается",
  "Swap ends on": "Выкуп заканчивается",
  "High tier FCFS ends on": "High tier FCFS заканчивается",
  "FCFS ends on": "FCFS заканчивается",
  "Starts after": "Начинается через",
  "Ends after": "Заканчивается через",
  "Whitelist starts after": "Вайтлист открывается через",
  "Whitelist ends after": "Вайтлист закрывается через",
  "Swap starts after": "Выкуп начинается через",
  "Swap ends after": "Выкуп заканчивается через",
  "FCFS ends after": "FCFS заканчивается через",
  "Next claim after": "Следующая Выплата через",
  "Next claim at": "Следующая Выплата",
  Refund: "Возврат",
  Refunded: "Возвращено",
  "You did not apply for IDO or your application was rejected":
    "Вы не подавали заявку в IDO или ваша заявка была отклонена",
  "You didn't win the allocation, but you can still participate in the FCFS. Follow the news":
    "Вы не выиграли аллокацию, но вы всё ещё можете поучаствовать в FCFS. Следите за новостями",
  "You didn't win the allocation, thank you for participation":
    "Вы не выиграли аллокацию, спасибо за участие",
  "Your application has been sent. The results of the lottery will be announced 24 hours before the start of IDO (Round 1).":
    "Ваша заявка принята. Итоги лотереи будут анонсированы за 24 часа до старта IDO (Раунд 1).",
  "Application has been accepted. The results will be published before the swap starts.":
    "Заявка одобрена. Результаты лотереи будут опубликованы до начала выкупа.",
  "Application was rejected. Check the correctness of social nicknames and try to apply again.":
    "Заявка отклонена. Проверьте корректность введенных никнеймов и попробуйте подать заявку снова.",
  "Apply to Whitelist": "Подать заявку",
  "Please make sure you have completed all the tasks on":
    "Убедитесь, пожалуйста, что вы выполнили все задания на",
  "Wallet address": "Адрес кошелька",
  Copied: "Скопировано",
  "Invesment History": "История Инвестиций",
  "No investmens yet": "Инвестиций пока нет",
  "My Account": "Мой аккаунт",
  Tomorrow: "Завтра",
  "My Tier": "Мой Тир",
  Earth: "Земля",
  Orbit: "Орбита",
  Galaxy: "Галактика",
  Universe: "Вселенная",
  "Mandatory social tasks": "Социальные задания обязательны",
  "Without social tasks": "Без социальных заданий",
  "Allocation via lottery": "Аллокация через лотерею",
  "Guaranteed allocation": "Гарантированная аллокация",
  "Guaranteed dynamic allocation": "Динамическая гарантированная аллокация",
  yes: "да",
  no: "нет",
  soon: "скоро",
  Min: "Мин",
  Max: "Макс",
  "Tier Bonus System": "Бонусная Система Тиров",
  Airdrop: "Airdrop",
  Commision: "Комиссия",
  Confirm: "Подтвердить",
  "Claim progress": "Прогресс Выплат",
  "Available For Claim": "Доступно для сбора",
  "Already Recieved": "Всего получено",
  "Total Allocation Power": "Всего Allocation Power",
  Claim: "Клейм",
  Next: "Следующая",
  Tokens: "Токены",
  Balance: "Баланс",
  Approve: "Разрешить",
  Approved: "Разрешено",
  swapButton: "Выкупить",
  partialSwapButton: "Выкупить {{amount}}",
  "Swap all": "Выкупить всё",
  "Insufficient balance": "Недостаточный баланс {{symbol}}",
  "Incorrect amount": "Некорректная сумма",
  "You paid in total": "Всего оплачено",
  "You will receive": "Вы получите",
  "There are no tokens available for redemption": "Нет токенов, доступных для выкупа.",
  "The pool is filled": "Пул заполнен",
  pitchdeck: "презентация",
  whitepaper: "документация",
  tokenomics: "токеномика",
  "Exchange Rate": "Курс Обмена",
  "Exchange Networks": "Сети Выкупа",
  "Total Raise": "Сумма Инвестиций",
  "Refund of funds": "Возврат средств",
  "You can return project tokens in the amount of the current claim":
    "Вы можете вернуть токены проекта в размере текущего клейма",
  "Refund of project tokens": "Возврат Токенов Проекта",
  "Reason for refund": "Причина возврата",
  "Specify the reason for the return": "Укажите причину возврата",
  "You can only return the entire amount": "Вы можете вернуть только указанную сумму",
  "Confirm refund": "Подтвердить возврат",
  Receiving: "Получение",
  Amount: "Сумма",
  "successfully returned, thank you for your participation":
    "успешно возвращены, спасибо за ваше участие",

  "successfully returned. The funds will be transferred to the wallet in the near future":
    "успешно возвращены. Средства будут переведены на кошелек в ближайшее время",
  "The time to return the project tokens is over, wait for the fund transfer to the wallet":
    "Время для возврата токенов проекта истекло, ожидайте перевода средств на кошелек",
  "View in": "Посмотреть на",
  "Complete a short survey and click the «Confirm refund» button. The amount of the refund is filled in automatically.":
    "Пройдите небольшой опрос и нажмите кнопку «Подтвердить возврат». Сумма возврата заполняется автоматически.",
  "Sign the transaction in a crypto wallet": "Подпишите транзакцию в криптокошельке",
  "Wait for the transaction response": "Дождитесь завершения транзакции",
  "Your funds successfully returned, thank you for your participation":
    "Ваши средства успешно возвращены, спасибо за ваше участие",
  "Return of tokens completed successfully. Wait for the transfer of funds to the wallet":
    "Возврат токенов успешно завершен. Ожидайте перевода средств на кошелек",
  "If you want a refund, then follow these steps":
    "Если вы хотите сделать возврат, выполните следующие действия.",
  "I have read the instructions": "Я прочитал(a) инструкции",
  Continue: "Продолжить",
  "Now you have time to prepare the funds for swap. You will be able to redeem allocation after the timer expires":
    "Теперь у вас есть время подготовить средства для выкупа. Вы сможете выкупить аллокацию после истечения таймера.",
  "The FСFS stage is underway, have time to buy additional tokens":
    "Этап FCFS идет полным ходом, успейте купить дополнительные токены",
  "You have successfully redeemed tokens! You will be able to receive them according to the distribution schedule.":
    "Вы успешно выкупили токены! Вы сможете получать их в соответствии с графиком выплат.",
  "Lottery is running": "Лотерея запущена.",
  "Please wait for the results": "Пожалуйста, дождитесь результатов.",
  "You have won the allocation": "Вы выиграли аллокацию",
  "Please prepare funds for swap": "Пожалуйста, подготовьте средства для выкупа",
  Lottery: "Лотерея",
  "Lottery results": "Результаты лотереи",
  Wait: "Ожидайте",
  "Project description": "Описание проекта",
  "Raise Currency": "Собираемая Монета",
  "Swap Amount": "Сумма Выкупа",
  "Claim Type": "Тип Выплат",
  "Claim on": "Выплаты на",
  "Advanced Protection System": "Расширенная Защита",
  "Protection System": "Защита",
  "No Refund": "Без Рефанда",
  Network: "Сеть",
  "Claim Network": "Сеть Клейма",
  "Payment Schedule": "График <0>Выплат</0>",
  Requirements: "Требования",
  "not required": "не требуется",
  "Tier not required": "Тир не требуется",
  required: "требуется",
  "Social activities": "Социальные активности",
  View: "Посмотреть",
  "IDO is over. Thanks for participation": "IDO окончено. Спасибо за участие",
  Days: "Дней",
  Hours: "Часов",
  Minutes: "Минут",
  Seconds: "Секунд",
  "No projects at this stage": "Нет проектов на этой стадии",
  "You have no active projects": "У вас нет активных проектов",
  "Applying for Whitelist": "Подача заявки в <0>Вайтлист</0>",
  "To apply to Whitelist you need to complete the tasks below":
    "Чтобы подать заявку на включение в Вайтлист, вам необходимо выполнить следующие задачи",
  "To apply to Whitelist you will need to complete the tasks below":
    "Выполните задания и примите участие в лотерее на включение в Вайтлист. Для инвесторов, имеющих Тир, при победе в лотерее Вайтлистинга, будет выделена дополнительная аллокация +10%.",
  "Participate in the Whitelist lottery":
    "Примите участие в лотерее Вайтлистинга и получите возможность участвовать в IDO без комиссии. Внимание! Если вы обладатель Тир на GAGARIN, вы гарантированно получаете аллокацию без участия в лотерее Вайтлистинга. Каждый участник лотереи, который имеет Тир на GAGARIN, и победивший в лотерее, получает +10% к выделенной аллокации. Подайте заявку, выполните задания и получите возможность участия в IDO с расширенными возможностями!",
  "Please make sure you have completed all the tasks and entered your contact information correctly so that we can check your social activities and accept your application.":
    "Убедитесь, пожалуйста, что вы выполнили все задания и правильно ввели ваши контактные данные, чтобы мы могли проверить выполнение социальных активностей и принять вашу заявку.",
  "Complete all tasks on the link": "Выполните все задания по ссылке",
  "click to go to SweepWidget": "нажмите для перехода в SweepWidget",
  "Send your nickname on social networks for verification":
    "Отправьте свой никнейм в социальных сетях для верификации",
  "Invalid characters. Allowed characters: a-z, 0-9, underscores.":
    "Недопустимые символы. Допустимые символы: a-z, 0-9, символы подчеркивания.",
  "Subscribe to telegram accounts": "Подпишитесь на telegram аккаунты",
  "Subscribe to twitter accounts": "Подпишитесь на twitter аккаунты",
  "After Whitelist opens, you will need to enter your social nicknames to check the completion of social tasks and accepts your application. You can subscribe now to be as prepared as possible for Whitelisting.":
    "После того, как откроется Вайтлист, вам нужно будет ввести свои никнеймы из соц. сетей, чтобы  мы могли проверить выполнение социальных активностей и принять вашу заявку. Вы можете подписаться прямо сейчас, чтобы быть максимально подготовленными к открытию Вайтлиста.",
  "There are GGR left until the next tier":
    "Осталось {{leftToNextTier}} GGR до перехода в следующий тир",
  "My IDO": "Мои IDO",
  "You are at top tier! Congratulations!": "Вы в самом высоком тире! Поздравляем!",
  Edit: "Редактировать",
  "Connect Smart Investing": "Подключить Умное Инвестирование",
  "Smart Investing is a form of investment where the investor provides funds that the gagarin platform invests in all projects placed on it automatically. The investor also receives income by airdrop at the end of the IDO":
    "Умное Инвестирование - это форма инвестирования, при которой инвестор предоставляет средства, которые платформа gagarin инвестирует во все размещенные на ней проекты автоматически. Инвестор также получает свою прибыль через airdrop в конце IDO",
  "This service is available to everyone. Fill in the fields to activate the service. As a payment for auto investment,a commission will be charged":
    "Эта услуга доступна каждому. Заполните поля, чтобы активировать услугу. В качестве оплаты за автоинвестирование будет взиматься комиссия",
  "Enter the amount to invest in USDT or BUSD. Then you will be able to supplement the balance of both currencies":
    "Введите сумму для инвестирования в USDT или BUSD. Тогда вы сможете пополнить баланс обеих валют",
  "Participate in FCFS?": "Участвовать в FCFS?",
  "Enter the amount in ": "Введите сумму в ",
  "Disconnect Smart Investing": "Отключить Умное Инвестирование",
  Replenish: "Пополнить",
  "Refund the funds?": "<0>Вернуть</0> средства?",
  Notifications: "Уведомления",
  "connect to telegram bot": "подключить telegram бота",
  "telegram bot": "telegram бот",
  "Replenishment of the balance": "Пополнение баланса",
  "loading...": "загрузка...",
  "The possibility of partial redemption is available to you. You can redeem the desired amount within the available range.":
    "Вам доступна возможность частичного выкупа. Вы можете выкупить желаемую сумму в пределах доступного диапазона.",
  "project tokens that you haven't claimed yet": "токены проекта, которые вы ещё не забрали",
  "Project tokens for refund": "Токены проекта на возврат",
  "You can return the project tokens only once": "Вернуть токены проекта вы можете только один раз",
  "The site is under maintenance. It will be available again very soon":
    "Cайт находится на техническом обслуживании. Очень скоро он будет доступен снова",
  "Participants' funds have been successfully refunded, thank you for your participation":
    "Средства участников успешно возвращены, спасибо вам за ваше участие",
  "Swap amount limit in": "Лимит выкупа в {{currencySymbol}}",
  "FCFS amount limit in": "Лимит FCFS в {{currencySymbol}}",
  "Swap Limits": "Лимиты на стадии выкупа",
  "FCFS Limits": "Лимиты на стадии FCFS",
  "To description": "К описанию",
  "Choose Network": "Выберите сеть",
  "Choose Wallet": "Выберите кошелёк",
  "I read and accept": "Я прочитал и принимаю",
  and: "и",
  Accept: "Примите",
  "Refund Funds": "Вернуть Cредства",
  "You didn't make the last claim, so you don't need to return tokens for a refund":
    "Вы не делали последний клейм, поэтому вам не нужно возвращать токены для рефанда",
  "Refund Policy":
    "В течение <1>двух часов</1> после даты первой выплаты, вы можете сделать полный возврат средств. Если вы уже забрали токены проекта, возврат будет недоступен. Пожалуйста, обратите внимание, что после возврата, этот IDO будет закрыт для вас.",
  "cancel and back to claim": "отмена и возврат к выплатам",
  "You can only return the full amount of tokens": "Вы можете вернуть только полный объём токенов",
  "Join the private group of investors": "Присоединяйтесь к приватной группе инвесторов",
  "Why Choose CoinEx Smart Chain": "Почему выбирают CoinEx Smart Chain",
  "DApps on Ethereum can be transferred directly to CSC.":
    "DApps на  Ethereum можно напрямую перенести в CSC.",
  "Unique opportunities for the investors of CoinEx Smart Chain":
    "Уникальные возможности для инвесторов CoinEx Smart Chain",
  "The opportunity to redeem project tokens for stablecoins":
    "Возможность выкупа токенов проекта за стейблкоины",
  "Participate in the private round of GAGARIN and get all the bonuses":
    "Участвуй в приватном раунде GAGARIN  и получи все бонусы",
  "Learn about the early investor support program":
    "Узнать о программе поддержки ранних инвесторов",
  "Projects on CoinEx Smart Chain": "Проекты на CoinEx Smart Chain",
  "The best projects of the CSC network will soon be available":
    "Лучшие проекты в сети CSC скоро будут доступны",
  "Buy GGR": "Купить GGR",
  "Switch Network": "Переключить сеть",
  "You are on an unsupported network.": "Вы находитесь в неподдерживаемой сети.",
  "You have not redeemed in this network.": "Вы не выкупали в этой сети.",
  "Switch the network to continue.": "Переключите сеть, чтобы продолжить.",
  "For this IDO we need an additional information. Fill the fields below please.":
    "Для этого IDO нам нужна дополнительная информация. Пожалуйста, заполните поля ниже.",
  "Crosschain message":
    "Уважаемые инвесторы!<1></1>Обратите внимание, что депозит для приобретения токенов {{projectTitle}} ({{tokenSymbol}}) доступен в нескольких блокчейн-сетях: {{networksList}}.<9></9> Распределение токенов {{projectTitle}}  будет осуществляться только в {{projectNetwork}}!",
  "Something went wrong. We are already figuring out what's going on and will immediately inform you about the results":
    "Что-то пошло не так. Мы уже выясняем, что происходит, и немедленно сообщим вам о результатах",
  "This project has no activities yet": "У этого проекта пока нет активностей 🚀",
  "Claim Type message":
    "Уважаемые инвесторы! <1></1> Обратите внимание, что распределение токенов {{projectTitle}} ({{tokenSymbol}}) будет осуществляться проектом самостоятельно.",
  // "tokens left in": "{{redeemed}} <2>{{symbol}}</2> в {{networkName}}",
  "You also have": "У вас осталось",
  "wrong network refund message": "{{redeemed}} <2>{{symbol}}</2> в {{networkName}}",
  "Refund confirm modal desc":
    'Обратите внимание, нажимая на кнопку "Да" вы подтверждаете своё намерение отказаться от участия в данном IDO.<1></1><2>Это действие необратимо.</2>',
  "Refund confirm modal title": "<0>Вернуть</0> средства?",
  "Thanks for participating!": "Спасибо за участие!",
  projectEnded: "Вы получили <1></1><2></2><3></3><4></4><5></5><6></6>",
  after: "через",
  "Sportzchain message":
    "Уважаемые инвесторы! <1></1> GAGARIN дает уникальную возможность инвесторам Sportzchain. В течение недели от времени клейма тем инвесторам, которые не затребуют токены будут начислены токены SPN из расчета 1000% APR за 7 дней на сумму первого клейма!",
  "Referral program": "Реферальная программа",
  "Referral Projects": "Реферальные проекты",
  Referrals: "Рефералы",
  Award: "Награда",
  "Your Address": "Ваш Адресс",
  "Total Refund": "Общий рефанд",
  Status: "Статус",
  Address: "Адрес",
  "in proccess": "в процессе",
  paid: "оплачено",
  "First Name": "Имя",
  "Last Name": "Фамилия",
  Country: "Страна",
  "Passport Photo": "Фото Паспорта",
  "I consent to the provision and processing of personal data to the GAGARIN partner for the KYC procedure":
    "Я даю согласие на предоставление и обработку персональных данных партнеру GAGARIN для процедуры KYC",
  "Aftermath message":
    "Уважаемые инвесторы! <1></1> На приобретение токенов Aftermath Islands существуют ограничения, с которыми вы можете ознакомиться на сайте проекта.",
  "Boxes Count": "Количество кейсов",
  "One Box": "Цена одного кейса",
  "Box description": "Описание кейса",
  "There are no boxes available for redemption": "Нет кейсов, доступных для выкупа",
  "The boxes are sold": "Кейсы проданы",
  "Box title": "Название кейса",
  Quantity: "Количество",
  "The current round is available only to GAGARIN investors":
    "Этот раунд доступен только для инвесторов GAGARIN",
  "The current round is available only to Galaxy or Universe Tiers owners":
    "Этот раунд доступен только для обладателей тиров Галактика или Вселенная",
  "FCFS round for GAGARIN investors. Allocations are not guaranteed and can be repurchased.":
    "FCFS раунд для инвесторов GAGARIN. Аллокации не гарантированы и могут быть перекуплены.",
  from: "от",
  "My purchases": "Мои покупки",
  "Enter the count": "Введите количество",
  "Go to claim": "Перейти к клейму",
  "Dear investor, NFT claim will take place at the project site":
    "Уважаемый инвестор, клейм NFT будет происходить на площадке проекта",
  Title: "Название",
  Left: "Осталось",
  Start: "Старт",
  End: "Конец",
  Chance: "Шанс",
  ClaimAction: "Забрать",
  "Grab the best NFTs while you have a time!": "Хватайте лучшие NFT пока есть время!",
  "Nft list": "Список Nft",
  Price: "Цена",
  "You did not apply for INO": "Вы не принимали участия в INO",
  "Participate in ongoing token sales": "Участвуйте в текущих продажах токенов",
  "How to participate in IDO?": "Как принять участие в IDO?",
  "Join the telegram chat": "Присоединяйтесь к чату telegram",
  referalProgramPage: {
    title: "GAGARIN <1>реферальная программа</1>",
  },
  smartInvestingPage: {
    title: "Получайте прибыль и airdrops<1></1><2>автоматически</2>",
  },
  projectRefund: {
    title: "Стадия <1>возврата</1>",
    desc: "Вы можете вернуть средства, потраченные на IDO этого проекта. <1></1> Если вы вернете средства, вы <3>больше не сможете</3> участвовать в этом IDO",
  },
  accountPage: {
    buy: "покупка",
    claim: "вывод",
  },
  mainPage: {
    title:
      "Экспертная <1></1> платформа для\u00A0<3>инкубации</3>\u00A0<5></5><6>проектов</6> и <8>запуска\u00A0IDO</8>",
    desc: "Запускаемые нами проекты получают полную техническую и маркетинговую поддержку. Не просто запускаем проекты, но помогаем им преодолеть\u00A0гравитацию!",
  },
  tags: {
    upcoming: "ожидается",
    live: "сейчас",
    closed: "закрыт",
    refund: "возврат",
    refunded: "возвращено",
    bsc: "BNB Chain",
    polygon: "polygon",
    okc: "okc",
    coinex: "coinex",
    csc: "csc",
    solana: "solana",
    postponed: "отложен",
    expanded: "расширен",
    ...NETWORK_NAMES_MAP,
  },
  projectFill: {
    filled: "Продажи завершены. <1></1> Спасибо за ваше участие.",
  },
  links: {
    whitepaper: "https://gagarin.world/GAGARIN_Whitepaper_RU.pdf",
    tgChat: "https://t.me/GAGARIN_Launchpad_Chat_RU",
  },
  coinexPage: {
    title: "GAGARIN <1></1> интегрирован с <3></3> <4>CoinEx Smart Chain</4>",
    desc: "CoinEx Smart Chain (CSC)\u00A0\u2014 децентрализованный и\u00A0высокоэффективный публичный блокчейн с\u00A0нативным токеном $CET, текущая капитализация которого превышает 20\u00A0млн долларов. CSC полностью совместим с\u00A0экосистемой Ethereum, обладает производительностью до\u00A010\u00A0000 транзакций в\u00A0секунду и\u00A0низкой стоимостью транзакций (в\u00A0настоящее время менее 0,005 доллара США).",
    advantageDecentralization: "Полная <1></1> децентрализация",
    advantageDecentralizationText:
      "Сеть CoinEx Smart Chain <1></1> поддерживает до\u00A0101\u00A0валидатора, которые сортируются и\u00A0утверждаются в\u00A0зависимости от\u00A0их\u00A0ставки токенов CET.",
    advantageHighPerformanceText:
      "Блоки генерируются за\u00A0секунды, что позволяет сети иметь высокую пропускную способность и\u00A0низкую цену транзакций.",
    advantageCompatibility: "Полная <1></1> совместимость с EVM",
    advantageHighPerformance: "Высокая производительность <1></1> при низких комиссиях",
    opportunityTwo: "Участие в\u00A0приватном раунде GAGARIN",
    opportunityThree: "Первоочередное участие в\u00A0IDO проектов",
  },
  FCFSFee:
    "Уважаемые инвесторы! <1></1> Обратите ваше внимание, что на этапе FCFS взимается невозвратная комиссия 5% за участие в IDO, если у вас нет Тира. <4>Застейкате</4> токены GGR, чтобы участвовать в IDO без комиссии.",
  timeline: {
    claim: "Клейм",
  },

  //  new translation
  accountInfo: {
    LinkTonMyAccount: "https://ton.gagarin.world/app/ru/dashboard",
    title: "Информация об аккаунте", // Информация об аккаунте
    balance: "Баланс", // Баланс
    networks: "В других сетях", // В других сетях
    staking: "В стейкинге", // В стейкинге
    claiming: " В вестинге", // В вестинге
    inWallet: " В кошельке", // В кошельке
  },
  staking: {
    lockTime: "Время блокировки",
    title: "Стейкинг", // Стейкинг
    till: "Заблокировано до", // Заблокировано до
    totalStaking: "Всего", //  Всего
    initialAmount: "Стейк", //  Стейк
    rewardAmount: "Награда", //  Награда
    subInfo: {
      text1: "Заблокируйте", // Заблокируйте
      text2: "GGR чтобы получить следующий тир", // чтобы получить следующий тир
      subText: "Добавить", // Добавить
    },
    balance: "Баланс", // Баланс
  },
  modal: {
    confirmationTitle: "Вы уверены?", // Вы уверены?
    titleSuccess: "Успех", // Успех
    titleError: "Ошибка", // Ошибка
    yes: "ДА",
    no: "НЕТ",
    textError: "Что-то пошло не так", // Что-то пошло не так
    claimModal: {
      title: "Получить", // Получить
      text: "Доступно к получению", // Доступно к получению
      textSuccess: "Готово! Вы успешно получили свои GGR", // Готово! Вы успешно получили свои GGR
    },
    stakingModal: {
      text: "Получите больше застейкав еще раз!!", // Получите больше застейкав еще раз!
      subText: "Повторить стейк", // Повторить стейк
      success: "Готово! Вы успешно застейкали свои GGR!", // Готово! Вы успешно застейкали свои GGR
      emergencyWithdrawDescription:
        "Если вы выведете свои токены до даты разлока, вы не получите вознаграждение за стейкинг, а также потеряете 30% от первоначального стейка.",
      youWillGet: "вы получите",
    },
    claimModalPrivate: {
      title: " Что вы теряете", // Чт овы теряете
      text1: "на",
      text2: "Изменятся условия для получения тиров", // Изменятся условия для получения тиров
      text3: " Вы будете получать меньшую аллокацию на проектах", // Вы будете получать меньшую аллокацию на проектах
    },
    withdrawModal: {
      text: "Вы можете повторить ставку на эту сумму и заработать еще больше!", //Вы можете повторить ставку на эту сумму и заработать еще больше!
      subText: "Повторить", //Повторить ставку
    },
  },
  buttons: {
    buttonStake: "Застейкать", // Застейкать
    buttonChange: "Изменить", // Изменить
    buttonWithdraw: "Вывести", // Вывести
    buttonBuy: "Купить GGR", // Купить GGR
    buttonClaim: "Получить", // Gjkexbnm
  },
  tier: {
    title: " Мой тир", // Мой тир
    HowToGet: "Как получить Тир инвестора?",
    receivedTier: "Полученный тир", // Полученный тир
    nextTier: "Следующий тир", // Следующий тир
    tierEarth: {
      name: "Земля", // Замля
      lottery: "Лотерея", // Лотерея
      tasks: "Обязательные социальные активности", // Обязательные социальные активности
      airdrop: "Без эйрдропа", // Без эйрдропа
    },
    tierOrbit: {
      name: "Орбита", // Орбита
      lottery: " Гарантированная аллокация", // Гарантированная аллокация
      tasks: "Обязательные социальные активности", // Обязательные социальные активности
      airdrop: "Без эйрдропа", // Без эйрдропа
    },
    tierGalaxy: {
      name: "Галактика", // Галактика
      lottery: "Гарантированная аллокация", // Гарантированная аллокация
      tasks: "Обязательные социальные активности", // Обязательные социальные активности
      airdrop: "Эйрдроп", // Эйрдроп
    },
    tierUniverse: {
      name: "Вселенная", // Вселенная
      lottery: "Гарантированная аллокация", // Гарантированная аллокация
      tasks: "Не обязательные социальные активности", // Не обязательные социальные активности
      airdrop: "Эйрдроп", // Эйрдроп
    },
    sorry: "Извините, у вас недостаточно GGR чтобы получить тир", // Извините, у вас недостаточно GGR чтобы получить тир
    nft: {
      title: "Bonus NFTs",
    },
    totalAllocationPower: "Всего Allocation Power",
    forTheNextTier: "Для следующего тира",
  },
  claim: {
    noBalance: "Нет доступных для получения токенов GGR",
    title: "Вестинг", // вестинг
    informationUpdate: "Information update",
    nextClaim: "Следующий разлок", // Следующий разлок
    totalAmount: "Всего", // Всего
    alreadyReceived: "Уже получено", //
    privateRound: {
      title: "Закрытая продажа", // Закрытая продажа
      text: "10% на TGE, затем равными частями каждый месяц в течение 12 месяцев", // ТУТ НАДО ЕЩЕ ДОРАБОТАТЬ РАУНДЫ
    },
    Marketing2: {
      title: "Маркетинг #2",
      text: "100% через 90 дней после TGE",
    },
    Marketing3: {
      title: "Маркетинг #3",
      text: "100% через 24 часа после TGE",
    },
    availableForClaim: "Доступно для получения", //
    moreInfo: "Подробнее",
  },
  month: "M",
  year: "Г",
  week: "Н",
  workingFCFSRound1:
    "Комиссия на выкуп токенов 0%, доступен только для инвесторов, имеющих Тир 1-4.",
  workingFCFSRound2:
    "Доступен для всех, комиссия на выкуп токенов 5% (для инвесторов Тир 1-4 - 0%).",
  tonLaunchpad: "https://ton.gagarin.world/app/ru",
  "Applications for Whitelisting for the Paysenger Community Sale are now open. Attention! Only $GGR holders and Tier holders who have submitted an application for the whitelist are eligible to participate in the sale.":
    "Прием заявок на участие в Paysenger Community Sale. Внимание! Принять участие в продаже могут только владельцы $GGR, обладатели Тир, подавшие заявку на WhiteList!",
  signLoginMessageModalBody:
    "Пожалуйста, подпишите Аутентификационное сообщение в своем крипто-кошельке либо нажмите кнопку «Повторить», если запрос на подпись не был получен.",
  signLoginMessageModalTitle: "<0>Подключение</0> кошелька",
  retry: "Повторить",
  cancel: "Отменить",
  "In Wallet": "В кошельке",
  "Lock Time": "Время блокировки",
  "Locked Till": "Заблокировано до",
  "Initial Stake": "Изначальный стейк",
  "Restake GGR": "Застейкать GGR",
  Earnings: "Доход",
  Change: "Изменить",
  Withdraw: "Вывести",
  Withdrawal: "Вывод токенов",
  "Could you confirm whether you are aware that withdrawing tokens before the specified time will result in the loss of 30% of the initial stake and forfeiting any rewards?":
    "Если вы заберете токены раньше времени, то потеряете 30% изначального стейка и не получите вознаграждение, вы уверены?",
  "You will get": "Вы получите",
  "Please enter GGR amount": "Введите количество GGR",
  "Link EVM address": "Привязать EVM адрес",
  "Enter your address": "Введите ваш адрес",
  "Linking two addresses will allow you to enjoy the benefits of GGR from either one. Allocation Power, NFT bonuses and other balances are added up, allowing you to get a bigger tier!":
    "Связывание двух адресов позволит вам пользоваться преимуществами GGR с любого из них. Allocation Power, бонусы разных NFT и другие балансы суммируются, что позволяет вам получить более высокий тир!",
  "Great! You have successfully linked a EVM address!": "Отлично! Вы успешно привязали EVM адрес!",
  "Oops! Something went wrong, try again later or contact our support.":
    "Ой! Что-то пошло не так, попробуйте позже или обратитесь в нашу службу поддержки.",
  "Invalid address": "Неверный адрес",
  "Wait a bit...": "Подождите немного...",
  "Staking AP": "Стейкинг AP",
  "In vesting": "В вестинге",
  "Bonus AP": "Бонус AP",
  "GGR In Wallets": "GGR В Кошельках",
  Vesting: "Вестинг",
  "Next unlock": "Следующий разлок",
  Received: "Получено",
  Remaining: "Осталось",
  "What you will lose?": "Что вы потеряете?",
  "Claim anyway": "Забрать в любом случае",
  "We're grateful to our early investor for believing in our project. We've prepared bonuses for you, but you'll lose them if you claim the tokens. We value our friendship and hope to continue be together towards our goals!":
    "Мы благодарны ранним инвесторам за веру в наш проект. Мы приготовили для вас бонусы, но вы потеряете их, если заберете токены. Мы ценим нашу дружбу и надеемся продолжать идти вместе к достижению поставленых целей!",
  "If you will claim the tokens, please be aware that it will result in a loss of 15% APR on your initial deposit":
    "Если вы заберете токены, это приведет к потере 15% годовых на ваш изначальный депозит",
  "Claiming tokens may seem like an easy win, but it comes with a high cost. If you claim the tokens, you will need more Allocation Power to receive the Investor Tier, which could cost you in the long run":
    "Получение токенов может показаться легкой победой, но если вы заберете токены, вам потребуется больше Allocation Power, чтобы получить Тир инвестора, что может стоить вам больше в долгосрочной перспективе",
  "Claiming tokens may result in a reduction of your allocation for projects. It may impact your ability to participate in future IDO":
    "Получение токенов может привести к сокращению ваших аллокаций на IDO. Это может повлиять на вашу способность участвовать в IDO в будущем",
  "Unfortunately, no vesting tokens have been allocated for you":
    "К сожалению, для вас не было выделено токенов",
  title: "Информация об аккаунте",
  "Total allocation power": "Всего Allocation Power",
  "Bonus NFTs": "Бонусные NFT",
  "Only NFT that gives the biggest tier is taken into account":
    "Только одно NFT дающее наибольший Тир учитывается",
  "Looks like your Allocation Power is currently not enough to have a Tier. You can easily solve it your tier by purchasing and staking GGR tokens. Don't miss out opportunity to maximize your investment potential. Buy and stake GGR tokens now!":
    "Похоже у вас недостаточно Allocation Power, чтобы иметь Тир. Вы можете легко исправить это купив и застейкав токены GGR. Не упустите возможность максимизировать свой инвестиционный потенциал. Купите и застейкайте токены GGR прямо сейчас!",
  "Garanteed allocation": "Гарантированная аллокация",
  "Gives you Orbit tier, 1% cashback and guaranteed allocation up to $400":
    "Дает вам Тир Орбита, 1% кэшбэк и гарантированную аллокацию до $400",
  "Gives you Galaxy tier, 5% cashback and guaranteed allocation up to $600":
    "Дает вам Тир Галактика, 5% кэшбэк и гарантированную аллокацию до  to $600",
  "Your addresses are not linked. Only AP from BNB is taken into account. Please":
    "Ваши адреса не связаны. Только AP в сети BNB учитывается в подчете тира. Пожалуйста",
  "link your addresses": "свяжите ваши адреса",
  "You have": "У вас есть",
  "old GGR that are not taken into account when calculating the AP. You should upgrade GGR.":
    "старых GGR, которые не учитываются при расчете AP. Вам следует обновить их.",
  "This staking program has been finished. To get AP, please use staking V2":
    "Эта программа стейкинга завершена. Чтобы получить AP, используйте стейкинг V2",
};

export default ru;
