import { useMutation } from "app/hooks/root-contract/mutation/use-mutation";
import claimContract from "services/claim.contract";
import { useChainId } from "wagmi";

export const useVestingReleaseMutation = () => {
  const chainId = useChainId();
  const releaseMutation = async () => {
    return await claimContract.releasePopulate({
      address: process.env.REACT_APP_VESTING_CONTRACT_ADDR,
      chainId: chainId,
    });
  };
  return useMutation(releaseMutation);
};
