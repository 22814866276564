import { ProjectStage } from "app/enums";
import cn from "classnames";
import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectProjectStage } from "store/slice";
import ProjectEnded from "../../project/project-ended/project-ended";
import { ProjectNotification } from "../../project/project-notification/project-notification";
import ProjectPause from "../../project/project-pause/project-pause";
import ProjectRefund from "../../project/project-refund/project-refund";
import ProjectRequirements from "../../project/project-requirements";
import ProjectSwap from "../../project/project-swap/project-swap";
import ProjectWhitelist from "../../project/project-whitelist/project-whitelist";
import IdoClaim from "../ido-claim/ido-claim";
import IdoFcfs from "../ido-fcfs/ido-fcfs";
import IdoFcfsHighTier from "../ido-fcfs/ido-fcfs__high-tier";
import IdoTimer from "../ido-timer";
import "./ido-wizard.sass";

type Props = {
  className?: string;
  ido: Ido;
};

const IdoWizard: FC<Props> = ({ className = "", ido }) => {
  const projectStage = useSelector(selectProjectStage);

  const width = useMemo(() => {
    if (projectStage <= ProjectStage.fcfs) {
      return "50%";
    }

    return "100%";
  }, [projectStage, ido]);

  const [notification, setNotification] = useState('')

  return (
    <div className={cn("ido-wizard", className)}>
      <div className="ido-wizard__row">
        <div className="ido-wizard__col" style={{ width }}>
          {projectStage <= ProjectStage.whitelist && (
            <ProjectRequirements className="ido-wizard__requirements" project={ido} />
          )}

          {projectStage >= ProjectStage.swapGap && projectStage < ProjectStage.highTierFcfs && (
            <ProjectSwap className="ido-wizard__swap" project={ido} />
          )}

          {projectStage === ProjectStage.highTierFcfs && <IdoFcfsHighTier className="ido-wizard__swap" ido={ido} />}
          {projectStage === ProjectStage.fcfs && <IdoFcfs className="ido-wizard__swap" ido={ido} />}

          {projectStage >= ProjectStage.claimGap && projectStage <= ProjectStage.claim && (
            <IdoClaim ido={ido} />
          )}

          {projectStage >= ProjectStage.refund && projectStage <= ProjectStage.refundPolicy && (
            <ProjectRefund project={ido} />
          )}

          {projectStage === ProjectStage.pause && (
            <>
              <ProjectPause />
            </>
          )}

          {projectStage === ProjectStage.ended && (
            <>
              <ProjectEnded project={ido} />
            </>
          )}
        </div>

        {projectStage <= ProjectStage.fcfs && (
          <div className="ido-wizard__col">
            <div className="ido-wizard__timer">
              <IdoTimer ido={ido} />
            </div>
            {projectStage <= ProjectStage.whitelist && <ProjectWhitelist project={ido} setNotification={setNotification} />}
          </div>
        )}
      </div>
      <ProjectNotification project={ido} notification={notification} />
    </div>
  );
};

export default IdoWizard;
