import { useClaimCurrencySymbol, useProjectImage, useReferrerStatistics } from "app/hooks";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ImageType, Referrer } from "sdk";
import { useReferralsCountQuery } from "store/api";
import "./referrer-item.sass";

type Props = {
  referrer: Referrer;
  className?: string;
};

const ReferrerItem: FC<Props> = ({ referrer: { id, ido } }) => {
  const { t } = useTranslation();
  const logo = useProjectImage(ImageType.LOGO, ido?.id);
  const claimCurrencySymbol = useClaimCurrencySymbol(ido);
  const { data } = useReferralsCountQuery(id);
  const { award } = useReferrerStatistics(id);

  return (
    <Link className="referrer-item" to={`/ido/${ido?.id}/referrer`}>
      <div className="referrer-item__info-project">
        {(logo || ido?.idoLogoUrl) && (
          <img src={logo || ido?.idoLogoUrl} alt="projectLogo" className="referrer-item__logo" />
        )}
        {ido && <div className="referrer-item__title">{ido.title}</div>}
      </div>
      <div className="referrer-item__info">
        <div className="referrer-item__key">{t("Referrals")}:</div>
        <div className="referrer-item__value">{data?.count || 0}</div>
      </div>
      <div className="referrer-item__info">
        <div className="referrer-item__key">{t("Award")}:</div>
        <div className="referrer-item__value">
          {award} {claimCurrencySymbol}
        </div>
      </div>
    </Link>
  );
};

export default ReferrerItem;
