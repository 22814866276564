import i18next from "app/localisation/config";
import { useMemo } from "react";
import { BaseProject, Ido, ProjectDates } from "sdk";
import { formatDate, getNextClaimByProject } from "../utils";

/**
 * @returns Date string "DD MMMM, YYYY HH:mm" format or "TBA"
 */
export const useWhitelistStart = (project?: BaseProject): string => {
  return useMemo(() => {
    if (!project || !project.whitelistStartDate) return "TBA";

    return formatDate(project.whitelistStartDate);
  }, [project, i18next.language]);
};

/**
 * @returns Date string "DD MMMM, YYYY HH:mm" format or "TBA"
 */
export const useWhitelistEnd = (project?: BaseProject): string => {
  return useMemo(() => {
    if (!project || !project.whitelistEndDate) return "TBA";

    return formatDate(project.whitelistEndDate);
  }, [project, i18next.language]);
};

/**
 * @returns Date string "DD MMMM, YYYY HH:mm" format or "TBA"
 */
export const useSwapStart = (project?: BaseProject): string => {
  return useMemo(() => {
    if (!project || !project.swapStartDate) return "TBA";

    return formatDate(project.swapStartDate);
  }, [project, i18next.language]);
};

/**
 * @returns Date string "DD MMMM, YYYY HH:mm" format or "TBA"
 */
export const useSwapEnd = (project?: BaseProject): string => {
  return useMemo(() => {
    if (!project || !project.swapEndDate) return "TBA";

    return formatDate(project.swapEndDate);
  }, [project, i18next.language]);
};

/**
 * @returns Date string "DD MMMM, YYYY HH:mm" format or "TBA"
 */
export const useFcfsStart = (project?: BaseProject): string => {
  return useMemo(() => {
    if (!project || !project.fcfsStartDate) return "TBA";

    return formatDate(project.fcfsStartDate);
  }, [project, i18next.language]);
};

/**
 * @returns Date string "DD MMMM, YYYY HH:mm" format or "TBA"
 */
export const useFcfsEnd = (project?: BaseProject): string => {
  return useMemo(() => {
    if (!project || !project.fcfsEndDate) return "TBA";

    return formatDate(project.fcfsEndDate);
  }, [project, i18next.language]);
};

/**
 * @returns Date string "DD MMMM, YYYY HH:mm" format or "TBA"
 */
export const useHighTierFcfsEnd = (project?: BaseProject): string => {
  return useMemo(() => {
    if (!project || !project.highTierFcfsEndDate) return "TBA";

    return formatDate(project.highTierFcfsEndDate);
  }, [project, i18next.language]);
};

/**
 *
 *
 * @returns {string} Date string "DD MMMM, YYYY HH:mm" format
 * @returns {string} or "TBA"
 * @returns {string} or "Payments are over"
 */
export const useNextClaimByProject = (project?: Ido, index: number = 0) => {
  return useMemo(() => {
    const nextClaim = getNextClaimByProject(project, index);
    let nextClaimView = "TBA";
    let nextClaimDate = 0;

    if (typeof nextClaim === "number") {
      nextClaimDate = nextClaim;
      nextClaimView = formatDate(nextClaim);
    } else {
      nextClaimView = nextClaim;
    }
    return { nextClaimView, nextClaimDate };
  }, [project, i18next.language]);
};

export const useStageDate = (project: BaseProject, projectFieldName: keyof ProjectDates) => {
  return useMemo(() => {
    if (!project || !projectFieldName || !project[projectFieldName]) return "TBA";
    //@ts-ignore
    return formatDate(project[projectFieldName]);
  }, [project, projectFieldName]);
};
