import { useTonConnectUI } from "@tonconnect/ui-react";
import CoinexPage from "app/components/coinex-page/coinex-page";
import ProjectRedirect from "app/components/project/project-redirect";
import { useAuth } from "app/hooks";
import { FC, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AccountPage from "./account.page";
import IdoPage from "./ido.page";
import MainPage from "./main.page";
import ReferalProgramPage from "./referral-program.page";
import ReferrerPage from "./referrer.page";

export type Params = {
  idoId: string;
  inoId: string;
};

type AppRoute = {
  path: Path;
  component: JSX.Element;
  exact?: boolean;
};

export enum Path {
  MAIN = "/",
  IDO_LIST = "/",
  IDO = "/ido/:idoId",
  OLD_IDO = "/projects/:idoId",
  ACCOUNT = "/account",
  COINEX = "/coinex",
  REFERRAL_PROGRAM = "/referral-program",
  REFERRER = "/ido/:idoId/referrer",
}

const publicRoutes: AppRoute[] = [
  {
    path: Path.MAIN,
    component: <MainPage />,
    exact: true,
  },
  {
    path: Path.OLD_IDO,
    component: <ProjectRedirect />,
    exact: true,
  },
  {
    path: Path.IDO_LIST,
    component: <MainPage />,
    exact: true,
  },
  {
    path: Path.IDO,
    component: <IdoPage />,
    exact: true,
  },
  {
    path: Path.COINEX,
    component: <CoinexPage />,
    exact: true,
  },
  {
    path: Path.ACCOUNT,
    component: <AccountPage />,
    exact: true,
  },
];

const privateRoutes: AppRoute[] = [
  {
    path: Path.REFERRAL_PROGRAM,
    component: <ReferalProgramPage />,
    exact: true,
  },
  {
    path: Path.REFERRER,
    component: <ReferrerPage />,
    exact: true,
  },
];

const Pages: FC = () => {
  const isAuthBnb = useAuth();
  const [tonConnectUI] = useTonConnectUI();
  function isAuth() {
    if (isAuthBnb || tonConnectUI.connected) {
      return true;
    }
    return false;
  }

  const routes = useMemo(
    () => (isAuth() ? [...publicRoutes, ...privateRoutes] : publicRoutes),
    [isAuth()]
  );

  return (
    <Switch>
      {routes.map(({ path, exact, component }) => (
        <Route path={path} exact={exact} key={path}>
          {component}
        </Route>
      ))}
      <Redirect to={Path.MAIN} />
    </Switch>
  );
};

export default Pages;
