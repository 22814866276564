import { useEffect, useState } from "react";
import { USER_LOGIN_SIGNATURE_KEY, sleep } from "sdk";
import { useLoginMutation, useRefreshMutation } from "store/api";
import { ConnectorData, useAccount, useChainId, useDisconnect, useSignMessage } from "wagmi";
import { default as TokenService, default as tokenService } from "../../services/token.service";
import { useActions } from "./use-actions";

export const useLogin = () => {
  const { disconnect } = useDisconnect();
  const { signMessageAsync, status } = useSignMessage();
  const chainId = useChainId();
  const [isMessageSigning, setIsMessageSigning] = useState(false);
  const { closeModal } = useActions();
  const { connector: activeConnector } = useAccount();

  const [login, loginResponse] = useLoginMutation();
  const loginMutation = async () => {
    try {
      await sleep(1000);
      setIsMessageSigning(true);
      const signature = await signMessageAsync({ message: USER_LOGIN_SIGNATURE_KEY });
      const token = await login({
        signature,
        chainId,
      });
      if ("data" in token) {
        TokenService.setToken(token.data);
        setIsMessageSigning(false);
        return true;
      } else {
        throw token.error;
      }
    } catch (error) {
      console.error(error);
      disconnect();
      setIsMessageSigning(false);
      return false;
    }
  };

  const [refresh, refreshResponse] = useRefreshMutation();

  const refreshMutation = async () => {
    try {
      if (!tokenService.tokens) throw new Error("not authorized");
      const token = await refresh(tokenService.tokens);
      if ("data" in token) {
        TokenService.setToken(token.data);
      } else {
        throw token.error;
      }
    } catch (error) {
      console.error(error);
      disconnect();
    }
  };

  useEffect(() => {
    const handleConnectorUpdate = async ({ account, chain }: ConnectorData) => {
      if (account) {
        console.log("new account", account);
        await loginMutation();
        closeModal();
      } else if (chain) {
        console.log("new chain", chain);
      }
    };
    if (activeConnector) {
      activeConnector.on("change", handleConnectorUpdate);
    }

    return () => activeConnector?.off("change", handleConnectorUpdate) as any;
  }, [activeConnector]);

  useEffect(() => {
    if (status === "error") {
      disconnect();
    }
  }, [status]);

  return {
    loginMutation: [loginMutation, loginResponse] as const,
    refreshMutation: [refreshMutation, refreshResponse] as const,
    isMessageSigning,
    setIsMessageSigning, // FIXME решение дерьмовое, но похоже ивент onDisconnect не работает
  };
};
