import cn from "classnames";
import "./claim-modal.sass";

interface IProps {
  className?: string;
  onSubmit?: () => Promise<void>;
  claimSum?: string;
  info: {
    title: string;
    info: string;
    success: boolean;
  };
}

const ClaimModalFinal: React.FC<IProps> = ({ className, claimSum, info }) => {
  return (
    <div className={cn("claim-modal-final", className)}>
      <div className={"claim-modal__title " + (info.success ? " _text-green" : " _text-red")}>
        {info.title}
      </div>
      <div className={"claim-modal__subtitle" + (info.success ? " _text-green" : " _text-red")}>
        {info.info}
      </div>
    </div>
  );
};

export default ClaimModalFinal;
