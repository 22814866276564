import { NOW_IN_SEC } from "app/constants";
import { getStakingContract } from "app/utils/contract-helper";
import { BigNumber } from "ethers";
import { ChainId } from "sdk";
import { useAccount, useChainId, useContractRead } from "wagmi";

export const useReleasableQuery = () => {
  const chainId = useChainId() as ChainId;
  const { address: account } = useAccount();
  const releasableQuery = useContractRead({
    chainId,
    ...getStakingContract(chainId),
    functionName: "releasable",
    enabled: Boolean(account),
    args: account && [account, BigInt(NOW_IN_SEC)],
    watch: true,
    select(data) {
      return BigNumber.from(data);
    },
  });
  return releasableQuery;
};

// One of your dependencies, babel-preset-react-app, is importing the
// "@babel/plugin-proposal-private-property-in-object" package without
// declaring it in its dependencies. This is currently working because
// "@babel/plugin-proposal-private-property-in-object" is already in your
// node_modules folder for unrelated reasons, but it may break at any time.

// babel-preset-react-app is part of the create-react-app project, which
// is not maintianed anymore. It is thus unlikely that this bug will
// ever be fixed. Add "@babel/plugin-proposal-private-property-in-object" to
// your devDependencies to work around this error. This will make this message
// go away.
