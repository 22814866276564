import cn from "classnames";
import { FC } from "react";
import "./circle.sass";

type Props = {
  colorType?: "default" | "darker";
  height?: number;
  width?: number;
};

const Circle: FC<Props> = ({ height, width, colorType = "default" }) => {
  const items = new Array(12).fill(1);

  return (
    <div className="circle" style={{ height, width }}>
      <div className="circle__inner">
        {items.map((_, i) => (
          <div key={i} className={cn("circle__item", { _darker: colorType === "darker" })} />
        ))}
      </div>
    </div>
  );
};

export default Circle;
