import { ProjectStage } from "app/enums";
import { SwapStage, useCurrency, useIdoRedeemed, useSwapStage } from "app/hooks";
import iReqOk from "assets/img/icons/req-ok.svg";
import iWait from "assets/img/wait-ellipse.png";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectProjectStage } from "store/slice";

type Props = {
  className?: string;
  project: Ido;
};

const ProjectSwap__Success: FC<Props> = ({ className = "", project }) => {
  const { t } = useTranslation();
  const projectStage = useSelector(selectProjectStage);
  const { claimCurrencyIcon, claimCurrencySymbol, exchangeCurrencySymbol, exchangeCurrencyIcon } =
    useCurrency(project);
  const swapStage = useSwapStage(project);
  const { redeemedAmountView, redeemedTokensAmountView } = useIdoRedeemed(project);

  return (
    <>
      <div className="project-swap__body">
        <div className="project-swap__body-col">
          <div className="project-swap__body-title">{t("You paid in total")}</div>
          <div className="project-swap__body-value">
            {exchangeCurrencyIcon && (
              <img width={30} height={30} src={exchangeCurrencyIcon} alt={exchangeCurrencySymbol} />
            )}
            {redeemedAmountView}
          </div>
        </div>
        <div className="project-swap__body-col">
          <div className="project-swap__body-title">{t("You will receive")}</div>
          <div className="project-swap__body-value">
            {claimCurrencyIcon && (
              <img width={30} height={30} src={claimCurrencyIcon} alt={claimCurrencySymbol} />
            )}
            {redeemedTokensAmountView}
          </div>
        </div>
      </div>
      {projectStage === ProjectStage.highTierFcfs && (
        <div className="project-swap__wait">
          <img className="_g-rotate-anim" width={22} height={22} src={iWait} alt="wait" />
          {t("The FСFS stage is underway, have time to buy additional tokens")}
        </div>
      )}
      {projectStage === ProjectStage.fcfs && (
        <div className="project-swap__wait">
          <img className="_g-rotate-anim" width={22} height={22} src={iWait} alt="wait" />
          {t("The FСFS stage is underway, have time to buy additional tokens")}
        </div>
      )}
      {swapStage === SwapStage.S8_SWAP_WIN_NOT_REDEEM && (
        <div className="project-swap__partial">
          {t(
            "The possibility of partial redemption is available to you. You can redeem the desired amount within the available range."
          )}
        </div>
      )}
      {swapStage === SwapStage.S9_SWAP_WIN_REDEEM && (
        <div className="project-swap__win">
          <img src={iReqOk} alt="ok" />
          {t(
            "You have successfully redeemed tokens! You will be able to receive them according to the distribution schedule."
          )}
        </div>
      )}
    </>
  );
};

export default ProjectSwap__Success;
