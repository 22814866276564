import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { News, Notification } from "sdk";
import tokenService from "../../services/token.service";

export const projectApi = createApi({
  reducerPath: "projectApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ROOT,
    prepareHeaders: (headers) => {
      if (tokenService.tokens) {
        headers.set("authorization", `Bearer ${tokenService.tokens.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    projectNotifications: builder.query<Notification[], string>({
      query: (id) => `/projects/${id}/notifications`,
    }),

    projectNews: builder.query<News[], string>({
      query: (id) => `/projects/${id}/news`,
    }),
  }),
});

export const { useProjectNotificationsQuery, useProjectNewsQuery } = projectApi;
