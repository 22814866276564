import { getErc20Contract } from "app/utils/contract-helper";
import { BigNumber, constants } from "ethers";
import { useMemo } from "react";
import { ChainId } from "sdk";
import { HexString } from "sdk/src/types/hex-string";
import { useAccount, useChainId, useContractRead } from "wagmi";

export const useBalanceQuery = ({ token, chainId }: { token?: HexString; chainId?: ChainId }) => {
  const currentChainId = useChainId() as ChainId;
  const usedChainId = chainId || currentChainId;
  const { address: account } = useAccount();
  const contract = useMemo(() => {
    if (!token || token === constants.AddressZero) return;
    return getErc20Contract(usedChainId, token);
  }, [token]);
  const balanceResponse = useContractRead({
    chainId: usedChainId,
    ...contract,
    functionName: "balanceOf",
    enabled: Boolean(account),
    args: account && contract && [account],
    watch: true,
    select(data) {
      return BigNumber.from(data);
    },
  });
  return balanceResponse;
};
