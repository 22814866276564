import { ChainId, GagarinVesting, GagarinVestingAbi } from "sdk";

import { ContractService } from "./contract.service";
export const chainId = process.env.CHAIN_ID || ChainId.BINANCE_MAINNET;

export class ClaimContract extends ContractService {
  chainId =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? ChainId.BINANCE_MAINNET
      : ChainId.BINANCE_TESTNET;
  addressContract = process.env.REACT_APP_VESTING_CONTRACT_ADDR as string;

  constructor() {
    super(GagarinVestingAbi);
  }

  totalVestingSchedules({
    addressContract = this.addressContract,
    chainId = this.chainId,
  }: {
    addressContract?: string;
    chainId?: ChainId;
  }) {
    const contract = this._getContract<GagarinVesting>(addressContract, chainId);
    return contract.totalVestingSchedules();
  }

  vestingSchedule({
    vestingScheduleId,
    addressContract = this.addressContract,
    chainId = this.chainId,
  }: {
    vestingScheduleId: number;
    addressContract?: string;
    chainId?: ChainId;
  }) {
    const contract = this._getContract<GagarinVesting>(addressContract, chainId);
    return contract.vestingSchedule(vestingScheduleId);
  }
  receiverInfo({
    addressContract = this.addressContract,
    chainId = this.chainId,
    address,
    vestingScheduleId,
  }: {
    address: string;
    vestingScheduleId: number;
    addressContract?: string;
    chainId?: ChainId;
  }) {
    const contract = this._getContract<GagarinVesting>(addressContract, chainId);
    return contract["receiverInfo(address,uint256)"](address, vestingScheduleId);
  }
  releasePopulate({
    address = this.addressContract,
    chainId = this.chainId,
  }: {
    address?: string;
    chainId?: ChainId;
  }) {
    const contract = this._getContract<GagarinVesting>(address, chainId);
    return contract.populateTransaction["release()"]();
  }
  releasable({
    addressContract = this.addressContract,
    chainId = this.chainId,
    address,
    vestingScheduleId,
    timestamp,
  }: {
    addressContract?: string;
    chainId?: ChainId;
    address: string;
    vestingScheduleId: number;
    timestamp: number;
  }) {
    const contract = this._getContract<GagarinVesting>(addressContract, chainId);
    return contract["releasable(address,uint256,uint256)"](address, vestingScheduleId, timestamp);
  }
  token({
    addressContract = this.addressContract,
    chainId = this.chainId,
  }: {
    addressContract?: string;
    chainId?: ChainId;
  }) {
    const contract = this._getContract<GagarinVesting>(addressContract, chainId);
    return contract.token();
  }
}
const claimContract = new ClaimContract();

export default claimContract;
