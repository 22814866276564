import { ReactComponent as CheckedIcon } from "assets/img/checkbox-checked.svg";
import cn from "classnames";
import { ChangeEventHandler, FC } from "react";
import "./checkbox.sass";

type Props = {
  className?: string;
  checked: boolean;
  setChecked: (value: boolean) => void;
};

const Checkbox: FC<Props> = ({ className, checked, setChecked, children }) => {
  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { checked },
  }) => {
    setChecked(checked);
  };

  return (
    <label className={cn(className, "checkbox")}>
      <input
        className="checkbox__input"
        value={""}
        checked={checked}
        onChange={onChange}
        type="checkbox"
      />
      <div className="checkbox__fake">
        <CheckedIcon className="checkbox__fake-icon" />
      </div>
      {children}
    </label>
  );
};

export default Checkbox;
