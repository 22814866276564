import { useAuth, useWidth } from "app/hooks";
import iCoinexBackgroundLeft from "assets/img/coinex-background-left.svg";
import iCoinexBackgroundMobile from "assets/img/coinex-background-mobile.svg";
import iCoinexBackgroundRight from "assets/img/coinex-background-right.svg";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import CheckAuth from "../check-auth";
import AdvantagesBlock from "./advantages-coinex";
import "./coinex-page.sass";
import NetworksCoinex from "./networks-coinex";
import OpportunitiesCoinex from "./opportunities-coinex";
import ProjectsCoinex from "./projects-coinex";

const CoinexPage: FC = () => {
  useTranslation();
  const isAuth = useAuth();
  const { isMobileWidth, isTabletWidth } = useWidth();

  return (
    <div className="page">
      <div className="coinex-page">
        <div className="page__inner">
          <div className="page__title">
            <Trans i18nKey="coinexPage.title">
              GAGARIN is {isMobileWidth && <br />} integrated with <br />
              <span className="_g-text-active-lighter"> CoinEx Smart Chain</span>
            </Trans>
          </div>
          <div className="page__desc">
            <Trans i18nKey="coinexPage.desc">
              CoinEx Smart Chain (CSC) is&nbsp;a&nbsp;decentralized and highly efficient public
              blockchain, whose native $CET token has a&nbsp;current capitalization of&nbsp;over
              $20&nbsp;million. CSC is&nbsp;fully compatible with the Ethereum ecosystem, can
              perform up&nbsp;to&nbsp;10,000 transactions per second and has a&nbsp;low transaction
              cost (currently less than $0.005).
            </Trans>
          </div>
          {!isAuth && (
            <CheckAuth
              maxWidth="unlimited"
              className="coinex-page__button-block"
              buttonClassName="coinex-page__button"
            />
          )}
          <div className="page__inner">
            <AdvantagesBlock />
            <OpportunitiesCoinex />
            <NetworksCoinex />
            <ProjectsCoinex />
          </div>
        </div>
        {!isMobileWidth && !isTabletWidth && (
          <>
            <img className="coinex-page__background left" src={iCoinexBackgroundLeft} alt="" />
            <img className="coinex-page__background right" src={iCoinexBackgroundRight} alt="" />
          </>
        )}
        {(isMobileWidth || isTabletWidth) && (
          <img className="coinex-page__background mobile" src={iCoinexBackgroundMobile} alt="" />
        )}
      </div>
    </div>
  );
};

export default CoinexPage;
