import { useTonConnectUI } from "@tonconnect/ui-react";
import RocketLoader from "app/components/loader/rocket-loader";
import ProjectList from "app/components/project/project.list";
import { ACCOUNT_PROJECTS_FILTERS, NO_PROJECTS_MESSAGE_ACCOUNT } from "app/constants";
import { useActiveFilter, useAuth } from "app/hooks";
import AccountBlock from "app/ton-components/components/account-block/account-block";
import AgreeModal from "app/ton-components/components/agree-modal/agree-modal";
import ClaimModal from "app/ton-components/components/claim-modal/claim-modal";
import Modal from "app/ton-components/components/modal/modal";
import PrivateClaimModal from "app/ton-components/components/private-claim-modal/private-claim-modal";
import { BridgeWidget } from "bridge-widget/bridge-widget";
import { FC, useState } from "react";
import { Redirect } from "react-router-dom";
import { useProjectsParticipatedByMeQuery } from "store/api";
import { useAccount } from "wagmi";
import { Path } from ".";
import "./account.page.sass";

type Props = {};

const AccountPage: FC<Props> = () => {
  const { address } = useAccount();
  const [isOpenPrivateClaimModal, setIsOpenPrivateClaimModal] = useState(false);
  const [isOpenAgreeModal, setIsOpenAgreeModal] = useState(false);
  const [isOpenClaimModal, setIsOpenClaimModal] = useState(false);
  const accountActiveFilter = useActiveFilter();
  const [tonConnectUI] = useTonConnectUI();
  const isAuthBnb = useAuth();

  const {
    data: projects,
    isLoading,
    isFetching,
    refetch,
  } = useProjectsParticipatedByMeQuery(
    { address: address || "", activeFilter: accountActiveFilter },
    { refetchOnMountOrArgChange: true, skip: !address || !isAuthBnb }
  );

  function isAuth() {
    if (isAuthBnb || tonConnectUI.connected) {
      return true;
    }
    return false;
  }

  if (!isAuth()) {
    return <Redirect push to={Path.MAIN} />;
  }

  return (
    <RocketLoader isLoading={false /* isSwitchNetworkLoading */}>
      <div className="page">
        <div className="account">
          <div className="page__inner">
            <div className="page__block">
              <AccountBlock />
            </div>
          </div>
          <div className="mb-10">
            <div className="page__bridge-widget">
              <BridgeWidget className="" />
            </div>
          </div>
          {isAuthBnb && (
            <div className="page__block">
              <ProjectList
                title="My IDO"
                projects={projects}
                filters={ACCOUNT_PROJECTS_FILTERS}
                isLoading={isLoading || isFetching}
                align="center"
                noProjectsMessage={NO_PROJECTS_MESSAGE_ACCOUNT}
              />
            </div>
          )}
        </div>
        <Modal
          isOpen={isOpenPrivateClaimModal}
          onRequestClose={() => {
            setIsOpenPrivateClaimModal(false);
          }}
        >
          <PrivateClaimModal />
        </Modal>
        <Modal
          isOpen={isOpenClaimModal}
          onRequestClose={() => {
            setIsOpenClaimModal(false);
          }}
        >
          <ClaimModal />
        </Modal>
        <Modal
          isOpen={isOpenAgreeModal}
          onRequestClose={() => {
            setIsOpenAgreeModal(false);
          }}
        >
          <AgreeModal
            onAgree={() => {}}
            onCancel={() => {
              setIsOpenAgreeModal(false);
            }}
          />
        </Modal>
      </div>
    </RocketLoader>
  );
};

export default AccountPage;
