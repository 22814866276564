import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./opportunities-coinex.sass";

const OpportunitiesCoinex: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="opportunities">
      <div className="opportunities__title">
        {t("Unique opportunities for the investors of CoinEx Smart Chain")}
      </div>
      <div className="coinex-page__block">
        <div className="coinex-page__content">
          <div className="opportunities__item">
            <div className="opportunities__item-num">1</div>
            <div className="opportunities__text">
              {t("The opportunity to redeem project tokens for stablecoins")}
            </div>
          </div>
          <div className="opportunities__item">
            <div className="opportunities__item-num">2</div>
            <div className="opportunities__text">
              <Trans i18nKey="coinexPage.opportunityTwo">
                Participation in&nbsp;the private round of&nbsp;GAGARIN
              </Trans>
            </div>
          </div>
          <div className="opportunities__item">
            <div className="opportunities__item-num">3</div>
            <div className="opportunities__text">
              <Trans i18nKey="coinexPage.opportunityThree">
                Priority participation in&nbsp;the IDOs of&nbsp;projects
              </Trans>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunitiesCoinex;
