import { SocialActivitiesForm } from "app/types/social-activities-form";
import { ReactComponent as TelegramIcon } from "assets/img/telegram-placeholder.svg";
import { ReactComponent as TwitterIcon } from "assets/img/twitter-placeholder.svg";
import cn from "classnames";
import { ChangeEventHandler, FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  form: SocialActivitiesForm;
  updateValue: (key: keyof SocialActivitiesForm, rawValue?: string) => void;
};

export const SocialActivitiesDefaultInputList: FC<Props> = ({
  form,
  updateValue,
}) => {
  const { t } = useTranslation();

  const onTelegramChange: ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    const telegram = value.match(/([a-zA-Z0-9]+_|[a-zA-Z0-9]+)+/g)?.join("");

    updateValue("telegram", telegram);
  };

  const onTwitterChange: ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    const twitter = value
      .match(/([_a-zA-Z0-9]+)+/g)
      ?.join("");

    updateValue("twitter", twitter);
  };

  return (
    <>
      <div className="social-activities__row-title">
        <div className="social-activities__row-title-count">1.</div>
        <div className="social-activities__row-title-text">
          {t("Send your nickname on social networks for verification")}
        </div>
      </div>
      <div className="social-activities__form-group">
        <div className="social-activities__form-input-wrapper">
          <input
            className="social-activities__form-input"
            placeholder={form.telegram.title}
            name={form.telegram.name}
            value={form.telegram.value}
            maxLength={33}
            onChange={onTelegramChange}
          />
          <div
            className={cn("social-activities__form-input-mask", {
              _active: !!form.telegram.value,
            })}
          >
            @
          </div>
          <TelegramIcon className="social-activities__form-input-icon" />
        </div>
        <div className="social-activities__form-input-wrapper">
          <input
            className="social-activities__form-input"
            placeholder={form.twitter.title}
            name={form.twitter.name}
            value={form.twitter.value}
            maxLength={16}
            onChange={onTwitterChange}
          />
          <div
            className={cn("social-activities__form-input-mask", {
              _active: !!form.twitter.value,
            })}
          >
            @
          </div>
          <TwitterIcon className="social-activities__form-input-icon" />
        </div>
      </div>
    </>
  );
};
