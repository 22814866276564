import Button from "app/components/button/button";
import Timer from "app/components/timer/timer";
import { ProjectStage } from "app/enums";
import {
  useActions,
  useAllowance,
  useApplication, useApproveMutation, useClaimCurrencySymbol,
  useExchangeCurrencySymbol,
  useInvestorInfo,
  useRedeemedByChains,
  useRefundAmount,
  useRefundApplication,
  useRefundMutation,
  useReturnProjectTokensMutation,
  useReturnTokensTimer
} from "app/hooks";
import { formatBigNumber } from "app/utils";
import { ReactComponent as BackIcon } from "assets/img/icons/back.svg";
import cn from "classnames";
import { constants } from "ethers";
import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ChainId, Ido, ROOT_ADDRESSES_MAP, RefundType } from "sdk";
import { AppDispatch } from "store";
import { useCreateRefundApplicationMutation } from "store/api";
import { selectProject } from "store/slice";
import { useChainId } from "wagmi";
import RefundConfirmModal from "./refund-confirm-modal";
import RefundModal from "./refund-modal";

type Props = {
  project: Ido;
};

const ProjectRefund__Body: FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const chainId = useChainId() as ChainId
  const { projectStage } = useSelector(selectProject);
  const [reason, setReason] = useState<string>("");
  const [hideModal, setHideModal] = useState<boolean>(false);
  const { openModal, closeModal, setIsImportantModal, setProjectStage, setModalProps } =
    useActions();
  const { investorInfo, isInvestorInfoLoading, refetchInvestorInfo } = useInvestorInfo(project)
  const [createRefundApplication, { isLoading: isCreateApplicationLoading }] =
    useCreateRefundApplicationMutation();
  const [approve, { isLoading: isApproveLoading }] = useApproveMutation()
  const isProjectTokensRefund = project.refundType === RefundType.RETURN_PROJECT_TOKENS
  const returnProjectTokensData = useMemo(() => {
    if (!isProjectTokensRefund) return
    const decimals = (project.claimCurrency && project.claimCurrency.length) ? project.claimCurrency[0].decimals : 0
    const claimCurrencyAddress = (project.claimCurrency && project.claimCurrency.length) ? project.claimCurrency[0].address : constants.AddressZero
    const returnAmount = formatBigNumber(investorInfo?.lastClaimAmount)
    return {
      returnAmount,
      decimals,
      claimCurrencyAddress
    }
  }, [project.claimCurrency, investorInfo])
  const { allowance, isAllowanceLoading, refetchAllowance } = useAllowance(returnProjectTokensData?.claimCurrencyAddress, returnProjectTokensData?.decimals)
  const { application } = useApplication(project);
  const { refundApplication, isRefundApplicationLoading, refetchRefundApplication } =
    useRefundApplication(project);
  const { refetchRedeemedByChains, isRedeemedByChainsLoading, isRedeemedByChainsFetching } =
    useRedeemedByChains(project);
  const [refund, { isLoading: isRefundTxLoading }] = useRefundMutation()
  const [returnProjectTokens, { isLoading: isReturnProjectTokensTxLoading }] = useReturnProjectTokensMutation()

  const exchangeCurrencySymbol = useExchangeCurrencySymbol(project);
  const claimCurrencySymbol = useClaimCurrencySymbol(project)
  const { refundAmountView } = useRefundAmount(project);

  const confirmRefund = useCallback(async () => {
    await createRefundApplication({
      applicationId: application?.id,
      reason,
      chainId,
    });
    await refund({ id: project.id, chainId })
    refetchInvestorInfo();
    refetchRefundApplication();
    refetchRedeemedByChains();
    closeModal();
  }, [application, reason, chainId, project]);

  const confirmReturnTokens = useCallback(async () => {
    await returnProjectTokens({ id: project.id, chainId })
    refetchInvestorInfo();
    refetchRefundApplication();
    refetchRedeemedByChains();
    closeModal();
  }, [application, chainId, project]);

  const onRefundClick = () => {
    if (investorInfo && !investorInfo.redeemed.isZero()) {
      return openModal(<RefundConfirmModal />);
    }

    confirmRefund();
  };

  const onReturnTokensClick = () => {
    confirmReturnTokens()
  }

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target: { value } }) => {
    setReason(value);
  };

  const backToClaim = () => {
    setProjectStage(ProjectStage.claim);
  };

  const isLoading = useMemo(() => {
    return (
      isRefundTxLoading ||
      isReturnProjectTokensTxLoading ||
      isCreateApplicationLoading ||
      isRedeemedByChainsLoading ||
      isRedeemedByChainsFetching ||
      isInvestorInfoLoading ||
      isApproveLoading ||
      isAllowanceLoading
    );
  }, [
    isRefundTxLoading,
    isReturnProjectTokensTxLoading,
    isCreateApplicationLoading,
    isRedeemedByChainsLoading,
    isRedeemedByChainsFetching,
    isInvestorInfoLoading,
    isApproveLoading,
    isAllowanceLoading
  ]);

  useEffect(() => {
    if (isRefundApplicationLoading) return;

    if (refundApplication) {
      setReason(refundApplication.reason || "");
      return;
    }

    if (hideModal) return;

    setIsImportantModal(true);
    openModal(<RefundModal project={project} />);
    setHideModal(true);
  }, [refundApplication, isRefundApplicationLoading, hideModal]);

  useEffect(() => {
    setModalProps({
      onConfirm: confirmRefund,
      isLoading,
    });
  }, [confirmRefund, isLoading]);

  useEffect(() => {
    return () => {
      setIsImportantModal(false);
      closeModal();
      setModalProps(undefined);
    };
  }, []);
  const returnTokensTimer = useReturnTokensTimer(project)

  const amount = useMemo(() => {
    if (isProjectTokensRefund) {
      return `${returnProjectTokensData?.returnAmount} ${claimCurrencySymbol}`
    } else {
      return `${refundAmountView} ${exchangeCurrencySymbol}`
    }
  }, [project.refundType, refundAmountView, exchangeCurrencySymbol,])

  return (
    <div className="project-refund__body">
      {!!returnTokensTimer && (<Timer {...returnTokensTimer} className="project-refund__timer" />)}
      <div className="project-refund__row">
        <div className="project-refund__title">{isProjectTokensRefund ? t("Refund of project tokens") : t('Refund of funds')}</div>
        <div className="project-refund__desc">
          {isProjectTokensRefund && (investorInfo?.lastClaimAmount.isZero() ? (
            <>{t("You didn't make the last claim, so you don't need to return tokens for a refund")}</>
          ) : (
            <>{t('You can return project tokens in the amount of the current claim')}</>
          ))}
          {!isProjectTokensRefund && (
            <Trans i18nKey="projectRefund.desc">
              You can return the funds spent on the IDO of this project. <br /> If you return the
              funds, you will <span>no longer be able</span> to participate in this IDO
            </Trans>
          )}
        </div>
      </div>
      <div className="project-refund__inner">
        {project.refundType !== RefundType.RETURN_PROJECT_TOKENS &&
          (<div className="project-refund__row">
            <div className="project-refund__title">{t("Reason for refund")}:</div>
            <div className="project-refund__input-container">
              <textarea
                value={reason}
                onChange={onChange}
                placeholder={t("Specify the reason for the return")}
                className="project-refund__input"
              />
            </div>
          </div>)
        }
        <div className="project-refund__row">
          <div className="project-refund__title">{t("Amount")}:</div>
          <div className="project-refund__amount-container">
            <div className="project-refund__amount">
              {amount}
            </div>
          </div>
          <div className="project-refund__row-desc">
            {t("You can only return the entire amount")}
          </div>
        </div>
        <div className="project-refund__button">
          {project.refundType === RefundType.RETURN_PROJECT_TOKENS ?
            (!!allowance && allowance.lt(investorInfo?.lastClaimAmount || '0')) ?
              (<Button onClick={async () => {
                await approve({
                  address: returnProjectTokensData?.claimCurrencyAddress || "",
                  spender: ROOT_ADDRESSES_MAP[project.network]
                })
                refetchAllowance()
              }} isLoading={isLoading} disabled={investorInfo?.lastClaimAmount.isZero()}>
                {`Approve ${claimCurrencySymbol}`}
              </Button>) :
              (<Button onClick={onReturnTokensClick} isLoading={isLoading} disabled={investorInfo?.lastClaimAmount.isZero()}>
                {t("Confirm return tokens")}
              </Button>) :
            (<Button onClick={onRefundClick} isLoading={isLoading} disabled={!reason}>
              {t("Confirm refund")}
            </Button>)}


          {projectStage === ProjectStage.refundPolicy && (
            <button
              className={cn("project-refund__title", "project-refund__back")}
              onClick={backToClaim}
              disabled={isLoading}
            >
              <BackIcon className="project-refund__back-icon" />
              {t("cancel and back to claim")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectRefund__Body;
