import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useActions, useAuth } from "app/hooks";

import { Path } from "app/pages";
import { ellipseAddress } from "app/ton-lk/utils";
import { ReactComponent as IconMetamask } from "assets/img/icons/icon-metamask.svg";
import { ReactComponent as IconTon } from "assets/img/icons/icon-ton.svg";
import LogoutIcon from "assets/img/logout.svg";
import { ReactComponent as ProfileIcon } from "assets/img/profile-icon.svg";
import { ReactComponent as SmartInvestingIcon } from "assets/img/smart-investing.svg";
import cn from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useReferrersQuery } from "store/api";
import { EWalletType, selectWalletType } from "store/slice";
import { useAccount, useDisconnect } from "wagmi";
import "./wallet-mobile.sass";
import "./wallet.sass";
type TProps = {
  closeMenu: () => void;
};

const WalletMobile: FC<TProps> = ({ closeMenu }) => {
  const { t } = useTranslation();
  const [tpeOpenUserMenu, setTypeUserMenu] = useState<"TON" | "BNB" | null>(null);
  const toggleUserMenu = (type: "TON" | "BNB" | null = null): void => {
    type === tpeOpenUserMenu ? setTypeUserMenu(null) : setTypeUserMenu(type);
  };
  const ref = useRef<HTMLDivElement>(null);

  ///bnb
  const { address } = useAccount();
  const isAuth = useAuth();
  const { openConnectModal } = useConnectModal();
  const { disconnect: disconnectBNB } = useDisconnect();
  const { data } = useReferrersQuery(address || "", { skip: !address });
  ///ton
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet(); //!NOTE - do not delete
  const currentAccount = tonConnectUI.account;
  const disconnectTon = async () => await tonConnectUI.disconnect();

  useEffect(() => {
    setTypeUserMenu(null);
  }, []);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setTypeUserMenu(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  const { setWalletType } = useActions();

  const walletType = useSelector(selectWalletType);

  return (
    <div className={cn("max-[1450px]:flex", "menu__wallet hidden flex-col gap-4 relative")}>
      <div className="text-2xl font-bold flex items-center justify-center text-center">
        Подключить Кошелек
      </div>
      <div className="rounded-2xl flex w-72 mx-auto uppercase cursor-pointer">
        <div
          onClick={async () =>
            tonConnectUI.connected ? toggleUserMenu("TON") : await tonConnectUI.openModal()
          }
          className={cn(
            "border-r-2 items-center justify-center rounded-l-2xl flex gap-2 border-solid border-[#212B44] p-4 w-1/2 ",
            {
              "bg-bg_grad_accent": !tonConnectUI.connected,
              "border-2 border-solid border-[#212B44]": tonConnectUI.connected,
            }
          )}
        >
          <IconTon className="wallet__img" />{" "}
          {tonConnectUI.connected ? ellipseAddress(currentAccount!.address, 3) : "ton"}
        </div>
        <div
          onClick={isAuth ? () => toggleUserMenu("BNB") : openConnectModal}
          className={cn("p-4 flex gap-2 items-center justify-center w-1/2 rounded-r-2xl", {
            "bg-bg_grad_accent_revers": !isAuth,
            "border-2 border-solid border-[#212B44]": isAuth,
          })}
        >
          <IconMetamask className="wallet__img" />
          {isAuth ? ellipseAddress(address, 3) : "bnb"}
        </div>
      </div>

      {tpeOpenUserMenu !== null && (
        <div ref={ref} onClick={() => setTypeUserMenu(null)} className="absolute w-full top-14">
          {tpeOpenUserMenu === "TON" && (
            <div className={cn("wallet-mobile _opened")}>
              <Link
                className="wallet-mobile__item"
                to={Path.ACCOUNT}
                onClick={() => {
                  setWalletType(EWalletType.TON);
                  closeMenu();
                }}
              >
                <div className="wallet-mobile__img">
                  <ProfileIcon />
                </div>
                <div className="wallet-mobile__title">{t("Account settings")}</div>
                <IconTon className="wallet__img" />
              </Link>

              <div className="wallet-mobile__item" onClick={() => disconnectTon()}>
                <img src={LogoutIcon} alt="" className="wallet-mobile__img" />
                <div className="wallet-mobile__title-special">{t("Log out")}</div>
                <IconTon className="wallet__img" />
              </div>
            </div>
          )}
          {tpeOpenUserMenu === "BNB" && (
            <div className={cn("wallet-mobile _opened")}>
              <Link
                className="wallet-mobile__item"
                to={Path.ACCOUNT}
                onClick={() => {
                  setWalletType(EWalletType.BNB);
                  closeMenu();
                }}
              >
                <div className="wallet-mobile__img">
                  <ProfileIcon />
                </div>
                <div className="wallet-mobile__title">{t("Account settings")}</div>
                <IconMetamask className="wallet__img" />
              </Link>
              {data && !!data.length && (
                <Link
                  className="wallet-mobile__item"
                  to={Path.REFERRAL_PROGRAM}
                  onClick={() => {
                    setWalletType(EWalletType.BNB);
                    closeMenu();
                  }}
                >
                  <div className="wallet-mobile__img">
                    <SmartInvestingIcon />
                  </div>
                  <div className="wallet-mobile__title">{t("Referral program")}</div>
                  <IconMetamask className="wallet__img" />
                </Link>
              )}
              <div className="wallet-mobile__item" onClick={() => disconnectBNB()}>
                <img src={LogoutIcon} alt="" className="wallet-mobile__img" />
                <div className="wallet-mobile__title-special">{t("Log out")}</div>
                <IconMetamask className="wallet__img" />
              </div>
            </div>
          )}
          {/* {tpeOpenUserMenu === "BNB" && (
            <UserMenu
              isUserMenuOpened={isUserMenuOpened && isAuth}
              closeUserMenu={() => setIsUserMenuOpened(false)}
            />
          )}
          {tpeOpenUserMenu === "TON" && (
            <UserMenuTon
              isUserMenuOpened={isUserMenuOpened && tonConnectUI.connected}
              closeUserMenu={() => setIsUserMenuOpened(false)}
            />
          )} */}
        </div>
      )}
    </div>
  );
};
export default WalletMobile;
