import { MAIN_PROJECTS_FILTERS_WITHOUT_MY, NO_PROJECTS_MESSAGE_COINEX } from "app/constants";
import { useActiveFilter } from "app/hooks";
import { FC } from "react";
import { useCoinexProjectsQuery } from "store/api";
import ProjectList from "../project/project.list";
import "./projects-coinex.sass";

const ProjectsCoinex: FC = () => {
  const currentProjectStatusFilter = useActiveFilter();

  const {
    data: projects,
    isLoading,
    isFetching,
  } = useCoinexProjectsQuery(currentProjectStatusFilter);

  return (
    <div className="projects-coinex">
      <ProjectList
        title="Projects on CoinEx Smart Chain"
        projects={projects}
        filters={MAIN_PROJECTS_FILTERS_WITHOUT_MY}
        isLoading={isLoading || isFetching || !projects}
        noProjectsMessage={NO_PROJECTS_MESSAGE_COINEX}
      />
    </div>
  );
};

export default ProjectsCoinex;
