import { ChainId } from "../enums";
import { Image } from "./image";
import { MarketingActivity } from "./marketing-activity";
import { ModalField } from "./modal-field";
import { ProjectLink } from "./project-link";
import { Translation } from "./translation";

export enum ProjectStatus {
  NOT_DEPLOYED = "not deployed",
  UPCOMING = "upcoming",
  REDEEM = "redeem",
  HIGH_TIER_FCFS = "high tier fcfs",
  FCFS = "fcfs",
  CLAIM = "claim",
  DISTRIBUTED = "distributed",
}

export enum RefundType {
  NO_REFUND = "noRefund",
  WITHOUT_PROJECT_TOKENS = "withoutProjectTokens",
  REFUND_POLICY_WITHOUT_CLAIM = "refundPolicyWithoutClaim",
  REFUND_POLICY_ADVANCED = "refundPolicyAdvanced",
  RETURN_PROJECT_TOKENS = "returnProjectTokens",
  REFUNDED = "refunded",
}

export type ProjectDates = {
  whitelistStartDate: number; // Date.now()
  whitelistEndDate: number; // Date.now()
  swapStartDate: number; // Date.now()
  swapEndDate: number; // Date.now()
  highTierFcfsEndDate: number; // Date.now()
  fcfsEndDate: number; // Date.now()
  fcfsStartDate?: number;
  claimStartDate?: number;
};

export type BaseProject = ProjectDates & {
  id: string;
  title: string;
  weight?: number; // вес для сортировки
  createdAt: number; // Date.now()
  updatedAt: number; // Date.now()
  network: ChainId;
  status: ProjectStatus;
  refundType: RefundType;
  invested: string;
  claimType: string;

  /* relations */
  modalFields?: ModalField[];
  activities?: MarketingActivity[];
  translations?: Translation[];
  links?: ProjectLink[];
  images?: Image[];
};
