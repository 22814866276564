import InputNumber from "app/components/input-number/input-number";
import { ProjectStage } from "app/enums";
import { useActions, useApplication, useCurrency, useExchangeRate, useSwapAmount } from "app/hooks";
import { trim } from "app/utils";
import iSwapArrowRight from "assets/img/icons/swap-arrow-right.svg";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectProjectStage, selectSwapInputValue } from "store/slice";
import ProjectSwap__UserBalance from "./project-swap__user-balance";

type Props = {
  className?: string;
  project: Ido;
};

const ProjectSwap__Body: FC<Props> = ({ project }: Props) => {
  const { t } = useTranslation();
  const { setSwapInputValue } = useActions();
  const swapInputValue = useSelector(selectSwapInputValue);
  const projectStage = useSelector(selectProjectStage);
  const { application } = useApplication(project);
  const exchangeRate = useExchangeRate(project);
  const { claimCurrencyIcon, claimCurrencySymbol, exchangeCurrencySymbol, exchangeCurrencyIcon } =
    useCurrency(project);
  const { minSwapAmountView, maxSwapAmountView, swapTokensAmount } = useSwapAmount(project);

  useEffect(() => {
    setSwapInputValue(trim(maxSwapAmountView));
  }, [maxSwapAmountView]);

  return (
    <div className="project-swap__body">
      <div className="project-swap__body-col">
        <div className="project-swap__body-title">{t("Amount")}</div>
        <div className="project-swap__body-value">
          {exchangeCurrencyIcon && (
            <img width={30} height={30} src={exchangeCurrencyIcon} alt={exchangeCurrencySymbol} />
          )}
          <InputNumber
            className="project-swap__body-input"
            value={swapInputValue}
            setValue={setSwapInputValue}
            variant="_blank"
            disabled={projectStage < ProjectStage.swap}
          />
        </div>
        {projectStage === ProjectStage.swap && (
          <>
            {application?.swappedAmount.isZero() && (
              <div
                className="project-swap__body-button"
                onClick={() => setSwapInputValue(trim(minSwapAmountView))}
              >
                {t("Min")} {minSwapAmountView}
              </div>
            )}
            <div
              className="project-swap__body-button"
              onClick={() => setSwapInputValue(trim(maxSwapAmountView))}
            >
              {t("Max")} {maxSwapAmountView}
            </div>
            <ProjectSwap__UserBalance project={project} inputValue={swapInputValue} setInputValue={setSwapInputValue} />
          </>
        )}
      </div>
      <img className="project-swap__body-arrow" src={iSwapArrowRight} alt="" />
      <div className="project-swap__body-col">
        <div className="project-swap__body-title">{t("Tokens")}</div>
        <div className="project-swap__body-value">
          {claimCurrencyIcon && (
            <img width={30} height={30} src={claimCurrencyIcon} alt={claimCurrencySymbol} />
          )}
          {swapTokensAmount}
        </div>
        <div className="project-swap__body-desc">
          1 {claimCurrencySymbol} = {exchangeRate} {exchangeCurrencySymbol}
        </div>
      </div>
    </div>
  );
};

export default ProjectSwap__Body;
