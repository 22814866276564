import BscscanIcon from "assets/img/bscscan.svg";
import DiscordIcon from "assets/img/discord.svg";
import DocumentIcon from "assets/img/document.svg";
import InstargramIcon from "assets/img/instagram.svg";
import WebsiteIcon from "assets/img/language.svg";
import MediumIcon from "assets/img/medium.svg";
import TelegramIcon from "assets/img/telegram.svg";
import TwitterIcon from "assets/img/twitter.svg";
import { FC } from "react";
import { ProjectLink, SocialLinkName } from "sdk";

const SOCIAL_LINK_ICON_MAP = {
  [SocialLinkName.Telegram]: TelegramIcon,
  [SocialLinkName.Twitter]: TwitterIcon,
  [SocialLinkName.Website]: WebsiteIcon,
  [SocialLinkName.Medium]: MediumIcon,
  [SocialLinkName.Discord]: DiscordIcon,
  [SocialLinkName.Instagram]: InstargramIcon,
  [SocialLinkName.Bscscan]: BscscanIcon,
  [SocialLinkName.Document]: DocumentIcon,
};

type Props = {
  link: ProjectLink;
};

const ProjectItemLinkSocial: FC<Props> = ({ link }) => {
  return (
    <a className="project-item__link" href={link.link} target="_blank">
      <img
        className="project-item__link-icon"
        src={SOCIAL_LINK_ICON_MAP[link.name as unknown as SocialLinkName]}
        alt={link.name}
      />
    </a>
  );
};

export default ProjectItemLinkSocial;
