import React, { HTMLAttributes } from "react";

// eslint-disable-next-line react/display-name
export const Input = React.forwardRef<
  HTMLInputElement,
  HTMLAttributes<HTMLInputElement> & { type?: string }
>((props, ref) => {
  return <input autoComplete="off" {...props} ref={ref} />;
});

export default Input;
