import { BigNumberish } from "ethers";
import { formatUnits } from "ethers/lib/utils";


export const ellipseAddress = (address = '', width = 4): string => {
  if (!address) return '';

  return `${address.slice(0, width + 2)}...${address.slice(-width)}`;
};

export const truncateAddress = (address: string, startWidth = 4, endWidth = 4) => {
  const truncateEthRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
  const match = address.match(truncateEthRegex);
  if (!match) return `${address.slice(0, startWidth)}...${address.slice(-endWidth)}`;
  return `${match[1]}…${match[2]}`;
};

export const truncateAmount = (str: string, maxDecimalDigits: number = 3) => {
  if (str.includes('.')) {
    const parts = str.split('.');
    let decimals = parts[1].slice(0, maxDecimalDigits);
    if (+decimals === 0) return parts[0];
    return parts[0] + '.' + parts[1].slice(0, maxDecimalDigits);
  }
  return str;
};

export const formatGgrAmount = (amount: BigNumberish = BigInt(0)) => {
  return truncateAmount(formatUnits(amount, 18));
};
