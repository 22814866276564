import { QueryParam } from "app/enums";
import { useQuery } from "app/hooks";
import { Project } from "app/types";
import { FC, useMemo } from "react";
import { MarketingActivity } from "sdk";
import MarketingActivityContent from "./marketing-activity-content";
import "./marketing-activity-feed.sass";

type Props = {
  selectedActivity?: MarketingActivity;
  project: Project;
};

const MarketingActivityFeed: FC<Props> = ({ selectedActivity, project }) => {
  const { queryParam: queryActivityType } = useQuery(QueryParam.MARKETING_ACTIVITY_TYPE);

  const sortedAcivities = useMemo(() => {
    if (!project.activities) return [];

    return [...project.activities].sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
  }, [project.activities]);

  return (
    <>
      {queryActivityType && project.activities && (
        <div className="marketing-activity-feed">
          {sortedAcivities.map((activity) => {
            if (selectedActivity && selectedActivity.type === activity.type) {
              return (
                <div className="marketing-activity-feed__item" key={activity.id}>
                  <MarketingActivityContent selectedActivity={activity} />
                </div>
              );
            }
          })}
        </div>
      )}
    </>
  );
};

export default MarketingActivityFeed;
