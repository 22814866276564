import iCoinexArchive from "assets/img/coinex-archive.svg";
import iCoinexPuzzle from "assets/img/coinex-puzzle.svg";
import iCoinexShield from "assets/img/coinex-shield.svg";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./advantages-coinex.sass";
import AppearContainer from "./appearing-container";

const AdvantagesCoinex: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="advantages">
      <div className="advantages__title">{t("Why Choose CoinEx Smart Chain")}</div>
      <div className="coinex-page__block">
        <div className="coinex-page__inner">
          <div className="coinex-page__content">
            <AppearContainer time={1} offset={-300}>
              <div className="advantages__item">
                <img className="advantages__img" src={iCoinexArchive} alt="" />
                <div className="advantages__name">
                  <Trans i18nKey="coinexPage.advantageCompatibility">
                    Full <br /> compatibility with EVM
                  </Trans>
                </div>
                <div className="advantages__text">
                  {t("DApps on Ethereum can be transferred directly to CSC.")}
                </div>
              </div>
            </AppearContainer>
            <AppearContainer time={1} offset={-300}>
              <div className="advantages__item">
                <img className="advantages__img" src={iCoinexShield} alt="" />
                <div className="advantages__name">
                  <Trans i18nKey="coinexPage.advantageDecentralization">
                    Full <br /> decentralization
                  </Trans>
                </div>
                <div className="advantages__text">
                  <Trans i18nKey="coinexPage.advantageDecentralizationText">
                    The CoinEx Smart Chain network <br /> supports up&nbsp;to&nbsp;101 validators
                    that are sorted and approved based on&nbsp;their CET token rate.
                  </Trans>
                </div>
              </div>
            </AppearContainer>
            <AppearContainer time={1} offset={-300}>
              <div className="advantages__item">
                <img className="advantages__img" src={iCoinexPuzzle} alt="" />
                <div className="advantages__name">
                  <Trans i18nKey="coinexPage.advantageHighPerformance">
                    High performance <br /> with low fees
                  </Trans>
                </div>
                <div className="advantages__text">
                  <Trans i18nKey="coinexPage.advantageHighPerformanceText">
                    Blocks are generated in&nbsp;seconds, allowing the network to&nbsp;have high
                    capacity and low transaction costs.
                  </Trans>
                </div>
              </div>
            </AppearContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvantagesCoinex;
