import { BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectIsProjectTimerRunning, selectSwapInputValue } from "store/slice";
import { useChainId } from "wagmi";
import { formatBigNumber, getTokensAmount, trim } from "../utils";
import { useApplication } from "./use-application";

/**
 * @returns Exchange Currency value
 */
export const useMaxSwapAmount = (project: Ido) => {
  const isProjectTimerRunning = useSelector(selectIsProjectTimerRunning);
  const { application } = useApplication(project);

  return useMemo(() => {
    if (!application) {
      return {
        maxSwapAmount: BigNumber.from(0),
        maxSwapAmountView: "0",
      };
    }

    const maxSwapAmount = application.amount.sub(application.swappedAmount);

    return {
      maxSwapAmount,
      maxSwapAmountView: formatBigNumber(maxSwapAmount),
    };
  }, [application, isProjectTimerRunning]);
};

/**
 * @returns Exchange Currency value
 */
export const useMinSwapAmount = (project: Ido) => {
  const chainId = useChainId();
  const { application } = useApplication(project);

  return useMemo(() => {
    if (!application)
      return {
        minSwapAmount: BigNumber.from(0),
        minSwapAmountView: "0",
      };

    const { minAmount: minSwapAmount, minPercent: minSwapPercent } = project;

    const amount = BigNumber.from(application.amount);
    let minAmount = amount.div(100).mul(minSwapPercent);

    if (minAmount.lt(minSwapAmount)) {
      minAmount = BigNumber.from(minSwapAmount);
    }

    return {
      minSwapAmount: minAmount,
      minSwapAmountView: formatBigNumber(minAmount),
    };
  }, [project, chainId]);
};

/**
 * @returns Claim Currency value
 */
export const useSwapTokensAmount = (project: Ido): string => {
  const { application } = useApplication(project);
  const swapInputAmount = useSwapInputAmount();
  const { maxSwapAmount } = useMaxSwapAmount(project);

  return useMemo(() => {
    if (!application) return "0";
    let swapAmount = swapInputAmount;

    if (swapAmount.gt(maxSwapAmount)) {
      swapAmount = maxSwapAmount;
    }

    const swapTokensAmount = getTokensAmount(project.exchangeRate, swapAmount);
    let swapTokensAmountView = formatBigNumber(swapTokensAmount);

    if ((+trim(swapTokensAmountView)).toFixed().length > 6) {
      swapTokensAmountView = swapTokensAmountView.split(".")[0];
    }

    return swapTokensAmountView;
  }, [application, project, swapInputAmount]);
};

export const useSwapInputAmount = () => {
  const swapInputValue = useSelector(selectSwapInputValue);

  return useMemo(() => parseEther(trim(swapInputValue) || "0"), [swapInputValue]);
};

export const useSwapAmount = (project: Ido) => {
  const maxSwapAmount = useMaxSwapAmount(project);
  const minSwapAmount = useMinSwapAmount(project);
  const swapTokensAmount = useSwapTokensAmount(project);
  const swapInputAmount = useSwapInputAmount();

  return useMemo(
    () => ({
      ...maxSwapAmount,
      ...minSwapAmount,
      swapTokensAmount,
      swapInputAmount,
    }),
    [maxSwapAmount, minSwapAmount, swapTokensAmount, swapInputAmount, project]
  );
};
