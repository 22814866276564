import cn from "classnames";
import { BigNumberish } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatGgrAmount } from "../../../utils";
import { Loader } from "../index";
import StakingStakedScene from "./staking.staked-scene";
import StakingWithdrawalAlertScene from "./staking.withdrawal-alert-scene";

// todo: ошибки никак не обрабатываются от бч

enum Scene {
  NOT_STAKED = "NOT_STAKED",
  STAKED = "STAKED",
  RESTAKE = "RESTAKE",
  SPIN = "SPIN",
  WITHDRAWAL_ALERT = "WITHDRAWAL_ALERT",
}

type TProps = {
  className?: string;
  myGgrWalletBalance: bigint;
  pools: {
    lockSeconds: bigint;
    rewardPercent: bigint;
  }[];
  stake?: { lockSeconds: bigint; updatedAt: bigint; amount: bigint; rewardAmount: bigint };
  isFetching: boolean;
  onSubmitStake: (amount: BigNumberish, lockSeconds: BigNumberish) => Promise<any>;
  onWithdraw: () => Promise<any>;
};

const Staking: React.FC<TProps> = ({
  className,
  myGgrWalletBalance,
  pools,
  stake,
  isFetching,
  onSubmitStake,
  onWithdraw,
}) => {
  const { t } = useTranslation();
  const [stakingActive, setActiveStakeng] = useState(1);
  const [isSceneSpin, setSceneSpin] = useState<boolean>(false);
  const [isSceneRestake, setSceneRestake] = useState<boolean>(false);
  const [isSceneWithdrawalAlert, setSceneWithdrawalAlert] = useState<boolean>(false);
  const [activePool, setActivePool] = useState<number>(0);

  const scene = useMemo(() => {
    if (isSceneSpin || isFetching) return Scene.SPIN;
    if (isSceneWithdrawalAlert) return Scene.WITHDRAWAL_ALERT;
    if (isSceneRestake) return Scene.RESTAKE;
    if (stake) return Scene.STAKED;
    return Scene.NOT_STAKED;
  }, [isSceneSpin, isSceneRestake, isSceneWithdrawalAlert, isFetching, stake]);

  const lockTill = useMemo(() => {
    if (scene === Scene.SPIN || !pools.length) {
      return new Date().toLocaleString();
    }
    if (scene === Scene.NOT_STAKED || scene === Scene.RESTAKE) {
      return new Date(
        Number(BigInt(Date.now()) + pools[activePool].lockSeconds * BigInt(1000))
      ).toLocaleString();
    } else if (scene === Scene.STAKED) {
      return new Date(
        Number(stake!.updatedAt * BigInt(1000) + stake!.lockSeconds * BigInt(1000))
      ).toLocaleString();
    }
    return new Date().toLocaleString();
  }, [activePool, pools, stake, scene]);

  const rewardPercent = useMemo(() => {
    if (scene === Scene.SPIN || !pools.length) {
      return "0";
    }
    if (scene === Scene.NOT_STAKED || scene === Scene.RESTAKE) {
      return pools[activePool].rewardPercent.toString();
    } else if (scene === Scene.STAKED) {
      return pools
        .find((pool) => pool.lockSeconds === stake!.lockSeconds)!
        .rewardPercent.toString();
    }
    return "0";
  }, [activePool, pools, stake, scene]);

  useEffect(() => {
    scene === Scene.NOT_STAKED || (scene === Scene.WITHDRAWAL_ALERT && setActiveStakeng(1));
  }, [scene]);

  return (
    <div className={cn("bg-bg_card rounded-3xl overflow-hidden transition-all", className)}>
      <div className="p-6">
        {scene === Scene.SPIN && (
          <>
            <div className="mb-4 flex items-center gap-2">
              <div className="p2b text-soft_sky">{t("Staking")}</div>
            </div>
            <Loader className="h-72 w-full" />
          </>
        )}
        {scene === Scene.NOT_STAKED && (
          <>
            <div className="mb-4 flex items-center gap-2">
              <div className="p2b text-soft_sky">{t("Staking")}</div>
            </div>
            <div className="p3 text-soft_sky">
              {t("This staking program has been finished. To get AP, please use staking V2")}
            </div>
          </>
        )}
        {scene === Scene.RESTAKE && stake && (
          <>
            <div className="mb-4 flex items-center gap-2">
              <div className="p2b text-soft_sky">{t("Staking")}</div>
            </div>
            <div className="p3 text-soft_sky">
              {t("This staking program has been finished. To get AP, please use staking V2")}
            </div>
          </>
        )}
        {scene === Scene.STAKED && stake && (
          <StakingStakedScene
            lockedTill={lockTill.toString()}
            initialStake={formatGgrAmount(stake.amount)}
            earnings={formatGgrAmount(stake.rewardAmount)}
            onChangeClick={() => {
              setSceneRestake(true);
              setActivePool(pools.findIndex((pool) => pool.lockSeconds === stake!.lockSeconds));
            }}
            onWithdrawClick={async () => {
              if (
                new Date(+(stake.lockSeconds + stake.updatedAt).toString()).getTime() * 1000 >
                Date.now()
              ) {
                setSceneWithdrawalAlert(true);
              } else {
                setSceneSpin(true);
                await onWithdraw();
                setSceneWithdrawalAlert(false);
                setSceneSpin(false);
              }
            }}
          />
        )}
        {scene === Scene.WITHDRAWAL_ALERT && stake && (
          <StakingWithdrawalAlertScene
            amount={formatGgrAmount((stake.amount * BigInt(70)) / BigInt(100))}
            onContinueClick={async () => {
              setSceneSpin(true);
              await onWithdraw();
              setSceneWithdrawalAlert(false);
              setSceneSpin(false);
            }}
            onBackClick={() => setSceneWithdrawalAlert(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Staking;
