import { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
  itemKey: string;
};

const ProjectInfoItem: FC<Props> = ({ className = "", itemKey, children }) => {
  const { t } = useTranslation();

  return (
    <div className={`${className}-item`}>
      <div className={`${className}-key`}>{t(itemKey)}</div>
      <div className={`${className}-value`}>{children}</div>
    </div>
  );
};

export default ProjectInfoItem;
