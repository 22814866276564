import { useProjectLinks } from "app/hooks";
import { Project } from "app/types";
import { FC } from "react";
import { useSelector } from "react-redux";
import { isIdo } from "sdk";
import { selectProjectLogo } from "store/slice";
import IdoTitlePostfix from "../ido-title-postfix";
import ProjectLinkDoc from "./project-link-docs";
import ProjectLinkSocial from "./project-link-social";

type Props = {
  className?: string;
  project: Project;
};

const ProjectHeader: FC<Props> = ({ project }) => {
  const [socailLinks, docsLinks] = useProjectLinks(project);
  const projectLogo = useSelector(selectProjectLogo);

  return (
    <>
      <div className="project__title">
        {(projectLogo || (isIdo(project) && project.idoLogoUrl)) && (
          <div
            className="project__logo"
            style={{
              backgroundImage: `url(${projectLogo || (isIdo(project) && project.idoLogoUrl)} )`,
              backgroundSize: "100%",
            }}
          />
        )}
        {project.title}{" "}<IdoTitlePostfix projectId={project?.id} />
      </div>
      <div className="project__links">
        {!!socailLinks.length &&
          socailLinks.map((link) => <ProjectLinkSocial key={link.id} link={link} />)}
        {!!docsLinks.length &&
          docsLinks.map((link) => <ProjectLinkDoc key={link.id} link={link} />)}
      </div>
    </>
  );
};

export default ProjectHeader;
