import TonAccountBlock from "app/ton-lk/ton-account-block";
import { FC } from "react";

const TonAccountBlockConnected: FC = () => {
  return (
    <div>
      <TonAccountBlock />
    </div>
  )
}

export default TonAccountBlockConnected

