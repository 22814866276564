import Button from "app/components/button/button";
import { useOutsideClick, useWidth } from "app/hooks";
import { ReactComponent as IconMetamask } from "assets/img/icons/icon-metamask.svg";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ellipseAddress } from "sdk";
import { useAccount } from "wagmi";
import CheckAuth from "../check-auth";
import { UserMenu } from "./user-menu";
import "./wallet.sass";
type TProps = {
  closeMenu?: () => void;
};

const Wallet: FC<TProps> = ({ closeMenu }) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const { isDesktopWidth } = useWidth();
  const menuRef = useRef<HTMLDivElement>(null);
  const [isUserMenuOpened, setIsUserMenuOpened] = useState<boolean>(false);

  const onConnectClick = (): void => {
    onClickGtagConnectWallet();
    if (closeMenu) {
      closeMenu();
    }
    setIsUserMenuOpened(false);
  };

  const toggleUserMenu = (): void => {
    setIsUserMenuOpened((state) => !state);
  };

  useOutsideClick(menuRef, () => {
    setIsUserMenuOpened(false);
  });
  function onClickGtagConnectWallet() {
    window.gtagEventConnectWalletClicked &&
      window.gtagEventConnectWalletClicked(t("Connect Wallet"));
  }
  return (
    <div className="wallet" ref={menuRef}>
      <CheckAuth onConnect={onConnectClick}>
        <Button className="_blank" onClick={toggleUserMenu}>
          <div className="wallet__inner">
            <IconMetamask className="wallet__img" />
            {ellipseAddress(address)}
          </div>
        </Button>
        {isDesktopWidth && (
          <UserMenu
            isUserMenuOpened={isUserMenuOpened}
            closeUserMenu={() => setIsUserMenuOpened(false)}
          />
        )}
      </CheckAuth>
    </div>
  );
};
declare global {
  interface Window {
    gtagEventConnectWalletClicked?(data: string): void;
  }
}
export default Wallet;
