import { ProjectStage } from "app/enums";
import { useActions, useInvestorInfo } from "app/hooks";
import { FC, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTimer } from "react-timer-hook";
import { Ido } from "sdk";
import { selectProjectStage } from "store/slice";
import Timer from "../timer/timer";

type Props = {
  className?: string;
  ido: Ido;
};

const IdoTimer: FC<Props> = ({ ido }) => {
  const projectStage = useSelector(selectProjectStage);
  const { setIsProjectTimerRunning } = useActions();
  const { investorInfo, refetchInvestorInfo } = useInvestorInfo(ido);

  const date = useMemo(() => {
    let date: Date | undefined

    if (projectStage < ProjectStage.whitelist) {
      date = new Date(ido.whitelistStartDate);
    }
    if (projectStage === ProjectStage.whitelist) {
      date = new Date(ido.whitelistEndDate);
    }
    if (projectStage >= ProjectStage.swapGap && projectStage <= ProjectStage.swapGapWithResults) {
      date = new Date(ido.swapStartDate);
    }
    if (projectStage === ProjectStage.swap) {
      date = new Date(ido.swapEndDate);
    }
    if (projectStage === ProjectStage.highTierFcfs) {
      date = new Date(ido.highTierFcfsEndDate ?? 0);
    }
    if (projectStage === ProjectStage.fcfs) {
      date = new Date(ido.fcfsEndDate);
    }
    return date
  }, [projectStage])

  const { seconds, minutes, hours, days, restart, isRunning } = useTimer({
    expiryTimestamp: date || new Date(),
    onExpire: () => {
      pingTimer();
    },
  });

  useEffect(() => {
    if (!investorInfo) return
    setIsProjectTimerRunning(isRunning);
    if (projectStage >= ProjectStage.claimGap) {
      refetchInvestorInfo();
    }
  }, [isRunning, refetchInvestorInfo]);

  const pingTimer = () => {
    if (date) {
      restart(date);
    }
  };

  useEffect(() => {
    pingTimer();
  }, [ido, isRunning]);

  const title = useMemo(() => {
    if (projectStage <= ProjectStage.whitelistGap) {
      return "Whitelist starts after";
    }
    if (projectStage === ProjectStage.whitelist) {
      return "Whitelist ends after";
    }
    if (projectStage >= ProjectStage.swapGap && projectStage <= ProjectStage.swapGapWithResults) {
      return "Swap starts after";
    }
    if (projectStage === ProjectStage.swap) {
      return "Swap ends after";
    }
    if (projectStage === ProjectStage.fcfsGap) {
      return "FCFS starts after";
    }
    if (projectStage === ProjectStage.highTierFcfs) {
      return "High tier FCFS ends after";
    }
    if (projectStage === ProjectStage.fcfs) {
      return "FCFS ends after";
    }
    if (projectStage === ProjectStage.ended) {
      return "Ended";
    }
    return "";
  }, [projectStage]);

  return <Timer days={days} hours={hours} minutes={minutes} seconds={seconds} title={title} />;
};

export default IdoTimer;
