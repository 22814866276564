import { ProjectStage } from "app/enums";
import { useCurrency, useIdoRedeemed } from "app/hooks";
import iReqOk from "assets/img/icons/req-ok.svg";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectProjectStage } from "store/slice";

type Props = {
  className?: string;
  ido: Ido;
};

const IdoFcfs__Success: FC<Props> = ({ ido }) => {
  const { t } = useTranslation();
  const projectStage = useSelector(selectProjectStage);
  const { claimCurrencyIcon, claimCurrencySymbol, exchangeCurrencySymbol, exchangeCurrencyIcon } =
    useCurrency(ido);

  const { redeemedAmountView, redeemedTokensAmountView } = useIdoRedeemed(ido);

  return (
    <>
      <div className="project-swap__body">
        <div className="project-swap__body-col">
          <div className="project-swap__body-title">{t("You paid in total")}</div>
          <div className="project-swap__body-value">
            {exchangeCurrencyIcon && (
              <img width={30} height={30} src={exchangeCurrencyIcon} alt={exchangeCurrencySymbol} />
            )}
            {redeemedAmountView}
          </div>
        </div>
        <div className="project-swap__body-col">
          <div className="project-swap__body-title">{t("You will receive")}</div>
          <div className="project-swap__body-value">
            {claimCurrencyIcon && (
              <img width={30} height={30} src={claimCurrencyIcon} alt={claimCurrencySymbol} />
            )}
            {redeemedTokensAmountView}
          </div>
        </div>
      </div>
      <div className="project-swap__win">
        <img src={iReqOk} alt="ok" />
        {t("There are no tokens available for redemption")}
        <br />
      </div>
      {projectStage >= ProjectStage.claimGap && (
        <div className="project-swap__win">
          <img src={iReqOk} alt="ok" />
          {t("The pool is filled")}
        </div>
      )}
    </>
  );
};

export default IdoFcfs__Success;
