import RocketLoader from "app/components/loader/rocket-loader";
import { usePersonalAccountChain } from "app/hooks";
import cn from "classnames";
import { FC } from "react";
import { useAccount } from "wagmi";
import BnbAccountBlockConnected from "./bnb-account-block__connected";
import BnbAccountBlockNotConnected from "./bnb-account-block__not-connected";

type Props = {
  className?: string;
};
const BnbAccountBlock: FC<Props> = ({ className }) => {
  const { address } = useAccount();

  const { isError, isLoading: isSwitchNetworkLoading } = usePersonalAccountChain({
    skip: !address,
  });

  if (isError) {
  }

  return (
    <div className={cn(className)}>
      <RocketLoader isLoading={isSwitchNetworkLoading}>
        {address ? (
          <BnbAccountBlockConnected address={address as string} />
        ) : (
          <BnbAccountBlockNotConnected />
        )}
      </RocketLoader>
    </div>
  );
};

export default BnbAccountBlock;
