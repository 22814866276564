import cn from "classnames";
import { TierTypes } from "graphql/account-query";
import { useTranslation } from "react-i18next";
// import Image from "next/image";
// import {SHARED_IMAGES} from '../assets/images'
// import {TierModel, TierSetType, TierType} from '../types/balances'
import Badge from "../badge/badge";
import Button from "../button/button";
import iArrow from "./arrow.svg";
import "./private-claim-modal.sass";

interface IProps {
  className?: string;
  onSubmit?: () => Promise<void>;
  tiers?: any[];
  tier?: TierTypes;
  claimSum?: string;
  click?: any;
}

const PrivateClaimModal: React.FC<IProps> = ({ className, onSubmit, tier, claimSum, click }) => {
  const { t, i18n } = useTranslation();
  const tiersMock: any[] = [
    {
      amount: "0",
      tierSet: "EARLY",
      type: t("tier.tierEarth.name"),
      toAmount: "500",
    },
    {
      amount: "2000",
      tierSet: "EARLY",
      type: t("tier.tierOrbit.name"),
      toAmount: "5000",
    },
    {
      amount: "5000",
      tierSet: "EARLY",
      type: t("tier.tierGalaxy.name"),
      toAmount: "20000",
    },
    {
      amount: "20000",
      tierSet: "EARLY",
      type: t("tier.tierUniverse.name"),
      toAmount: "60000",
    },
  ];
  function MyTier() {
    if (!tier) {
      return -1;
    }
    if (tier === "GALAXY") {
      return 0;
    } else if (tier === "ORBIT") {
      return 1;
    } else if (tier === "UNIVERSE") {
      return 2;
    } else {
      return -1;
    }
  }
  const data = {
    title: t("modal.claimModalPrivate.title") + "?",
    loseApr: (
      <div className="_p2">
        <span className="_green">15% APR</span> {t("modal.claimModalPrivate.text1")} {claimSum} GGR
      </div>
    ),
    tier: t("modal.claimModalPrivate.text2"),
    lessAlloc: t("modal.claimModalPrivate.text3"),
    button: `${t("modal.claimModal.title")} ${claimSum} GGR`,
  };
  return (
    <div className={cn("private-claim-modal", className)}>
      <div className="private-claim-modal__title">{data.title}</div>

      <div className="private-claim-modal__line">
        <div className="private-claim-modal__line-rounded-num">1</div>
        <div>
          <div className="private-claim-modal__line-text _p2">{data.loseApr}</div>
        </div>
      </div>
      <div className="private-claim-modal__line">
        <div className="private-claim-modal__line-rounded-num">2</div>
        <div>
          <div className="private-claim-modal__line-text _p2">{data.tier}</div>
        </div>
      </div>
      <div className="private-claim-modal__tiers">
        {tiersMock.map((el, i) => (
          <div key={i} className="private-claim-modal__tier">
            <div className="private-claim-modal__tier-title _p3">
              {el.type}{" "}
              {i === MyTier() && (
                <Badge
                  className="private-claim-modal__tier-badge"
                  type="green-contrast"
                  title={t("My Tier")}
                />
              )}
            </div>
            <div className="private-claim-modal__tier-ap">
              <div className="private-claim-modal__tier-ap-amount _green">{el.amount} AP</div>
              <img src={iArrow} alt="arrow" />
              <div className="private-claim-modal__tier-ap-amount _gray-sub">{el.toAmount} AP</div>
            </div>
          </div>
        ))}
      </div>
      <div className="private-claim-modal__line">
        <div className="private-claim-modal__line-rounded-num">3</div>
        <div>
          <div className="private-claim-modal__line-text _p2 _mb-6">{data.lessAlloc}</div>
        </div>
      </div>
      <Button onClick={() => click()} title={data.button} />
    </div>
  );
};

export default PrivateClaimModal;
