import { PAYMENTS_OVER } from "app/constants";
import { ProjectStage, TimelineStage } from "app/enums";
import { useIdoStage, useLeftToRaiseAmount } from "app/hooks";
import i18next from "app/localisation/config";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Ido } from "sdk";
import { formatDate, formatTimelineDate, getNextClaimByProject, isRefund } from "../../utils";
import ProjectTimeline from "../project/project-timeline";

type Props = {
  className?: string;
  ido: Ido;
};

const buildDatesArray = (ido: Ido, stage: TimelineStage) => {
  const { t } = i18next;
  const dates: string[] = [];

  if (stage === TimelineStage.UPCOMING && ido.whitelistFree) {
    const whitelistStartDate = formatTimelineDate(ido.whitelistStartDate);
    const whitelistEndDate = formatTimelineDate(ido.whitelistEndDate);
    dates.push(`${t("Whitelist start")} — ${whitelistStartDate}`);
    dates.push(`${t("Whitelist end")} — ${whitelistEndDate}`);
  }
  if (stage === TimelineStage.SWAP) {
    const swapStartDate = formatTimelineDate(ido.swapStartDate);
    const swapEndDate = formatTimelineDate(ido.swapEndDate);
    dates.push(`${t("Swap start")} — ${swapStartDate}`);
    dates.push(`${t("Swap end")} — ${swapEndDate}`);
  }
  if (stage === TimelineStage.HIGH_TIER_FCFS) {
    const fcfsEndDate = formatTimelineDate(ido.fcfsEndDate);
    dates.push(`${t("High tier FCFS end")} — ${fcfsEndDate}`);
  }
  if (stage === TimelineStage.FCFS) {
    const fcfsEndDate = formatTimelineDate(ido.fcfsEndDate);
    dates.push(`${t("FCFS end")} — ${fcfsEndDate}`);
  }
  if (stage === TimelineStage.CLAIMABLE) {
    const nextClaimDate = getNextClaimByProject(ido);

    if (isRefund(ido.refundType)) {
      dates.push("");
    } else if (nextClaimDate === PAYMENTS_OVER) {
      dates.push(t(PAYMENTS_OVER));
    } else {
      const nextClaim = nextClaimDate === "TBA" ? nextClaimDate : formatDate(nextClaimDate);
      dates.push(`${t("Next claim")} — ${nextClaim}`);
    }
  }

  return dates;
};

const IdoTimeline: FC<Props> = ({ className, ido: project }) => {
  const { i18n } = useTranslation();
  const projectStage = useIdoStage(project);
  const leftToRaiseAmount = useLeftToRaiseAmount(project);

  const stagesWithoutFcfs = useMemo(
    () => [
      {
        id: [
          ProjectStage.whitelistTba,
          ProjectStage.whitelistGap,
          ProjectStage.whitelist,
          ProjectStage.swapGap,
          ProjectStage.swapGapWithResults,
        ],
        title: "Upcoming",
        dates: buildDatesArray(project, TimelineStage.UPCOMING),
        dateActive: projectStage <= ProjectStage.swapGapWithResults,
        active: true,
      },
      {
        id: [ProjectStage.swap],
        title: "Swap",
        dates: buildDatesArray(project, TimelineStage.SWAP),
        dateActive: projectStage === ProjectStage.swap,
        active: projectStage >= ProjectStage.swap,
      },
      {
        id: [ProjectStage.claimGap],
        title: "Filled",
        dateActive: projectStage === ProjectStage.claimGap,
        active: projectStage >= ProjectStage.claimGap,
      },
      {
        id: [ProjectStage.claim],
        title: "Claimable",
        dates: buildDatesArray(project, TimelineStage.CLAIMABLE),
        dateActive:
          projectStage === ProjectStage.claim || projectStage === ProjectStage.refundPolicy,
        active: projectStage >= ProjectStage.claim,
      },
      {
        id: [ProjectStage.ended, ProjectStage.refund],
        title: "Ended",
        dateActive: projectStage === ProjectStage.ended || projectStage === ProjectStage.refund,
        active: projectStage === ProjectStage.ended || projectStage === ProjectStage.refund,
      },
    ],
    [projectStage, i18n.language]
  );

  const stagesWithFcfs = useMemo(
    () => [
      ...stagesWithoutFcfs.slice(0, 2),
      {
        id: [ProjectStage.fcfs],
        title: "FCFS",
        dates: buildDatesArray(project, TimelineStage.FCFS),
        dateActive: projectStage === ProjectStage.fcfs && !leftToRaiseAmount.isZero(),
        active: projectStage >= ProjectStage.fcfs,
      },
      ...stagesWithoutFcfs.slice(2),
    ],
    [stagesWithoutFcfs, projectStage, leftToRaiseAmount, i18n.language]
  );

  const stagesWithHighTierFcfs = useMemo(
    () => [
      ...stagesWithoutFcfs.slice(0, 2),
      {
        id: [ProjectStage.fcfs],
        title: "High&nbsp;tier&nbsp;FCFS",
        dates: buildDatesArray(project, TimelineStage.FCFS),
        dateActive: projectStage === ProjectStage.highTierFcfs && !leftToRaiseAmount.isZero(),
        active: projectStage >= ProjectStage.highTierFcfs,
      },
      ...stagesWithoutFcfs.slice(2),
    ],
    [stagesWithoutFcfs, projectStage, leftToRaiseAmount, i18n.language]
  );

  const stages = useMemo(
    () => {
      if (projectStage === ProjectStage.highTierFcfs) {
        return stagesWithHighTierFcfs
      } else if (projectStage === ProjectStage.fcfs) {
        return stagesWithFcfs
      } else {
        return stagesWithoutFcfs
      }
    },
    [projectStage, project, stagesWithFcfs, stagesWithoutFcfs, stagesWithHighTierFcfs]
  );

  return <ProjectTimeline className={className} stages={stages} />;
};

export default IdoTimeline;
