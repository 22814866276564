import { useActions, useWidth } from "app/hooks";
import cn from "classnames";
import { CSSProperties, FC, isValidElement, useEffect, useMemo } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { selectLayout } from "store/slice";
import "./modal.sass";

const overlay: CSSProperties = {
  background: "#0C0D0E80",
  zIndex: 11,
};
const contentDefault: CSSProperties = {
  top: "50%",
  left: "50%",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "#0A132C",
};
const contentDesktop: CSSProperties = {
  border: "none",
  borderRadius: 40,
  maxWidth: 720,
};
const contentTablet: CSSProperties = {
  border: "none",
  borderRadius: 40,
  maxWidth: "80%",
};
const contentMobile: CSSProperties = {
  border: "1px solid #212B44",
  borderRadius: 20,
  maxWidth: "calc(100% - 32px)",
};

type Props = {};

const ModalProvider: FC<Props> = () => {
  const { isModalOpen, modalNode, modalContentStyles, isModalClosing } = useSelector(selectLayout);
  const { closeModal } = useActions();
  const { isMobileWidth, isTabletWidth } = useWidth();

  const style = useMemo(() => {
    if (isTabletWidth) {
      return {
        overlay,
        content: {
          ...contentDefault,
          ...contentTablet,
          ...modalContentStyles,
        },
      };
    }

    if (isMobileWidth) {
      return {
        overlay,
        content: {
          ...contentDefault,
          ...modalContentStyles,
          ...contentMobile,
        },
      };
    }

    return {
      overlay,
      content: {
        ...contentDefault,
        ...contentDesktop,
        ...modalContentStyles,
      },
    };
  }, [isMobileWidth, isTabletWidth, modalContentStyles]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isModalOpen]);

  return (
    <ReactModal
      ariaHideApp={false}
      style={style}
      onRequestClose={closeModal}
      isOpen={isModalOpen}
      className="modal"
      overlayClassName={cn("modal__overlay", {
        _opening: isModalOpen,
        _closing: isModalClosing,
      })}
    >
      <div className="modal__close" onClick={closeModal} />
      <div className="modal__body">{isValidElement(modalNode) && modalNode}</div>
    </ReactModal>
  );
};

export default ModalProvider;
