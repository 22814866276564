import { ProjectStage } from "app/enums";
import { isRefund } from "app/utils";
import { BigNumber } from "ethers";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Application, ApplicationStatus, Ido, ProjectStatus, RawInvestorInfo } from "sdk";
import { selectIsProjectTimerRunning } from "store/slice";
import { useActions } from "./use-actions";
import { useApplication } from "./use-application";
import { useLeftToRaiseAmount } from "./use-left-to-raise";
import { useRawInvestorInfo } from "./use-raw-investor-info";

export const getIdoStage = (
  leftToRaiseAmount: BigNumber,
  ido?: Ido,
  application?: Application,
  rawInvestorInfo?: RawInvestorInfo
): ProjectStage => {
  if (!ido) return ProjectStage.pause;
  if (isRefund(ido.refundType)) {
    return ProjectStage.refund;
  }
  if (
    ido.status === ProjectStatus.CLAIM &&
    application &&
    application.status === ApplicationStatus.REFUND
  ) {
    return ProjectStage.refund;
  }
  if (!ido.whitelistStartDate) {
    return ProjectStage.whitelistTba;
  } else if (ido.status === ProjectStatus.NOT_DEPLOYED && Date.now() < ido.whitelistStartDate) {
    return ProjectStage.whitelistGap;
  } else if (
    ido.status === ProjectStatus.NOT_DEPLOYED &&
    Date.now() >= ido.whitelistStartDate &&
    Date.now() < ido.whitelistEndDate
  ) {
    return ProjectStage.whitelist;
  } else if (ido.status === ProjectStatus.NOT_DEPLOYED && Date.now() >= ido.whitelistEndDate) {
    return ProjectStage.swapGap;
  } else if (
    ido.status === ProjectStatus.UPCOMING &&
    Date.now() < ido.swapStartDate /* &&
    !!project.blockchainId */
  ) {
    return ProjectStage.swapGapWithResults;
  } else if (ido.status === ProjectStatus.REDEEM && Date.now() < ido.swapStartDate) {
    return ProjectStage.swapGapWithResults;
  } else if (
    ido.status === ProjectStatus.REDEEM &&
    Date.now() >= ido.swapStartDate &&
    Date.now() < ido.swapEndDate
  ) {
    return ProjectStage.swap;
  } else if (ido.status === ProjectStatus.FCFS && Date.now() <= ido.swapEndDate) {
    return ProjectStage.swap;
  } else if (
    ido.status === ProjectStatus.HIGH_TIER_FCFS &&
    leftToRaiseAmount.gt(0) &&
    Date.now() > ido.swapEndDate &&
    Date.now() < ido.highTierFcfsEndDate
  ) {
    return ProjectStage.highTierFcfs;
  } else if (
    ido.status === ProjectStatus.FCFS &&
    leftToRaiseAmount.gt(0) &&
    Date.now() > ido.highTierFcfsEndDate &&
    Date.now() < ido.fcfsEndDate
  ) {
    return ProjectStage.fcfs;
  } else if (ido.status === ProjectStatus.FCFS && Date.now() > ido.fcfsEndDate) {
    return ProjectStage.claimGap;
  } else if (ido.status === ProjectStatus.FCFS && leftToRaiseAmount.lte(0)) {
    return ProjectStage.claimGap;
  } else if (
    ido.status === ProjectStatus.CLAIM &&
    (!ido.payments?.length || Date.now() < ido.payments?.[0]?.date)
  ) {
    return ProjectStage.claimGap;
  } else if (rawInvestorInfo && rawInvestorInfo.claimed.toNumber() === ido.payments?.length) {
    return ProjectStage.ended;
  } else if (
    ido.status === ProjectStatus.CLAIM &&
    ido.payments?.length &&
    Date.now() >= ido.payments[0].date
  ) {
    return ProjectStage.claim;
  }
  return ProjectStage.pause;
};

export const useIdoStage = (ido?: Ido): ProjectStage => {
  const leftToRaiseAmount = useLeftToRaiseAmount(ido);
  const isProjectTimerRunning = useSelector(selectIsProjectTimerRunning);
  const { application } = useApplication(ido);
  const { rawInvestorInfo } = useRawInvestorInfo(ido);

  return useMemo(() => {
    return getIdoStage(leftToRaiseAmount, ido, application, rawInvestorInfo);
  }, [ido, isProjectTimerRunning, leftToRaiseAmount, application, rawInvestorInfo]);
};

export const useUpdateIdoStage = (ido?: Ido): void => {
  const { setProjectStage } = useActions();
  const projectStage = useIdoStage(ido);

  useEffect(() => {
    setProjectStage(projectStage);
  }, [ido, projectStage]);
};
