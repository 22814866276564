import { getStakingContract } from "app/utils/contract-helper";
import { ChainId } from "sdk";
import { useChainId, useContractRead } from "wagmi";

export const useGetTokenQuery = () => {
  const chainId = useChainId() as ChainId;
  const response = useContractRead({
    chainId,
    ...getStakingContract(chainId),
    functionName: "GGR",
    watch: true,
  });
  return response;
};
