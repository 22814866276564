import { QueryParam } from "app/enums";
import { useActiveFilter, useQuery } from "app/hooks";
import { ActiveFilter } from "app/types/projects-filters";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectScreenWidth } from "store/slice";
import "./switcher.sass";

const ITEM_KEY: string = "switcher-item-";

type Props = {
  filters: ActiveFilter[];
};

const Switcher: FC<Props> = ({ filters }) => {
  const { t, i18n } = useTranslation();
  const screenWidth = useSelector(selectScreenWidth);
  const [activeFilterStyles, setActiveFilterStyles] = useState({
    left: 0,
    width: 0,
    height: 0,
    transition: "",
  });
  const activeFilter = useActiveFilter();
  const { queryParam, setQueryParam } = useQuery(QueryParam.PROJECT_FILTER);

  const onClick = (filter: ActiveFilter) => {
    setQueryParam(QueryParam.PROJECT_FILTER, filter);
  };

  const updateActiveBlockStyles = (filter: ActiveFilter): void => {
    let animated = true;
    const { left, width, height } = activeFilterStyles;
    if (!left && !width && !height) {
      animated = false;
    }

    const element = document.getElementById(ITEM_KEY + filter);
    if (element) {
      const { left, width, height } = element.getBoundingClientRect();

      setActiveFilterStyles({
        left,
        width,
        height,
        transition: animated ? "all 0.3s" : "",
      });
    }
  };

  useEffect(() => {
    setQueryParam(QueryParam.PROJECT_FILTER, activeFilter);
    updateActiveBlockStyles(activeFilter);
  }, [screenWidth, i18n.language, activeFilter]);

  return (
    <div className="switcher">
      <div className="switcher__items">
        <div className="switcher__active" style={activeFilterStyles} />
        {filters.map((filter) => (
          <div
            key={ITEM_KEY + filter}
            id={ITEM_KEY + filter}
            className={cn("switcher__item", {
              _active: activeFilter === filter,
            })}
            onClick={() => onClick(filter)}
          >
            <div className="switcher__item-text">{t(filter)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Switcher;
