import { Tooltip } from "app/components/tooltip/tooltip";
import { ActivityStatus, QueryParam } from "app/enums";
import { useMarketingActivity, useQuery, useWidth } from "app/hooks";
import { Project } from "app/types";
import { getActivityIcon } from "app/utils";
import cn from "classnames";
import { FC } from "react";
import { Link } from "react-router-dom";
import { MarketingActivityType } from "sdk";
import "./project-item-marketing-activity.sass";

type Props = {
  activityType: MarketingActivityType;
  project: Project;
};

export const ProjectItemMarketingActivity: FC<Props> = ({ activityType, project }) => {
  const { isDesktopWidth } = useWidth();
  const { buttonText, activityStatus } = useMarketingActivity(activityType, project.activities);
  const { createQueryParam } = useQuery();

  return (
    <Link
      to={`ido/${project.id}?${createQueryParam(QueryParam.MARKETING_ACTIVITY_TYPE, activityType)}`}
    >
      <Tooltip text={activityType} position={isDesktopWidth ? "top" : "left"}>
        <div
          className={cn("project-item-activity__item", {
            _ended: activityStatus === ActivityStatus.ENDED,
            _now: activityStatus === ActivityStatus.NOW,
          })}
        >
          <img
            src={getActivityIcon(activityStatus, activityType)}
            alt=""
            className={cn("project-item-activity__img", {
              "_not-started": activityStatus === ActivityStatus.NOT_STARTED,
              _ended: activityStatus === ActivityStatus.ENDED,
            })}
          />
          {buttonText && (
            <div
              className={cn("project-item-activity__text", {
                _now: activityStatus === ActivityStatus.NOW,
              })}
            >
              {buttonText}
            </div>
          )}
        </div>
      </Tooltip>
    </Link>
  );
};
