import { gql } from "@apollo/client";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import WalletTon from "app/components/header/wallet-ton";
import { useLinkBalancesMutation } from "app/hooks/graphql/use-link-balances.mutation";
import { useAccountQuery, useSyncAccountMutation } from "graphql/@generated/graphql";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTonJBalanceQuery } from "store/api/ton.api";
import { Address } from "ton-core";
import { useAccount, useBalance, useDisconnect } from "wagmi";
import { LinkAddress, Modal, MyBalance, MyTier } from "./components/v2";
import { formatGgrAmount } from "./utils";

const LINK_BALANCES_MUTATION = gql`
  mutation LinkBalances($bnbAddr: String!, $tonAddr: String!) {
    linkBalances(bnbAddr: $bnbAddr, tonAddr: $tonAddr) {
      id
    }
  }
`;

export type ConnectedAddr = { addr: string; network: "BNB" | "TON" };

export default function AccountBlockInfo() {
  const { disconnect: disconnectBnb } = useDisconnect();

  const { address: myBnbAddr } = useAccount();

  const myTonAddr = useTonAddress(false);
  const [syncAccount] = useSyncAccountMutation();
  const [connectedAddrs, setConnectedAddrs] = useState<ConnectedAddr[]>([]);

  useEffect(() => {
    const _connectedAddrs: ConnectedAddr[] = [];
    if (myBnbAddr) {
      syncAccount({ variables: { syncAccountInput: { addr: myBnbAddr } } });
      _connectedAddrs.push({ addr: myBnbAddr, network: "BNB" });
    }
    if (myTonAddr) {
      syncAccount({ variables: { syncAccountInput: { addr: myTonAddr } } });
      _connectedAddrs.push({ addr: myTonAddr, network: "TON" });
    }
    setConnectedAddrs(_connectedAddrs);
  }, [myBnbAddr, myTonAddr]);

  const { data: accountData, refetch: refetchAccount } = useAccountQuery({
    variables: { getAccountInput: { addr: myBnbAddr || myTonAddr } },
    skip: !myBnbAddr && !myTonAddr,
  });

  const [isOpenLinkAddressModal, setOpenLinkBalModal] = useState(false);
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    const i = setInterval(async () => {
      if (myBnbAddr) {
        await syncAccount({ variables: { syncAccountInput: { addr: myBnbAddr } } });
        refetchAccount();
      } else if (myTonAddr) {
        await syncAccount({ variables: { syncAccountInput: { addr: myTonAddr } } });
        refetchAccount();
      }
    }, 10000);
    return () => clearInterval(i);
  }, []);

  const [
    linkBalances,
    { data: linkBalancesData, loading: linkBalancesLoading, error: linkBalancesError },
  ] = useLinkBalancesMutation();

  const { data: mySrcJBal } = useTonJBalanceQuery(
    { tonAddr: myTonAddr, jAddr: process.env.REACT_APP_PUBLIC_TON_GGR_ADDR },
    { pollingInterval: 5000, skip: !myTonAddr }
  );
  const { data: dstJBal } = useTonJBalanceQuery(
    {
      tonAddr: myTonAddr,
      jAddr: process.env.REACT_APP_PUBLIC_TON_NEW_GGR_ADDR,
    },
    { pollingInterval: 5000, skip: !myTonAddr }
  );
  const { data: myBnbGgrBal } = useBalance({
    address: myBnbAddr,
    token: process.env.REACT_APP_GGR_CONTRACT_ADDR as `0x${string}`,
  });
  const myWalletBal = useMemo(() => {
    return formatGgrAmount(BigInt(myBnbGgrBal?.value || 0) + BigInt(dstJBal || BigInt(0)));
  }, [myBnbGgrBal, dstJBal]);

  const nfts = useMemo(() => {
    if (accountData && accountData.account) {
      const res: any[] = [];
      accountData.account.balances.forEach((balance) => {
        balance.tonAmba1Nfts.forEach((tonAmbaNftR1) => {
          res.push(tonAmbaNftR1);
        });
      });
      return res;
    }
    return [];
  }, [accountData]);

  const { t: tMyTier } = useTranslation();
  const { t: tLinkAddress } = useTranslation();

  const linked = useMemo(() => {
    if (accountData && accountData.account && myBnbAddr && myTonAddr) {
      return (
        accountData.account.balances.filter(
          (balance) =>
            balance.addr.toLowerCase() === myBnbAddr.toLowerCase() ||
            balance.addr.toLowerCase() === Address.parse(myTonAddr).toRawString().toLowerCase()
        ).length === 2
      );
    } else {
      return true;
    }
  }, [myBnbAddr, myTonAddr, accountData]);

  return (
    <>
      <div className="flex flex-col gap-8 w-[450px]">
        {(!myTonAddr || !myBnbAddr) && (
          <div className="w-full flex justify-content-center items-center gap-2">
            {!myTonAddr && <WalletTon />}
            {!myBnbAddr && <ConnectButton />}
          </div>
        )}
        {accountData && accountData.account && (
          <>
            <MyBalance
              mySrcJBal={myTonAddr ? mySrcJBal : BigInt(0)}
              linked={linked}
              totalAp={accountData.account.totalAp || "0"}
              stakingAp={accountData.account.stakingAp || "0"}
              bonusAp={
                (
                  BigInt(accountData.account.bonusAp) + BigInt(accountData.account.ghostAp)
                ).toString() || "0"
              }
              walletAmount={myWalletBal.toString()}
              connectedAddrs={connectedAddrs}
              handleLogoutClick={async ({ addr }: { addr: string }) => {
                switch (addr) {
                  case myBnbAddr:
                    return await disconnectBnb();
                  case myTonAddr:
                    return await tonConnectUI.disconnect();
                }
              }}
              handleLinkBalClick={async () => {
                setOpenLinkBalModal(true);
                try {
                  await linkBalances({
                    variables: {
                      linkBalancesInput: {
                        bnbAddr: myBnbAddr as string,
                        tonAddr: myTonAddr,
                      },
                    },
                  });
                  await syncAccount({ variables: { syncAccountInput: { addr: myTonAddr } } });
                  await refetchAccount();
                  setTimeout(() => {
                    setOpenLinkBalModal(false);
                  }, 5000);
                } catch (err) {
                  console.log(err);
                  setTimeout(() => {
                    setOpenLinkBalModal(false);
                  }, 5000);
                }
              }}
            />
            {console.log(nfts)}
            <MyTier
              tier={accountData.account.tier as any}
              nfts={nfts as any}
              totalAp={accountData.account.totalAp || "0"}
              t={tMyTier}
            />
          </>
        )}
      </div>

      <Modal
        opened={isOpenLinkAddressModal}
        title={tLinkAddress("Link EVM address")}
        handleCloseClick={() => setOpenLinkBalModal(false)}
      >
        <LinkAddress
          linkAddressData={linkBalancesData}
          linkAddressLoading={linkBalancesLoading}
          linkAddressError={linkBalancesError}
        />
      </Modal>
    </>
  );
}
