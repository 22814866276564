import Button from "app/components/button/button";
import SocialActivities from "app/components/social-activities/social-activities";
import {
  useActions,
  useAllowance,
  useApplication,
  useApproveMutation,
  useBalance,
  useExchangeCurrency,
  useFcfsAmount,
  useRedeemIdoFcfsMutation,
} from "app/hooks";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChainId, Ido, ROOT_ADDRESSES_MAP } from "sdk";
import {
  useIdoByIdQuery,
  useSyncIdoRedeemedMutation,
} from "store/api";
import { useAccount, useChainId } from "wagmi";

type Props = {
  className?: string;
  ido: Ido;
};

const IdoFcfs__Buttons: FC<Props> = ({ ido }) => {
  const { t } = useTranslation();
  const { address } = useAccount()
  const chainId = useChainId() as ChainId
  const { setFcfsInputValue, openModal } = useActions();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { refetch: refetchIdo } = useIdoByIdQuery(ido.id);
  const [redeem, { isLoading: isRedeemLoading, isError: isRedeemError }] =
    useRedeemIdoFcfsMutation();
  const [syncRedeemed, { isLoading: isSyncRedeemedLoading }] = useSyncIdoRedeemedMutation();
  const { exchangeCurrencySymbol, currentExchangeCurrency } = useExchangeCurrency(ido);
  const [approve, { isLoading: isApproveLoading }] = useApproveMutation();
  const { allowance, isAllowanceLoading, refetchAllowance } = useAllowance(
    currentExchangeCurrency?.address,
    currentExchangeCurrency?.decimals
  );
  const { balance, isBalanceLoading } = useBalance(
    currentExchangeCurrency?.address,
    currentExchangeCurrency?.decimals
  );

  const { application, refetchApplication, isApplicationLoading } = useApplication(ido);
  const { availableFcfsAmount, fcfsInputAmount } = useFcfsAmount(ido);

  const onApproveClick = async () => {
    if (!currentExchangeCurrency) return;

    await approve({
      address: currentExchangeCurrency.address,
      spender: ROOT_ADDRESSES_MAP[chainId],
    });
    refetchAllowance();
    refetchApplication();
  };

  const swap = async () => {
    await redeem({
      id: ido.id,
      amount: fcfsInputAmount,
      chainId,
      address: ROOT_ADDRESSES_MAP[chainId],
    });
    await syncRedeemed(ido.id);
    setFcfsInputValue("");
    refetchIdo();
    refetchApplication();
  };

  const onSwapClick = async () => {
    if (errorMessage) {
      setErrorMessage("");
    }

    if (!application) {
      openModal(<SocialActivities project={ido} onSubmit={swap} />);
    } else {
      await swap();
    }
  };

  useEffect(() => {
    if (isRedeemError) {
      setErrorMessage("Incorrect amount");
    }
  }, [isRedeemError]);

  const disabled = useMemo(() => {
    if (
      currentExchangeCurrency &&
      allowance &&
      allowance.gte(fcfsInputAmount) &&
      !fcfsInputAmount.isZero() &&
      balance &&
      balance.gte(fcfsInputAmount) &&
      fcfsInputAmount.lte(availableFcfsAmount)
    ) {
      return false;
    }
    return true;
  }, [address, allowance, fcfsInputAmount, balance, availableFcfsAmount, currentExchangeCurrency]);

  const isLoading = useMemo(() => {
    if (
      isApproveLoading ||
      isAllowanceLoading ||
      isRedeemLoading ||
      isBalanceLoading ||
      isApplicationLoading ||
      isSyncRedeemedLoading
    ) {
      return true;
    }
    return false;
  }, [
    isApproveLoading,
    isAllowanceLoading,
    isRedeemLoading,
    isBalanceLoading,
    isApplicationLoading,
    isSyncRedeemedLoading,
  ]);

  useEffect(() => {
    syncRedeemed(ido.id);
  }, []);

  return (
    <>
      <div className="project-swap__buttons">
        {!allowance || allowance.lt(fcfsInputAmount) ? (
          <Button
            onClick={onApproveClick}
            isLoading={isLoading}
            disabled={!currentExchangeCurrency}
          >
            {t("Approve")}
          </Button>
        ) : (
          <Button onClick={onSwapClick} isLoading={isLoading} disabled={disabled}>
            {balance.gte(fcfsInputAmount)
              ? t("swapButton")
              : t("Insufficient balance", { symbol: exchangeCurrencySymbol })}
          </Button>
        )}
      </div>
      <div className="project-swap__error">{t(errorMessage)}</div>
    </>
  );
};

export default IdoFcfs__Buttons;
