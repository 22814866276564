import cn from "classnames";
import { PropsWithChildren } from "react";
import ReactModal from "react-modal";
// import { SHARED_IMAGES } from 'ui';
import iClose from "./close.svg";
import "./modal.sass";

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    minWidth: "340px",
    border: "none",
    zIndex: 99999,
    background: "transparent",
    padding: 0,
  },
  overlay: {
    zIndex: 99998,
    backgroundColor: "rgba(16, 16, 32, 0.8)",
    width: "100vw",
    height: "100vh",
  },
};

const Modal: React.FC<PropsWithChildren<IProps>> = ({ isOpen, children, onRequestClose }) => {
  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={styles}>
      <div
        className={cn("modal-ton")}
        style={{
          background:
            "linear-gradient(0deg, #19222F, #19222F), linear-gradient(180deg, #050D18 0%, #0E1F31 100%)",
        }}
      >
        <div
          className="modal-ton__close"
          onClick={() => {
            onRequestClose();
          }}
        >
          <img src={iClose} alt="close" />
        </div>
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
