import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import {
  closeModal,
  getKycStatus,
  kycSlice,
  layoutSlice,
  projectSlice,
  referrerPageSlice,
  walletTypeSlice,
} from "store/slice";

export const useActions = () => {
  const dispatch = useDispatch<AppDispatch>();
  return bindActionCreators(
    {
      ...kycSlice.actions,
      getKycStatus,
      ...projectSlice.actions,
      ...layoutSlice.actions,
      ...walletTypeSlice.actions,
      closeModal,
      ...referrerPageSlice.actions,
    },
    dispatch
  );
};
