import { useIdoTitlePostfix } from 'app/hooks'
import { FC } from 'react'

type PropsType = {
  projectId?: string
}

const IdoTitlePostfix: FC<PropsType> = ({ projectId }) => {

  const postfix = useIdoTitlePostfix(projectId)
  if (!postfix) {
    return <></>
  }
  return (
    <span style={postfix.style}>{postfix.postfix}</span>
  )
}

export default IdoTitlePostfix
