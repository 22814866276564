import { BigNumber } from "ethers";
import { ChainId } from "sdk";
import apiService from "services/api.service";
import rootContract from "services/root.contract";
import { useMutation } from "./use-mutation";

export const useRedeemIdoFcfsMutation = () => {
  const redeemIdoFcfsMutation = async ({
    address,
    id,
    chainId,
    amount,
  }: {
    id: string;
    amount: BigNumber;
    chainId: ChainId;
    address: string;
  }) => {
    const voucher = await apiService.getIdoFcfsMetaTx({
      id,
      amount: amount.toString(),
      chainId,
    });
    return await rootContract.redeemIdoPopulate({ address, chainId, voucher });
  };
  return useMutation(redeemIdoFcfsMutation);
};
