import axios, { AxiosInstance } from "axios";
import { ChainId } from "sdk";
import { VoucherV2 } from "sdk/src/contracts/typechain-types/RootV2";
import TokenService from "./token.service";

class ApiService {
  private _instance: AxiosInstance;

  constructor() {
    this._instance = axios.create({
      baseURL: process.env.REACT_APP_API_ROOT,
    });
    this._initInterceptor();
  }

  private _initInterceptor = () => {
    this._instance.interceptors.request.use((config) => {
      const tokens = TokenService.tokens;
      if (!tokens) return config;

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      };
    });
  };

  getIdoSwapMetaTx = async ({
    id,
    amount,
    chainId,
  }: {
    id: string;
    amount: string;
    chainId: ChainId;
  }) => {
    const { data } = await this._instance.get<VoucherV2.RedeemIdoVoucherStruct>(
      `/idos/${id}/redeemMetaTx?amount=${amount}&chainId=${chainId}`
    );
    return data;
  };

  getIdoFcfsMetaTx = async ({
    id,
    amount,
    chainId,
  }: {
    id: string;
    amount: string;
    chainId: ChainId;
  }) => {
    const { data } = await this._instance.get(
      `/idos/${id}/fcfsMetaTx?amount=${amount}&chainId=${chainId}`
    );
    return data;
  };

  getRefundMetaTx = async ({ id, chainId }: { id: string; chainId: ChainId }) => {
    const { data } = await this._instance.get(`/idos/${id}/refundMetaTx?chainId=${chainId}`);
    return data;
  };

  getReturnTokensMetaTx = async ({ id, chainId }: { id: string; chainId: ChainId }) => {
    const { data } = await this._instance.get(
      `/idos/${id}/returnProjectTokensMetaTx?chainId=${chainId}`
    );
    return data;
  };

  postLoginNetwork = async ({ address, ...body }: { chainId: number; address: string }) => {
    const { data } = await this._instance.post(`/users/${address}/login-networks`, body);
    return data;
  };
}

export default new ApiService(); // TODO make a hooks
