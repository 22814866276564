import cn from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import "./tabs.sass";

type Props = {
  list: string[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
};

const Tabs: FC<Props> = ({ list, selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();

  return (
    <div className="tab__list">
      {list.map((tab) => (
        <button
          className={cn("tab", { _selected: selectedTab === tab })}
          onClick={() => setSelectedTab(tab)}
        >
          {t(tab)}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
