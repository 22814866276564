import Button from "app/components/button/button";
import SocialActivities from "app/components/social-activities/social-activities";
import { ProjectStage } from "app/enums";
import { ApplicationStatus, useActions, useApplicationStatus, useAuth, useProjectTasks } from "app/hooks";
import iReject from "assets/img/status-reject.svg";
import iSuccess from "assets/img/status-success.svg";
import iWait from "assets/img/wait-ellipse.png";
import cn from "classnames";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Ido, LinkName } from "sdk";
import { selectProjectStage } from "store/slice";
import "./application-status.sass";

type Props = {
  project: Ido;
  setNotification: (notification: string) => void
};

const AplicationStatus: FC<Props> = ({ project, setNotification }) => {
  const { t } = useTranslation();
  const isAuth = useAuth();
  const projectStage = useSelector(selectProjectStage);
  const { openModal } = useActions();
  const applicationStatus = useApplicationStatus(project);

  const projectTasks = useProjectTasks(project);

  const { showButton, notification } = useMemo(() => {
    if (
      applicationStatus === ApplicationStatus.notCreated ||
      applicationStatus === ApplicationStatus.rejected
    ) {
      return {
        showButton: true,
        notification: project.id === '6464c4bc2d3b5cea84d59498' ? t('Applications for Whitelisting for the Paysenger Community Sale are now open. Attention! Only $GGR holders and Tier holders who have submitted an application for the whitelist are eligible to participate in the sale.') : t('Participate in the Whitelist lottery')
      };
    }
    const sweepWidget = projectTasks?.find(el => el.name === LinkName.SweepWidget && el.isTask)
    return {
      showButton: false, notification: sweepWidget && `${t('Please make sure you have completed all the tasks on')} <a style='text-decoration: underline;' target='_blank' href='${sweepWidget.link}'>SweepWidget</a>`
    };
  }, [applicationStatus]);

  const { title, icon, isIconAnimated } = useMemo(() => {
    if (applicationStatus === ApplicationStatus.approved) {
      return {
        title:
          "Application has been accepted. The results will be published before the swap starts.",
        icon: iSuccess,
        isIconAnimated: false,
      };
    }
    if (applicationStatus === ApplicationStatus.rejected) {
      return {
        title:
          "Application was rejected. Check the correctness of social nicknames and try to apply again.",
        icon: iReject,
        isIconAnimated: false,
      };
    }
    return {
      title:
        "Your application has been sent. The results of the lottery will be announced 24 hours before the start of IDO (Round 1).",
      icon: iWait,
      isIconAnimated: true,
    };
  }, [applicationStatus]);

  useEffect(() => () => setNotification(''), [notification])
  useEffect(() => setNotification(notification), [notification])


  useEffect(() => {
    console.log({ applicationStatus });
  }, [applicationStatus]);

  return (
    <>
      {applicationStatus > ApplicationStatus.notCreated && isAuth && (
        <div
          className={cn("application-status", {
            _rejected: applicationStatus === ApplicationStatus.rejected,
          })}
        >
          <div className="application-status__inner">
            <>
              {applicationStatus === ApplicationStatus.interview && (
                <h3 className="application-status__text_support">{t("Wait")}</h3>
              )}
              <img
                src={icon}
                alt=""
                className={cn("application-status__icon", {
                  "_g-rotate-anim": isIconAnimated,
                })}
              />
              <h3 className="application-status__text">{t(title)}</h3>
            </>
          </div>
        </div>
      )}

      {showButton && (
        <Button
          disabled={projectStage < ProjectStage.whitelist || !isAuth}
          onClick={() => openModal(<SocialActivities project={project} />)}
        >
          {t("Apply to Whitelist")}
        </Button>
      )}
    </>
  );
};

export default AplicationStatus;
