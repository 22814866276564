import { useMutation } from "app/hooks/root-contract/mutation/use-mutation";
import { constants } from "ethers";
import erc20Contract from "services/ERC20.contract";
import { useChainId } from "wagmi";

export const useApproveMutation = () => {
  const chainId = useChainId();
  const amount = constants.MaxUint256;
  const approveMutation = async ({ address, spender }: { address: string; spender: string }) => {
    return await erc20Contract.approvePopulate({
      address,
      spender,
      amount,
      chainId,
    });
  };
  return useMutation(approveMutation);
};
