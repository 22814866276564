import { ChainId, ROOT_ADDRESSES_MAP } from "sdk";
import apiService from "services/api.service";
import rootContract from "services/root.contract";
import { useMutation } from "./use-mutation";

export const useRefundMutation = () => {
  const refundMutation = async ({ id, chainId }: { id: string; chainId: ChainId }) => {
    const voucher = await apiService.getRefundMetaTx({ id, chainId });
    return await rootContract.refundPopulate({
      address: ROOT_ADDRESSES_MAP[chainId],
      voucher,
      chainId: chainId,
    });
  };
  return useMutation(refundMutation);
};
