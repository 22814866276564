import cn from "classnames";
import { useMemo } from "react";
import iTierEarth from "../../../../assets/img/tier/Earth_img_tier.svg";
import iTierGalaxy from "../../../../assets/img/tier/galaxy_img_tier.svg";
import { TierType } from "../../types/tier";
import { TonAmbaNftR1Type, TTonAmbaNftR1 } from "../../types/ton-amba-nft-r1";
import iEpic from "./epic.jpg";
import { Hint, SimpleList } from "./index";
import KeyValueList from "./key-value-list";
import iRare from "./rare.jpg";

import iTierOrbit from "./../../../../assets/img/tier/orbit_img_tier.svg";
import iTierUniverse from "./../../../../assets/img/tier/Universe_img_tier.svg";

type TProps = {
  className?: string;
  totalAp: string;
  allocationPowerTillNextTier?: string;
  tier: TierType;
  nfts: TTonAmbaNftR1[];
  t: (key: string) => string;
};

const MyTier: React.FC<TProps> = ({
  className,
  tier,
  nfts,
  totalAp,
  allocationPowerTillNextTier,
  t,
}) => {
  const tierImage = useMemo(() => {
    switch (tier) {
      case "EARTH":
        return iTierEarth;
      case "GALAXY":
        return iTierGalaxy;
      case "ORBIT":
        return iTierOrbit;
      case "UNIVERSE":
        return iTierUniverse;
      default:
        return undefined;
    }
  }, [tier]);

  const tierText = useMemo(() => {
    switch (tier) {
      case TierType.EARTH:
        return {
          title: t("Earth"),
          items: [{ value: t("Allocation via lottery") }, { value: t("Mandatory social tasks") }],
        };
      case TierType.ORBIT:
        return {
          title: t("Orbit"),
          items: [{ value: t("Garanteed allocation") }, { value: t("Mandatory social tasks") }],
        };
      case TierType.GALAXY:
        return {
          title: t("Galaxy"),
          items: [{ value: t("Garanteed allocation") }, { value: t("Mandatory social tasks") }],
        };
      case TierType.UNIVERSE:
        return {
          title: t("Universe"),
          items: [{ value: t("Garanteed allocation") }, { value: t("Without social tasks") }],
        };
      default:
        return undefined;
    }
  }, [tier]);

  return (
    <div className={cn("bg-bg_card rounded-3xl p-6", className)}>
      <div className="mb-4 flex items-center gap-2">
        <div className="p2b text-text_primary">{t("My Tier")}</div>
      </div>

      {tier && (
        <div className="mb-8 last:mb-0">
          <div className="mb-4 flex items-center gap-4">
            {tier !== TierType.NONE ? (
              <>
                {tierImage && (
                  <img className="h-24 w-24" width="96" height="96" src={tierImage} alt="" />
                )}
                <SimpleList title={tierText?.title} items={tierText?.items}></SimpleList>
              </>
            ) : (
              <SimpleList
                items={[
                  {
                    value: t(
                      "Looks like your Allocation Power is currently not enough to have a Tier. You can easily solve it your tier by purchasing and staking GGR tokens. Don't miss out opportunity to maximize your investment potential. Buy and stake GGR tokens now!"
                    ),
                  },
                ]}
              ></SimpleList>
            )}
          </div>
          <KeyValueList
            items={[
              { key: t("Total allocation power"), value: `${totalAp} AP` },
              ...(allocationPowerTillNextTier
                ? [{ key: t("Till next tier"), value: `${allocationPowerTillNextTier} AP` }]
                : []),
            ]}
          />
        </div>
      )}

      {nfts && !!nfts.length && (
        <>
          <div className="mb-4 flex items-center gap-2">
            <div className="p2b text-text_primary">{t("Bonus NFTs")}</div>
            <Hint dropdown={t("Only NFT that gives the biggest tier is taken into account")} />
          </div>
          {nfts.map((nft) => (
            <div className="mb-4 flex gap-4 last:mb-0" key={nft.id}>
              <img className="h-24 w-24 rounded" src={nft.type === "EPIC" ? iEpic : iRare} alt="" />
              <SimpleList
                title={`⚡️${nft.type === "EPIC" ? "Epic" : "Rare"} NFTs #${nft.index}`}
                items={[
                  {
                    value:
                      nft.type === TonAmbaNftR1Type.EPIC
                        ? t(
                            "Gives you Galaxy tier, 5% cashback and guaranteed allocation up to $600"
                          )
                        : t(
                            "Gives you Orbit tier, 1% cashback and guaranteed allocation up to $400"
                          ),
                  },
                ]}
              ></SimpleList>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default MyTier;
