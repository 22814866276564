import { useWidth } from "app/hooks";
import { TimelineStageData } from "app/types";
import iArrow from "assets/img/timeline-arrow.svg";
import cn from "classnames";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import "./project-timeline.sass";

type Props = {
  className?: string;
  stages: TimelineStageData[];
};

const ProjectTimeline: FC<Props> = ({ className, stages }) => {
  const { t } = useTranslation();
  const { isMobileWidth } = useWidth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleTimeline = (): void => {
    setIsOpen((state) => !state);
  };

  return (
    <div
      className={cn("project-timeline", className, {
        _open: isOpen,
      })}
      onClick={toggleTimeline}
    >
      <div className="project-timeline__inner">
        {isMobileWidth && (
          <>
            <div className="project-timeline__button">
              <div className="project-timeline__title">{t("Timeline")}</div>
              <img
                className={cn("project-timeline__button-arrow", {
                  _open: isOpen,
                })}
                src={iArrow}
                alt="arrow"
                width={22}
                height={22}
              />
            </div>
            <div className="project-timeline__header">
              <div className="project-timeline__column-title">{t("Stage")}</div>
              <div className="project-timeline__column-title">{t("Duration")}</div>
            </div>
          </>
        )}
        {stages.map(({ active, title, dates, dateActive }, i) => (
          <div key={i} className="project-timeline__item">
            <div className="project-timeline__item-row">
              <div
                className={cn("project-timeline__item-title", {
                  _active: active && !isMobileWidth,
                  "_text-active": isMobileWidth && dateActive,
                })}
                dangerouslySetInnerHTML={{ __html: t(title) }}
              >
              </div>
              <div
                className={cn("project-timeline__item-line", {
                  _active: isMobileWidth ? dateActive : stages[i + 1]?.active,
                })}
              />
            </div>
            {dates && (
              <div className="project-timeline__item-dates">
                {dates.map((date, i) => (
                  <div
                    className={cn("project-timeline__item-date", { _active: dateActive })}
                    key={i}
                    dangerouslySetInnerHTML={{
                      __html: isMobileWidth ? date.replace("—", "<br />") : date,
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectTimeline;
