import BscscanIcon from "assets/img/bscscan-icon.svg";
import DiscordIcon from "assets/img/discord-icon.svg";
import DocumentIcon from "assets/img/document-icon.svg";
import InstargramIcon from "assets/img/instagram-icon.svg";
import MediumIcon from "assets/img/medium-icon.svg";
import TelegramIcon from "assets/img/telegram-icon.svg";
import TwitterIcon from "assets/img/twitter-icon.svg";
import WebsiteIcon from "assets/img/website-icon.svg";
import cn from "classnames";
import { FC } from "react";
import { ProjectLink, SocialLinkName } from "sdk";

const SOCIAL_LINK_ICON_MAP = {
  [SocialLinkName.Telegram]: TelegramIcon,
  [SocialLinkName.Twitter]: TwitterIcon,
  [SocialLinkName.Website]: WebsiteIcon,
  [SocialLinkName.Medium]: MediumIcon,
  [SocialLinkName.Discord]: DiscordIcon,
  [SocialLinkName.Instagram]: InstargramIcon,
  [SocialLinkName.Bscscan]: BscscanIcon,
  [SocialLinkName.Document]: DocumentIcon,
};

type Props = {
  link: ProjectLink;
};

const ProjectLinkSocial: FC<Props> = ({ link }) => {
  return (
    <a
      className={cn("project__link", `_${link.name.toLowerCase()}`)}
      href={link.link}
      target="_blank"
    >
      <img
        src={SOCIAL_LINK_ICON_MAP[link.name as unknown as SocialLinkName]}
        alt={link.name}
        className="project__link-icon"
      />
    </a>
  );
};

export default ProjectLinkSocial;
