import { ProjectStage } from "app/enums";
import {
  useCurrency,
  useFcfsEnd,
  useFcfsStart,
  useIdoStage,
  useInvestedAmount,
  useInvestedPercent,
  useInvestedTokensAmount,
  useSwapEnd,
  useSwapStart,
  useTotalRaiseAmount,
  useTotalRaiseTokensAmount,
  useWhitelistEnd,
  useWhitelistStart,
  useWidth,
} from "app/hooks";
import { FC, useMemo } from "react";
import { Ido } from "sdk";
import ProjectFill from "../project/project-fill";

type Props = {
  ido: Ido;
  className?: string;
};

const IdoFill: FC<Props> = ({ ido, className }) => {
  const projectStage = useIdoStage(ido);
  const totalRaiseTokensAmount = useTotalRaiseTokensAmount(ido);
  const investedPercent = useInvestedPercent(ido.invested, ido.totalRaise);
  const totalInvestedAmount = useInvestedAmount(ido);
  const totalRaiseAmount = useTotalRaiseAmount(ido);
  const { claimCurrencyIcon, claimCurrencySymbol, exchangeCurrencySymbol, exchangeCurrencyIcon } =
    useCurrency(ido);
  const investedTokensAmount = useInvestedTokensAmount(ido);
  const { isMobileWidth } = useWidth();
  const whitelistStart = useWhitelistStart(ido);
  const whitelistEnd = useWhitelistEnd(ido);
  const swapStart = useSwapStart(ido);
  const swapEnd = useSwapEnd(ido);
  const fcfsStart = useFcfsStart(ido);
  const fcfsEnd = useFcfsEnd(ido);

  const title = useMemo(() => {
    const separator = isMobileWidth ? <br /> : "—";

    if (projectStage < ProjectStage.whitelist) {
      return { text: "Whitelist start", separator, date: whitelistStart };
    }
    if (projectStage === ProjectStage.whitelist && projectStage < ProjectStage.swapGap) {
      return { text: "Whitelist end", separator, date: whitelistEnd };
    }
    if (projectStage >= ProjectStage.swapGap && projectStage < ProjectStage.swap) {
      return { text: "Swap start", separator, date: swapStart };
    }
    if (projectStage >= ProjectStage.swap && projectStage < ProjectStage.fcfsGap) {
      return { text: "Swap end", separator, date: swapEnd };
    }
    if (projectStage >= ProjectStage.fcfsGap && projectStage < ProjectStage.highTierFcfs) {
      return { text: "High tier FCFS start", separator, date: fcfsStart };
    }
    if (projectStage >= ProjectStage.highTierFcfs && projectStage < ProjectStage.fcfs) {
      return { text: "High tier FCFS end", separator, date: fcfsEnd };
    }
    if (projectStage >= ProjectStage.fcfs && projectStage < ProjectStage.claimGap) {
      return { text: "FCFS end", separator, date: fcfsEnd };
    }
    return { text: "", separator: "", date: "" };
  }, [
    projectStage,
    isMobileWidth,
    whitelistStart,
    whitelistEnd,
    swapStart,
    swapEnd,
    fcfsStart,
    fcfsEnd,
  ]);

  return (
    <ProjectFill
      className={className}
      projectStage={projectStage}
      investedPercent={investedPercent}
      title={title}
    >
      <div className="project-fill__raise">
        {isMobileWidth ? (
          `${totalInvestedAmount} / ${totalRaiseAmount} ${exchangeCurrencySymbol}`
        ) : (
          <>
            {exchangeCurrencyIcon ? (
              <img
                className="project-fill__icon"
                src={exchangeCurrencyIcon}
                alt={exchangeCurrencySymbol}
                width={22}
                height={22}
              />
            ) : (
              exchangeCurrencySymbol
            )}{" "}
            {totalInvestedAmount}
          </>
        )}
      </div>
      <div className="project-fill__fill">
        {isMobileWidth ? (
          `${investedTokensAmount} / ${totalRaiseTokensAmount} ${claimCurrencySymbol}`
        ) : (
          <>
            {investedTokensAmount} / {totalRaiseTokensAmount}{" "}
            {claimCurrencyIcon ? (
              <img
                className="project-fill__icon"
                src={claimCurrencyIcon}
                alt={claimCurrencySymbol}
                width={22}
                height={22}
              />
            ) : (
              claimCurrencySymbol
            )}
          </>
        )}
      </div>
    </ProjectFill>
  );
};

export default IdoFill;
