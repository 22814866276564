export enum CurrencyTicket {
  BUSD = "BUSD",
  USDT = "USDT",
  USDC = "USDC",
}

export enum Connector {
  Injected = "injected",
  WalletConnect = "walletconnect",
  OKX = "custom-okx",
}

export enum KycStatus {
  Success = "success",
  Pending = "pending",
  Idle = "idle",
  Reject = "reject",
}

export enum TimelineStage {
  TBA,
  UPCOMING,
  SWAP,
  FCFS,
  HIGH_TIER_FCFS,
  FILLED,
  CLAIMABLE,
  ENDED,
}

export enum TierAirdrop {
  YES = "yes",
  SOON = "soon",
  NO = "no",
}

export enum TierAllocation {
  LOTTERY = "Allocation via lottery",
  GUARANTEED = "Guaranteed allocation",
  DYNAMIC = "Guaranteed dynamic allocation",
}

export enum TierSocailTasks {
  REQUIRED = "Mandatory social tasks",
  OPTIONAL = "Without social tasks",
}

export enum TierIndex {
  TIER_1,
  TIER_2,
  TIER_3,
  TIER_4,
}

export enum InvestmentType {
  BUY = "accountPage.buy",
  CLAIM = "accountPage.claim",
}

export enum QueryParam {
  MARKETING_ACTIVITY_TYPE = "activity",
  REFERAL_CODE = "ref",
  PROJECT_FILTER = "projectFilter",
}

export enum LocalStorageItem {
  REFERAL_CODE = "ref",
}

export enum ActivityStatus {
  NOW,
  NOT_STARTED,
  ENDED,
}

export enum ProjectStage {
  whitelistTba = 1, // дата whitelist неизвестна
  whitelistGap, // дата whitelist известна, но не наступила
  whitelist, // whitelist открыт
  swapGap, // whitelist закрыт, но лотерея не проведена
  swapGapWithResults, // whitelist закрыт, лотерея проведена, но дата swap не наступила
  swap, // swap начался
  fcfsGap, // swap закончился, fcfs не начался
  highTierFcfs, // swap закончился, fcfs не начался
  fcfs, // fcfs начался
  claimGap, // fcfs закончился, дата claim не наступила
  claim, // claim начался
  ended,
  refund, // возврат средств
  refundPolicy, // ручной возврат средств после TGE
  pause,
}
