import Button from "app/components/button/button";
import { ProjectStage } from "app/enums";
import { useActions, useApplication, useProjectTasks } from "app/hooks";
import { Project } from "app/types";
import { SocialActivitiesForm } from "app/types/social-activities-form";
import iSweepWidget from "assets/img/Logo-SweepWidget.png";

import cn from "classnames";
import { FC, FormEventHandler, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LinkName, isIdo } from "sdk";
import { useCreateApplicationMutation } from "store/api";
import { selectProjectStage } from "store/slice";
import Checkbox from "../checkbox/checkbox";
import { ProjectNotification } from "../project/project-notification/project-notification";
import { SocialActivitiesAdditionalInputList } from "./social-activities-additional-input.list";
import { SocialActivitiesDefaultInputList } from "./social-activities-default-input.list";
import { SocialActivitiesLinksList } from "./social-activities-links.list";
import "./social-activities.sass";

type Props = {
  className?: string;
  project: Project;
  onSubmit?: () => Promise<void>;
};

const SocialActivities: FC<Props> = ({ className, project, onSubmit }) => {
  const { t } = useTranslation();
  const { closeModal } = useActions();
  const projectStage = useSelector(selectProjectStage);
  const additionalFields: Omit<SocialActivitiesForm, "telegram" | "twitter"> = useMemo(() => {
    if (!project.modalFields) return {};

    return project.modalFields.reduce((result, { name, title, required }) => {
      return {
        ...result,
        [name]: {
          title,
          value: "",
          name,
          required,
        },
      };
    }, {});
  }, [project.modalFields]);

  const defaultForm: SocialActivitiesForm = {
    telegram: {
      value: "",
      title: "Telegram username",
      name: "telegram",
      required: true,
    },
    twitter: {
      value: "",
      title: "Twitter username",
      name: "twitter",
      required: true,
    },
    ...additionalFields,
  };

  const [form, setForm] = useState({ ...defaultForm });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);

  const isFilled = useMemo(() => {
    return !Object.values(form)
      .map(({ value, required }) => (required ? !!value : true))
      .includes(false);
  }, [form]);

  const isView = useMemo(() => {
    return projectStage < ProjectStage.whitelist;
  }, [projectStage]);

  const { refetchApplication } = useApplication(project);
  const [
    createApplication,
    {
      error: createApplicationError,
      isSuccess: isCreateApplicationSuccess,
      isLoading: isCreateApplicationLoading,
    },
  ] = useCreateApplicationMutation();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const body = Object.entries(form).reduce(
      (result, [key, { value }]) => ({ ...result, [key]: value }),
      { projectId: project.id }
    );
    if (isIdo(project)) {
      await createApplication(body);
      refetchApplication();
    }

    if (onSubmit) {
      onSubmit(); // без await, т.к. просто нужно запустить
      reset();
    }
  };

  const updateValue = (key: keyof SocialActivitiesForm, rawValue?: string): void => {
    setForm((state) => ({
      ...state,
      [key]: { ...state[key], value: rawValue || "" },
    }));
  };

  const reset = () => {
    closeModal();
    setForm({ ...defaultForm });
  };

  const disabled = useMemo(
    () =>
      isCreateApplicationLoading ||
      !isFilled ||
      (form["file"] && !checked),
    [isCreateApplicationLoading, isFilled, form, checked]
  );

  useEffect(() => {
    if (isCreateApplicationSuccess && !onSubmit) reset();
  }, [isCreateApplicationSuccess, onSubmit]);

  useEffect(() => {
    if (createApplicationError) {
      let error: any = createApplicationError;

      if (error?.data?.error?.statusCode && error?.data?.error?.statusCode === 409) {
        setErrorMessage(error?.data?.error?.message);
      }
    }
  }, [createApplicationError]);

  const projectTasks = useProjectTasks(project);

  return (
    <form
      className={cn("social-activities", className)}
      onSubmit={handleSubmit}
      id="apply-application-form"
    >
      <div className="social-activities__title">
        <Trans i18nKey="Applying for Whitelist">
          <span className="_g-text-active-lighter">Mandatory</span> social activities
        </Trans>
      </div>
      <div className="social-activities__subtitle">
        {t("To apply to Whitelist you will need to complete the tasks below")}
      </div>
      {!isView && (
        <div className="social-activities__row">
          <SocialActivitiesDefaultInputList form={form} updateValue={updateValue} />
          <div className="social-activities__form-error">{t(errorMessage)}</div>
        </div>
      )}
      {projectTasks?.some(el => el.name === LinkName.SweepWidget) ? (
        <>
          <div className="social-activities__row">
            <div className="social-activities__row-title">
              {!isView && <div className="social-activities__row-title-count">2.</div>}
              <div className="social-activities__row-title-text">
                {t("Complete all tasks on the link")}
              </div>
            </div>

            <div className="social-activities__list">
              {
                projectTasks
                  ?.filter((task) => task.name === LinkName.SweepWidget)
                  .map((task) => (
                    <a
                      className="social-activities__list-item"
                      key={task.id}
                      href={task.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={iSweepWidget} className="social-activities__list-item-icon" />
                      {t('click to go to SweepWidget')}
                    </a>))
              }
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="social-activities__row">
            <div className="social-activities__row-title">
              {!isView && <div className="social-activities__row-title-count">2.</div>}
              <div className="social-activities__row-title-text">
                {t("Subscribe to telegram accounts")}
              </div>
            </div>
            <SocialActivitiesLinksList project={project} type="telegram" />
          </div>
          <div className="social-activities__row">
            <div className="social-activities__row-title">
              {!isView && <div className="social-activities__row-title-count">3.</div>}
              <div className="social-activities__row-title-text">
                {t("Subscribe to twitter accounts")}
              </div>
            </div>
            <SocialActivitiesLinksList project={project} type="twitter" />
          </div>
        </>
      )}
      {project.modalFields?.length && !isView && (
        <div className="social-activities__row">
          <SocialActivitiesAdditionalInputList form={form} updateValue={updateValue} />
          {form["file"] && (
            <Checkbox checked={checked} setChecked={setChecked}>
              {t(
                "I consent to the provision and processing of personal data to the GAGARIN partner for the KYC procedure"
              )}
            </Checkbox>
          )}
        </div>
      )}
      <div className="social-activities__footer" style={{ marginBottom: 36 }}>
        {isView ? (
          <div className="social-activities__desc">
            {t(
              "After Whitelist opens, you will need to enter your social nicknames to check the completion of social tasks and accepts your application. You can subscribe now to be as prepared as possible for Whitelisting."
            )}
          </div>
        ) : (
          <Button
            form="apply-application-form"
            disabled={disabled}
            type="submit"
            className="social-activities__form-submit"
          >
            {t("Confirm")}
          </Button>

        )}
      </div>
      <ProjectNotification project={project} notification={t('Please make sure you have completed all the tasks and entered your contact information correctly so that we can check your social activities and accept your application.')} />
    </form>
  );
};

export default SocialActivities;
