import iWait from "assets/img/wait-ellipse.png";
import { useTranslation } from "react-i18next";

type TProps = { className?: string };

function ProjectSwap__Wait({ className = "" }: TProps) {
  const { t } = useTranslation();
  return (
    <div className="project-swap__wait">
      <img className="_g-rotate-anim" width={22} height={22} src={iWait} alt="wait" />
      {t("Lottery is running")}
      <br />
      {t("Please wait for the results")}
    </div>
  );
}

export default ProjectSwap__Wait;
