import { MutationHookOptions } from "@apollo/client";
import {
  LinkBalancesMutation,
  LinkBalancesMutationVariables,
  useLinkBalancesMutation as useLinkBalancesMutationBase,
} from "graphql/@generated/graphql";

export const useLinkBalancesMutation = (
  baseOptions?: MutationHookOptions<LinkBalancesMutation, LinkBalancesMutationVariables>
) => {
  const syncRes = useLinkBalancesMutationBase({
    // refetchQueries: [{ query: AccountDocument }],
    ...baseOptions,
  });

  return syncRes;
};
