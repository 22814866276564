import { findTranslation } from "app/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Translation, TranslationType } from "sdk";

export const useProjectTranslation = (
  { translations }: { translations?: Translation[] },
  translationType: TranslationType
): string => {
  const { i18n } = useTranslation();

  return useMemo(() => {
    return findTranslation(translationType, translations);
  }, [translations, translationType, i18n.language]);
};
