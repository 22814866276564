import { refundedProjectsId } from "app/constants";
import { useMemo } from "react";
import { Ido, RefundType, timeIsOver } from "sdk";
import { useApplication } from "./use-application";
import { useInvestorInfo } from "./use-investor-info";
import { useRedeemedByChains } from "./use-redeemed-by-chains";
import { useRefundApplication } from "./use-refund-application";

export enum RefundStage {
  R1_NO_APP = 1,
  R2_HAS_APP_NOT_REDEEM,
  R3_HAS_APP_REDEEM,
  R4_HAS_APP_REDEEM_SPECIAL,
  R5_REFUNDED,
  R6_AVAILABLE_TO_RETURN_TOKENS,
  R7_PROJECT_TOKENS_RETURNED,
  R8_RETURN_TOKENS_END_TIME,
}

export const useRefundStage = (project?: Ido) => {
  const { application } = useApplication(project);
  const { refundApplication } = useRefundApplication(project);
  const { redeemedByChains, redeemedByCurrentChain } = useRedeemedByChains(project);
  const { investorInfo } = useInvestorInfo(project);

  return useMemo(() => {
    if (!project) return RefundStage.R1_NO_APP;
    if (!application && (!redeemedByChains || !redeemedByChains.length)) {
      return RefundStage.R1_NO_APP;
    }
    if (
      (project.refundType === RefundType.REFUNDED && application) ||
      (refundApplication && redeemedByCurrentChain && redeemedByCurrentChain.redeemed.isZero())
    ) {
      return RefundStage.R5_REFUNDED;
    }
    if (!investorInfo?.returnedTokensAmount?.isZero()) {
      return RefundStage.R7_PROJECT_TOKENS_RETURNED;
    }
    if (
      application &&
      !refundApplication &&
      !refundedProjectsId.includes(project.id) &&
      (!redeemedByChains || !redeemedByChains.length)
    ) {
      return RefundStage.R2_HAS_APP_NOT_REDEEM;
    }
    if (
      project.refundType === RefundType.RETURN_PROJECT_TOKENS &&
      timeIsOver(project.returnTokensEndTime)
    ) {
      return RefundStage.R8_RETURN_TOKENS_END_TIME;
    }
    if (
      application &&
      redeemedByChains &&
      redeemedByChains.length &&
      !refundedProjectsId.includes(project.id) &&
      project.refundType === RefundType.RETURN_PROJECT_TOKENS
    ) {
      return RefundStage.R6_AVAILABLE_TO_RETURN_TOKENS; // FIXME добавить ограничение по времени
    }
    if (
      application &&
      redeemedByChains &&
      redeemedByChains.length &&
      !refundedProjectsId.includes(project.id)
    ) {
      return RefundStage.R3_HAS_APP_REDEEM;
    }
    if (
      application &&
      redeemedByChains &&
      redeemedByChains.length &&
      refundedProjectsId.includes(project.id)
    ) {
      return RefundStage.R4_HAS_APP_REDEEM_SPECIAL;
    }
    return RefundStage.R1_NO_APP;
  }, [
    project,
    application,
    redeemedByChains,
    refundApplication,
    redeemedByCurrentChain,
    investorInfo,
  ]);
};
