import { getVestingContract } from "app/utils/contract-helper";
import { ChainId } from "sdk";
import { useChainId, useContractRead } from "wagmi";

export const useGetVestingTokenQuery = () => {
  const chainId = useChainId() as ChainId;
  const response = useContractRead({
    chainId,
    ...getVestingContract(chainId),
    functionName: "token",
  });
  return response;
};
