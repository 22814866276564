import { useWidth } from "app/hooks";
import { RefObject, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Ido, Ino } from "sdk";
import { selectScreenWidth } from "store/slice";
import "swiper/scss";
import { useActiveFilter } from "./use-active-filter";

export const PROJECT_ITEM_WIDTH_MOBILE: number = 290;
export const PROJECT_ITEM_WIDTH_TABLET: number = 315;
export const PROJECT_LIST_GAP_MOBILE: number = 14;
export const PROJECT_LIST_GAP_TABLET: number = 11;

export const useProjectsSwiper = (
  innerRef: RefObject<HTMLDivElement>,
  itemsRef: RefObject<HTMLDivElement>,
  projects?: Ido[] | Ino[]
) => {
  const mainActiveFilter = useActiveFilter();
  const screenWidth = useSelector(selectScreenWidth);
  const [initialMarginLeft, setInitialMarginLeft] = useState<number>(0);
  const [slidesPerView, setSlidesPerView] = useState<number>(1);
  const [isSwiper, setIsSwiper] = useState<boolean>(true);
  const { isMobileWidth, isTabletWidth } = useWidth();
  const spaceBetween = useMemo(
    () => (isMobileWidth ? PROJECT_LIST_GAP_MOBILE : PROJECT_LIST_GAP_TABLET),
    [isMobileWidth]
  );

  useEffect(() => {
    if (itemsRef?.current && isSwiper) {
      const { width } = itemsRef.current.getBoundingClientRect();
      let itemWidth = PROJECT_ITEM_WIDTH_TABLET;
      let gap = PROJECT_LIST_GAP_TABLET;
      if (isMobileWidth) {
        itemWidth = PROJECT_ITEM_WIDTH_MOBILE;
        gap = PROJECT_LIST_GAP_MOBILE;
      }
      const slidesPerView = width / (itemWidth + gap / 2);
      setSlidesPerView(+slidesPerView.toFixed(2));
    }
  }, [screenWidth, itemsRef, projects, isSwiper]);

  useEffect(() => {
    if (innerRef?.current && isTabletWidth) {
      const { left } = innerRef.current.getBoundingClientRect();
      setInitialMarginLeft(left);
    } else {
      setInitialMarginLeft(0);
    }
  }, [screenWidth, innerRef, projects, isSwiper]);

  useEffect(() => {
    if (
      (projects?.length && isMobileWidth && projects.length > 1) ||
      (projects?.length && isTabletWidth && projects.length > 2)
    ) {
      setIsSwiper(true);
    } else {
      setIsSwiper(false);
    }
  }, [projects, isMobileWidth, isTabletWidth]);

  return useMemo(
    () => ({ initialMarginLeft, slidesPerView, isSwiper, spaceBetween }),
    [
      innerRef,
      itemsRef,
      initialMarginLeft,
      slidesPerView,
      isSwiper,
      spaceBetween,
      screenWidth,
      mainActiveFilter,
      projects,
    ]
  );
};
