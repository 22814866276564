import { useTranslation } from "react-i18next";
import { Button, ButtonSize, KeyValueList } from "../index";

type TProps = {
  lockedTill: string;
  initialStake: string;
  earnings: string;
  onChangeClick: () => any;
  onWithdrawClick: () => any;
  isChangeDisabled?: boolean;
};

const StakingStakedScene: React.FC<TProps> = ({
  lockedTill,
  initialStake,
  earnings,
  onChangeClick,
  onWithdrawClick,
  isChangeDisabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-4 flex items-center gap-2">
        <div className="p2b text-text_primary">{t("Staking")}</div>
      </div>

      <KeyValueList
        className="mb-4"
        items={[
          {
            key: t("Locked Till"),
            value: `${lockedTill}`,
          },
          {
            key: t("Initial Stake"),
            value: `${initialStake} GGR`,
          },
          {
            key: t("Earnings"),
            value: `${earnings} GGR`,
          },
        ]}
      />

      <div className="flex gap-2">
        <Button
          className="w-1/2"
          text={t("Withdraw")!}
          size={ButtonSize.LG}
          onClick={onWithdrawClick}
        />
      </div>
    </>
  );
};

export default StakingStakedScene;
