import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GgrBalance } from "app/types/ggr-balance";
import { getIdosFilter } from "app/utils/get-idos-filter";
import { AuthTokens, ChainId, Ido, RawApplication, RefundApplication, Tier, User } from "sdk";
import tokenService from "../../services/token.service";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ROOT,
    prepareHeaders: (headers) => {
      if (tokenService.tokens) {
        headers.set("authorization", `Bearer ${tokenService.tokens.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    patchMe: builder.mutation<void, { twitterHandle: string; telegramHandle: string }>({
      query: (body) => ({
        url: `/users/me`,
        method: "PATCH",
        body,
      }),
    }),

    getMe: builder.query<User, void>({
      query: () =>
        `/users/me?filter=${JSON.stringify({
          include: ["applications"],
        })}`,
    }),

    application: builder.query<RawApplication, string>({
      query: (idoId) => `/idos/${idoId}/applications/my`,
    }),

    syncIdoRedeemed: builder.mutation<void, string>({
      query: (id) => ({
        url: `idos/${id}/syncRedeemedAmount`,
        method: "POST",
      }),
    }),

    refundApplication: builder.query<
      RefundApplication,
      { applicationId: string; chainId: ChainId }
    >({
      query: ({ applicationId, chainId }) =>
        `applications/${applicationId}/refund-applications?filter=${JSON.stringify({
          where: { or: [{ chainId }, { chainId: { exists: false } }] },
        })}`,
      transformResponse: ([refundApplication]: RefundApplication[]) => refundApplication,
    }),

    tiers: builder.query<Tier[], void>({
      query: () => `/tiers`,
    }),

    projectsParticipatedByMe: builder.query<Ido[], { address: string; activeFilter: any }>({
      query: ({ address, activeFilter }) => {
        const filter = getIdosFilter(activeFilter);

        return `/users/${address}/idos?filter=${JSON.stringify(filter)}`;
      },
    }),

    getGgrBalance: builder.query<GgrBalance, string>({
      query: (address) =>
        `/balances/${address}?filter=${JSON.stringify({
          include: [{ relation: "investments", scope: { order: "date DESC" } }],
        })}`,
    }),

    updatePercents: builder.mutation<void, string>({
      query: (address) => ({
        url: `/balances/${address}/syncPercents`,
        method: "POST",
      }),
    }),

    login: builder.mutation<AuthTokens, { signature: string; chainId: ChainId }>({
      query: (body) => ({
        url: "/users/login",
        method: "POST",
        body,
      }),
    }),

    refresh: builder.mutation<AuthTokens, AuthTokens>({
      query: (body) => ({
        url: "/refresh",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  usePatchMeMutation,
  useGetMeQuery,
  useApplicationQuery,
  useSyncIdoRedeemedMutation,
  useProjectsParticipatedByMeQuery,
  useGetGgrBalanceQuery,
  useUpdatePercentsMutation,
  useTiersQuery,
  useRefundApplicationQuery,
  useLoginMutation,
  useRefreshMutation,
} = userApi;
