import cn from "classnames";
import { FC, useMemo, useState } from "react";
import "./tooltip.sass";

type Props = {
  text?: string;
  position?: "left" | "top" | "right" | "bottom";
};

export const Tooltip: FC<Props> = ({ children, text, position = "top" }) => {
  const [hidden, setHidden] = useState<boolean>(true);

  const style = useMemo(() => {
    switch (position) {
      case "left":
        return {
          top: "50%",
          left: "-10px",
          transform: "translate(-100%, -50%)",
        };
      case "right":
        return {
          top: "50%",
          right: "-10px",
          transform: "translate(100%, -50%)",
        };
      case "bottom":
        return {
          top: "10px",
          left: "50%",
          transform: "translate(-50%, 100%)",
        };
      default:
        return {
          top: "-10px",
          left: "50%",
          transform: "translate(-50%, -100%)",
        };
    }
  }, [position]);

  return (
    <div
      className="tooltip"
      onMouseEnter={() => setHidden(false)}
      onMouseLeave={() => setHidden(true)}
    >
      <div
        className={cn("tooltip__container", { _hidden: hidden })}
        style={style}
      >
        <div className="tooltip__text">{text}</div>
      </div>
      {children}
    </div>
  );
};
