import { getTokensAmount } from "app/utils";
import { BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Ido, ROOT_ADDRESSES_MAP } from "sdk";
import { selectIsProjectTimerRunning } from "store/slice";
import { useAccount } from "wagmi";
import { useApplication } from "./use-application";
import { useAuth } from "./use-auth";
import { useBlockchainProject } from "./use-blockchain-project";
import { useChains } from "./use-chains";
import { useRawInvestorInfo } from "./use-raw-investor-info";

export const useInvestorInfo = (
  project?: Ido,
  address: string = ROOT_ADDRESSES_MAP[project?.network || 0]
) => {
  const { address: account } = useAccount();
  const isAuth = useAuth();
  const isProjectTimerRunning = useSelector(selectIsProjectTimerRunning);
  const {
    rawInvestorInfo,
    isInvestorInfoLoading,
    refetchRawInvestorInfo,
    isRawInvestorInfoFetching,
  } = useRawInvestorInfo(project, address);
  const { application, refetchApplication, isApplicationLoading } = useApplication(project);
  const { blockchainProject, isBlockchainProjectLoading, refetchBlockchainProject } =
    useBlockchainProject(project);
  const { isCorrectChain, chainId } = useChains(project);

  const refetchInvestorInfo = useCallback((): void => {
    refetchRawInvestorInfo();
    refetchBlockchainProject();
    refetchApplication();
  }, [refetchRawInvestorInfo, refetchBlockchainProject, refetchApplication]);

  return useMemo(() => {
    if (!project || !rawInvestorInfo || !blockchainProject || !application) {
      return {
        investorInfo: null,
        isInvestorInfoLoading:
          isInvestorInfoLoading || isBlockchainProjectLoading || isApplicationLoading,
        refetchInvestorInfo,
      };
    }

    const { amount } = application;
    const { redeemed, claimed: parsedClaimedCount, returnedTokensAmount } = rawInvestorInfo;
    const {
      props: { claimDates, claimPercent },
    } = blockchainProject;

    const claimedCount = parsedClaimedCount.toNumber();
    let isAvailableToClaim = false;
    let claimed = BigNumber.from(0);
    let redeemedTokens = BigNumber.from(0);

    if (
      claimedCount < claimDates.length &&
      claimDates[claimedCount].toNumber() * 1000 <= Date.now()
    ) {
      isAvailableToClaim = true;
    }

    const totalPercents = claimPercent.reduce((result, percent, i) => {
      if (parsedClaimedCount.gt(i)) {
        return result.add(percent);
      }
      return result;
    }, BigNumber.from(0));

    if (!redeemed.isZero()) {
      claimed = redeemed
        .mul(parseEther("1"))
        .div(100e5)
        .mul(totalPercents)
        .div(project.exchangeRate);
      redeemedTokens = getTokensAmount(project.exchangeRate, redeemed);
    }

    const nextClaimIndex = claimDates.findIndex((claimDate) => {
      return claimDate.toNumber() * 1000 > Date.now();
    });

    let claimIsOver = false;
    if (claimDates && claimDates.length) {
      claimIsOver = claimDates[claimDates.length - 1].toNumber() * 1000 < Date.now();
    }
    let availableToClaim = BigNumber.from(0);
    for (let i = claimedCount; i < claimDates.length; i++) {
      let claimDate = claimDates[i].toNumber() * 1000;
      if (Date.now() >= claimDate) {
        const availableAmount = redeemedTokens.div(100e5).mul(claimPercent[i]);
        availableToClaim = availableToClaim.add(availableAmount);
      } else {
        break;
      }
    }

    let lastClaimAmount = BigNumber.from(0);
    if (
      (claimIsOver && claimedCount === claimDates.length) ||
      (claimedCount === nextClaimIndex && nextClaimIndex > 0)
    ) {
      let claimIndex = nextClaimIndex === -1 ? claimDates.length - 1 : nextClaimIndex - 1;
      lastClaimAmount = redeemed
        .mul(parseEther("1"))
        .mul(claimPercent[claimIndex])
        .div(100e5)
        .div(project.exchangeRate);
    }
    console.log("investorInfo refetch");
    return {
      investorInfo: {
        amount,
        redeemed,
        claimed,
        redeemedTokens,
        isAvailableToClaim,
        nextClaimIndex,
        availableToClaim,
        returnedTokensAmount,
        account,
        lastClaimAmount,
      },
      isInvestorInfoLoading: isInvestorInfoLoading || isBlockchainProjectLoading,
      refetchInvestorInfo: refetchInvestorInfo,
      isInvestorInfoFetching: isRawInvestorInfoFetching,
    };
  }, [
    project,
    rawInvestorInfo,
    blockchainProject,
    account,
    isAuth,
    chainId,
    isCorrectChain,
    isProjectTimerRunning,
    refetchInvestorInfo,
    application,
  ]);
};
