import { formatBigNumber } from "app/utils";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ReferrerStatistics } from "sdk";
import { useReferrerStatisticsQuery } from "store/api";
import { selectActivePage, selectItemsPerPage } from "store/slice";

export const useReferrerStatistics = (referrerId: string = "") => {
  const itemsPerPage = useSelector(selectItemsPerPage);
  const activePage = useSelector(selectActivePage);
  const [foundError, setFoundError] = useState<boolean>(false);
  const { data, isLoading, error } = useReferrerStatisticsQuery(
    {
      referrerId,
      limit: itemsPerPage,
      skip: activePage * itemsPerPage,
    },
    { skip: foundError || !referrerId }
  );

  useEffect(() => {
    if (foundError) return;

    // TODO сбрасывать, при переключении страницы
    if (error) setFoundError(!!error);
  }, [error]);

  return useMemo(() => {
    let redeemed = "0";
    let refunded = "0";
    let award = "0";
    let referrals: ReferrerStatistics["referrals"] = [];

    if (!data) {
      return { redeemed, refunded, referrals, award, isReferrerStatisticsLoading: isLoading };
    }

    redeemed = formatBigNumber(data.redeemed);
    refunded = formatBigNumber(data.refunded);
    award = formatBigNumber(data.award);
    referrals = data.referrals.map((referral) => ({
      ...referral,
      redeemed: formatBigNumber(referral.redeemed),
      refunded: formatBigNumber(referral.refunded),
      award: formatBigNumber(referral.award),
    }));

    return {
      redeemed,
      refunded,
      referrals,
      award,
      isReferrerStatisticsLoading: isLoading,
    };
  }, [data, isLoading, referrerId]);
};
