import { ProjectStage } from "app/enums";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectIsProjectTimerRunning, selectProjectStage } from "store/slice";
import { useApplication } from "./use-application";
import { ApplicationStatus, useApplicationStatus } from "./use-application-status";

export enum SwapStage {
  S1_SWAP_GAP_HAS_NO_APP = 1,
  S2_SWAP_GAP_HAS_APP,
  S3_SWAP_GAP_WITH_RESULTS_HAS_NO_APP,
  S4_SWAP_GAP_WITH_RESULTS_WIN,
  S5_SWAP_GAP_WITH_RESULTS_LOSE,
  S6_SWAP_HAS_NO_APP,
  S7_SWAP_LOSE,
  S8_SWAP_WIN_NOT_REDEEM,
  S9_SWAP_WIN_REDEEM,
}

export const useSwapStage = (project: Ido): SwapStage => {
  const projectStage = useSelector(selectProjectStage);
  const isProjectTimerRunning = useSelector(selectIsProjectTimerRunning);

  const { application } = useApplication(project);
  const applicationStatus = useApplicationStatus(project);

  const isRedeemed = useMemo(() => {
    if (!application) return false;

    return application.amount.lte(application.swappedAmount);
  }, [projectStage, application]);

  return useMemo(() => {
    if (
      projectStage === ProjectStage.swapGap &&
      (applicationStatus === ApplicationStatus.notCreated ||
        applicationStatus === ApplicationStatus.rejected)
    ) {
      return SwapStage.S1_SWAP_GAP_HAS_NO_APP;
    }

    if (
      projectStage === ProjectStage.swapGap &&
      (applicationStatus === ApplicationStatus.approved ||
        applicationStatus === ApplicationStatus.interview)
    ) {
      return SwapStage.S2_SWAP_GAP_HAS_APP;
    }

    if (
      projectStage === ProjectStage.swapGapWithResults &&
      applicationStatus === ApplicationStatus.notCreated
    ) {
      return SwapStage.S3_SWAP_GAP_WITH_RESULTS_HAS_NO_APP;
    }

    if (
      projectStage === ProjectStage.swapGapWithResults &&
      applicationStatus === ApplicationStatus.win
    ) {
      return SwapStage.S4_SWAP_GAP_WITH_RESULTS_WIN;
    }

    if (
      projectStage === ProjectStage.swapGapWithResults &&
      applicationStatus === ApplicationStatus.lose
    ) {
      return SwapStage.S5_SWAP_GAP_WITH_RESULTS_LOSE;
    }

    if (projectStage === ProjectStage.swap && applicationStatus === ApplicationStatus.notCreated) {
      return SwapStage.S6_SWAP_HAS_NO_APP;
    }

    if (projectStage === ProjectStage.swap && applicationStatus === ApplicationStatus.lose) {
      return SwapStage.S7_SWAP_LOSE;
    }

    if (
      projectStage === ProjectStage.swap &&
      applicationStatus === ApplicationStatus.win &&
      !isRedeemed
    ) {
      return SwapStage.S8_SWAP_WIN_NOT_REDEEM;
    }

    if (
      projectStage === ProjectStage.swap &&
      applicationStatus === ApplicationStatus.win &&
      isRedeemed
    ) {
      return SwapStage.S9_SWAP_WIN_REDEEM;
    }

    return SwapStage.S1_SWAP_GAP_HAS_NO_APP;
  }, [projectStage, applicationStatus, isRedeemed, isProjectTimerRunning]);
};
