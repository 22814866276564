import { useMemo } from "react";

export const useIdoTitlePostfix = (projectId?: string) => {
  return useMemo(() => {
    if (!projectId) return;
    const style = { textTransform: "none", opacity: 0.3, fontSize: 18 } as const;
    let postfix = "";
    switch (projectId) {
      case "6464c4bc2d3b5cea84d59498": {
        postfix = "Community Sale";
        break;
      }
      case "64b6904716faee0966a94ab0": {
        postfix = "Private Sale";
        break;
      }
      case "64f6efa57ae61eefb3544389": {
        postfix = "Giveaway";
        break;
      }
      case "657178c119d6833dbcc21743": {
        postfix = "Private Sale - 2";
        break;
      }
      case "65819af194c10dab7cdb44fe": {
        postfix = "KOLs";
        break;
      }
      case "658a9b6394cbe3e897e4d947": {
        postfix = "Giveaway";
        break;
      }
      case "65b230a405f54d4d84759e00": {
        postfix = "Giveaway, AMA";
        break;
      }
      case "6601809a1ab31c6cc3b1e8d5": {
        postfix = "Private Sale";
        break;
      }
      case "66017e511ab31c6cc3b1e8c5": {
        postfix = "Private Sale";
        break;
      }
      case "661f80bb510d8eed2aa5aaa9": {
        postfix = "Private Sale";
        break;
      }
      case "662b76c1065d39e5eb49bac7": {
        postfix = "Private Sale";
        break;
      }
      case "663205bdd4bac93e4c3637d9": {
        postfix = "Private Sale";
        break;
      }
      case "66431d45f86ae54b8b14d5d1": {
        postfix = "Private Sale";
        break;
      }
      case "6666c7689218cf844c48a293": {
        postfix = "Private Sale";
        break;
      }
      case "667011997a018300437871f8": {
        postfix = "Private Sale";
        break;
      }
      case "668f77f45fb396192571600d": {
        postfix = "Venture Deal";
        break;
      }
      case "66960eab3ecf29d9ad7fb707": {
        postfix = "Venture Deal";
        break;
      }
      case "66d04ac298cccaecef46bec2": {
        postfix = "Giveaway";
        break;
      }
    }
    if (postfix) {
      return { postfix, style };
    }
  }, [projectId]);
};
