import RocketLoader from "app/components/loader/rocket-loader";
import { useProjectImage } from "app/hooks";
import { Params } from "app/pages";
import cn from "classnames";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ImageType } from "sdk";
import { useIdoByIdQuery, useReferrerByIdoQuery } from "store/api";
import { useAccount } from "wagmi";
import IdoTimeline from "../ido/ido-timeline";
import ReferralsList from "./referrals.list";
import ReferrerInfo from "./referrer-info";
import "./referrer.sass";

const Referrer: FC = () => {
  const { idoId } = useParams<Params>();
  const { address } = useAccount()

  const { data: ido, isLoading: isIdoLoading } = useIdoByIdQuery(idoId);
  const { data: referrer, isLoading: isReferrerLoading } = useReferrerByIdoQuery({
    idoId,
    address: address || '',
  }, { skip: !address });
  const logo = useProjectImage(ImageType.LOGO, ido?.id);

  return (
    <div className="referrer">
      <RocketLoader className="referrer__loader" isLoading={isIdoLoading || isReferrerLoading}>
        {ido && (
          <>
            <div className="referrer__project-info">
              <div className={cn("project__title", "referrer__project-title")}>
                {(logo || ido.idoLogoUrl) && (
                  <div
                    className="project__logo"
                    style={{
                      backgroundImage: `url(${logo || ido.idoLogoUrl} )`,
                      backgroundSize: "100%",
                    }}
                  />
                )}
                {ido.title}
              </div>
              <IdoTimeline className="referrer__project-timeline" ido={ido} />
            </div>
          </>
        )}
        {referrer && <ReferrerInfo referrer={referrer} />}
        <ReferralsList referrer={referrer} />
      </RocketLoader>
    </div>
  );
};

export default Referrer;
