import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Ido, TranslationType } from "sdk";

export const useProjectVestingSchedule = (project: Ido): string => {
  const { i18n } = useTranslation();

  return useMemo(() => {
    if (!project.translations || !project.translations.length) {
      return project.vestingSchendule;
    }

    const projectVestingSchedule = project.translations.find(
      (translation) =>
        translation.locale === i18n.language &&
        translation.type === TranslationType.VESTING_SCHEDULE
    );

    return projectVestingSchedule?.value || project.vestingSchendule;
  }, [project, i18n.language]);
};
