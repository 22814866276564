import { useTonConnectUI } from "@tonconnect/ui-react";
import cn from "classnames";
import { FC } from "react";
import TonAccountBlockConnected from "./ton-account-block__connected";
import TonAccountBlockNotConnected from "./ton-account-block__not-connected";

type Props = {
  className?: string;
};
const TonAccountBlock: FC<Props> = ({ className }) => {
  const [tonConnectUI] = useTonConnectUI();
  return (
    <div className={cn(className)}>
      {tonConnectUI.connected ? <TonAccountBlockConnected /> : <TonAccountBlockNotConnected />}
    </div>
  );
};

export default TonAccountBlock;
