import { ProjectStage } from "app/enums";
import { getRefundPolicyEndDate } from "app/utils";
import { TierTypes } from "graphql/account-query";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTimer } from "react-timer-hook";
import { Ido, RefundType } from "sdk";
import { selectProject } from "store/slice";
import { useActions } from "./use-actions";
import { useApplication } from "./use-application";
import { useAuth } from "./use-auth";
import { useChains } from "./use-chains";
import { useInvestorInfo } from "./use-investor-info";

export const useRefundPolicy = (project?: Ido, tier?: TierTypes) => {
  const isAuth = useAuth();
  const { setIsRefundPolicyAvailable } = useActions();
  const { projectStage, isProjectTimerRunning } = useSelector(selectProject);
  const { application } = useApplication(project);
  const { investorInfo } = useInvestorInfo(project);
  const { isCorrectChain } = useChains(project);

  useEffect(() => {
    if (!project) return;

    const refundEndDate = getRefundPolicyEndDate(project, tier);
    if (
      isAuth &&
      projectStage === ProjectStage.claim &&
      refundEndDate > Date.now() &&
      application &&
      isCorrectChain &&
      investorInfo &&
      !investorInfo.redeemed.isZero() &&
      (project.refundType === RefundType.REFUND_POLICY_WITHOUT_CLAIM ||
        project.refundType === RefundType.REFUND_POLICY_ADVANCED) &&
      investorInfo.claimed.isZero()
    ) {
      setIsRefundPolicyAvailable(true);
    } else {
      setIsRefundPolicyAvailable(false);
    }
  }, [
    project,
    isAuth,
    application,
    projectStage,
    investorInfo,
    isProjectTimerRunning,
    isCorrectChain,
    tier,
  ]);
};

export const useRefundPolicyTimer = (project?: Ido, tier?: TierTypes) => {
  const { setIsRefundPolicyAvailable, setProjectStage } = useActions();
  const { projectStage } = useSelector(selectProject);

  const { seconds, minutes, hours, restart, days } = useTimer({
    expiryTimestamp: new Date(0),
    onExpire: () => {
      pingTimer();
    },
  });

  useEffect(() => {
    if (tier) {
      pingTimer();
    }
  }, [tier]);

  const pingTimer = () => {
    if (!project) return;

    const refundEndDate = getRefundPolicyEndDate(project, tier);
    if (refundEndDate > Date.now()) {
      restart(new Date(refundEndDate));
    } else {
      setIsRefundPolicyAvailable(false);
      if (projectStage === ProjectStage.refundPolicy) {
        setProjectStage(ProjectStage.claim);
      }
    }
  };

  useEffect(() => {
    pingTimer();
  }, [project]);

  return useMemo(() => ({ seconds, minutes, hours, days }), [seconds, minutes, hours, days]);
};

export const useReturnTokensTimer = (project?: Ido) => {
  const { seconds, minutes, hours, restart, days } = useTimer({
    expiryTimestamp: new Date(0),
    onExpire: () => {
      pingTimer();
    },
  });

  const pingTimer = () => {
    if (!project) return;

    const refundEndDate = project.returnTokensEndTime || 0;
    if (refundEndDate > Date.now()) {
      restart(new Date(refundEndDate));
    }
  };

  useEffect(() => {
    pingTimer();
  }, [project]);

  return useMemo(() => {
    if (!project || project.refundType !== RefundType.RETURN_PROJECT_TOKENS) {
      return;
    }
    return { seconds, minutes, hours, days };
  }, [seconds, minutes, hours, days]);
};
