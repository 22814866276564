import {
  ACTIVITIES_ICON_MAP,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_SHORT,
  PAYMENTS_OVER,
  TIMELINE_DATE_FORMAT,
  TIMELINE_DATE_FORMAT_SHORT,
} from "app/constants";
import i18next from "app/localisation/config";
import { BigNumber, BigNumberish } from "ethers";
import { formatUnits, parseEther } from "ethers/lib/utils";
import { TierTypes } from "graphql/account-query";
import _ from "lodash";
import moment from "moment";
import "moment/locale/ru";
import {
  Currency,
  FOUR_HOURS,
  Ido,
  MarketingActivityType,
  RefundType,
  SIX_HOURS,
  THREE_HOURS,
  Translation,
  TranslationType,
  getNumberWithSpaces,
  isFullDate,
  isIdo,
  parseDecimals,
  sleep,
} from "sdk";
import { TWO_HOURS } from "services/token.service";
import { ActivityStatus } from "./enums";
import { Project } from "./types";

export const formatBigNumber = (
  number: BigNumberish | undefined,
  length: number = 3,
  decimals: number = 18
): string => {
  if (!number) return "0";

  let formatted = formatUnits(number, decimals);
  const index = formatted.indexOf(".");
  if (length && formatted.length - index - 1 > length) {
    formatted = formatted.slice(0, index + length);
  }
  if (formatted.endsWith(".0")) {
    formatted = formatted.slice(0, -2);
  }

  return formatZero(formatted);
};

export const getValueOrTBA = (value: any) => {
  if (_.isEmpty(value)) {
    return "TBA";
  }
  return value;
};

export const formatZero = (number: string | number): string => {
  const splitedNumber = number.toString().split(".");
  if (splitedNumber[1]) {
    splitedNumber[1] = splitedNumber[1].replace(/0*$/, "");
  }
  if (splitedNumber[1]) {
    return getNumberWithSpaces(splitedNumber.join("."));
  }
  return getNumberWithSpaces(splitedNumber[0]);
};

/**
 * @returns {string} Date string "DD MMMM, YYYY HH:mm" format
 */
export const formatDate = (timestamp: number): string => {
  const date = moment(timestamp).locale(i18next.language);
  if (isFullDate(timestamp)) {
    return date.format(DEFAULT_DATE_FORMAT);
  }
  return date.utc().format(DEFAULT_DATE_FORMAT_SHORT);
};

/**
 * @returns {string} Date string ""DD.MM.YYYY, HH:mm"" format
 * @returns {string} TBA
 */
export const formatTimelineDate = (timestamp: number | 0): string => {
  if (!timestamp) return "TBA";
  const date = moment(timestamp).locale(i18next.language);
  if (isFullDate(timestamp)) {
    return date.format(TIMELINE_DATE_FORMAT);
  }
  return date.utc().format(TIMELINE_DATE_FORMAT_SHORT);
};

/**
 * @returns {string} Date string "DD MMMM, YYYY HH:mm" format
 * @returns {string} TBA
 * @returns {string} Payments are over
 */
export const getNextClaimByProject = (
  project?: Ido,
  index: number = 0
): number | "TBA" | typeof PAYMENTS_OVER => {
  if (!project || !project.payments?.length) return "TBA";
  if (index > project.payments.length - 1) return PAYMENTS_OVER;

  if (Date.now() >= project.payments[index].date) {
    return getNextClaimByProject(project, index + 1);
  } else {
    return project.payments[index].date;
  }
};

export const checkIsNumeric = (value: string, maxLength: number = 3): boolean => {
  try {
    const splittedValue = value.split(".");
    if (splittedValue[1] && maxLength && splittedValue[1].length > maxLength) {
      throw new Error();
    }
    // empty string
    if (!value) {
      return true;
    }
    parseEther(value);
    return true;
  } catch (error) {
    console.log("invalid input");
    return false;
  }
};

export const trim = (string?: string): string => {
  return (string || "").replace(/\s/g, "");
};

export const getTokensAmount = (
  exchangeRate: BigNumberish,
  amount: BigNumber,
  decimals: BigNumberish = 18
): BigNumber => {
  const etherAmount = parseDecimals(amount, decimals);

  return etherAmount.mul(parseEther("1")).div(exchangeRate);
};

export const getStableAmount = (
  exchangeRate: BigNumberish,
  tokensAmount: BigNumber,
  decimals: BigNumberish = 18
): BigNumber => {
  const etherTokensAmount = parseDecimals(tokensAmount, decimals);

  return etherTokensAmount.mul(exchangeRate).div(parseEther("1"));
};

export const checkIsGtLimit = (value: string, limit: string): boolean => {
  const parsedInputValue = parseEther(value || "0");
  const parsedLimit = parseEther(limit || "0");

  return parsedInputValue.gt(parsedLimit);
};

export const getActivityIcon = (
  activityStatus: ActivityStatus,
  activityType: MarketingActivityType
) => {
  if (activityStatus === ActivityStatus.NOW) {
    return ACTIVITIES_ICON_MAP[activityType.toLowerCase()].active;
  }
  return ACTIVITIES_ICON_MAP[activityType.toLowerCase()].inactive;
};

export const getQueryParam = (name: string) => {
  return new URLSearchParams(window.location.search).get(name);
};

export const getRefundPolicyEndDate = (project: Ido, tier?: TierTypes) => {
  const commonRefundPolicyTime = project.refundPolicyTime ?? TWO_HOURS;
  let refundPolicyTimeByTier: number;
  switch (tier) {
    case "NONE": {
      refundPolicyTimeByTier = project.refundPolicyTimeForTier0 ?? TWO_HOURS;
      break;
    }
    case "EARTH": {
      refundPolicyTimeByTier = project.refundPolicyTimeForTier1 ?? TWO_HOURS;
      break;
    }
    case "ORBIT": {
      refundPolicyTimeByTier = project.refundPolicyTimeForTier2 ?? THREE_HOURS;
      break;
    }
    case "GALAXY": {
      refundPolicyTimeByTier = project.refundPolicyTimeForTier3 ?? FOUR_HOURS;
      break;
    }
    case "UNIVERSE": {
      refundPolicyTimeByTier = project.refundPolicyTimeForTier4 ?? SIX_HOURS;
      break;
    }
    default: {
      refundPolicyTimeByTier = commonRefundPolicyTime;
      break;
    }
  }
  const refundPolicyTime =
    refundPolicyTimeByTier > commonRefundPolicyTime
      ? refundPolicyTimeByTier
      : commonRefundPolicyTime;

  return project.payments?.[0].date + refundPolicyTime;
};

export const isRefund = (refundType: RefundType) => {
  return (
    refundType === RefundType.RETURN_PROJECT_TOKENS ||
    refundType === RefundType.WITHOUT_PROJECT_TOKENS ||
    refundType === RefundType.REFUNDED
  );
};

export const formatTimerNumber = (number: number): string => {
  if (number < 0) return "00";

  let string = number.toString();
  if (string.length < 2) string = "0" + string;

  return string;
};

export const findTranslation = (translationType: TranslationType, translations?: Translation[]) => {
  if (!translations || !translations.length) return "";

  const foundTranslation = translations.find(
    ({ locale, type }) => locale === i18next.language && type === translationType
  );
  if (!foundTranslation) return "";

  return foundTranslation.value;
};

export const getExchangeCurrencies = (project?: Project) => {
  let currencies: Currency[] | undefined = [];
  if (!project) return currencies;

  if (isIdo(project)) {
    currencies = project.exchangeCurrency;
  } else {
    currencies = project.exchangeCurrencies;
  }

  return currencies || [];
};

export const getClaimCurrencies = (project?: Project) => {
  let currencies: Currency[] | undefined = [];
  if (!project) return currencies;

  if (isIdo(project)) {
    currencies = project.claimCurrency;
  }

  return currencies || [];
};

export const mockRequest = async (timeout: number) => {
  await sleep(timeout);
};
