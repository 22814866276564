import { MutationHookOptions } from "@apollo/client";
import {
  SyncAccountMutation,
  SyncAccountMutationVariables,
  useSyncAccountMutation as useSyncAccountMutationBase,
} from "graphql/@generated/graphql";

export const useSyncAccountMutation = (
  baseOptions?: MutationHookOptions<SyncAccountMutation, SyncAccountMutationVariables>
) => {
  const syncRes = useSyncAccountMutationBase({
    // refetchQueries: [{ query: AccountDocument }],
    ...baseOptions,
  });

  return syncRes;
};
