import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MainProjectsFilter } from "app/types/projects-filters";
import { getCscAndFilter, getIdosAdFilter, getIdosFilter } from "app/utils/get-idos-filter";
import { ChainId, Ido, RawApplication, RedeemedByChain, RefundApplication } from "sdk";
import tokenService from "../../services/token.service";

export const idoApi = createApi({
  reducerPath: "idoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ROOT,
    prepareHeaders: (headers) => {
      if (tokenService.tokens) {
        headers.set("authorization", `Bearer ${tokenService.tokens.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    idos: builder.query<Ido[], { activeFilter: MainProjectsFilter; address?: string }>({
      query: ({ activeFilter, address }) => {
        const filter = getIdosFilter(activeFilter);

        if (address && activeFilter === "My") {
          return `/users/${address}/idos?filter=${JSON.stringify(filter)}`;
        }

        return `/idos?filter=${JSON.stringify(filter)}`;
      },
    }),

    idosAd: builder.query<Ido[], string | undefined>({
      query: (title) => {
        const filter = getIdosAdFilter(title);

        return `/idos?filter=${JSON.stringify(filter)}`;
      },
    }),

    coinexProjects: builder.query<Ido[], MainProjectsFilter>({
      query: (activeFilter) => {
        const projectsFilter = getIdosFilter(activeFilter);
        const cscAndFilter = getCscAndFilter();
        // есть проверка на существование 'and'
        // @ts-ignore
        const and = [...(projectsFilter.where.and || []), ...cscAndFilter];
        const filter = {
          ...projectsFilter,
          where: {
            ...projectsFilter.where,
            and,
          },
        };

        return `/idos?filter=${JSON.stringify(filter)}`;
      },
    }),

    idoById: builder.query<Ido, string>({
      query: (id) =>
        `/idos/${id}?filter=${JSON.stringify({
          include: [
            { relation: "exchangeCurrency" },
            { relation: "claimCurrency" },
            { relation: "payments" },
            { relation: "links" },
            { relation: "translations" },
            { relation: "modalFields" },
            {
              relation: "activities",
              scope: { include: [{ relation: "translations" }] },
            },
          ],
        })}`,
    }),

    createApplication: builder.mutation<
      RawApplication,
      {
        projectId: string;
        [key: string]: string;
      }
    >({
      query: ({ projectId, ...body }) => ({
        url: `/idos/${projectId}/applications/my`,
        method: "POST",
        body,
      }),
    }),

    // getRefundMetaTx: builder.mutation<any, { project: Ido; chainId: ChainId }>({
    //   query: ({ project, chainId }) => ({
    //     url: `/idos/${project.id}/refundMetaTx?chainId=${chainId}`,
    //     method: "POST",
    //   }),
    // }),

    // getReturnProjectTokensMetaTx: builder.mutation<any, { project: Ido; chainId: ChainId }>({
    //   query: ({ project, chainId }) => ({
    //     url: `/idos/${project.id}/returnProjectTokensMetaTx?chainId=${chainId}`,
    //     method: "POST",
    //   }),
    // }),

    createRefundApplication: builder.mutation<
      RefundApplication,
      Omit<Partial<RefundApplication>, "chainId"> & { chainId: ChainId }
    >({
      query: ({ applicationId, ...body }) => ({
        url: `/applications/${applicationId}/refund-applications`,
        method: "POST",
        body,
      }),
    }),

    redeemedByChains: builder.query<RedeemedByChain[], { projectId: string; account: string }>({
      query: ({ projectId, account }) => `/idos/${projectId}/${account}/redeemedByChains`,
    }),

    // idoSwapMetaTx: builder.query<
    //   /* VoucherV2.RedeemIdoVoucherStruct */ any,
    //   {
    //     id: string;
    //     amount: string;
    //     chainId: ChainId;
    //   }
    // >({
    //   query: ({ id, amount, chainId }) =>
    //     `/idos/${id}/redeemMetaTx?amount=${amount}&chainId=${chainId}`,
    // }),

    // idoFcfsMetaTx: builder.query<
    //   /* VoucherV2.RedeemIdoVoucherStruct */ any,
    //   {
    //     id: string;
    //     amount: string;
    //     chainId: ChainId;
    //   }
    // >({
    //   query: ({ id, amount, chainId }) =>
    //     `/idos/${id}/fcfsMetaTx?amount=${amount}&chainId=${chainId}`,
    // }),

    syncRefundApplication: builder.mutation<void, { chainId: ChainId; projectId: string }>({
      query: ({ projectId, ...body }) => ({
        url: `/idos/${projectId}/refund-applications/sync`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useIdosQuery,
  useIdoByIdQuery,
  useCreateApplicationMutation,
  useCreateRefundApplicationMutation,
  useCoinexProjectsQuery,
  useRedeemedByChainsQuery,
  useSyncRefundApplicationMutation,
  useIdosAdQuery,
  // useGetRefundMetaTxMutation,
  // useGetReturnProjectTokensMetaTxMutation,
  // useIdoSwapMetaTxQuery,
  // useIdoFcfsMetaTxQuery,
} = idoApi;
