import { JettonMinter } from "./jetton-minter.contract";
import { JettonWallet } from "./jetton-wallet.contract";
import { LockPool } from "./lock-pool.contract";

import { TonClient } from "ton";

const tonClient = new TonClient({
  endpoint: process.env.REACT_APP_PUBLIC_TON_CLIENT_ENDPOINT!,
  apiKey: process.env.REACT_APP_PUBLIC_TON_CLIENT_API_KEY!,
});

export { JettonMinter, JettonWallet, LockPool, tonClient };
