import { useBalanceQuery, useGetDataClaimQuery, useGetVestingTokenQuery, useVestingReleaseMutation } from "app/hooks";
import { VestingData } from "app/hooks/vesting-contract/query/use-get-claim-data";
import cn from "classnames";
import { TierTypes } from "graphql/account-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HexString } from "sdk/src/types/hex-string";
import AgreeModal from "../agree-modal/agree-modal";
import ClaimModal from "../claim-modal/claim-modal";
import ClaimModalFinal from "../claim-modal/claim-modal-final";
import KVList from "../kv-list/kv-list";
import Modal from "../modal/modal";
import PrivateClaimModal from "../private-claim-modal/private-claim-modal";
import iMorInfo from "./../../image/more-info.svg";
import oUpload from "./../../image/upload.png";
import "./claim-progress.sass";

interface IProps {
  className?: string;
  claimData?: VestingData[];
  address: string;
  tier?: TierTypes;
  syncAccountData: () => Promise<void>;
}
export enum VestingType {
  "Claim progress",
  "Private round",
  "Seed round",
  "Public round",
}

const ClaimProgress: React.FC<IProps> = ({
  className,
  claimData,
  address,
  tier,
  syncAccountData,
}) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { refetch, isLoading: isClaimDataLoading } = useGetDataClaimQuery();
  const [claim, { isLoading, isSuccess }] = useVestingReleaseMutation();
  const [claimProgressStage, setClaimProgressStage] = useState(0);
  const { data: token } = useGetVestingTokenQuery();
  const { refetch: refetchGgrBalance } = useBalanceQuery(
    { token: token as HexString }
  );
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  async function doClaim() {
    await claim();
    await syncAccountData();
    refetch();
    refetchGgrBalance();
    setClaimProgressStage(2);
    setModalOpen(true);
  }

  const subData = claimData ? claimData.filter((el) => el.vid !== 0) : undefined;
  const claimProgress = claimData ? claimData.find((el) => el.vid === 0) : undefined;

  const data = {
    claimInfo: [
      {
        title: t("claim.nextClaim"),
        value:
          claimProgress && claimProgress.nextClaim !== 0
            ? `${new Date(claimProgress.nextClaim).getDate() < 10
              ? "0" + new Date(claimProgress.nextClaim).getDate()
              : new Date(claimProgress.nextClaim).getDate()
            } ${month[new Date(claimProgress.nextClaim).getMonth()]}, ${new Date(
              claimProgress.nextClaim
            ).getFullYear()} ${new Date(claimProgress.nextClaim).getHours()}:${new Date(claimProgress.nextClaim).getMinutes() < 10
              ? "0" + new Date(claimProgress.nextClaim).getMinutes()
              : new Date(claimProgress.nextClaim).getMinutes()
            }`
            : "",
      },
      {
        title: t("claim.totalAmount"),
        value: claimProgress ? claimProgress.totalAmount + " GGR" : t("claim.informationUpdate"),
      },
      {
        title: t("claim.alreadyReceived"),
        value: claimProgress
          ? claimProgress.alreadyReceived + " GGR"
          : t("claim.informationUpdate"),
      },
    ],
    claim: [
      {
        title: t("claim.availableForClaim"),
        value: (
          <div className="claim-info ">
            {claimData && claimProgress && claimProgress.availableToClaim !== "0" ? (
              isLoading || isClaimDataLoading ? (
                <svg className="spinner" viewBox="0 0 50 50">
                  <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  ></circle>
                </svg>
              ) : (
                <div
                  onClick={async () => {
                    setModalOpen(true);
                  }}
                  className="claim-progress__button"
                >
                  {" "}
                  <img className="claim-info__img  " alt="upload" src={oUpload} />
                </div>
              )
            ) : (
              ""
            )}{" "}
            <div>
              {claimProgress
                ? claimProgress.availableToClaim + " GGR"
                : t("claim.informationUpdate")}
            </div>
          </div>
        ),
        disabled: true,
      },
    ],
    success: {
      title: "Success",
      info: `Done! Claiming GGR was successful`,
      success: true,
    },
    error: {
      title: "Error",
      info: `Something went wrong`,
      success: false,
    },
    claimMoreInfo: [
      {},
      {
        title: t("claim.privateRound.title"),
        text: t("claim.privateRound.text"),
      },
      {
        title: t("claim.Marketing2.title"),
        text: t("claim.Marketing2.text"),
      },
      {
        title: t("claim.Marketing3.title"),
        text: t("claim.Marketing3.text"),
      },
    ],
  };

  if (claimProgress?.totalAmount === "0") {
    return (
      <div className="claim-progress__no-data">
        {" "}
        <div className="claim-progress__title">{t("claim.title")}</div>
        <div className="claim-progress__no-data-text">{t("claim.noBalance")}</div>
      </div>
    );
  }
  return (
    <>
      {" "}
      {isClaimDataLoading || isLoading ? (
        <div className="stacking-view__loader">
          {" "}
          <svg className="spinner" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
          </svg>
        </div>
      ) : (
        ""
      )}
      <div
        className={cn("claim-progress", className, isClaimDataLoading || isLoading ? " _blur" : "")}
      >
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => {
            setModalOpen(false);
            setClaimProgressStage(0);
          }}
        >
          {claimProgressStage === 0 && (
            <>
              {subData &&
                subData.find(
                  (el) => el.vid === 1 && !!!+el.alreadyReceived && !!+el.availableToClaim
                ) ? (
                <PrivateClaimModal
                  click={() => setClaimProgressStage(1)}
                  tier={tier}
                  claimSum={claimProgress?.availableToClaim}
                />
              ) : (
                <ClaimModal
                  click={() => setClaimProgressStage(1)}
                  claimSum={claimProgress?.availableToClaim}
                />
              )}
            </>
          )}

          {claimProgressStage === 1 &&
            (isLoading ? (
              <div className="claim-loading">
                <svg className="spinner" viewBox="0 0 50 50">
                  <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  ></circle>
                </svg>
              </div>
            ) : (
              <AgreeModal
                onAgree={() => {
                  setModalOpen(false);
                  doClaim();
                }}
                onCancel={() => {
                  setModalOpen(false);
                  setClaimProgressStage(0);
                }}
              />
            ))}
          {claimProgressStage === 2 && (
            <ClaimModalFinal info={isSuccess ? data.success : data.error} />
          )}
        </Modal>
        <div className="claim-progress__title">{t("claim.title")}</div>
        <KVList items={data.claimInfo} />
        {subData && (
          <div className={"claim-progress__items " + (isOpen ? " _open" : "")}>
            {subData.map((el, i) => {
              return (
                <div className="claim-progress__item" key={"claim-progress__item-" + i}>
                  <div className="claim-progress__item-title">
                    {data.claimMoreInfo[el.vid].title}
                  </div>
                  <KVList
                    items={[
                      {
                        title: t("claim.nextClaim"),
                        value:
                          el && el.nextClaim !== 0
                            ? `${new Date(el.nextClaim).getDate() < 10
                              ? "0" + new Date(el.nextClaim).getDate()
                              : new Date(el.nextClaim).getDate()
                            } ${month[new Date(el.nextClaim).getMonth()]}, ${new Date(
                              el.nextClaim
                            ).getFullYear()} ${new Date(el.nextClaim).getHours()}:${new Date(el.nextClaim).getMinutes() < 10
                              ? "0" + new Date(el.nextClaim).getMinutes()
                              : new Date(el.nextClaim).getMinutes()
                            }`
                            : "",
                      },
                      { title: t("claim.totalAmount"), value: el.totalAmount + " GGR" },
                      { title: t("claim.alreadyReceived"), value: el.alreadyReceived + " GGR" },
                      { title: data.claimMoreInfo[el.vid].text, value: "" },
                    ]}
                  />
                </div>
              );
            })}
          </div>
        )}
        <div className={"claim-progress__claim " + (isOpen ? " _border" : "")}>
          <KVList items={data.claim} />
        </div>
        {subData && subData?.length > 0 ? (
          <div className="claim-progress__more-info" onClick={() => setOpen(!isOpen)}>
            {t("claim.moreInfo")}
            <img
              className={"claim-progress__more-info-img" + (isOpen ? " _transform" : "")}
              src={iMorInfo}
              alt="iMorInfo"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ClaimProgress;
