import { Translation } from "./translation";

export enum MarketingActivityType {
  AMA = "AMA",
  GIVEAWAY = "Giveaway",
  ANOTHER = "Another",
  ASK_AND_WIN = "Ask & Win",
}

export type MarketingActivity = {
  id: string;
  type: MarketingActivityType;
  banner?: string;
  title: string;
  text: string;
  startDate: number;
  endDate: number;
  translations?: Translation[];
};
