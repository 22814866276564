import { useActions, useAuth, useWidth } from "app/hooks";
import { Path } from "app/pages";
import { LINKS } from "app/ton-components/links/links";
import iGgr from "assets/img/ggr-token-icon.png";

import iLaunch from "assets/img/launch.svg";
import cn from "classnames";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import MenuScrollLink from "./menu-scroll-link";
import "./menu.sass";
import WalletMobile from "./wallet-mobile";

type Props = {
  isOpen: boolean;
  closeMenu: () => void;
};

export const Menu: FC<Props> = ({ isOpen, closeMenu }) => {
  const { openModal } = useActions();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isAuth = useAuth();
  const { isDesktopWidth } = useWidth();

  const isIdoLink = useMemo(() => {
    const splitedPathname = pathname.split("/");

    return !!(splitedPathname.length && splitedPathname[1]);
  }, [pathname]);

  return (
    <div className={cn("menu", { _open: isOpen })}>
      <div className="menu__inner">
        <WalletMobile closeMenu={closeMenu} />
        <a className="menu__item" href="https://gagarin.world">
          {t("About")}
        </a>
        <MenuScrollLink
          isLink={isIdoLink}
          to={Path.IDO_LIST}
          onClick={closeMenu}
          hashId="project-list"
        >
          IDO
        </MenuScrollLink>
        <a href={t("tonLaunchpad")} className="menu__item">
          TON
        </a>
        {isAuth ? (
          <Link to={Path.ACCOUNT}>
            <div className={cn("menu__item", "menu__item-staking")}>
              <div className={cn("menu__item", "")}>{t("Staking")}</div>
            </div>
          </Link>
        ) : (
          <div
            // onClick={() => (isAuth ? "" : <Web3Modal/>)}
            className={cn("menu__item", "menu__item-staking")}
          >
            <div className={cn("menu__item", "")}>{t("Staking")}</div>
          </div>
        )}

        {/* <Link className="menu__item" to={Path.COINEX} onClick={closeMenu}>
          <img src={iCoinexChain} className="menu__item-img" alt="" />
          CSC
        </Link> */}

        <a href={LINKS.buyGGRLink} target="_blank" className="menu__item" rel="noreferrer">
          <img src={iGgr} width="20" />
          {t("Buy")} GGR
        </a>

        <a
          className="menu__item"
          href="https://docs.google.com/forms/d/e/1FAIpQLSd2k27Q42v1_naKoLSxca2pgAGEerzdzqHLf6yeZUUiJuMbiw/viewform"
          target="_blank"
          rel="noreferrer"
        >
          <img src={iLaunch} />
          {t("Apply Project")}
        </a>
      </div>
      {/* {isAuth && (
        <div className={cn("max-[1450px]:flex", "hidden")}>
          <UserMenu isUserMenuOpened={true} closeUserMenu={closeMenu} />
        </div>
      )} */}
    </div>
  );
};
