import cn from "classnames";
import { useTranslation } from "react-i18next";
import "./agree-modal-withdraw.sass";

interface IProps {
  className?: string;
  onAgree: VoidFunction;
  onCancel: VoidFunction;
  sum?: string;
}

const AgreeModalWithdraw: React.FC<IProps> = ({ className, onAgree, onCancel, sum = "0" }) => {
  const { t, i18n } = useTranslation();
  const data = {
    title: t("buttons.buttonWithdraw"),
    text: t('modal.stakingModal.emergencyWithdrawDescription'),
    subText: t('modal.stakingModal.youWillGet'),
    button1: t("modal.yes"),
    button2: t("modal.no"),
  };

  return (
    <div className={cn("agree-modal-withdraw", className)}>
      <div className="agree-modal-withdraw__title">{data.title}</div>
      <div className="agree-modal-withdraw__text">{data.text}</div>
      <div className="agree-modal-withdraw__sub-text">{data.subText}</div>
      <div className="agree-modal-withdraw__sum">{sum} GGR</div>
      <div className="agree-modal-withdraw__buttons">
        <button className="agree-modal-withdraw__button _bg-red" onClick={onAgree}>
          {data.button1}
        </button>
        <button className="agree-modal-withdraw__button  _bg-green  " onClick={onCancel}>
          {data.button2}
        </button>
      </div>
    </div>
  );
};

export default AgreeModalWithdraw;
