import { TraceMsg } from "tonapi-sdk-js";
import TonWeb from "tonweb";

export const sleep = (timeout: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

export function isMobile(): boolean {
  return window.innerWidth <= 500;
}

export function openLink(href: string, target = "_self") {
  window.open(href, target, "noreferrer noopener");
}

const MAX_ITERATION_COUNT = 8;
const POll_INTERVAL = 15_000;

const checkTrace = async (
  trace: TraceMsg,
  refetch: () => Promise<TraceMsg>,
  start: (hash: string) => void,
  end: () => void
) => {
  start("hash");
  if (!trace.tx) {
    await refetch();
    end();
  } else {
    if (trace.tx.outMsgs.length) {
      trace.tx.outMsgs.forEach((msg) => checkTrace(msg, refetch, start, end));
      end();
    } else {
      end();
    }
  }
};

export const formatTonAddress = (tonAddressRaw?: string) => {
  if (!tonAddressRaw) return;
  return new TonWeb.Address(tonAddressRaw).toString(true, true);
};
