import { ReactComponent as IconClaim } from "assets/img/icons/icon-claim.svg";
import { ReactComponent as IconComparisonArrow } from "assets/img/icons/icon-comparison-arrow.svg";
import cn from "classnames";
import { TierTypes } from "graphql/account-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TierType } from "../../types/tier";
import { formatGgrAmount } from "../../utils";
import { Back, Button, ButtonSize, ButtonType, Loader } from "./index";
import KeyValueList from "./key-value-list";
import NumberedList from "./numbered-list";

enum Scene {
  EMPTY,
  INFO,
  EARLY_ALERT,
  SPIN,
}

type TProps = {
  className?: string;
  nextUnlock: Date;
  totalAmount: bigint;
  received: bigint;
  remaining: bigint;
  claimable: bigint;
  myTier?: TierTypes;
  isAddrAddedToVesting: boolean;
  isFetching: boolean;
  isEarlyAlert: boolean;
  onClaimClick: () => Promise<any>;
};

const Vesting: React.FC<TProps> = ({
  className,
  nextUnlock,
  totalAmount,
  received,
  remaining,
  claimable,
  myTier,
  isAddrAddedToVesting,
  isFetching = false,
  isEarlyAlert = false,
  onClaimClick,
}) => {
  const { t } = useTranslation();

  const [scene, setScene] = useState(Scene.INFO);

  useEffect(() => {
    setScene(isFetching ? Scene.SPIN : Scene.INFO);
  }, [isFetching]);
  useEffect(() => {
    setScene(!isAddrAddedToVesting && !isFetching ? Scene.EMPTY : Scene.INFO);
  }, [isFetching, isAddrAddedToVesting]);

  return (
    <div className={cn("bg-bg_card rounded-3xl p-6", className)}>
      {scene === Scene.INFO && (
        <>
          <div className="mb-4 flex items-center gap-2">
            <div className="p2b text-soft_sky">{t("Vesting")}</div>
          </div>
          <KeyValueList
            className="mb-4"
            items={[
              {
                key: t("Next unlock"),
                value: nextUnlock.toLocaleString(),
              },
              {
                key: t("Total amount"),
                value: `${formatGgrAmount(totalAmount)} GGR`,
              },
              {
                key: t("Received"),
                value: `${formatGgrAmount(received)} GGR`,
              },
              {
                key: t("Remaining"),
                value: `${formatGgrAmount(remaining)} GGR`,
              },
              {
                key: t("Claimable"),
                value: `${formatGgrAmount(claimable)} GGR`,
              },
            ]}
          />
          <Button
            onClick={async () => {
              if (isEarlyAlert) {
                setScene(Scene.EARLY_ALERT);
              } else {
                setScene(Scene.SPIN);
                await onClaimClick();
                setScene(Scene.INFO);
              }
            }}
            icon={<IconClaim width={18} height={18} />}
            size={ButtonSize.LG}
            type={claimable > 0 ? ButtonType.TRANSPARENT : ButtonType.DISABLED_TRANSPARENT}
            className="w-full"
            text={t("Claim")!}
          />
        </>
      )}

      {scene === Scene.EARLY_ALERT && (
        <>
          <div className="mb-4 flex items-baseline justify-between gap-2">
            <div className="p2b text-soft_sky">{t("Vesting")}</div>
            <Back onClick={() => setScene(Scene.INFO)} />
          </div>
          <div className="p3 text-misty_lavender mb-4">
            {t(
              "We're grateful to our early investor for believing in our project. We've prepared bonuses for you, but you'll lose them if you claim the tokens. We value our friendship and hope to continue be together towards our goals!"
            )}
          </div>
          <NumberedList
            className="mb-4"
            title={t("What you will lose?")}
            items={[
              {
                key: t(
                  "If you will claim the tokens, please be aware that it will result in a loss of 15% APR on your initial deposit"
                ),
              },
              {
                key: t(
                  "Claiming tokens may seem like an easy win, but it comes with a high cost. If you claim the tokens, you will need more Allocation Power to receive the Investor Tier, which could cost you in the long run"
                ),
                value: (
                  <div className="grid grid-cols-1 gap-2 ">
                    {[
                      {
                        name: t("Earth"),
                        currentAP: "0",
                        nextAP: "500",
                        image: `../../../../assets/img/tiers/tier-earth.png`,
                        type: TierType.EARTH,
                      },
                      {
                        name: t("Orbit"),
                        currentAP: "2000",
                        nextAP: "5000",
                        image: `../../../../assets/img/tiers/tier-galaxy.png`,
                        type: TierType.ORBIT,
                      },
                      {
                        name: t("Galaxy"),
                        currentAP: "10000",
                        nextAP: "20000",
                        image: `../../../../assets/img/tiers/tier-orbit.png`,
                        type: TierType.GALAXY,
                      },
                      {
                        name: t("Universe"),
                        currentAP: "20000",
                        nextAP: "60000",
                        image: `../../../../assets/img/tiers/tier-universe.png`,
                        type: TierType.UNIVERSE,
                      },
                    ].map((tier, i) => (
                      <div className="flex items-center gap-2" key={i}>
                        <img width="48" height="48" src={tier.image} alt="" />
                        <div>
                          <div className="p3 text-soft_sky flex items-baseline gap-2">
                            <div>{tier.name}</div>
                            {myTier && tier.type === myTier && <div>—</div>}
                            {myTier && tier.type === myTier && <div>{t("My Tier")}</div>}
                          </div>
                          <div className="flex flex-wrap items-baseline gap-2">
                            <div className="p3 text-deep_jungle_green">{tier.currentAP} AP</div>
                            <IconComparisonArrow width="14" height="6" />
                            <div className="p3 text-misty_lavender">{tier.nextAP} AP</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ),
              },
              {
                key: t(
                  "Claiming tokens may result in a reduction of your allocation for projects. It may impact your ability to participate in future IDO"
                ),
              },
            ]}
          />
          <Button
            onClick={async () => {
              setScene(Scene.SPIN);
              await onClaimClick();
              setScene(Scene.INFO);
            }}
            size={ButtonSize.LG}
            type={ButtonType.DANGER_TRANSPARENT}
            className="w-full"
            text={t("Claim anyway")!}
          />
        </>
      )}

      {scene === Scene.SPIN && (
        <>
          <div className="mb-4 flex items-center gap-2">
            <div className="p2b text-soft_sky">{t("Vesting")}</div>
          </div>
          <Loader className="h-72 w-full" />
        </>
      )}

      {scene === Scene.EMPTY && (
        <>
          <div className="mb-4 flex items-center gap-2">
            <div className="p2b text-soft_sky">{t("Vesting")}</div>
          </div>
          <div className="p3 text-soft_sky">
            {t("Unfortunately, no vesting tokens have been allocated for you")}
          </div>
        </>
      )}
    </div>
  );
};

export default Vesting;
