import { BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectFcfsInputValue } from "store/slice";
import { formatBigNumber, getTokensAmount, trim } from "../utils";
import { useApplication } from "./use-application";
import { useLeftToRaiseAmount } from "./use-left-to-raise";

/**
 * @returns Exchange Currency value
 */
export const useAvailableFcfsAmount = (project: Ido) => {
  const leftToRaiseAmount = useLeftToRaiseAmount(project);
  const { application } = useApplication(project);

  return useMemo(() => {
    const fcfsMaxAmount = BigNumber.from(project.fcfsMaxCount || 0);

    let availableFcfsAmount = fcfsMaxAmount.sub(application?.fcfsAmount || 0);

    if (availableFcfsAmount.gt(leftToRaiseAmount)) {
      availableFcfsAmount = leftToRaiseAmount;
    }

    if (availableFcfsAmount.lt(0)) {
      availableFcfsAmount = BigNumber.from(0);
    }

    return {
      availableFcfsAmount: availableFcfsAmount,
      availableFcfsAmountView: formatBigNumber(availableFcfsAmount),
    };
  }, [project, application, leftToRaiseAmount]);
};

/**
 * @returns Claim Currency value
 */
export const useAvailableFcfsTokensAmount = (project: Ido, tier?: string) => {
  const fcfsInputValue = useSelector(selectFcfsInputValue);
  const leftToRaiseAmount = useLeftToRaiseAmount(project);
  const { application } = useApplication(project);

  return useMemo(() => {
    const fcfsMaxAmount = BigNumber.from(project.fcfsMaxCount || 0);

    let availableFcfsAmount = fcfsMaxAmount.sub(application?.fcfsAmount || 0);

    if (availableFcfsAmount.gt(leftToRaiseAmount)) {
      availableFcfsAmount = leftToRaiseAmount;
    }

    if (fcfsInputValue) {
      availableFcfsAmount = parseEther(fcfsInputValue);
    }

    if (availableFcfsAmount.lt(0)) {
      availableFcfsAmount = BigNumber.from(0);
    }

    const availableFcfsTokensAmount = getTokensAmount(project.exchangeRate, availableFcfsAmount);
    let fee = BigNumber.from(0);
    if (tier && tier === "NONE" && !application?.isGarant) {
      fee = availableFcfsTokensAmount.mul(5).div(100);
    }
    let availableFcfsTokensAmountView = formatBigNumber(availableFcfsTokensAmount);

    if ((+trim(availableFcfsTokensAmountView)).toFixed().length > 6) {
      availableFcfsTokensAmountView = availableFcfsTokensAmountView.split(".")[0];
    }

    const availableFcfsTokensAmountViewWithFee = formatBigNumber(
      availableFcfsTokensAmount.sub(fee)
    );

    return {
      availableFcfsTokensAmount,
      availableFcfsTokensAmountView,
      availableFcfsTokensAmountViewWithFee,
    };
  }, [project, application, fcfsInputValue, leftToRaiseAmount]);
};

export const useFcfsInputAmount = () => {
  const fcfsInputValue = useSelector(selectFcfsInputValue);

  return useMemo(() => parseEther(trim(fcfsInputValue) || "0"), [fcfsInputValue]);
};

export const useFcfsAmount = (project: Ido, tier?: string) => {
  const availableFcfsAmount = useAvailableFcfsAmount(project);
  const availableFcfsTokensAmount = useAvailableFcfsTokensAmount(project, tier);
  const fcfsInputAmount = useFcfsInputAmount();

  return useMemo(
    () => ({
      ...availableFcfsAmount,
      ...availableFcfsTokensAmount,
      fcfsInputAmount,
    }),
    [availableFcfsAmount, availableFcfsTokensAmount, fcfsInputAmount]
  );
};
