import iPause from "assets/img/pause-icon.svg";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import "./project-pause.sass";

type Props = {};

const ProjectPause: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="project-pause">
      <img src={iPause} alt="pause" className="project-pause__icon" />
      <div className="project-pause__desc">
        {t(
          "Something went wrong. We are already figuring out what's going on and will immediately inform you about the results"
        )}
      </div>
    </div>
  );
};

export default ProjectPause;
