import cn from "classnames";
import { FC, HTMLAttributes } from "react";
import { LINKS } from "../../links/links";
import "./button.sass";

export type TButtonData = {
  title: React.ReactNode;
  badge?: string;
  size?: ButtonSize;
  type?: ButtonType;
  href?: LINKS;
  onClick?: () => any;
};

export enum ButtonType {
  disabled,
  primary,
  accent,
  purple,
  red,
}

export enum ButtonSize {
  lg,
  md,
  sm,
}

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, "title" | "onClick">, TButtonData {}

const Button: FC<Props> = ({
  className,
  title,
  type = ButtonType.primary,
  badge,
  href,
  size = ButtonSize.md,
  onClick = () => {},
}) => {
  const Tag = href ? "a" : "div";
  return (
    <Tag
      className={cn("ton-button", { " _disabled": type === ButtonType.disabled }, className)}
      href={href!}
      target="_blank"
      onClick={onClick}
    >
      {badge && <div className={cn("ton-button__badge")}>{badge}</div>}
      <div
        className={cn("ton-button__content", {
          " _border": type === ButtonType.disabled || type === ButtonType.primary,
          " _primary": type === ButtonType.primary,
          "ton-button___content _accent": type === ButtonType.accent,
          " _purple": type === ButtonType.purple,
          " _disabled": type === ButtonType.disabled,
          " _red": type === ButtonType.red,
        })}
      >
        <div
          className={cn("ton-button__text ", {
            " _p1": size === ButtonSize.lg,
            " _p2": size === ButtonSize.md,
            " _p3": size === ButtonSize.sm,
          })}
        >
          {title}
        </div>
      </div>
    </Tag>
  );
};

export default Button;
