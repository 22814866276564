import { ConnectButton } from "@rainbow-me/rainbowkit";
import { FC } from "react";

const BnbAccountBlockNotConnected: FC = () => {
  return (
    <div className="flex justify-start items-start h-full w-full">
      <ConnectButton />
    </div>
  );
};

export default BnbAccountBlockNotConnected;
