import { ConnectButton } from '@rainbow-me/rainbowkit';
import Button from "app/components/button/button";
import {
  MAIN_PROJECTS_FILTERS,
  MAIN_PROJECTS_FILTERS_WITHOUT_MY,
  NO_PROJECTS_MESSAGE_MAIN,
} from "app/constants";
import { useActions, useActiveFilter, useAuth, useWidth } from "app/hooks";
import { Path } from "app/pages";
import { ReactComponent as LaunchIcon } from "assets/img/LaunchIcon.svg";
import AuditIcon from "assets/img/audit-icon.svg";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useIdosQuery } from "store/api";
import { useAccount } from "wagmi";
import ProjectList from "../components/project/project.list";
import "./main.page.sass";

const MainPage: FC = () => {
  const { t } = useTranslation();
  const { openModal } = useActions();
  const activeFilter = useActiveFilter();
  const { address } = useAccount()
  const { data: idos, isLoading, isFetching } = useIdosQuery({ activeFilter, address }, { refetchOnMountOrArgChange: true });
  const isAuth = useAuth();
  const { isMobileWidth } = useWidth();

  const filters = isAuth ? MAIN_PROJECTS_FILTERS : MAIN_PROJECTS_FILTERS_WITHOUT_MY;

  return (
    <div className="page">
      <div className="page__inner">
        <div className="page__title">
          <Trans i18nKey="mainPage.title">
            Expert{isMobileWidth && <br />} platform for
            <span className="_g-text-active-lighter">incubating</span>&nbsp;
            {isMobileWidth && <br />}
            <span className="_g-text-active-lighter">projects</span>
            and
            <span className="_g-text-active-lighter"> launching&nbsp;IDOs</span>
          </Trans>
        </div>
        <div className="page__desc">{t("mainPage.desc")}</div>
      </div>
      <div className="page__inner">
        <div className="page__block">
          <div className="main__buttons">

            {!isAuth ? (
              <ConnectButton />
            ) : (
              <>
                <a href="https://gagarin.world/" target="_blank" rel="noreferrer">
                  <Button className="_blank">{t("About GAGARIN")}</Button>
                </a>
                <Link to={Path.ACCOUNT}>
                  {" "}
                  <Button>{t("Stake GGR")}</Button>
                </Link>
                {/* <Web3Button /> FIXME */}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="page__block">
        <ProjectList
          title="Projects on GAGARIN"
          projects={idos}
          filters={filters}
          isLoading={isLoading || isFetching || !idos}
          noProjectsMessage={NO_PROJECTS_MESSAGE_MAIN}
        />
      </div>
      <div className="page__block">
        <div className="page__inner">
          <div className="main__launch">
            <div className="main__launch-inner">
              <div className="main__launch-title">
                {t("Want to launch your project on the GAGARIN Launchpad?")}
              </div>
              <div className="main__launch-desc">
                {t(
                  "Leave an application for acceleration and launch a project together with our experts"
                )}
              </div>
              <a
                className="main__launch-btn"
                href="https://forms.gle/JHjVv7GaxAyEwfEE7"
                target="_blank"
                rel="noreferrer"
              >
                <LaunchIcon className="main__launch-btn-icon" width={16} height={16} />
                {t("apply to launch")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="page__inner">
        <a
          href="https://solidity.finance/audits/Gagarin/"
          target="_blank"
          className="main__audit"
          rel="noreferrer"
        >
          <img src={AuditIcon} alt="audit" className="main__audit-icon" />
          <div className="main__audit-title">{t("SOLIDITY.FINANCE AUDIT PASSED")}</div>
          <div className="main__audit-desc">{t("WE ARE AUDITED")}!</div>
        </a>
      </div>
    </div>
  );
};

export default MainPage;
