import iAmaActive from "assets/img/ama-active.svg";
import iAma from "assets/img/ama.svg";
import iAnotherActive from "assets/img/another-active.svg";
import iAnother from "assets/img/another.svg";
import iAskAndWinActive from "assets/img/ask-and-win-active.svg";
import iAskAndWin from "assets/img/ask-and-win.svg";
import iBscLogo from "assets/img/bsc-logo.svg";
import iCscLogo from "assets/img/csc-chain.svg";
import iEthereumLogo from "assets/img/ethereum-chain.svg";
import iGiveawayActive from "assets/img/giveaway-active.svg";
import iGiveaway from "assets/img/giveaway.svg";
import iOkcLogo from "assets/img/okc-logo.png";
import iPolygonLogo from "assets/img/polygon-logo.svg";
import iTierEarth from "assets/img/tier-earth.svg";
import iTierGalaxy from "assets/img/tier-galaxy.svg";
import iTierOrbit from "assets/img/tier-orbit.svg";
import iTierUniverse from "assets/img/tier-universe.svg";
import { ChainId, MarketingActivityType, TierName, Wallet } from "sdk";
import { Chain } from "wagmi";
import { Connector, TierAllocation } from "./enums";

export const MAIN_PROJECTS_FILTERS = ["Live", "Upcoming", "Closed", "All", "My"];
export const MAIN_PROJECTS_FILTERS_WITHOUT_MY = ["Live", "Upcoming", "Closed", "All"];
export const LIVE_FILTER = MAIN_PROJECTS_FILTERS[0];
export const UPCOMING_FILTER = MAIN_PROJECTS_FILTERS[1];



export const ACCOUNT_PROJECTS_FILTERS: ["All", "Live", "Closed"] = ["All", "Live", "Closed"];

export const DEFAULT_DATE_FORMAT: string = "DD MMMM, YYYY HH:mm";
export const DEFAULT_DATE_FORMAT_SHORT: string = "DD MMMM, YYYY";
export const TIMELINE_DATE_FORMAT: string = "DD.MM.YYYY, HH:mm";
export const TIMELINE_DATE_FORMAT_SHORT: string = "DD.MM.YYYY";
export const ACTIVITY_DATE_FORMAT: string = "DD MMM";
export const PAYMENTS_OVER = "Payments are over";
export const TOP_TIER_MESSAGE = "You are at top tier! Congratulations!" as const;
export const NO_PROJECTS_MESSAGE_MAIN = "No projects at this stage";
export const NO_PROJECTS_MESSAGE_ACCOUNT = "You have no active projects";
export const NO_PROJECTS_MESSAGE_COINEX =
  "The best projects of the CSC network will soon be available";

export const NETWORK_ICONS = {
  [ChainId.UNSET]: "",
  [ChainId.BINANCE_MAINNET]: iBscLogo,
  [ChainId.BINANCE_TESTNET]: iBscLogo,
  [ChainId.CSC_MAINNET]: iCscLogo,
  [ChainId.CSC_TESTNET]: iCscLogo,
  [ChainId.OKC_MAINNET]: iOkcLogo,
  [ChainId.OKC_TESTNET]: iOkcLogo,
  [ChainId.POLYGON_MAINNET]: iPolygonLogo,
  [ChainId.POLYGON_TESTNET]: iPolygonLogo,
  [ChainId.ETHEREUM_MAINNET]: iEthereumLogo,
};

export const CONNECTORS_BY_WALLET = {
  [Wallet.Metamask]: Connector.Injected,
  [Wallet.TrustWallet]: Connector.Injected,
  [Wallet.WalletConnect]: Connector.WalletConnect,
  [Wallet.OKX]: Connector.OKX,
};

export const WALLETS_BY_CONNECTOR = {
  [Connector.Injected]: Wallet.Metamask,
  [Connector.WalletConnect]: Wallet.WalletConnect,
  [Connector.OKX]: Wallet.OKX,
};

export const TIERS_ICON_MAP = {
  [TierName.TIER_1]: iTierEarth,
  [TierName.TIER_2]: iTierOrbit,
  [TierName.TIER_3]: iTierGalaxy,
  [TierName.TIER_4]: iTierUniverse,
};

export const ACTIVITIES_ICON_MAP = {
  [MarketingActivityType.AMA.toLowerCase()]: {
    inactive: iAma,
    active: iAmaActive,
  },
  [MarketingActivityType.GIVEAWAY.toLowerCase()]: {
    inactive: iGiveaway,
    active: iGiveawayActive,
  },
  [MarketingActivityType.ANOTHER.toLowerCase()]: {
    inactive: iAnother,
    active: iAnotherActive,
  },
  [MarketingActivityType.ASK_AND_WIN.toLowerCase()]: {
    inactive: iAskAndWin,
    active: iAskAndWinActive,
  },
};

export const TIERS_ALLOCATION_MAP = {
  lottery: TierAllocation.LOTTERY,
  garanted: TierAllocation.GUARANTEED,
  dynamic: TierAllocation.DYNAMIC,
};

export const REFUND_STORAGE_KEY = "refundGuideChecked";

export const obeliskId = "6288fd334ff612b46284d61c";
export const elfMatrixId = "629e2d61740749b99a21993e";
export const shibafriendId = "628bada4adf4d2ccf11cd87a";
export const tagoverseId = "62f51dde50ce727ee75fa695";
export const movensId = "62fbc1076830331e0dcae896";
export const swordOfBloodId = "62f90b41610636ed48a39dda";
export const bizwerseWorldId = "633be409fab725bd2f149275";
export const sportzchain = "6308955dcee8aac181dd0fb8";
export const blockombatId = "62f6804a75e960be826fb925";
export const aftermathId = "6331ea5cb530dbf47e7f566b";
export const keedxId = "633c4fc4a5fe15d1e25f003f";
export const tofycoinId = "6350fe1ae76088be1095a2e6";

export const refundedProjects = [
  {
    id: obeliskId,
    txHashList: [
      {
        hash: "0x2668acfe8f1ee0d3e5de806cfa47a489eba239993122825ba2ea35e39603e095",
        chainId: ChainId.BINANCE_MAINNET,
      },
    ],
  },
  {
    id: elfMatrixId,
    txHashList: [
      {
        hash: "0x486da841fbfb8dda7a3e59e9d321e4a5e47c17cc3e1d6e17c4a66f30cbfaba58",
        chainId: ChainId.BINANCE_MAINNET,
      },
    ],
  },
  {
    id: shibafriendId,
    txHashList: [
      {
        hash: "0x37ae6c5a87f407e566f25cd3d19de7b03a92fa2fdfec7be03d5baa7bd7d0d24b",
        chainId: ChainId.BINANCE_MAINNET,
      },
      {
        hash: "0x1d51551347ed080aa8c6bdb7263cb31aa867e359ddefd8a79238d1baed29583e",
        chainId: ChainId.BINANCE_MAINNET,
      },
    ],
  },
  {
    id: blockombatId,
    txHashList: [
      {
        hash: "0xf230aaeb49557ed2a2196028e62f25f75888c09d13a428937595b88a3049c86d",
        chainId: ChainId.BINANCE_MAINNET,
      },
      {
        hash: "0x4d77bc2786b132ea62c32731d37c4c9e5cc9459853ae462177ed6f74f5d9387c",
        chainId: ChainId.CSC_MAINNET,
      },
    ],
  },
];
export const refundedProjectsId = [obeliskId, elfMatrixId, shibafriendId, blockombatId];
export const postponedProjects = [swordOfBloodId];
export const expandedProjects: string[] = [];
export const privateSwapProjects: string[] = [bizwerseWorldId, keedxId, tofycoinId];

export const NOW_IN_SEC = Math.floor(Date.now() / 1000);

export const customChains: Chain[] = [
  {
    id: 7_001,
    name: "ZetaChain Testnet",
    network: "ZetaChain Testnet",
    nativeCurrency: {
      decimals: 18,
      name: "Zeta",
      symbol: "aZETA",
    },
    rpcUrls: {
      public: { http: ["https://zetachain-athens-evm.blockpi.network/v1/rpc/public"] },
      default: { http: ["https://zetachain-athens-evm.blockpi.network/v1/rpc/public"] },
    },
    blockExplorers: {
      etherscan: { name: "ZetaScan", url: "https://explorer.zetachain.com" },
      default: { name: "ZetaScan", url: "https://explorer.zetachain.com/" },
    },
    // contracts: {
    //   multicall3: {
    //     address: "0xca11bde05977b3631167028862be2a173976ca11",
    //     blockCreated: 11_907_934,
    //   },
    // },
  },
  {
    id: 12_227_329,
    name: "NEOX Chain Testnet",
    network: "NEOX Chain Testnet",
    nativeCurrency: {
      decimals: 18,
      name: "GAS",
      symbol: "GAS",
    },
    rpcUrls: {
      public: { http: ["https://neoxseed1.ngd.network:444"] },
      default: { http: ["https://neoxseed1.ngd.network:444"] },
    },
    blockExplorers: {
      etherscan: { name: "NEOX Chain explorer", url: "https://xt1scan.ngd.network/" },
      default: { name: "NEOX Chain explorer", url: "https://explorer.zetachain.com/" },
    },
    // contracts: {
    //   multicall3: {
    //     address: "0xca11bde05977b3631167028862be2a173976ca11",
    //     blockCreated: 11_907_934,
    //   },
    // },
  },
];
