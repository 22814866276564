import { AuthTokens } from "app/types/auth-tokens";

export const ONE_HOUR = 3600000 as const;
export const TWO_HOURS = ONE_HOUR * 2;

class TokenService {
  static storageName: string = "tokens";

  get tokens(): AuthTokens | null {
    const stringTokens = localStorage.getItem(TokenService.storageName);
    if (stringTokens) {
      return JSON.parse(stringTokens);
    }
    return null;
  }

  setToken(tokens: AuthTokens): void {
    localStorage.setItem(TokenService.storageName, JSON.stringify(tokens));
  }

  removeToken(): void {
    if (this.tokens) {
      localStorage.removeItem(TokenService.storageName);
    }
  }
}

export default new TokenService();
