import { useCallback } from "react";
import { CurrencyData } from "sdk";
import { useWalletClient } from "wagmi";

export const useAddCurrency = () => {
  const { data } = useWalletClient();

  const addCurrency = useCallback(
    async ({ type = "ERC20", ...options }: CurrencyData) => {
      if (!data) {
        console.error("Can't add the currency on metamask because window.ethereum is undefined");
        return false;
      }
      let isSuccess: boolean = false;

      try {
        isSuccess = await data.request({
          method: "wallet_watchAsset",
          //@ts-ignore
          params: {
            type,
            options,
          },
        });
      } catch (err: any) {
        console.error("Failed to add the currency:", err);
      }

      return isSuccess;
    },
    [data]
  );

  return addCurrency;
};
