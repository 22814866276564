import { BigNumber } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Ido, ROOT_ADDRESSES_MAP } from "sdk";
import { selectIsProjectTimerRunning, selectProjectStage } from "store/slice";
import { useAccount, useChainId } from "wagmi";
import { useInvestorQuery } from ".";
import { useReturnProjectTokens } from "./root-contract/query/use-return-project-tokens";
import { useAuth } from "./use-auth";

export const useRawInvestorInfo = (
  project?: Ido,
  address: string = ROOT_ADDRESSES_MAP[project?.network || 0]
) => {
  const { address: account } = useAccount();
  const chainId = useChainId();
  const isAuth = useAuth();
  const [errorFound, setErrorFound] = useState<boolean>();
  const isProjectTimerRunning = useSelector(selectIsProjectTimerRunning);
  const projectStage = useSelector(selectProjectStage);

  const {
    data: investorData,
    isLoading: isInvestorLoading,
    refetch: investorRefetch,
    error: investorError,
    isFetching: isInvestorFetching,
  } = useInvestorQuery({
    projectId: project?.id,
  });

  const {
    data: returnedTokensAmount,
    isLoading: isReturnedProjectTokensAmountLoading,
    refetch: returnedProjectTokensAmountRefetch,
    error: returnedProjectTokensError,
    isFetching: isReturnedProjectTokensAmountFetching,
  } = useReturnProjectTokens({
    projectId: project?.id,
  });
  const refetch = useCallback(() => {
    investorRefetch();
    returnedProjectTokensAmountRefetch();
  }, [investorRefetch, returnedProjectTokensAmountRefetch]);

  const data = useMemo(() => {
    if (!investorData || returnedTokensAmount === undefined) return;
    return {
      claimed: BigNumber.from(investorData?.claimed),
      redeemed: BigNumber.from(investorData?.redeemed),
      returnedTokensAmount: BigNumber.from(returnedTokensAmount),
    };
  }, [investorData?.claimed, investorData?.redeemed, returnedTokensAmount]);

  // console.log({ data });

  useEffect(() => {
    if (investorError || returnedProjectTokensError) setErrorFound(true);
  }, [investorError, returnedProjectTokensError]);

  return useMemo(
    () => ({
      rawInvestorInfo: data,
      isInvestorInfoLoading: isInvestorLoading || isReturnedProjectTokensAmountLoading,
      refetchRawInvestorInfo: refetch,
      isRawInvestorInfoFetching: isInvestorFetching || isReturnedProjectTokensAmountFetching,
    }),
    [
      // project,
      data,
      isInvestorLoading,
      isReturnedProjectTokensAmountLoading,
      isInvestorFetching,
      isReturnedProjectTokensAmountFetching,
      refetch,
      // account,
      // isAuth,
      isProjectTimerRunning,
      chainId,
      address,
      projectStage,
    ]
  );
};
