import { formatEther } from "ethers/lib/utils";
import { useMemo } from "react";

export const useInvestedPercent = (invested?: string | number, total?: string | number): number => {
  return useMemo(() => {
    if (!invested || !total) return 0;

    let formattedInvested;
    let formattedTotal;

    if (typeof invested === "string") {
      formattedInvested = +formatEther(invested);
    } else {
      formattedInvested = invested;
    }

    if (typeof total === "string") {
      formattedTotal = +formatEther(total);
    } else {
      formattedTotal = total;
    }

    if (!formattedInvested) return 0;

    const result = 100 / (formattedTotal / formattedInvested);
    if (result > 100) return 100;

    return Math.ceil(result);
  }, [invested, total]);
};
