export function redactorTextLength(value: string, length: number) {
  const startString = value.substr(0, length);
  const endString = value.substr(value.length - length, value.length - 1);
  return startString.concat("...", endString);
}

export function copyAddress(value: string) {
  navigator.clipboard.writeText(value);
}
export function validValue(n: string) {
  const pattern = /^\d+(\.?)\d*$/g;

  const validate = pattern.test(n);

  if (validate === true || n === "") {
    return n;
  }
}
