import { FC } from "react";
import { useTranslation } from "react-i18next";
import Footer from "./footer";
import Header from "./header/header";
import "./layout.sass";
import ModalProvider from "./modal/modal";

type Props = {};

const Layout: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const isMaintenance = false;

  return (
    <div className="layout">
      <div className="layout__inner">
        <div className="layout__header">
          <Header />
        </div>
        {isMaintenance ? (
          <div className="maintenance">
            {t("The site is under maintenance. It will be available again very soon")} :)
          </div>
        ) : (
          <div className="layout__body">{children}</div>
        )}
        <div className="layout__footer">
          <Footer />
        </div>
      </div>
      <ModalProvider />
    </div>
  );
};

export default Layout;
