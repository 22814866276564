import { ProjectStage } from "app/enums";
import { Project } from "app/types";
import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isIdo } from "sdk";
import { useRedeemedByChainsQuery } from "store/api";
import { selectProjectStage } from "store/slice";
import { useAccount, useChainId } from "wagmi";
import { useAuth } from "./use-auth";

export const useRedeemedByChains = (project?: Project) => {
  const isAuth = useAuth();
  const [errorFound, setErrorFound] = useState<boolean>();
  const { address } = useAccount();
  const chainId = useChainId();
  const projectStage = useSelector(selectProjectStage);

  const { data, isLoading, isFetching, refetch, error } = useRedeemedByChainsQuery(
    {
      projectId: project?.id || "",
      account: address || "",
    },
    {
      skip:
        !project ||
        !chainId ||
        !isAuth ||
        !address ||
        projectStage < ProjectStage.claim ||
        !isIdo(project) ||
        errorFound,
    }
  );

  useEffect(() => {
    if (error) setErrorFound(!!error);
  }, [error]);

  return useMemo(() => {
    const redeemedByChains = data?.map(({ chainId, redeemed }) => ({
      chainId,
      redeemed: BigNumber.from(redeemed),
    }));

    return {
      redeemedByCurrentChain: redeemedByChains?.find((data) => data.chainId === chainId),
      redeemedByChains,
      redeemedNonZeroByChains: redeemedByChains?.filter(({ redeemed }) => !redeemed.isZero()),
      isRedeemedByChainsLoading: isLoading,
      isRedeemedByChainsFetching: isFetching,
      refetchRedeemedByChains: refetch,
    };
  }, [data, isLoading, isFetching, refetch, isAuth, project, chainId, address, projectStage]);
};
