import { ChainId, GagarinStakingAbi, GagarinVestingAbi, ROOT_ADDRESSES_MAP, RootAbi } from "sdk";
import { HexString } from "sdk/src/types/hex-string";
import { Abi, PublicClient, WalletClient, getContract as viemGetContract } from "viem";
import { Address, erc20ABI } from "wagmi";

export const getContract = <TAbi extends Abi | unknown[], TWalletClient extends WalletClient>({
  abi,
  address,
  chainId,
  publicClient,
  signer,
}: {
  abi: TAbi;
  address: Address;
  chainId?: ChainId;
  signer?: TWalletClient;
  publicClient?: PublicClient;
}) => {
  const c = viemGetContract({
    abi,
    address,
    publicClient: publicClient, //?? viemClients[chainId],
    walletClient: signer,
  });
  return {
    ...c,
    account: signer?.account,
    chain: signer?.chain,
  };
};

export const getRootContract = (chainId: ChainId) => {
  const address = ROOT_ADDRESSES_MAP[chainId] as HexString;
  return getContract({
    abi: RootAbi,
    address,
    chainId,
  });
};

export const getStakingContract = (chainId: ChainId) => {
  const address = process.env.REACT_APP_STAKING_CONTRACT_ADDR as HexString;
  return getContract({
    abi: GagarinStakingAbi,
    address,
    chainId,
  });
};

export const getVestingContract = (chainId: ChainId) => {
  const address = process.env.REACT_APP_VESTING_CONTRACT_ADDR as HexString;
  return getContract({
    abi: GagarinVestingAbi,
    address,
    chainId,
  });
};

export const getGgrContract = (chainId: ChainId) => {
  const address = process.env.REACT_APP_GGR_CONTRACT_ADDR as HexString;
  return getContract({
    abi: erc20ABI,
    address,
    chainId,
  });
};

export const getErc20Contract = (chainId: ChainId, address: HexString) => {
  return getContract({
    abi: erc20ABI,
    address,
    chainId,
  });
};
