import { useWidth } from "app/hooks";
import { RefObject, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectScreenWidth } from "store/slice";
import "swiper/scss";

const NEWS_ITEM_WIDTH_MOBILE = 315;
export const NEWS_LIST_GAP_MOBILE = 10;

export const useNewsSwiper = (tiersRef: RefObject<HTMLDivElement>) => {
  const screenWidth = useSelector(selectScreenWidth);
  const [slidesPerView, setSlidesPerView] = useState<number>(1);
  const [isSwiper, setIsSwiper] = useState<boolean>(true);
  const { isDesktopWidth } = useWidth();

  useEffect(() => {
    if (tiersRef?.current && isSwiper) {
      const { width } = tiersRef.current.getBoundingClientRect();
      const slidesPerView = width / (NEWS_ITEM_WIDTH_MOBILE + NEWS_LIST_GAP_MOBILE / 2);
      setSlidesPerView(+slidesPerView.toFixed(2));
    }
  }, [screenWidth, tiersRef, tiersRef?.current, isSwiper]);

  useEffect(() => {
    if (isDesktopWidth) {
      setIsSwiper(false);
    } else {
      setIsSwiper(true);
    }
  }, [isDesktopWidth]);

  return useMemo(
    () => ({ slidesPerView, isSwiper }),
    [tiersRef, tiersRef?.current, slidesPerView, isSwiper, screenWidth, isDesktopWidth]
  );
};
