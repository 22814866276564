import cn from "classnames";

interface IProps {
  className?: string;
  title: string;
}

const LoadingState: React.FC<IProps> = ({ className, title }) => {
  return (
    <div className={cn("mx-auto", className)}>
      <div className=" text-p3 text-white">{title}</div>
    </div>
  );
};

export default LoadingState;
