import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface InitialState {
  activePage: number;
  itemsPerPage: number;
}

const initialState: InitialState = {
  activePage: 0,
  itemsPerPage: 10,
};

export const referrerPageSlice = createSlice({
  initialState,
  name: "referrer-page",
  reducers: {
    setActivePage: (state, { payload }: PayloadAction<number>) => {
      state.activePage = payload;
    },
  },
});

export const selectReferrerPage = (store: RootState) => store.referrerPage;
export const selectActivePage = (store: RootState) => store.referrerPage.activePage;
export const selectItemsPerPage = (store: RootState) => store.referrerPage.itemsPerPage;
