import { useExchangeCurrencySymbol, useWidth } from "app/hooks";
import cn from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ellipseAddress, Ido, ReferrerStatistics } from "sdk";
import "./referral-info.sass";

type Props = {
  referral: ReferrerStatistics["referrals"][number];
  project?: Ido;
};

const ReferralInfo: FC<Props> = ({ referral, project }) => {
  const { t } = useTranslation();
  const { isDesktopWidth, isTabletWidth } = useWidth();
  const exchangeCurrencySymbol = useExchangeCurrencySymbol(project);

  return (
    <div className="referral-info">
      <div className="referral-info__address">
        <div className="referral-info__item">
          <div className="referral-info__key">{t("Address")}</div>
          <div className="referral-info__value">
            {isDesktopWidth || isTabletWidth ? referral.address : ellipseAddress(referral.address)}
          </div>
        </div>
      </div>
      <div className="referral-info__stats">
        <div className="referral-info__item">
          <div className="referral-info__key">{t("Total amount")}</div>
          <div className={cn("referral-info__value", { _blank: referral.redeemed === "0" })}>
            {referral.redeemed} {exchangeCurrencySymbol}
          </div>
        </div>
        <div className="referral-info__item">
          <div className="referral-info__key">{t("Total Refund")}</div>
          <div
            className={cn("referral-info__value", "_red", { _blank: referral.refunded === "0" })}
          >
            {referral.refunded} {exchangeCurrencySymbol}
          </div>
        </div>
        <div className="referral-info__item">
          <div className="referral-info__key">{t("Award")}</div>
          <div className={cn("referral-info__value", { _green: referral.award !== "0" })}>
            {referral.award} {exchangeCurrencySymbol}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralInfo;
