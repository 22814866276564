import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import Button from "app/components/button/button";
import { useOutsideClick, useWidth } from "app/hooks";
import { ReactComponent as IconTon } from "assets/img/icons/icon-ton.svg";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ellipseAddress } from "sdk";
import { UserMenuTon } from "./user-menu-ton";
import "./wallet.sass";
type TProps = {
  closeMenu?: () => void;
};

const WalletTon: FC<TProps> = ({ closeMenu }) => {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet(); //!NOTE - do not delete
  const currentAccount = tonConnectUI.account;
  const { t } = useTranslation();
  const { isDesktopWidth } = useWidth();
  const menuRef = useRef<HTMLDivElement>(null);
  const [isUserMenuOpened, setIsUserMenuOpened] = useState<boolean>(false);

  const toggleUserMenu = (): void => {
    setIsUserMenuOpened((state) => !state);
  };

  useOutsideClick(menuRef, () => {
    setIsUserMenuOpened(false);
  });
  return (
    <div className="wallet" ref={menuRef}>
      {!tonConnectUI.connected ? (
        <div>
          <Button onClick={async () => await tonConnectUI.openModal()}>
            <div className="flex items-center justify-center gap-2">
              <IconTon className="wallet__img" /> {t("Connect Wallet")}{" "}
            </div>
          </Button>
        </div>
      ) : (
        <>
          <Button className="_blank" onClick={toggleUserMenu}>
            <div className="wallet__inner">
              <IconTon className="wallet__img" />
              {ellipseAddress(currentAccount!.address)}
            </div>
          </Button>
          {isDesktopWidth && (
            <UserMenuTon
              isUserMenuOpened={isUserMenuOpened}
              closeUserMenu={() => setIsUserMenuOpened(false)}
            />
          )}
        </>
      )}
    </div>
  );
};
declare global {
  interface Window {
    gtagEventConnectWalletClicked?(data: string): void;
  }
}
export default WalletTon;

//async () => await tonConnectUI.openModal() открыть модалку подключения
//  const currentWallet = tonConnectUI.wallet;
//async () => await tonConnectUI.disconnect()
// const currentAccount = tonConnectUI.account;
// const currentIsConnectedStatus = tonConnectUI.connected;
