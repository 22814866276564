import { ReactComponent as IconArrow } from "assets/img/icons/icon-arrow.svg";
import cn from "classnames";
import { useTranslation } from "react-i18next";
type TProps = {
  className?: string;
  onClick: () => void;
};

const Back: React.FC<TProps> = ({ className, onClick }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn("group/back-button flex cursor-pointer items-center gap-2", className)}
      onClick={onClick}
    >
      <IconArrow width={10} height={10} />
      <div className="p3 text-misty_lavender group-hover/back-button:text-soft_sky transition-all">
        {t("Back")}
      </div>
    </div>
  );
};

export default Back;
