import { useState } from "react";
import { useSendTransaction, useWaitForTransaction } from "wagmi";

export const useMutation = <T extends (...args: any) => Promise<any>>(fn: T) => {
  const { sendTransactionAsync, ...transactionResponse } = useSendTransaction();
  const waitTransactionResponse = useWaitForTransaction({
    hash: transactionResponse.data?.hash,
  });
  const [isTxLoading, setIsTxLoading] = useState(false);
  const mutation = async (...args: Parameters<typeof fn>) => {
    try {
      setIsTxLoading(true);
      const tx = await fn(...args);
      const transactionResponse = await sendTransactionAsync(tx);
      setIsTxLoading(false);
      return transactionResponse;
    } catch (error) {
      setIsTxLoading(false);
      console.error(error);
    }
  };

  const response = {
    isLoading: transactionResponse.isLoading || waitTransactionResponse.isLoading || isTxLoading,
    isSuccess: transactionResponse.isSuccess || waitTransactionResponse.isSuccess,
    isError: transactionResponse.isError || waitTransactionResponse.isError,
  };
  return [mutation, response, transactionResponse, waitTransactionResponse] as const;
};
