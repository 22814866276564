import { ReactComponent as FileUploadIcon } from "assets/img/file-upload.svg";
import cn from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import "./file-input.sass";

type Props = {
  className?: string;
  title?: string;
  name?: string;
  fileName?: string;
  setValue: (value?: string) => void;
};

const FileInput: FC<Props> = ({ name, fileName, className, title, setValue }) => {
  const { t } = useTranslation();
  return (
    <label className={cn("file-input__label", className)}>
      <FileUploadIcon className="file-input__icon" />
      <div className={cn("file-input__filename", { _empty: !fileName })}>
        {fileName || t(title || "")}
      </div>
      <input
        className={cn("file-input")}
        type="file"
        name={name}
        // tmp
        onChange={({ target: { files } }) => setValue(files?.[0]?.name)}
      />
    </label>
  );
};

export default FileInput;
