import { ActivityStatus, QueryParam } from "app/enums";
import { useMarketingActivity, useQuery } from "app/hooks";
import { getActivityIcon } from "app/utils";
import cn from "classnames";
import { FC } from "react";
import { MarketingActivity, MarketingActivityType } from "sdk";
import { Tooltip } from "../tooltip/tooltip";
import "./marketing-activity-button.sass";

type Props = {
  activityType: MarketingActivityType;
  activitiesList?: MarketingActivity[];
};

const MarketingActivityButton: FC<Props> = ({ activityType, activitiesList }) => {
  const { clearQueryParams, setQueryParam, queryParam } = useQuery(
    QueryParam.MARKETING_ACTIVITY_TYPE
  );
  const { currentActivity, activityStatus, buttonText } = useMarketingActivity(
    activityType,
    activitiesList
  );

  const onClick = () => {
    if (queryParam === activityType) {
      return clearQueryParams();
    }

    if (!currentActivity) return;

    setQueryParam(QueryParam.MARKETING_ACTIVITY_TYPE, currentActivity.type);
  };

  return (
    <Tooltip text={activityType}>
      <button
        className={cn("marketing-activity-button", {
          "_not-started": activityStatus === ActivityStatus.NOT_STARTED,
          _selected: queryParam === activityType,
          _ended: activityStatus === ActivityStatus.ENDED,
        })}
        onClick={onClick}
      >
        <img
          className="marketing-activity-button__img"
          src={getActivityIcon(activityStatus, activityType)}
        />
        {buttonText && (
          <div
            className={cn("marketing-activity-button__title", {
              "_not-started": activityStatus === ActivityStatus.NOT_STARTED,
              _selected: queryParam === activityType,
              _ended: activityStatus === ActivityStatus.ENDED,
            })}
          >
            {buttonText}
          </div>
        )}
      </button>
    </Tooltip>
  );
};

export default MarketingActivityButton;
