import { useAllowance, useApproveMutation, useBalanceQuery, useDepositMutation, useEmergencyWithdrawMutation, useGetPoolQuery, useGetTokenQuery, useGetUserQuery, useReStakeMutation, useReleasableQuery, useWithdrawMutation } from "app/hooks";
import { validValue } from "app/ton-components/helper/helper";
import { formatBigNumber } from "app/utils";
import cn from "classnames";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddressZero } from "sdk";
import { HexString } from "sdk/src/types/hex-string";
import AgreeModal from "../agree-modal/agree-modal";
import AgreeModalWithdraw from "../agree-modal/agree-modal-withdraw";
import AmountInput from "../amount-input/amount-input";
import Button, { ButtonType } from "../button/button";
import ClaimModalFinal from "../claim-modal/claim-modal-final";
import WithdrawModal from "../claim-modal/withdraw-modal";
import Modal from "../modal/modal";
import SelectLine from "../select-line/select-line";
import "./stacking-view.sass";

interface IProps {
  className?: string;
  address: string;
  syncAccountData: () => Promise<void>;
}

enum StakingState {
  "none",
  "notStaked",
  "staked",
  "changeStake",
}

const ONE_MONTH = 60 * 60 * 24 * 30 * 1000;

const StackingView: React.FC<IProps> = ({ className, address, syncAccountData }) => {
  const { t, i18n } = useTranslation();
  const [stakingState, setStakingState] = useState(StakingState.none);
  const [isModalOpen, setModalOpen] = useState(false);
  const [stakingProgressStage, setStakingProgressStage] = useState(0);

  const poolsData = {
    lockTime: t("staking.lockTime"),
    totalStaking: t("staking.totalStaking"),
    nextTier:
      "Place a bet of 3,000 GGR more to go to the next level and get a guaranteed distribution",
    items: [
      {
        apMultiplier: 100,
        title: `2${t("week")}`,
        value: "0.5",
        disabled: false,
        pid: 1,
      },
      {
        apMultiplier: 110,
        title: "1M",
        value: "1",
        disabled: false,
        pid: 2,
      },
      {
        apMultiplier: 120,
        title: "3M",
        value: "3",
        disabled: false,
        pid: 3,
      },
      {
        apMultiplier: 130,
        title: "6M",
        value: "6",
        disabled: false,
        pid: 4,
      },
      {
        apMultiplier: 150,
        title: `1${t("year")}`,
        value: "12",
        disabled: false,
        pid: 5,
      },
    ],
    success: {
      title: t("modal.titleSuccess"),
      info: t("modal.stakingModal.success"),
      success: true,
    },
    error: {
      title: t("modal.titleError"),
      info: t("modal.textError"),
      success: false,
    },
  };

  const [selectedPool, setSelectedPool] = useState(0);
  const [
    deposit,
    { isLoading: isDepositLoading, isSuccess: isDepositSuccess, isError: isDepositError },
  ] = useDepositMutation();
  const [
    reStake,
    { isLoading: isReStakeLoading, isError: isReStakeError, isSuccess: isReStakeSuccess },
  ] = useReStakeMutation();
  const [withdraw, { isLoading: isWithdrawLoading, isSuccess: isWithdrawSuccess }] =
    useWithdrawMutation();
  const [
    emergencyWithdraw,
    { isLoading: isEmergencyWithdrawLoading, isSuccess: isEmergencyWithdrawSuccess },
  ] = useEmergencyWithdrawMutation();
  const { data: token, isLoading: isTokenLoading } = useGetTokenQuery();
  const {
    data: ggrBalance,
    isLoading: isGgrBalanceLoading,
    refetch: refetchGgrBalance,
  } = useBalanceQuery(
    { token: token as HexString },
  );
  const {
    data: user,
    isLoading: isUserLoading,
    refetch: refetchUser,
  } = useGetUserQuery();
  const { data: pool, isLoading: isPoolLoading } = useGetPoolQuery(
    { pid: user?.pid?.toString() as HexString },
  );
  const [approve, { isLoading: isApproveLoading }] = useApproveMutation();
  const { allowance, refetchAllowance } = useAllowance(
    token,
    18,
    process.env.REACT_APP_STAKING_CONTRACT_ADDR
  );

  useEffect(() => {
    if (!user) return
    if (isReStakeError || isDepositError) {
      refetchUser();
    }
  }, [isDepositError, isReStakeError]);

  const { data: releasable, isLoading: isReleasableLoading } = useReleasableQuery()

  const stakeData = useMemo(() => {
    if (!user || stakingState === StakingState.notStaked || !pool || pool.pid.isZero() || !releasable) return;
    const stakingEndDate = user.stakedAt.add(pool.lockPeriod);
    const initialAmount = user.stakedAmount;
    const emergencyAmount = initialAmount.sub(
      initialAmount.mul(+formatUnits(pool.emergencyWithdrawFee, 12)).div("100")
    );
    const receiverReward = user.totalReward;
    const reward = releasable.add(receiverReward);
    const total = reward.add(initialAmount);
    return {
      stakingEndDate: stakingEndDate.toNumber() * 1000,
      releasable: formatBigNumber(releasable, 5),
      reward: formatBigNumber(reward, 5),
      total: formatBigNumber(total, 5),
      initialAmount: formatBigNumber(initialAmount, 5),
      receiverReward: formatBigNumber(receiverReward, 5),
      emergencyAmount: formatBigNumber(emergencyAmount, 5),
    };
  }, [user, pool, stakingState, releasable])

  const lockTime = useMemo(() => {
    if (stakeData) {
      return moment(stakeData.stakingEndDate);
    }
    return moment(Date.now() + ONE_MONTH * +poolsData.items[selectedPool].value);
  }, [stakeData, selectedPool]);

  const [inputValue, setInputValue] = useState("0");
  useEffect(() => {
    if (!user) return;
    if (user.pid.isZero()) {
      setStakingState(StakingState.notStaked);
    } else {
      setStakingState(StakingState.staked);
      setSelectedPool(poolsData.items.findIndex((el) => el.pid === user.pid.toNumber()));
    }
  }, [user]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = validValue(e.target.value);
    if (number !== undefined) {
      setInputValue(number);
    }
  };

  const onMaxClick = () => {
    setInputValue(Math.floor(+formatUnits(ggrBalance || "0")).toString());
  };

  const globalRefetch = () => {
    refetchGgrBalance();
    refetchUser();
    syncAccountData();
  };

  const onStakeBtnClick = async () => {
    if (
      !user?.pid.isZero() &&
      selectedPool !== poolsData.items.findIndex((el) => el.pid === user?.pid.toNumber())
    ) {
      const amount = parseUnits(inputValue.toString());
      await reStake({ pid: poolsData.items[selectedPool].pid });
      if (!amount.isZero()) {
        await deposit({ pid: poolsData.items[selectedPool].pid, amount });
      }
    } else {
      if (!inputValue) return; // TODO add error
      const amount = parseUnits(inputValue.toString());
      await deposit({ pid: poolsData.items[selectedPool].pid, amount });
    }
    globalRefetch();
    setStakingProgressStage(2);
    setModalOpen(true);
    setInputValue("0");
  };

  const onChangeBtnClick = () => {
    setStakingState(StakingState.changeStake);
  };

  const onWithdrawBtn = () => {
    setModalOpen(true);
    setStakingProgressStage(0);
  };

  const onEmergencyWithdrawBtn = async () => {
    setModalOpen(true);
    setStakingProgressStage(4);
  };

  const isApproveNeed = useMemo(() => {
    const parsedInputValue = parseUnits(inputValue.toString() || '0')
    return allowance.isZero() || allowance.lt(parsedInputValue)
  }, [allowance, inputValue])

  const changePoolList = useMemo(() => {
    if (stakingState === StakingState.changeStake) {
      const currentPoolLock = poolsData.items.find((el) => el.pid === user?.pid.toNumber());
      const pools = poolsData.items.map((el) => ({
        ...el,
        disabled: +el.value < Number(currentPoolLock?.value || 0),
      }));
      return pools;
    } else {
      return [];
    }
  }, [stakingState]);

  const isInitialLoading =
    stakingState === StakingState.none ||
    (stakingState === StakingState.staked && !stakeData) ||
    isTokenLoading ||
    isReleasableLoading;
  const isTransactionLoading =
    isDepositLoading ||
    isReStakeLoading ||
    isApproveLoading ||
    isWithdrawLoading ||
    isEmergencyWithdrawLoading;
  const isTransactionSuccess = isDepositSuccess || isReStakeSuccess || isWithdrawSuccess;

  return (
    <>
      {" "}
      {isInitialLoading || isTransactionLoading ? (
        <div className="stacking-view__loader">
          {" "}
          <svg className="spinner" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
          </svg>
        </div>
      ) : (
        ""
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setModalOpen(false);
          setStakingProgressStage(0);
        }}
      >
        {stakingProgressStage === 0 && (
          <WithdrawModal withdrawSum={stakeData?.total} click={() => setStakingProgressStage(1)} />
        )}
        {stakingProgressStage === 2 && (
          <ClaimModalFinal
            info={(() => {
              if (isTransactionSuccess) return poolsData.success;
              return poolsData.error;
            })()}
          />
        )}
        {stakingProgressStage === 4 && (
          <AgreeModalWithdraw
            sum={stakeData?.emergencyAmount}
            onAgree={async () => {
              setModalOpen(false);
              await emergencyWithdraw();
              globalRefetch();
            }}
            onCancel={() => {
              setModalOpen(false);
              setStakingProgressStage(0);
            }}
          />
        )}
        {stakingProgressStage === 1 &&
          (isDepositLoading ? (
            <div className="claim-loading">
              <svg className="spinner" viewBox="0 0 50 50">
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </div>
          ) : (
            <AgreeModal
              onAgree={async () => {
                setModalOpen(false);
                await withdraw();
                globalRefetch();
              }}
              onCancel={() => {
                setModalOpen(false);
                setStakingProgressStage(0);
              }}
            />
          ))}
      </Modal>
      <div
        className={cn(
          "stacking-view",
          className,
          isInitialLoading || isTransactionLoading ? " _loader" : ""
        )}
      >
        <div className="stacking-view__title">{t("staking.title")}</div>
        <div className="stacking-view__info">
          <div className="stacking-view__info-item">
            <div className="stacking-view__info-title">AP MULTIPLIER</div>
            <div className="stacking-view__info-text">{`${poolsData.items[selectedPool].apMultiplier}%`}</div>
          </div>
          <div className="stacking-view__info-item">
            <div className="stacking-view__info-title">{t("staking.till")}</div>
            <div className="stacking-view__info-text">{lockTime.format("L")}</div>
          </div>
        </div>
        {(stakingState === StakingState.staked || stakingState === StakingState.changeStake) && stakeData ? (
          <>

            <div className=" stacking-view__lock-time">
              {poolsData.totalStaking}
            </div>
            <div className="stacking-view__total">
              <div className="stacking-view__total-title">{stakeData.total} GGR</div>
              <div className="stacking-view__total-decs">
                <div className="stacking-view__total-decs-title">{t("staking.initialAmount")}</div>
                <div className="stacking-view__total-decs-info">{stakeData.initialAmount} GGR</div>
              </div>
              <div className="stacking-view__total-decs">
                <div className="stacking-view__total-decs-title">{t("staking.rewardAmount")}</div>
                <div className="stacking-view__total-decs-info _text-green">
                  {stakeData.reward} GGR
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {stakingState === StakingState.notStaked && (
          <>
            <SelectLine
              click={setSelectedPool}
              className="stacking-view__select-line"
              items={poolsData.items}
              actionElement={selectedPool}
            />
            <AmountInput
              className="stacking-view__amount-input "
              balance={ggrBalance ? formatBigNumber(ggrBalance, 5) : ""}
              input={{ value: inputValue, onChange: onInputChange }}
              onMaxClick={onMaxClick}
            />
            {/* <div className="stacking-view__amount-input ">
              {data.nextTier}{" "}
              <Badge type="green" className="stacking-view__badge _pointer " title="Add 3000 GGR" />
            </div> */}
          </>
        )}
        {stakingState === StakingState.changeStake && (
          <>
            <div className=" stacking-view__lock-time">
              {poolsData.lockTime}
            </div>
            <SelectLine
              click={setSelectedPool}
              className="stacking-view__select-line"
              items={changePoolList}
              actionElement={selectedPool}
            />
            <AmountInput
              className="stacking-view__amount-input "
              balance={ggrBalance ? formatBigNumber(ggrBalance, 5) : ""}
              input={{ value: inputValue, onChange: onInputChange }}
              onMaxClick={onMaxClick}
            />
            {/* <div className="stacking-view__amount-input ">
              {data.nextTier}{" "}
              <Badge type="green" className="stacking-view__badge _pointer " title="Add 3000 GGR" />
            </div> */}
          </>
        )}
        {/* {stakingState === StakingState.notStaked && (
        <div className="stacking-view__tiers ">
          <div className="stacking-view__grid ">
            <div className="stacking-view__grid-item ">
              <div className="stacking-view__grid-item-title ">
                {tiers.currentTier.title}{" "}
                <Badge
                  type="red-contrast"
                  className=" stacking-view__badge"
                  title="Received tier"
                />
              </div>
              {tiers.currentTier.description.map((el, i) => (
                <div className="stacking-view__grid-item-text" key={`staking-description-1-${i}`}>
                  {el}
                </div>
              ))}
            </div>
            <div className="stacking-view__grid-item  ">
              <div className="stacking-view__grid-item-title ">
                {tiers.nextTier.title}{" "}
                <Badge
                  type="green-contrast"
                  className=" stacking-view__badge"
                  title="Received tier"
                />
              </div>
              {tiers.nextTier.description.map((el, i) => (
                <div className="stacking-view__grid-item-text" key={`staking-description-2-${i}`}>
                  {el}
                </div>
              ))}
            </div>
          </div>
          <img className="stacking-view__img " src={iNext} alt={"arrow"} />
        </div>
      )} */}
        {stakingState === StakingState.notStaked &&
          (isApproveNeed ? (
            <Button
              title="Approve"
              onClick={async () => {
                await approve({
                  address: token || AddressZero,
                  spender: process.env.REACT_APP_STAKING_CONTRACT_ADDR,
                });
                refetchAllowance();
              }}
            />
          ) : (
            <Button title={t("buttons.buttonStake")} onClick={onStakeBtnClick} />
          ))}
        {stakingState === StakingState.staked && (
          <div className="stacking-view__buttons">
            <Button title={t("buttons.buttonChange")} onClick={onChangeBtnClick} />
            {lockTime > moment() ? (
              <Button
                type={ButtonType.red}
                title={t("buttons.buttonWithdraw")}
                onClick={onEmergencyWithdrawBtn}
              />
            ) : (
              <Button
                type={ButtonType.primary}
                title={t("buttons.buttonWithdraw")}
                onClick={onWithdrawBtn}
              />
            )}
          </div>
        )}
        {stakingState === StakingState.changeStake &&
          (isApproveNeed ? (
            <Button
              title="Approve"
              onClick={async () => {
                await approve({
                  address: token || AddressZero,
                  spender: process.env.REACT_APP_STAKING_CONTRACT_ADDR,
                });
                refetchAllowance();
              }}
            />
          ) : (
            <Button title={t("buttons.buttonChange")} onClick={onStakeBtnClick} />
          ))
        }
      </div>
    </>
  );
};

export default StackingView;
