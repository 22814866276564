import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CSSProperties, ReactNode } from "react";
import { sleep } from "sdk";
import { RootState } from "..";
interface InitialState {
  modalNode: ReactNode;
  modalProps: any;
  isModalOpen: boolean;
  isModalClosing: boolean;
  modalContentStyles: CSSProperties | undefined;
  isImportantModal: boolean;
  screenWidth: number;
}

const initialState: InitialState = {
  modalNode: null,
  modalProps: undefined,
  isModalOpen: false,
  isModalClosing: false,
  modalContentStyles: undefined,
  isImportantModal: false,
  screenWidth: window.innerWidth,
};

export const closeModal = createAsyncThunk("layout/closeModal", async () => {
  await sleep(300);
});

export const layoutSlice = createSlice({
  initialState,
  name: "layout",
  reducers: {
    openModal: (state, { payload }: PayloadAction<ReactNode>) => {
      state.modalNode = payload;
      state.isModalOpen = true;
    },
    setModalProps: (state, { payload }: PayloadAction<any>) => {
      state.modalProps = payload;
    },
    setModalContentStyles: (state, { payload }: PayloadAction<CSSProperties | undefined>) => {
      state.modalContentStyles = payload;
    },
    setIsImportantModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isImportantModal = payload;
    },
    setScreenWidth: (state, { payload }: PayloadAction<number>) => {
      state.screenWidth = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeModal.pending, (state) => {
      state.isModalClosing = true;
    });
    builder.addCase(closeModal.fulfilled, (state) => {
      state.isModalClosing = false;
      if (state.isImportantModal) {
        window.location.replace(`https://${window.location.hostname}/`);
      } else {
        state.isModalOpen = false;
        state.modalNode = null;
      }
    });
  },
});

export const selectLayout = (store: RootState) => store.layout;
export const selectIsModalOpen = (store: RootState) => store.layout.isModalOpen;
export const selectIsModalClosing = (store: RootState) => store.layout.isModalClosing;
export const selectModalNode = (store: RootState) => store.layout.modalNode;
export const selectModalContentStyles = (store: RootState) => store.layout.modalContentStyles;
export const selectIsImportantModal = (store: RootState) => store.layout.isImportantModal;
export const selectModalProps = (store: RootState) => store.layout.modalProps;
export const selectScreenWidth = (store: RootState) => store.layout.screenWidth;
