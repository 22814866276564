export enum LINKS {
  launchpad = "https://app.gagarin.world/",
  mainLanding = "https://gagarin.world/",
  incubationLanding = "https://incubation.gagarin.world/",
  gfpLanding = "https://gfp.gagarin.world/",
  privateLanding = "https://private.gagarin.world/",
  tonGagarinWorld = "https://ton.gagarin.world/",

  docs = "https://gagarin-launchpad.gitbook.io/internal-wiki",
  docsInvestors = "https://gagarin-launchpad.gitbook.io/gagarin/gagarin-overview/for-investors",
  docsProjects = "https://gagarin-launchpad.gitbook.io/gagarin/gagarin-overview/for-projects",
  docsServices = "https://gagarin-launchpad.gitbook.io/gagarin/gagarin-overview/for-projects/services-for-crypto-projects",
  docsGFT = "https://gagarin-launchpad.gitbook.io/gagarin/gagarin-overview/for-projects/gagarin-fundraising-tool",
  docsGFP = "https://gagarin-launchpad.gitbook.io/gagarin/fundraising-portal/fundraising-portal.-faq",

  socialTaplink = "https://taplink.cc/gagarin.launchpad",
  socialTw = "https://twitter.com/GAGARIN_World",
  socialTg = "https://t.me/Gagarin_Launchpad_Chat",
  socialDs = "https://discord.com/invite/6FPvg5RDbc",
  socialMd = "https://medium.com/@GAGARIN.World",
  socialIn = "https://www.linkedin.com/company/gagarin-launchpad/mycompany/",
  socialTgTonEn = "https://t.me/Ton_Gagarin_World_Chat",
  socialTgTonRu = "https://t.me/Chat_Ton_Gagarin_World_Ru",
  socialTgTur = "https://t.me/GAGARIN_Launchpad_Chat_TUR",
  socialTgCn = "https://t.me/Gagarin_Launchpad_Chat_Ch",
  socialTgRu = "https://t.me/GAGARIN_Launchpad_Chat_RU",
  socialTgEn = "https://t.me/Gagarin_Launchpad_Chat",
  socialTgAnonsEn = "https://t.me/gagarin_launchpad",
  socialTgAnonsRu = "https://t.me/gagarin_launchpad_ru",
  socialTgAnonsTonEn = "https://t.me/ton_gagarin_EN",
  socialTgAnonsTonRu = "https://t.me/ton_gagarin_RU",

  supportBot = "https://t.me/GAGARIN_World_Support_Bot",

  audit = "https://solidity.finance/audits/Gagarin/",

  formProjects = "https://forms.gle/HnGB296DJTN1bRtE8",
  formPartners = "https://forms.gle/7X8u3sQ429CnvwXm6",
  presentationIncubation = "",
  whitepaperEn = "https://gagarin.world/GAGARIN_Whitepaper_EN.pdf",
  whitepaperRu = "https://gagarin.world/GAGARIN_Whitepaper_RU.pdf",
  tokenomicsEn = "https://gagarin.world/GAGARIN_Tokenomics_EN.pdf",
  tokenomicsRu = "https://gagarin.world/GAGARIN_Tokenomics_RU.pdf",
  termsOfService = "https://gagarin.world/GGR_Terms_of_Use_v01.pdf",
  privacyPolicy = "https://gagarin.world/GGR_Privacy_Policy_v01.pdf",
  email = "mailto:yury@gagarin.world",

  ton = "https://ton.org/",

  anchorForm = "#form",

  buyGGRLink = "https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0xA90Da9e3c71DDFcC2D793F80029aCBd21A4A0Db6",
}
