import cn from "classnames";
import { useTranslation } from "react-i18next";
import iGgr from "../../image/ggr-icon.png";
import Badge from "../badge/badge";
import Input from "../input/input";
import "./amount-input.sass";

interface IProps {
  className?: string;
  balance: string;
  input: any;
  onMaxClick: () => void;
}

const AmountInput: React.FC<IProps> = ({ className, balance, input, onMaxClick }) => {
  const { t, i18n } = useTranslation();
  const data = {
    balance: t("staking.balance"),
    badges: [
      {
        title: "Max",
        onClick: onMaxClick,
      },
    ],
  };
  return (
    <div className={cn("amount-input ", className)}>
      <div className={cn("amount-input__info ")}>
        <div className="amount-input__info-balance ">
          {data.balance} {`${balance} GGR`}
        </div>
        {data.badges.map((el) => (
          <Badge
            className="amount-input__info-badge"
            title={el.title}
            onClick={el.onClick}
            key={el.title}
          />
        ))}
      </div>
      <div className="amount-input__input">
        <img className="amount-input__input-img " src={iGgr} alt="ggr" />
        <div className="amount-input__input-text ">GGR</div>
        <Input {...input} />
      </div>
    </div>
  );
};

export default AmountInput;
