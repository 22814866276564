import InputNumber from "app/components/input-number/input-number";
import ProjectSwap__UserBalance from "app/components/project/project-swap/project-swap__user-balance";
import { useActions, useCurrency, useExchangeRate, useFcfsAmount } from "app/hooks";
import { trim } from "app/utils";
import iSwapArrowRight from "assets/img/icons/swap-arrow-right.svg";
import { useAccountQuery } from "graphql/@generated/graphql";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectFcfsInputValue } from "store/slice";
import { useAccount } from "wagmi";

type Props = {
  className?: string;
  ido: Ido;
};

const IdoFcfs__Body: FC<Props> = ({ ido }) => {
  const { t } = useTranslation();
  const { address } = useAccount()
  const { setFcfsInputValue } = useActions();
  const fcfsInputValue = useSelector(selectFcfsInputValue);
  const exchangeRate = useExchangeRate(ido);
  const { claimCurrencyIcon, claimCurrencySymbol, exchangeCurrencySymbol, exchangeCurrencyIcon } =
    useCurrency(ido);

  const { data: accountData,
    loading: isAccountDataLoading,
    error: accountDataError,
    refetch: accountDataRefetch, } = useAccountQuery({
      variables: {
        getAccountInput: {
          addr: address,
        },
      },
    });

  const { availableFcfsAmountView, availableFcfsTokensAmountView, availableFcfsTokensAmountViewWithFee } = useFcfsAmount(ido, accountData?.account.tier);
  useEffect(() => {
    setFcfsInputValue(trim(availableFcfsAmountView));
  }, [availableFcfsAmountView]);

  return (
    <div className="project-swap__body">
      <div className="project-swap__body-col">
        <div className="project-swap__body-title">{t("Amount")}</div>
        <div className="project-swap__body-value">
          {exchangeCurrencyIcon && (
            <img width={30} height={30} src={exchangeCurrencyIcon} alt={exchangeCurrencySymbol} />
          )}
          <InputNumber
            placeholder={availableFcfsAmountView}
            className="project-swap__body-input"
            value={fcfsInputValue}
            setValue={setFcfsInputValue}
            variant="_blank"
          />
        </div>
        <div
          className="project-swap__body-button"
          onClick={() => setFcfsInputValue(trim(availableFcfsAmountView))}
        >
          {t("Max")} {availableFcfsAmountView}
        </div>
        <ProjectSwap__UserBalance project={ido} inputValue={fcfsInputValue} setInputValue={setFcfsInputValue} />
      </div>
      <img className="project-swap__body-arrow" src={iSwapArrowRight} alt="" />
      <div className="project-swap__body-col">
        <div className="project-swap__body-title">{t("Tokens")}</div>
        <div className="project-swap__body-value">
          {claimCurrencyIcon && (
            <img width={30} height={30} src={claimCurrencyIcon} alt={claimCurrencySymbol} />
          )}
          {availableFcfsTokensAmountViewWithFee}
        </div>
        <div className="project-swap__body-desc">
          1 {claimCurrencySymbol} = {exchangeRate} {exchangeCurrencySymbol}
        </div>
      </div>
    </div>
  );
};

export default IdoFcfs__Body;
