import iArrowLink from "assets/img/arrow-link.svg";
import cn from "classnames";
import { FC } from "react";
import "./outer-link.sass";

type Props = {
  href?: string;
  className?: string;
};

const OuterLink: FC<Props> = ({ href, className, children }) => {
  return (
    <a href={href} target="_blank" className={cn("outer-link", { [`${className}`]: !!className })}>
      {children}
      <img src={iArrowLink} alt="" className="outer-link__arrow" />
    </a>
  );
};

export default OuterLink;
