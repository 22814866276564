import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../";
export enum EWalletType {
  'BNB',
  "TON"
}
interface InitialState {
  swapWalletType: EWalletType;

}

const initialState: InitialState = {
  swapWalletType: EWalletType.BNB,

};

export const walletTypeSlice = createSlice({
  initialState,
  name: "walletType",
  reducers: {
    setWalletType: (state, { payload }: PayloadAction<EWalletType>) => {
      state.swapWalletType = payload;
    }

  }
});

// export const selectWalletType = (store: RootState) => store.walletType
export const selectWalletType = (store: RootState) => store.walletType.swapWalletType;

