import { ReactComponent as IconQuestionWhite } from "assets/img/icons/icon-question-white.svg";
import cn from "classnames";
import { ReactNode } from "react";

export enum HintType {
  QUESTION = "QUESTION",
}

type TProps = {
  className?: string;
  title?: string;
  type?: HintType;
  dropdown?: ReactNode;
};

const Badge: React.FC<TProps> = ({ className, title, dropdown, type = HintType.QUESTION }) => {
  return (
    <div
      className={cn(
        "bg-bg_secondary hover:bg-bg_secondary_active group/hint relative flex h-5 w-5 cursor-pointer rounded-full transition-all",
        className
      )}
    >
      <IconQuestionWhite className="m-auto" width={8} height={13} />
      <div className="p3 text-text_primary bg-bg_secondary invisible absolute bottom-full left-1/2 w-max max-w-xs -translate-x-1/2 rounded p-2 text-center opacity-0 shadow-lg backdrop-blur transition-all group-hover/hint:visible group-hover/hint:-translate-y-2 group-hover/hint:opacity-100">
        {dropdown}
      </div>
    </div>
  );
};

export default Badge;
