import { Project } from "app/types";
import { useMemo } from "react";
import { LinkName, ProjectLink } from "sdk";

export const useProjectLinks = (project?: Project): [ProjectLink[], ProjectLink[]] => {
  return useMemo(() => {
    const socialLinks: ProjectLink[] = [];
    const docsLinks: ProjectLink[] = [];
    if (!project || !project.links) return [socialLinks, docsLinks];

    project.links.forEach((link) => {
      if (link.name === LinkName.SweepWidget) return;
      if (
        link.name === LinkName.Pitchdeck ||
        link.name === LinkName.Tokenomics ||
        link.name === LinkName.Whitepaper
      ) {
        docsLinks.push(link);
      } else {
        socialLinks.push(link);
      }
    });
    return [socialLinks, docsLinks];
  }, [project]);
};

export const useProjectTasks = (project?: Project): ProjectLink[] | null => {
  return useMemo(() => {
    if (!project || !project.links) return null;

    const links = project.links.filter(({ isTask }) => isTask);
    if (!links.length) return null;

    return links;
  }, [project]);
};
