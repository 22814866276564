import { CurrencyTicket } from "app/enums";
import { useMemo } from "react";
import { Ido, isIdo } from "sdk";

import { Project } from "app/types";
import iBusd from "assets/img/icons/busd.png";
import iUsdt from "assets/img/icons/usdt.svg";
import iUsdc from "assets/img/usdc.svg";
import { useChainId } from "wagmi";
import { useIdoImages } from "./use-project-images";

export const useClaimCurrencyIconUrl = (ido?: Ido) => {
  const { projectLogo, projectTokenLogo } = useIdoImages(ido);

  return useMemo(() => {
    if (projectTokenLogo) return projectTokenLogo;
    if (ido?.tokenLogoUrl) return ido?.tokenLogoUrl;
    if (projectLogo) return projectLogo;

    return ido?.idoLogoUrl || "";
  }, [ido, projectLogo, projectTokenLogo]);
};

/**
 * @returns Claim Currency symbol or "TBA"
 */
export const useClaimCurrencySymbol = (project?: Ido): string => {
  return useMemo(() => {
    if (!project || !project.claimCurrency || !project.claimCurrency.length) {
      return "TBA";
    }

    return project.claimCurrency[0].ticket;
  }, [project]);
};

/**
 * @returns Claim Currency decimals or 18
 */
export const useClaimCurrencyDecimals = (project?: Ido): number => {
  return useMemo(() => {
    if (!project || !project.claimCurrency || !project.claimCurrency.length) {
      return 18;
    }

    return project.claimCurrency[0].decimals;
  }, [project]);
};

export const useCurrentExchangeCurrency = (project?: Project) => {
  const chainId = useChainId();

  return useMemo(() => {
    if (!project) return;

    let currencies;
    if (isIdo(project)) {
      currencies = project.exchangeCurrency;
    } else {
      currencies = project.exchangeCurrencies;
    }

    if (!currencies || !currencies.length) return;

    const chainIds = currencies.map(({ chainId }) => chainId);
    let index = chainIds.findIndex((id) => id === chainId);

    if (index < 0) {
      index = chainIds.findIndex((id) => id === project.network);
      if (index < 0 && currencies.length) {
        index = 0;
      }
    }

    const currentExchangeCurrency = currencies[index];
    if (!currentExchangeCurrency) return;

    return currentExchangeCurrency;
  }, [project, chainId]);
};

/**
 * @returns Exchange Currency or "TBA"
 */
export const useExchangeCurrencySymbol = (project?: Project): string => {
  const currentExchangeCurrency = useCurrentExchangeCurrency(project);

  return useMemo(() => {
    if (!project || !currentExchangeCurrency) return "TBA";

    return currentExchangeCurrency.ticket;
  }, [project, currentExchangeCurrency]);
};

export const useExchangeCurrencyIconUrl = (project?: Project): string | null => {
  const exchangeCurrencySymbol = useExchangeCurrencySymbol(project);

  return useMemo(() => {
    if (!project || exchangeCurrencySymbol === "TBA") return null;
    if (exchangeCurrencySymbol === CurrencyTicket.BUSD) return iBusd;
    if (exchangeCurrencySymbol === CurrencyTicket.USDC) return iUsdc;
    return iUsdt;
  }, [project, exchangeCurrencySymbol]);
};

export const useExchangeCurrencyAddress = (project?: Project) => {
  const currentExchangeCurrency = useCurrentExchangeCurrency(project);

  return useMemo(() => {
    if (!currentExchangeCurrency) return "";

    return currentExchangeCurrency.address;
  }, [project, currentExchangeCurrency]);
};

export const useClaimCurrency = (project?: Ido) => {
  const claimCurrencyIcon = useClaimCurrencyIconUrl(project);
  const claimCurrencySymbol = useClaimCurrencySymbol(project);
  const claimCurrencyDecimals = useClaimCurrencyDecimals(project);

  return useMemo(
    () => ({ claimCurrencyIcon, claimCurrencySymbol, claimCurrencyDecimals }),
    [claimCurrencyIcon, claimCurrencySymbol, claimCurrencyDecimals, project]
  );
};

export const useExchangeCurrency = (project?: Project) => {
  const exchangeCurrencyIcon = useExchangeCurrencyIconUrl(project);
  const exchangeCurrencySymbol = useExchangeCurrencySymbol(project);
  const exchangeCurrencyAddress = useExchangeCurrencyAddress(project);
  const currentExchangeCurrency = useCurrentExchangeCurrency(project);

  return useMemo(
    () => ({
      exchangeCurrencyIcon,
      exchangeCurrencySymbol,
      exchangeCurrencyAddress,
      currentExchangeCurrency,
    }),
    [
      exchangeCurrencyIcon,
      exchangeCurrencySymbol,
      exchangeCurrencyAddress,
      currentExchangeCurrency,
      project,
    ]
  );
};

export const useCurrency = (project: Ido) => {
  const exchangeCurrency = useExchangeCurrency(project);
  const claimCurrency = useClaimCurrency(project);

  return useMemo(
    () => ({ ...exchangeCurrency, ...claimCurrency }),
    [exchangeCurrency, claimCurrency, project]
  );
};
