import DocumentIcon from "assets/img/doc-icon.svg";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ProjectLink } from "sdk";
import "./project-link.sass";

type Props = {
  link: ProjectLink;
};

const ProjectLinkDoc: FC<Props> = ({ link: { name, link } }) => {
  const { t } = useTranslation();
  return (
    <a className="project__link" href={link} target="_blank">
      <img src={DocumentIcon} alt={name} className="project__link-icon" />
      <div className="project__link-title">{t(name)}</div>
    </a>
  );
};

export default ProjectLinkDoc;
