import { useAccountQuery } from "graphql/@generated/graphql";
import { useEffect } from "react";
import { useSyncAccountMutation } from "./graphql/use-sync-account.mutation";

export const useBalanceService = ({ address }: { address: string }) => {
  const accountResponse = useAccountQuery({
    variables: {
      getAccountInput: {
        addr: address,
      },
    },
  });

  const syncBalanceResponse = useSyncAccountMutation({
    variables: {
      syncAccountInput: {
        addr: address,
      },
    },
  });

  useEffect(() => {
    syncAccountData();
  }, []);

  const syncAccountData = async () => {
    syncBalanceResponse[0]().then(() => accountResponse.refetch());
  };

  return {
    accountResponse,
    syncBalanceResponse,
    syncAccountData,
  };
};
