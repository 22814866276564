import { FC } from "react";
import Rocket from "./rocket";

type Props = {
  className?: string;
  isLoading: boolean;
};

const RocketLoader: FC<Props> = ({ className, isLoading, children }) => {
  return (
    <>
      {isLoading ? (
        <div className={className}>
          <Rocket />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default RocketLoader;
