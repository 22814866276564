import { ReactComponent as IconGgrWhite } from "assets/img/icons/icon-ggr-white.svg";
import { ReactComponent as IconSuccess } from "assets/img/icons/icon-success.svg";
import cn from "classnames";
import Button, { ButtonType } from "./button";
import LoadingState from "./loading-state";

interface IProps {
  className?: string;
  onSubmit: () => Promise<void>;
  stage: EStages;
  isDisabled: boolean;
  amount: string;
}

enum EStages {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}

const UpgradingTokensModal: React.FC<IProps> = ({
  className,
  onSubmit,
  stage,
  isDisabled,
  amount,
}) => {
  return (
    <div className={cn("flex max-w-[390px] flex-col", className)}>
      {stage === EStages.IDLE && (
        <>
          <div className="flex flex-col">
            <div className="text-text_secondary mb-6 max-w-xs text-sm">
              Due to the improvement of the platform, we have reissued the GGR token, you have to
              exchange old {amount} GGR on your wallet for new ones.
            </div>
            {isDisabled ? (
              <div>
                <Button
                  text={"Upgrade tokens"}
                  icon={<IconGgrWhite width={24} height={24} />}
                  className="mb-3 mt-1 w-full opacity-50 cursor-default"
                  type={ButtonType.ACCENT_ALT}
                />
                <p className="text-sm text-center text-orange-800">Insufficient balance*</p>
              </div>
            ) : (
              <Button
                text={"Upgrade tokens"}
                icon={<IconGgrWhite width={24} height={24} />}
                className="mb-3 mt-1 w-full"
                type={ButtonType.ACCENT_ALT}
                onClick={() => {
                  onSubmit();
                }}
              />
            )}
          </div>
        </>
      )}
      {stage === EStages.PENDING && (
        <div className="flex items-center justify-center flex-col py-3 gap-6">
          <div className="">
            <svg className="spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
          </div>
          <LoadingState title={"Loading..."} className="flex w-[340px] justify-around" />
        </div>
      )}
      {stage === EStages.SUCCESS && (
        <div className="text-green text-p3 flex items-center justify-center gap-3">
          <IconSuccess width={24} height={24} />
          {"The tokens have been successfully upgraded"}
        </div>
      )}
      {stage === EStages.ERROR && <div className="text-red text-p3">{"error"}</div>}
    </div>
  );
};

export default UpgradingTokensModal;
