import { useChains } from "app/hooks";
import { Project } from "app/types";
import { FC, useMemo } from "react";
import { ChainConfig } from "sdk";
import { CheckNetworkBody } from "./check-network-body";
import "./check-network.sass";

type Props = {
  project: Project;
  className?: string;
  exact?: boolean;
  chainsList?: ChainConfig[];
};

export const CheckNetwork: FC<Props> = ({ project, className, exact, children, chainsList }) => {
  const { isCorrectChain, isSupportedChain, chainId } = useChains(project);

  const isValidChain = useMemo(
    () => (exact ? isCorrectChain : isSupportedChain),
    [isCorrectChain, isSupportedChain, exact, chainId]
  );

  return (
    <>
      {!isValidChain ? (
        <CheckNetworkBody
          project={project}
          className={className}
          exact={exact}
          chainsList={chainsList}
        />
      ) : (
        children
      )}
    </>
  );
};
