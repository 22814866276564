import { BigNumber } from "ethers";
import { ChainId, ERC20 } from "sdk";
import { erc20ABI } from "wagmi";
import { ContractService } from "./contract.service";

class ERC20Contract extends ContractService {
  constructor() {
    super(erc20ABI);
  }

  approvePopulate({
    address,
    spender,
    amount,
    chainId,
  }: {
    address: string;
    spender: string;
    amount: BigNumber;
    chainId: ChainId;
  }) {
    const contract = this._getContract<ERC20>(address, chainId);
    return contract.populateTransaction.approve(spender, amount);
  }
}

const erc20Contract = new ERC20Contract();

export default erc20Contract;
