import { ReactComponent as IconClose } from "assets/img/icons/icon-close.svg";
import cn from "classnames";
import { PropsWithChildren } from "react";
import { Button } from "./index";

interface IProps {
  className?: string;
  title: string;
  opened: boolean;
  handleCloseClick: VoidFunction;
}

const Modal: React.FC<PropsWithChildren<IProps>> = ({
  opened,
  title,
  className,
  children,
  handleCloseClick,
}) => {
  return (
    <div
      className={cn("fixed top-0 left-0 z-50 h-screen w-screen transition-all", {
        "invisible opacity-0": !opened,
        "visible opacity-100": opened,
      })}
    >
      <div
        className="bg-bg_drop absolute top-0 left-0 z-40 h-full w-full backdrop-blur"
        onClick={handleCloseClick}
      />
      <div
        className={cn(
          "bg-bg_card absolute top-1/2 left-1/2 z-50 box-border flex h-screen w-screen -translate-x-1/2 -translate-y-1/2 flex-col overflow-y-auto p-6 md:h-auto md:w-auto md:rounded-3xl",
          className
        )}
      >
        <div className="mb-6 flex items-center justify-between gap-2">
          <div className="h3b text-text_primary">{title}</div>
          <Button icon={<IconClose width={26} height={26} />} square onClick={handleCloseClick} />
        </div>
        <div className="flex grow items-center justify-center">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
