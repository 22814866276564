import { useTonConnectUI } from "@tonconnect/ui-react";
import { ConnectedAddr } from "app/ton-lk/account-block";
import { ReactComponent as GgrBg } from "assets/img/common/ggr-bg.svg";
import { ReactComponent as IconBnb } from "assets/img/icons/icon-bnb.svg";
import { ReactComponent as IconCopy } from "assets/img/icons/icon-copy.svg";
import { ReactComponent as IconBackWhite } from "assets/img/icons/icon-ggr-white.svg";
import { ReactComponent as IconLogout } from "assets/img/icons/icon-logout.svg";
import cn from "classnames";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useSdlpSwapMutation } from "store/api/ton.api";
import iconTvm from "../../../../assets/img/icons/icon-tvm.png";
import { formatGgrAmount, truncateAddress } from "../../utils";
import { Button, ButtonType, Modal } from "./index";
import KeyValueList from "./key-value-list";
import UpgradingTokensModal from "./upgrading-token-modal";

type TProps = {
  className?: string;
  totalAp: string;
  stakingAp: string;
  bonusAp: string;
  walletAmount: string;
  connectedAddrs: ConnectedAddr[];
  handleLogoutClick: ({ addr }: { addr: string }) => void;
  linked: boolean;
  handleLinkBalClick: () => void;
  mySrcJBal?: bigint;
};
enum EStages {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}
const MyBalance: React.FC<TProps> = ({
  className,
  totalAp,
  stakingAp,
  bonusAp,
  walletAmount,
  connectedAddrs,
  handleLogoutClick,
  linked,
  handleLinkBalClick,
  mySrcJBal,
}) => {
  const [tonConnectUI] = useTonConnectUI();
  const { t } = useTranslation();
  const [isOpenUpgradingTokensModal, setIsOpenUpgradingTokensModal] = useState(false);
  const [stage, setStage] = useState<EStages>(EStages.IDLE);
  const [sdlpSwap, { isLoading: isLoadingSdlpSwap, isError: isErrorSdlpSwap }] =
    useSdlpSwapMutation();

  const onUpgrade = async () => {
    await sdlpSwap({
      tonConnectUI,
      amount: mySrcJBal || BigInt(0),
    })
      .then(() => {
        setStage(EStages.SUCCESS);
        setIsOpenUpgradingTokensModal(true);
      })
      .catch(() => {
        setStage(EStages.ERROR);
        setIsOpenUpgradingTokensModal(true);
      });
  };

  useEffect(() => {
    isLoadingSdlpSwap && setStage(EStages.PENDING);
    isErrorSdlpSwap && setStage(EStages.ERROR);
  }, [isLoadingSdlpSwap, isErrorSdlpSwap]);
  return (
    <div
      className={cn(
        "relative overflow-hidden rounded-3xl bg-right-top bg-no-repeat p-6",
        className
      )}
      style={{
        backgroundImage: `linear-gradient(
          84.84deg,
          rgba(54, 82, 229, 0.8) 0.79%,
          rgba(0, 152, 255, 0.8) 48.04%,
          rgba(1, 198, 255, 0.8) 120.05%
        ), url(assets/img/common/ggr-bg.svg)`,
      }}
    >
      <GgrBg className="absolute right-0 top-0 -z-10" />
      <div className="mb-4 flex items-center justify-between gap-2">
        <div className="p2b text-text_primary">{t("My Account")}</div>
      </div>
      <div className="mb-4">
        {connectedAddrs.map(({ addr, network }, i) => (
          <div className="mb-2 flex justify-between" key={i}>
            <div className="flex gap-2">
              <Button
                icon={
                  network === "BNB" ? (
                    <IconBnb width={24} height={24} />
                  ) : (
                    <img src={iconTvm} width={24} height={24} />
                  )
                }
                text={truncateAddress(addr, 6)}
                narrow
              />
            </div>
            <div className="flex gap-2">
              <CopyToClipboard text={addr}>
                <Button icon={<IconCopy width={16} height={16} />} narrow />
              </CopyToClipboard>
              <Button
                icon={
                  <IconLogout width={16} height={16} onClick={() => handleLogoutClick({ addr })} />
                }
                narrow
              />
            </div>
          </div>
        ))}
      </div>
      {!linked && (
        <div className="p3 mb-4">
          {t("Your addresses are not linked. Only AP from BNB is taken into account. Please")}{" "}
          <span className="underline cursor-pointer" onClick={handleLinkBalClick}>
            {t("link your addresses")}
          </span>
          .
        </div>
      )}
      <KeyValueList
        items={[
          {
            key: t("Total Allocation Power"),
            value: `${totalAp} AP`,
            isBig: true,
          },
          {
            key: t("Staking AP"),
            value: `${stakingAp} AP`,
          },
          {
            key: t("Bonus AP"),
            value: `${bonusAp} AP`,
          },
          {
            key: t("GGR In Wallets"),
            value: `${walletAmount} GGR`,
          },
        ]}
      />
      {mySrcJBal && (
        <>
          <div className="p3 mt-4">
            {t("You have")} {formatGgrAmount(mySrcJBal)}{" "}
            {t(
              "old GGR that are not taken into account when calculating the AP. You should upgrade GGR."
            )}
          </div>
          <Button
            text={"Upgrade tokens"}
            icon={<IconBackWhite width={24} height={24} />}
            className="mt-4 w-full"
            type={ButtonType.DEFAULT}
            onClick={() => setIsOpenUpgradingTokensModal(true)}
          />
        </>
      )}
      <Modal
        opened={isOpenUpgradingTokensModal}
        title={"Upgrade tokens"}
        handleCloseClick={() => {
          setStage(EStages.IDLE);
          setIsOpenUpgradingTokensModal(false);
        }}
      >
        <UpgradingTokensModal
          isDisabled={!mySrcJBal}
          stage={stage}
          onSubmit={onUpgrade}
          amount={formatGgrAmount(mySrcJBal)}
        />
      </Modal>
    </div>
  );
};

export default MyBalance;
