import { JettonMaster } from "ton";
import {
  Address,
  beginCell,
  Cell,
  Contract,
  contractAddress,
  ContractProvider,
  Sender,
  toNano,
} from "ton-core";

export type JettonMinterData = {
  totalSupply: bigint;
  adminAddress: Address;
  content: Cell;
  jettonWalletCode: Cell;
};

JettonMaster;

export class JettonMinter implements Contract {
  readonly address: Address;

  constructor(addr: Address) {
    this.address = addr;
  }

  static createFromAddress(address: Address) {
    return new JettonMinter(address);
  }

  async sendMint(
    provider: ContractProvider,
    via: Sender,
    params: {
      address: Address;
      amount: bigint;
    }
  ) {
    return await provider.internal(via, {
      value: toNano("0.4"),
      body: beginCell()
        .storeUint(21, 32)
        .storeUint(0, 64)
        .storeAddress(params.address)
        .storeCoins(toNano("0.3"))
        .storeRef(
          beginCell()
            .storeUint(0x178d4519, 32)
            .storeUint(0, 64)
            .storeCoins(params.amount)
            .storeAddress(null)
            .storeAddress(null)
            .storeCoins(0)
            .storeBit(false)
            .endCell()
        )
        .endCell(),
    });
  }

  async sendDeploy(
    provider: ContractProvider,
    via: Sender,
    params: {
      init: { code: Cell; data: Cell };
      body?: Cell;
      value?: bigint;
      deployValue?: bigint;
    }
  ) {
    await provider.internal(via, {
      value: params.value ?? toNano("0.1"),
    });
    return contractAddress(0, params.init);
  }

  async getWalletAddress(provider: ContractProvider, owner: Address) {
    const { stack } = await provider.get("get_wallet_address", [
      { type: "slice", cell: beginCell().storeAddress(owner).endCell() },
    ]);
    const [address] = [stack.readAddress()];

    return {
      address,
    };
  }

  async getJettonData(provider: ContractProvider) {
    const { stack } = await provider.get("get_jetton_data", []);
    const [totalSupply, mintable, adminAddressess, content, walletCode] = [
      stack.readBigNumber(),
      stack.readBoolean(),
      stack.readAddress(),
      stack.readCell(),
      stack.readCell(),
    ];

    return {
      totalSupply,
      mintable,
      adminAddressess,
      content,
      walletCode,
    };
  }
}
