import { useMemo } from "react";
import { parseDecimals } from "sdk";
import { HexString } from "sdk/src/types/hex-string";
import { useAccount, useChainId } from "wagmi";
import { useBalanceQuery } from "./erc20-contract/query/use-balance";

export const useBalance = (token: string = "", decimals?: number, account?: string) => {
  const { address } = useAccount();
  const chainId = useChainId();

  const { data, isLoading, refetch } = useBalanceQuery({ token: token as HexString });

  return useMemo(
    () => ({
      balance: parseDecimals(data, decimals),
      isBalanceLoading: isLoading,
      refetchBalance: refetch,
    }),
    [data, isLoading, refetch, address, chainId, account, token, decimals]
  );
};
