import { ProjectStage } from "app/enums";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectIsProjectTimerRunning, selectProjectStage } from "store/slice";
import { useApplication } from "./use-application";
import { useAuth } from "./use-auth";

export enum ApplicationStatus {
  notCreated = 1,
  rejected,
  interview,
  approved,
  win,
  lose,
}

export const useApplicationStatus = (project: Ido): ApplicationStatus => {
  const isAuth = useAuth();
  const { application } = useApplication(project);
  const isProjectTimerRunning = useSelector(selectIsProjectTimerRunning);
  const projectStage = useSelector(selectProjectStage);

  return useMemo(() => {
    if (!application || !isAuth) {
      return ApplicationStatus.notCreated;
    }
    if (
      application.status === "approved" &&
      projectStage >= ProjectStage.swapGapWithResults &&
      !application.amount.isZero()
    )
      return ApplicationStatus.win;
    if (
      application.status === "approved" &&
      projectStage >= ProjectStage.swapGapWithResults &&
      application.amount.isZero()
    )
      return ApplicationStatus.lose;
    if (application.status === "inreview") {
      return ApplicationStatus.interview;
    }
    if (application.status === "rejected") {
      return ApplicationStatus.rejected;
    }
    return ApplicationStatus.approved;
  }, [project, application, isAuth, isProjectTimerRunning, projectStage]);
};
