import { FC, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useAccount } from "wagmi";
import "./app.sass";
import Layout from "./components/layout";
import RocketLoader from "./components/loader/rocket-loader";
import PendingSignLoginMessageModal from "./components/project/project-refund/pending-sign-modal";
import { useConnect } from "./hooks";
import { useSyncAccountMutation } from "./hooks/graphql/use-sync-account.mutation";
import { useResize } from "./hooks/use-resize";
import Pages from "./pages";

const GlobalHooks: FC = () => {
  useResize();

  return null;
};

function App() {
  const { address } = useAccount();

  const [syncAccount] = useSyncAccountMutation();

  useEffect(() => {
    if (address) {
      syncAccount({
        variables: {
          syncAccountInput: {
            addr: address,
          },
        }
      })
    }
  }, [address]);
  const { isLoginMessageSigning, openModal } = useConnect();

  useEffect(() => {
    if (isLoginMessageSigning) {
      openModal(<PendingSignLoginMessageModal />);
    }
  }, [isLoginMessageSigning]);

  return (
    <div className="app">
      <Router>
        <GlobalHooks />
        <Layout>
          <RocketLoader isLoading={isLoginMessageSigning}>
            <Pages />
          </RocketLoader>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
