import { ReactComponent as RocketIcon } from "assets/img/rocket.svg";
import { FC } from "react";
import "./rocket.sass";

type Props = {
  className?: string;
  height?: number | string;
  width?: number | string;
};

const Rocket: FC<Props> = ({ height, width }) => {
  return (
    <div className="rocket" style={{ height, width }}>
      <div className="rocket__inner">
        <RocketIcon className="rocket__icon" />
      </div>
    </div>
  );
};

export default Rocket;
