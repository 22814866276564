import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectScreenWidth } from "store/slice";

export enum Width {
  mobile,
  tablet = 640,
  smallDesktop = 961,
  desktop = 1320,
}

export const useWidth = () => {
  const screenWidth = useSelector(selectScreenWidth);

  return useMemo(() => {
    const views = {
      isMobileWidth: false,
      isTabletWidth: false,
      isSmallDesktopWidth: false,
      isDesktopWidth: false,
    };
    if (screenWidth < Width.tablet) {
      return { ...views, isMobileWidth: true };
    }
    if (screenWidth >= Width.tablet && screenWidth < Width.smallDesktop) {
      return { ...views, isTabletWidth: true };
    }
    if (screenWidth >= Width.smallDesktop && screenWidth < Width.desktop) {
      return { ...views, isSmallDesktopWidth: true };
    }
    if (screenWidth >= Width.desktop) {
      return { ...views, isDesktopWidth: true };
    }

    return views;
  }, [screenWidth]);
};
