import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { ProjectStage } from "app/enums";
import { Project } from "app/types";
import { BigNumber } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Application, isIdo } from "sdk";
import tokenService from "services/token.service";
import { useApplicationQuery } from "store/api";
import { selectProjectStage } from "store/slice";
import { useAccount, useChainId } from "wagmi";
import { useAuth } from "./use-auth";

export const useApplication = (project?: Project) => {
  const isAuth = useAuth();
  const { address } = useAccount();
  const [foundError, setFoundError] = useState<SerializedError | FetchBaseQueryError>();
  const chainId = useChainId();
  const projectStage = useSelector(selectProjectStage);
  const { data, isLoading, refetch, error } = useApplicationQuery(project?.id || "", {
    pollingInterval: 10000,
    skip:
      !isIdo(project) ||
      !isAuth ||
      !project ||
      projectStage < ProjectStage.whitelist ||
      !!foundError,
  });

  const refetchApplication = useCallback(() => {
    try {
      refetch();
    } catch (error) {
      console.error(error);
    }
  }, [refetch]);

  useEffect(() => {
    if (error) {
      try {
        setFoundError(error);
        setTimeout(refetchApplication, 10000);
      } catch (error) {
        console.error(error);
      }
    }
  }, [error]);

  useEffect(() => {
    refetchApplication();
  }, [address]);

  return useMemo(() => {
    let application: Application | undefined;
    if (data) {
      application = {
        ...data,
        amount: BigNumber.from(data.amount || 0),
        swappedAmount: BigNumber.from(data.swappedAmount || 0),
        fcfsAmount: BigNumber.from(data.fcfsAmount || 0),
      };
    }

    let isApplicationLoading = true;
    if (project) {
      isApplicationLoading = isLoading;
    }

    return {
      application,
      isApplicationLoading,
      refetchApplication,
    };
  }, [
    data,
    isLoading,
    refetchApplication,
    projectStage,
    isAuth,
    chainId,
    project,
    tokenService.tokens?.accessExpiresIn,
  ]);
};
