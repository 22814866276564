import iFail from "assets/img/icons/fail.svg";
import cn from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import "./project-lose.sass";

type Props = {
  className?: string;
  type:
  | "no-application"
  | "lose-lottery"
  | "not-redeemed_ido"
  | "investor-only"
  | "high-tier-only"
};

const ProjectLose: FC<Props> = ({ className, type = "no-application" }) => {
  const { t } = useTranslation();
  return (
    <div className={cn("project-lose", className)}>
      <div className="project-lose__item">
        <img src={iFail} alt="fail" className="project-lose__icon" width={40} height={40} />
        {type === "no-application" && (
          <div className="project-lose__title">
            {t("You did not apply for IDO or your application was rejected")}
          </div>
        )}
        {type === "lose-lottery" && (
          <div className="project-lose__title">
            {t(
              "You didn't win the allocation, but you can still participate in the FCFS. Follow the news"
            )}
            !
          </div>
        )}
        {type === "investor-only" && (
          <div className="project-lose__title">
            {t("The current round is available only to GAGARIN investors")}
          </div>
        )}
        {type === "high-tier-only" && (
          <div className="project-lose__title">
            {t("The current round is available only to Galaxy or Universe Tiers owners")}
          </div>
        )}
        {type === "not-redeemed_ido" && (
          <div className="project-lose__title">
            {t("You didn't win the allocation, thank you for participation")}!
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectLose;
