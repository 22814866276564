import { Language } from "../enums";

export enum TranslationType {
  DESCRIPTION = "description",
  VESTING_SCHEDULE = "vestingSchedule",
  TITLE = "title",
  TEXT = "text",
  LINK = "link",
}

export type Translation = {
  id: string;
  type: TranslationType;
  locale: Language;
  value: string;

  idoId?: string;
  activityId?: string;
  inoId?: string;
  nftBoxId?: string;
  nftId?: string;
  notificationId?: string;
  newsId?: string;
};
