import { NEWS_LIST_GAP_MOBILE, useNewsSwiper, useWidth } from "app/hooks";
import { Project } from "app/types";
import { FC, useRef } from "react";
import { useProjectNewsQuery } from "store/api";
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectManual from "../project-manuals/project-manual";
import ProjectNewsItem from "./project-news-item";
import "./project-news.sass";

type Props = {
  project: Project;
};

const ProjectNews: FC<Props> = ({ project }) => {
  const { isMobileWidth } = useWidth();
  const { data } = useProjectNewsQuery(project.id);
  const listRef = useRef<HTMLDivElement>(null);
  const { isSwiper, slidesPerView } = useNewsSwiper(listRef);

  return (
    <div className="project-news">
      {!isMobileWidth && <ProjectManual project={project} />}
      <div className="project-news__list" ref={listRef}>
        {data && !!data.length && (
          <>
            {isSwiper ? (
              <Swiper spaceBetween={NEWS_LIST_GAP_MOBILE} slidesPerView={slidesPerView}>
                {data.map((news, i) => (
                  <SwiperSlide key={i}>
                    <ProjectNewsItem key={news.id} news={news} />
                  </SwiperSlide>
                ))}

                <SwiperSlide />
              </Swiper>
            ) : (
              <>
                {data.map((news) => (
                  <ProjectNewsItem key={news.id} news={news} />
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectNews;
