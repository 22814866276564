import { QueryParam } from "app/enums";
import { useProjectTranslation, useQuery } from "app/hooks";
import { Project } from "app/types";
import { FC } from "react";
import { TranslationType } from "sdk";
import { DescriptionTab } from "./description-wizard";

type Props = {
  project: Project;
  selectedTab: string;
};

const Description: FC<Props> = ({ project, selectedTab }) => {
  const projectDesc = useProjectTranslation(project, TranslationType.DESCRIPTION);
  const { queryParam: queryActivityType } = useQuery(QueryParam.MARKETING_ACTIVITY_TYPE);

  return (
    <>
      {(!queryActivityType || !project.activities) && (
        <>
          {selectedTab === DescriptionTab.PROJECT_DESC && (
            <div
              className="description-wizard__body"
              dangerouslySetInnerHTML={{
                __html: projectDesc,
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default Description;
