import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Ido, ROOT_ADDRESSES_MAP } from "sdk";
import { selectProjectStage } from "store/slice";
import { useChainId } from "wagmi";

import { useBlockchainProjectQuery } from ".";
import { useAuth } from "./use-auth";

export const useBlockchainProject = (
  project: Ido | undefined,
  address: string = ROOT_ADDRESSES_MAP[project?.network || 0]
) => {
  const isAuth = useAuth();
  const [errorFound, setErrorFound] = useState<boolean>();
  const chainId = useChainId();
  const projectStage = useSelector(selectProjectStage);

  const { data, isLoading, refetch, error } = useBlockchainProjectQuery({
    projectId: project?.id,
  });

  useEffect(() => {
    if (error) setErrorFound(!!error);
  }, [error]);

  return useMemo(
    () => ({
      blockchainProject: data,
      isBlockchainProjectLoading: isLoading,
      refetchBlockchainProject: refetch,
    }),
    [project, isAuth, data, isLoading, refetch, chainId, address, projectStage]
  );
};
