import { LIVE_FILTER, MAIN_PROJECTS_FILTERS } from "app/constants";
import { QueryParam } from "app/enums";
import { ActiveFilter } from "app/types/projects-filters";
import { useMemo } from "react";
import { useQuery } from "./use-query";

export const useActiveFilter = () => {
  const { queryParam } = useQuery(QueryParam.PROJECT_FILTER);
  return useMemo(() => {
    const isCorrectFilter = queryParam && MAIN_PROJECTS_FILTERS.includes(queryParam);
    if (!isCorrectFilter) return LIVE_FILTER;

    return queryParam as ActiveFilter;
  }, [queryParam]);
};
