import { Ido, ROOT_ADDRESSES_MAP } from "sdk";
import rootContract from "services/root.contract";
import { useMutation } from "./use-mutation";

export const useClaimMutation = () => {
  const claimMutation = async ({ id, network }: Ido) => {
    return await rootContract.claimProjectPopulate({
      address: ROOT_ADDRESSES_MAP[network],
      id,
      chainId: network,
    });
  };
  return useMutation(claimMutation);
};
