import { gql } from "@apollo/client";
import { useTonAddress } from "@tonconnect/ui-react";
import WalletTon from "app/components/header/wallet-ton";
import cn from "classnames";
import { useAccountQuery } from "graphql/@generated/graphql";
import { TierTypes } from "graphql/account-query";
import { useTranslation } from "react-i18next";
import { StakingWidget } from "staking-widget/staking-widget";
import { Address } from "ton";
import StakingTabs from "./components/v2/staking/staking-tabs";
import StakingConnector from "./connector/staking-connector";
import VestingConnector from "./connector/vesting-connector";
import { standardizeTonAddress } from "./utils/ton";

const LINK_BALANCES_MUTATION = gql`
  mutation LinkBalances($evmAddress: String!, $tvmAddress: String!) {
    linkBalances(evmAddress: $evmAddress, tvmAddress: $tvmAddress) {
      id
    }
  }
`;

export default function TonAccountBlock() {
  // const router = useHistory();
  const { t } = useTranslation();

  const myAddrRaw = useTonAddress(false);

  const { data: getAccountData, refetch: refetchAccountData } = useAccountQuery({
    variables: {
      getAccountInput: {
        addr: myAddrRaw ?? standardizeTonAddress(myAddrRaw),
      },
    },
    skip: !myAddrRaw,
  });

  return (
    <>
      {myAddrRaw && (
        <div>
          <div className="mx-auto max-w-screen-xl">
            <div
              className={cn(
                "relative grid grid-cols-1 gap-8 max-lg:max-w-[500px] lg:grid-cols-1 xls:grid-cols-2"
              )}
            >
              <div className="flex flex-col gap-8">
                {myAddrRaw && (
                  <StakingTabs
                    stakingNewChild={<StakingWidget />}
                    stakingOldChild={<StakingConnector myAddr={Address.parse(myAddrRaw)} />}
                  />
                )}
              </div>
              <div className="flex flex-col gap-8">
                {myAddrRaw && (
                  <VestingConnector
                    myAddr={Address.parse(myAddrRaw)}
                    myTier={getAccountData?.account?.tier as TierTypes}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!myAddrRaw && (
        <div className="w-full flex justify-content-center items-center">
          <WalletTon />
        </div>
      )}
    </>
  );
}
