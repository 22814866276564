import { Base64 } from "@tonconnect/protocol";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { TierTypes } from "graphql/account-query";
import { useEffect, useMemo, useState } from "react";
import { Address, beginCell, toNano } from "ton-core";
import { Vesting } from "../components/v2";
import { tonClient } from "../ton-services/ton";
import { sleep } from "../ton-services/utils";

type TProps = {
  className?: string;
  myAddr: Address;
  myTier?: TierTypes;
};

const VestingConnector: React.FC<TProps> = ({ className, myAddr, myTier }) => {
  const [tonConnectUI] = useTonConnectUI();

  const [isFetching, setFetching] = useState(false);
  const [nextUnlock, setNextUnlock] = useState(new Date());
  const [totalAmount, setTotalAmount] = useState(BigInt(0));
  const [received, setReceived] = useState(BigInt(0));
  const [remaining, setRemaining] = useState(BigInt(0));
  const [claimable, setClaimable] = useState(BigInt(0));

  const [isAddrAddedToVesting, setAddrAddedToVesting] = useState(false);

  const fetch = async (triggerFetching: boolean = true) => {
    try {
      if (triggerFetching) setFetching(true);
      const { stack: getClaimInfoStack } = await tonClient.callGetMethod(
        Address.parse(process.env.REACT_APP_PUBLIC_TON_VESTING_ADDR!),
        "get_claim_info",
        [
          { type: "slice", cell: beginCell().storeAddress(myAddr).endCell() },
          { type: "int", value: BigInt(process.env.REACT_APP_PUBLIC_TON_VESTING_SCHEDULE_ID!) },
        ]
      );
      const [amount, released, availableToClaim, nextClaimDate, stakingReward] = [
        getClaimInfoStack.readBigNumber(),
        getClaimInfoStack.readBigNumber(),
        getClaimInfoStack.readBigNumber(),
        getClaimInfoStack.readBigNumber(),
        getClaimInfoStack.readBigNumber(),
      ];
      setAddrAddedToVesting(true);
      setNextUnlock(new Date(+(nextClaimDate * BigInt(1000)).toString()));
      setTotalAmount(amount + stakingReward);
      setReceived(released);
      setRemaining(amount - released);
      setClaimable(availableToClaim);
    } catch (err) {
      setAddrAddedToVesting(false);
      console.log("VestingConnector fetch get_claim_info err:", err);
    }
    if (triggerFetching) setFetching(false);
  };

  const sendClaim = async () => {
    try {
      await tonConnectUI.sendTransaction({
        validUntil: Date.now() + 1000000,
        messages: [
          {
            address: process.env.REACT_APP_PUBLIC_TON_VESTING_ADDR!,
            amount: toNano("1").toString(),
            payload: Base64.encode(
              beginCell().storeUint(63, 32).storeUint(0, 64).endCell().toBoc()
            ),
          },
        ],
      });
      await sleep(15000);
    } catch (err) {
      console.error("send staking error", err);
    }
  };

  useMemo(() => {
    if (myAddr && myTier) fetch();
  }, [myAddr.toRawString(), myTier]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (myAddr) fetch(false);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Vesting
      nextUnlock={nextUnlock}
      totalAmount={totalAmount}
      received={received}
      remaining={remaining}
      claimable={claimable}
      isFetching={isFetching}
      // todo: fix early alert
      isEarlyAlert={false}
      myTier={myTier}
      isAddrAddedToVesting={isAddrAddedToVesting}
      onClaimClick={sendClaim}
    />
  );
};

export default VestingConnector;
