import { LIVE_FILTER, UPCOMING_FILTER } from "app/constants";
import { MainProjectsFilter } from "app/types/projects-filters";
import { ChainId, DatabaseFilter, Ido, ProjectStatus, Where } from "sdk";

export const getDefaultIdosFilter = (): DatabaseFilter<Ido> => {
  return {
    order: ["weight DESC"],
    include: ["links", "exchangeCurrency", "claimCurrency", "payments", "activities"],
    where: { visibility: true },
  };
};

export const getIdosAdFilter = (title?: string, limit: number = 3): DatabaseFilter<Ido> => {
  const defaultFilter = getDefaultIdosFilter();

  const upcomingFilter = getAndIdosFilter(UPCOMING_FILTER)!;
  const liveFilter = getAndIdosFilter(LIVE_FILTER)!;

  const filter = {
    ...defaultFilter,
    // for some reason not working with id
    where: {
      title: { neq: title },
      ...defaultFilter.where,
      and: [{ or: [...upcomingFilter, ...liveFilter] }],
    },
    limit,
  };

  return filter;
};

const getAndIdosFilter = (mainActiveFilter: MainProjectsFilter): Where<Ido>[] | undefined => {
  switch (mainActiveFilter) {
    case "Upcoming":
      return [
        {
          or: [{ whitelistStartDate: { gt: Date.now() } }, { whitelistStartDate: 0 }],
        },
        {
          status: ProjectStatus.NOT_DEPLOYED,
        },
      ];
    case "Live":
      return [
        {
          or: [
            {
              and: [
                { whitelistStartDate: { lte: Date.now() } },
                { whitelistStartDate: { neq: 0 } },
                { status: ProjectStatus.NOT_DEPLOYED },
              ],
            },
            {
              and: [
                { whitelistStartDate: { lte: Date.now() } },
                { status: ProjectStatus.UPCOMING },
              ],
            },
            {
              and: [
                { whitelistStartDate: { lte: Date.now() } },
                { swapEndDate: { gt: Date.now() } },
                { status: ProjectStatus.REDEEM },
              ],
            },
            {
              and: [
                { whitelistStartDate: { lte: Date.now() } },
                { fcfsEndDate: { gt: Date.now() } },
                { status: ProjectStatus.HIGH_TIER_FCFS },
              ],
            },
            {
              and: [
                { whitelistStartDate: { lte: Date.now() } },
                { fcfsEndDate: { gt: Date.now() } },
                { status: ProjectStatus.FCFS },
              ],
            },
          ],
        },
      ];
    case "Closed":
      return [
        {
          or: [
            { status: ProjectStatus.CLAIM },
            { status: ProjectStatus.DISTRIBUTED },
            {
              and: [{ fcfsEndDate: { lte: Date.now() } }, { status: ProjectStatus.FCFS }],
            },
            {
              and: [{ swapEndDate: { lte: Date.now() } }, { status: ProjectStatus.REDEEM }],
            },
          ],
        },
      ];
    default:
      return undefined;
  }
};

export const getCscAndFilter = () => {
  return [
    {
      or: [{ network: ChainId.CSC_MAINNET }, { network: ChainId.CSC_TESTNET }],
    },
  ];
};

export const getIdosFilter = (mainActiveFilter: MainProjectsFilter): DatabaseFilter<Ido> => {
  const defaultFilter = getDefaultIdosFilter();

  switch (mainActiveFilter) {
    case "Upcoming":
      return {
        ...defaultFilter,
        where: {
          ...defaultFilter.where,
          and: getAndIdosFilter(mainActiveFilter),
        },
      };

    case "Live":
      return {
        ...defaultFilter,
        where: {
          ...defaultFilter.where,
          and: getAndIdosFilter(mainActiveFilter),
        },
      };

    case "Closed":
      return {
        ...defaultFilter,
        where: {
          ...defaultFilter.where,
          and: getAndIdosFilter(mainActiveFilter),
        },
      };

    default:
      return defaultFilter;
  }
};
