import { Base64 } from "@tonconnect/protocol";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { BigNumber, BigNumberish } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { Address, Dictionary, beginCell, toNano } from "ton-core";
import { Staking } from "../components/v2";
import { LockPool, tonClient } from "../ton-services/ton";
import { sleep } from "../ton-services/utils";
import { TPool, TStake } from "../types/lock-pool";

type TProps = {
  className?: string;
  myAddr: Address;
};

const StakingConnector: React.FC<TProps> = ({ className, myAddr }) => {
  const [isFetching, setFetching] = useState(false);
  const [tonConnectUI] = useTonConnectUI();

  const [myGgrWalletAddr, setMyGgrWalletAddr] = useState<Address>();
  const [myGgrWalletBalance, setMyGgrWalletBalance] = useState<bigint>(BigInt(0));
  const [pools, setPools] = useState<TPool[]>([]);
  const [stake, setStake] = useState<TStake>();

  const fetch = async (triggerFetching: boolean = true) => {
    if (triggerFetching) setFetching(true);
    try {
      const { stack: getWalletAddressStack } = await tonClient.callGetMethod(
        Address.parse(process.env.REACT_APP_PUBLIC_TON_GGR_ADDR!),
        "get_wallet_address",
        [{ type: "slice", cell: beginCell().storeAddress(myAddr).endCell() }]
      );
      const [addr] = [getWalletAddressStack.readAddress()];
      setMyGgrWalletAddr(addr);

      const { state: myGgrWalletState } = await tonClient.getContractState(addr);
      if (myGgrWalletState === "active") {
        const { stack: getWalletDataStack } = await tonClient.callGetMethod(
          addr,
          "get_wallet_data"
        );
        const [amount] = [getWalletDataStack.readBigNumber()];
        setMyGgrWalletBalance(amount);
      }
    } catch (err) {
      console.log("StakingConnector fetch err:", err);
    }

    try {
      const { stack: getPoolsStack } = await tonClient.callGetMethod(
        Address.parse(process.env.REACT_APP_PUBLIC_TON_LOCK_POOL_ADDR!),
        "get_pools",
        []
      );
      const [poolsCell] = [getPoolsStack.readCell()];
      const poolsDict = Dictionary.loadDirect(
        Dictionary.Keys.BigInt(128),
        Dictionary.Values.BigInt(128),
        poolsCell
      );
      setPools(
        poolsDict.keys().map((key) => ({
          lockSeconds: key,
          rewardPercent: poolsDict.get(key)!,
        }))
      );
    } catch (err) {
      console.log("StakingConnector fetch err:", err);
    }

    try {
      const { stack: getStakeStack } = await tonClient.callGetMethod(
        Address.parse(process.env.REACT_APP_PUBLIC_TON_LOCK_POOL_ADDR!),
        "get_stake",
        [{ type: "slice", cell: beginCell().storeAddress(myAddr).endCell() }]
      );

      const [stakeCell] = [getStakeStack.readCell().beginParse()];

      const lockSeconds = stakeCell.loadUintBig(128);
      const updatedAt = stakeCell.loadUintBig(128);
      const amount = stakeCell.loadCoins();

      const { stack: calcRewardAmountStack } = await tonClient.callGetMethod(
        Address.parse(process.env.REACT_APP_PUBLIC_TON_LOCK_POOL_ADDR!),
        "calc_reward_amount",
        [{ type: "slice", cell: beginCell().storeAddress(myAddr).endCell() }]
      );
      const [rewardAmount] = [calcRewardAmountStack.readBigNumber()];
      setStake({ lockSeconds, updatedAt, amount, rewardAmount });
      setFetching(false);
    } catch (err) {
      setStake(undefined);
      console.log("StakingConnector fetch err:", err);
    }
    if (triggerFetching) setFetching(false);
  };

  const sendStake = async (amount: BigNumberish, lockSeconds: BigNumberish) => {
    const msgBody = LockPool.buildTransferNotificationStakeMsgBody(lockSeconds);

    try {
      const body = beginCell()
        .storeUint(0xf8a7ea5, 32)
        .storeUint(0, 64)
        .storeCoins(BigNumber.from(amount).toBigInt())
        .storeAddress(Address.parse(process.env.REACT_APP_PUBLIC_TON_LOCK_POOL_ADDR!))
        .storeAddress(myAddr)
        .storeDict(Dictionary.empty())
        .storeCoins(toNano("0.5"))
        .storeMaybeRef(msgBody)
        .endCell();

      await tonConnectUI.sendTransaction({
        validUntil: Date.now() + 1000000,
        messages: [
          {
            address: myGgrWalletAddr!.toRawString(),
            amount: toNano("1").toString(),
            payload: Base64.encode(body.toBoc()),
          },
        ],
      });
      await sleep(15000);
    } catch (err) {
      console.error("send staking error", err);
    }
  };

  const sendWithdraw = async () => {
    const body = beginCell().storeUint(0xa49b63c9, 32).storeUint(0, 64).endCell();
    try {
      await tonConnectUI.sendTransaction({
        validUntil: Date.now() + 1000000,
        messages: [
          {
            address: process.env.REACT_APP_PUBLIC_TON_LOCK_POOL_ADDR!,
            amount: toNano("1").toString(),
            payload: Base64.encode(body.toBoc()),
          },
        ],
      });
      await sleep(15000);
    } catch (err) {
      console.error("send staking error", err);
    }
  };

  useMemo(() => {
    if (myAddr) fetch();
  }, [myAddr.toRawString()]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (myAddr) fetch(false);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Staking
      myGgrWalletBalance={myGgrWalletBalance}
      pools={pools}
      stake={stake}
      isFetching={isFetching}
      onSubmitStake={sendStake}
      onWithdraw={sendWithdraw}
    />
  );
};

export default StakingConnector;
