import iReqOk from "assets/img/icons/req-ok.svg";
import { useTranslation } from "react-i18next";

type TProps = { className?: string };

function ProjectSwap__Win({ className = "" }: TProps) {
  const { t } = useTranslation();
  return (
    <div className="project-swap__win">
      <img src={iReqOk} alt="ok" />
      {t("You have won the allocation")}!
      <br />
      {t("Please prepare funds for swap")}
    </div>
  );
}

export default ProjectSwap__Win;
