import {
  useCurrency,
  useExchangeRate,
  useProjectVestingSchedule,
  useTotalRaiseAmount,
  useTotalRaiseTokensAmount
} from "app/hooks";
import iArrowRight from "assets/img/icons/arrow-right.svg";
import iDatepicker from "assets/img/icons/date-picker.svg";
import { FC, useMemo } from "react";
import { Ido } from "sdk";
import ProjectInfo from "../project/project-info";
import IdoExchangeNetworksList from "./ido-exchange-networks-list";
import IdoFill from "./ido-fill";

type Props = {
  className?: string;
  ido: Ido;
};

const IdoInfo: FC<Props> = ({ className = "", ido }) => {
  const totalRaiseAmount = useTotalRaiseAmount(ido);
  const totalRaiseTokensAmount = useTotalRaiseTokensAmount(ido);
  const vestingSchedule = useProjectVestingSchedule(ido);
  const exchangeRate = useExchangeRate(ido, 'auto');
  const { claimCurrencySymbol, exchangeCurrencySymbol } = useCurrency(ido);

  const items = useMemo(
    () => [
      {
        itemKey: "Exchange Rate",
        children: (
          <>
            {exchangeRate === "TBA"
              ? exchangeRate
              : `1 ${claimCurrencySymbol} = ${exchangeRate} ${exchangeCurrencySymbol}`}
          </>
        ),
      },
      {
        itemKey: "Swap Amount",
        children: (
          <>
            {exchangeRate === "TBA"
              ? exchangeRate
              : `${totalRaiseTokensAmount} ${claimCurrencySymbol}`}
          </>
        ),
      },
      {
        itemKey: "Total Raise",
        children: (
          <>
            {totalRaiseAmount !== "TBA" && "$"}
            {totalRaiseAmount}
          </>
        ),
      },
      {
        itemKey: "Exchange Networks",
        children: (
          <>
            {exchangeRate === "TBA"
              ? exchangeRate
              : <IdoExchangeNetworksList project={ido} />
            }
          </>
        ),
      },
    ],
    [
      totalRaiseAmount,
      claimCurrencySymbol,
      totalRaiseTokensAmount,
      exchangeRate,
      exchangeCurrencySymbol,
    ]
  );

  return (
    <ProjectInfo project={ido} additionalItems={items}>
      {vestingSchedule && (
        <div
          className="project-info__schedule"
        // onClick={() => openModal(<ProjectPaymentShedule project={project} />)}
        >
          <img src={iDatepicker} alt="" />
          {vestingSchedule}
          <img src={iArrowRight} alt="" />
        </div>
      )}
      <IdoFill className="project-item__progress" ido={ido} />
    </ProjectInfo>
  );
};

export default IdoInfo;
