export type TTonAmbaNftR1 = {
  id: number;
  index: number;
  address: string;
  image: string;
  name: string;
  type: TonAmbaNftR1Type;
};

export enum TonAmbaNftR1Type {
  RARE = 'RARE',
  EPIC = 'EPIC'
}
