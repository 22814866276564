import { ApplicationStatus, useActions, useApplicationStatus } from "app/hooks";
import iReqEmpty from "assets/img/icons/req-empty.svg";
import iReqOk from "assets/img/icons/req-ok.svg";
import iReqFail from "assets/img/status-reject.svg";
import iWait from "assets/img/wait-ellipse.png";
import cn from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Ido } from "sdk";
import Button from "../button/button";
import CheckAuth from "../check-auth";
import SocialActivities from "../social-activities/social-activities";
import "./project-requirements.sass";

type Props = {
  className?: string;
  project: Ido;
};

const ProjectRequirements: FC<Props> = ({ className = "", project }: Props) => {
  const { t } = useTranslation();
  const { openModal } = useActions();

  const applicationStatus = useApplicationStatus(project);

  return (
    <div className={cn("project-requirements", className)}>
      <div className="project-requirements__title">{t("Requirements")}</div>
      <div className="project-requirements__list">
        <div className="project-requirements__item">
          <div className="project-requirements__item-key">
            <img src={iReqOk} alt="ok" />
            {t("Tier not required")}
          </div>
          <div className="project-requirements__item-value"></div>
        </div>
        <div className="project-requirements__item">
          <div
            className={cn("project-requirements__item-key", {
              _wait: project.kyc && applicationStatus === ApplicationStatus.interview,
              _fail: project.kyc && applicationStatus === ApplicationStatus.rejected,
            })}
          >
            {project.kyc ? (
              <>
                {applicationStatus === ApplicationStatus.interview && (
                  <img src={iWait} alt="wait" className="_g-rotate-anim" />
                )}
                {applicationStatus === ApplicationStatus.notCreated && (
                  <img src={iReqEmpty} alt="empty" />
                )}
                {applicationStatus === ApplicationStatus.approved && <img src={iReqOk} alt="ok" />}
                {applicationStatus === ApplicationStatus.rejected && (
                  <img src={iReqFail} alt="fail" />
                )}
                KYC {t("required")}
              </>
            ) : (
              <>
                <img src={iReqOk} alt="ok" />
                KYC {t("not required")}
              </>
            )}
          </div>
          <div className="project-requirements__item-value"></div>
        </div>
        <div className="project-requirements__item">
          <div
            className={cn("project-requirements__item-key", {
              _wait: applicationStatus === ApplicationStatus.interview,
              _fail: applicationStatus === ApplicationStatus.rejected,
            })}
          >
            {applicationStatus === ApplicationStatus.interview && (
              <img src={iWait} alt="wait" className="_g-rotate-anim" />
            )}
            {applicationStatus === ApplicationStatus.notCreated && (
              <img src={iReqEmpty} alt="empty" />
            )}
            {applicationStatus === ApplicationStatus.approved && <img src={iReqOk} alt="ok" />}
            {applicationStatus === ApplicationStatus.rejected && <img src={iReqFail} alt="fail" />}
            {t("Social activities")}
          </div>
          <div className="project-requirements__item-value">
            <CheckAuth buttonClassName="_s">
              {applicationStatus === ApplicationStatus.notCreated && (
                <Button
                  className="_s"
                  onClick={() => openModal(<SocialActivities project={project} />)}
                >
                  {t("View")}
                </Button>
              )}
            </CheckAuth>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectRequirements;
