import { useMemo } from "react";
import { Ido } from "sdk";
import { useApplication } from "./use-application";
import { useIdoTotalRedeemed } from "./use-ido-redeemed";
import { useInvestorInfo } from "./use-investor-info";
import { RefundStage, useRefundStage } from "./use-refund-stage";

export enum ClaimStage {
  C1_NO_APP = 1,
  C2_HAS_APP_NOT_REDEEM,
  C3_HAS_APP_REFUNDED,
  C4_CLAIM,
}

export const useIdoClaimStage = (ido: Ido) => {
  const refundStage = useRefundStage(ido);
  const { application } = useApplication(ido);
  const { investorInfo } = useInvestorInfo(ido);
  const redeemed = useIdoTotalRedeemed(ido);

  return useMemo(() => {
    if (
      refundStage !== RefundStage.R5_REFUNDED &&
      !application &&
      (!investorInfo || investorInfo.redeemed.isZero())
    ) {
      return ClaimStage.C1_NO_APP;
    }
    if (
      refundStage !== RefundStage.R5_REFUNDED &&
      application &&
      redeemed.isZero() &&
      (!investorInfo || investorInfo.redeemed.isZero())
    ) {
      return ClaimStage.C2_HAS_APP_NOT_REDEEM;
    }
    if (refundStage === RefundStage.R5_REFUNDED) {
      return ClaimStage.C3_HAS_APP_REFUNDED;
    }
    return ClaimStage.C4_CLAIM;
  }, [ido, refundStage, application, investorInfo, redeemed]);
};
