import { useMutation } from "app/hooks/root-contract/mutation/use-mutation";
import stakingContract from "services/staking.contract";
import { useChainId } from "wagmi";

export const useWithdrawMutation = () => {
  const chainId = useChainId();
  const withdrawMutation = async () => {
    return await stakingContract.withdrawPopulate({
      address: process.env.REACT_APP_STAKING_CONTRACT_ADDR,
      chainId: chainId,
    });
  };
  return useMutation(withdrawMutation);
};
