import { useConnectModal } from '@rainbow-me/rainbowkit';
import Button from "app/components/button/button";
import { useAuth } from "app/hooks";
import { ReactComponent as IconMetamask } from "assets/img/icons/icon-metamask.svg";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
  buttonClassName?: string;
  onConnect?: () => void;
  maxWidth?: "limited" | "unlimited";
};

const CheckAuth: FC<Props> = ({
  onConnect,
  className,
  buttonClassName,
  maxWidth = "limited",
  children,
}) => {
  const { t } = useTranslation();
  const isAuth = useAuth();
  const { openConnectModal } = useConnectModal()

  const style = useMemo(() => {
    if (maxWidth === "limited") {
      return { maxWidth: 250 };
    }
  }, [maxWidth]);

  return (
    <>
      {!isAuth ? (
        <div className={className} style={style}>
          {/* <Button className={buttonClassName} onClick={onClick}>
            {t("Connect Wallet")}
          </Button> */}
          {/* <Web3Button /> */}
          {/* <ConnectButton /> */}
          <Button onClick={openConnectModal}>
            <div className="flex items-center justify-center gap-2">
              <IconMetamask className="wallet__img" /> {t("Connect Wallet")}{" "}
            </div>
          </Button>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default CheckAuth;
