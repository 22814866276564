import { useProjectImage, useProjectTranslation } from "app/hooks";
import { FC } from "react";
import { ImageType, News, TranslationType } from "sdk";
import "./project-news-item.sass";

type Props = {
  news: News;
};

const ProjectNewsItem: FC<Props> = ({ news }) => {
  const banner = useProjectImage(ImageType.BANNER, news.id);
  const title = useProjectTranslation(news, TranslationType.TITLE);
  const description = useProjectTranslation(news, TranslationType.DESCRIPTION);
  const link = useProjectTranslation(news, TranslationType.LINK);

  return (
    <a href={link} className="project-news-item" target='_blank' rel="noreferrer">
      {banner && (
        <div className="project-news-item__banner" style={{ backgroundImage: `url(${banner})` }} />
      )}
      <div className="project-news-item__inner">
        <div className="project-news-item__title" dangerouslySetInnerHTML={{ __html: title }} />
        <div
          className="project-news-item__desc"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </a>
  );
};

export default ProjectNewsItem;
