import { getRootContract } from "app/utils/contract-helper";
import { BigNumber } from "ethers";
import { ChainId, getHexFromId } from "sdk";
import { useChainId, useContractRead } from "wagmi";

export const useBlockchainProjectQuery = ({ projectId }: { projectId?: string }) => {
  const chainId = useChainId() as ChainId;
  const blockchainProjectResponse = useContractRead({
    chainId,
    ...getRootContract(chainId),
    functionName: "projectById",
    enabled: Boolean(projectId),
    args: projectId ? [BigNumber.from(getHexFromId(projectId)).toBigInt()] : undefined,
    watch: true,
    select(data) {
      return {
        id: BigNumber.from(data[0]),
        props: {
          token: data[1].token,
          claimToken: data[1].claimToken,
          price: BigNumber.from(data[1].price),
          claimDates: data[1].claimDates.map((el) => BigNumber.from(el)),
          claimPercent: data[1].claimPercent.map((el) => BigNumber.from(el)),
        },
        isActive: data[2],
      };
    },
  });
  return blockchainProjectResponse;
};
