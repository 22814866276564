import { useEffect, useMemo, useState } from "react";
import { Ido, ImageType } from "sdk";
import { useActions } from "./use-actions";

export const getProjectImage = (type: ImageType, ownerId?: string): Promise<string> => {
  return new Promise((resolve) => {
    if (!ownerId) return resolve("");

    const image = new Image();
    image.src = `${process.env.REACT_APP_API_ROOT}/images/${ownerId}/${type}`;
    image.onload = () => resolve(image.src);
    image.onerror = () => resolve("");
  });
};

export const useImages = (types: ImageType[], ownerIds: (string | undefined)[]) => {
  const [urls, setUrls] = useState<string[]>([""]);

  const setImageUrls = () => {
    return ownerIds.map((ownerId, i) => {
      if (!ownerId) return;

      const image = new Image();
      image.src = `${process.env.REACT_APP_API_ROOT}/images/${ownerId}/${types[i]}`;
      image.onload = () => setUrls((state) => [...state.slice(0, i), image.src, ...state.slice(i)]);
      image.onerror = () => setUrls((state) => [...state.slice(0, i), "", ...state.slice(i)]);

      return image;
    });
  };

  useEffect(() => {
    const images = setImageUrls();

    return () => {
      images.forEach((image) => {
        if (!image) return;

        image.onload = null;
        image.onerror = null;
      });
    };
  }, [types, ownerIds]);

  return urls;
};

export const useProjectImage = (type: ImageType, ownerId?: string) => {
  const [url, setUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true); // wip

  const setImageUrl = async () => {
    const url = await getProjectImage(type, ownerId);
    setUrl(url);
    setIsLoading(false);
  };

  useEffect(() => {
    setImageUrl();
  }, [type, ownerId]);

  return url;
};

export const useIdoImages = (ido?: Ido) => {
  const projectBanner = useProjectImage(ImageType.BANNER, ido?.id);
  const projectLogo = useProjectImage(ImageType.LOGO, ido?.id);
  const projectTokenLogo = useProjectImage(ImageType.TOKEN_LOGO, ido?.id);

  return useMemo(() => {
    return { projectBanner, projectLogo, projectTokenLogo };
  }, [ido, projectBanner, projectLogo, projectTokenLogo]);
};

export const useUpdateIdoImages = (project?: Ido) => {
  const { setProjectBanner, setProjectLogo, setProjectTokenLogo } = useActions();
  const { projectBanner, projectLogo, projectTokenLogo } = useIdoImages(project);

  useEffect(() => {
    if (projectBanner) setProjectBanner(projectBanner);
    if (projectLogo) setProjectLogo(projectLogo);
    if (projectTokenLogo) setProjectTokenLogo(projectTokenLogo);
  }, [projectBanner, projectLogo, projectTokenLogo]);
};
