import { formatBigNumber, getTokensAmount } from "app/utils";
import { BigNumber } from "ethers";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Ido, ProjectStatus } from "sdk";
import { selectProjectStage } from "store/slice";
import { useApplication } from "./use-application";
import { useInvestorInfo } from "./use-investor-info";

export const useIdoTotalRedeemed = (ido: Ido) => {
  const { application } = useApplication(ido);

  return useMemo(() => {
    if (!application) return BigNumber.from(0);

    return application.swappedAmount.add(application.fcfsAmount);
  }, [ido, application]);
};

export const useIdoRedeemedAmountView = (ido: Ido): string => {
  const { application } = useApplication(ido);
  const redeemed = useIdoTotalRedeemed(ido);

  return useMemo(() => {
    if (!application) return "0";

    return formatBigNumber(redeemed);
  }, [ido, application, redeemed]);
};

export const useIdoRedeemedTokensAmountView = (ido: Ido): string => {
  const { application } = useApplication(ido);
  const { investorInfo } = useInvestorInfo(ido);
  const redeemed = useIdoTotalRedeemed(ido);
  const projectStage = useSelector(selectProjectStage);

  return useMemo(() => {
    let redeemedTokensAmount = BigNumber.from(0);

    if (ido.status === ProjectStatus.CLAIM) {
      if (investorInfo) {
        redeemedTokensAmount = investorInfo.redeemedTokens;
      }
    } else {
      if (application) {
        redeemedTokensAmount = getTokensAmount(ido.exchangeRate, redeemed);
      }
    }

    return formatBigNumber(redeemedTokensAmount);
  }, [application, ido, redeemed, investorInfo, projectStage]);
};

export const useIdoRedeemed = (ido: Ido) => {
  const redeemedAmountView = useIdoRedeemedAmountView(ido);
  const redeemedTokensAmountView = useIdoRedeemedTokensAmountView(ido);

  return useMemo(
    () => ({ redeemedAmountView, redeemedTokensAmountView }),
    [redeemedAmountView, redeemedTokensAmountView]
  );
};
