import { useProjectTasks } from "app/hooks";
import { Project } from "app/types";
import { ReactComponent as TelegramTaskIcon } from "assets/img/telegram-task.svg";
import { ReactComponent as TwitterTaskIcon } from "assets/img/twitter-task.svg";
import { FC } from "react";

type Props = {
  project: Project;
  type: "telegram" | "twitter";
};

export const SocialActivitiesLinksList: FC<Props> = ({ project, type }) => {
  const projectTasks = useProjectTasks(project);

  return (
    <div className="social-activities__list">
      {projectTasks
        ?.filter((task) => task.name === type)
        .map((task) => (
          <a
            className="social-activities__list-item"
            key={task.id}
            href={task.link}
            target="_blank"
            rel="noreferrer"
          >
            {type === "telegram" && (
              <TelegramTaskIcon className="social-activities__list-item-icon" />
            )}
            {type === "twitter" && (
              <TwitterTaskIcon className="social-activities__list-item-icon" />
            )}
            @{task.link.split("/").pop()}
          </a>
        ))}
      {type === "telegram" && (
        <>
          <a
            className="social-activities__list-item"
            href={"https://t.me/gagarin_launchpad"}
            target="_blank"
            rel="noreferrer"
          >
            <TelegramTaskIcon className="social-activities__list-item-icon" />
            @gagarin_launchpad
          </a>
          <a
            className="social-activities__list-item"
            href={"https://t.me/+vfh1bbErbOxlYTky"}
            target="_blank"
            rel="noreferrer"
          >
            <TelegramTaskIcon className="social-activities__list-item-icon" />
            @Gagarin_Launchpad_Chat
          </a>{" "}
        </>
      )}
      {type === "twitter" && (
        <a
          className="social-activities__list-item"
          href={"https://twitter.com/GAGARIN_World"}
          target="_blank"
          rel="noreferrer"
        >
          <TwitterTaskIcon className="social-activities__list-item-icon" />
          @GAGARIN_World
        </a>
      )}
    </div>
  );
};
