import { ACTIVITY_DATE_FORMAT } from "app/constants";
import { ActivityStatus } from "app/enums";
import i18next from "app/localisation/config";
import { findTranslation } from "app/utils";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  getArrayOfUniqueObjects,
  MarketingActivity,
  MarketingActivityType,
  TranslationType,
} from "sdk";

export const getActivityStatus = (activity?: MarketingActivity) => {
  if (!activity) {
    return { buttonText: "", activityStatus: ActivityStatus.ENDED };
  }

  const { startDate, endDate } = activity;

  if (startDate <= Date.now() && (endDate > Date.now() || !endDate)) {
    return { buttonText: "Now", activityStatus: ActivityStatus.NOW };
  }
  if (startDate >= Date.now() || !startDate) {
    return {
      buttonText: moment(startDate).locale(i18next.language).format(ACTIVITY_DATE_FORMAT),
      activityStatus: ActivityStatus.NOT_STARTED,
    };
  }

  return {
    buttonText: moment(endDate).locale(i18next.language).format(ACTIVITY_DATE_FORMAT),
    activityStatus: ActivityStatus.ENDED,
  };
};

export const useSortedMarketingActivities = (activitiesList?: MarketingActivity[]) => {
  return useMemo(() => {
    if (!activitiesList) return [];

    const activitiesCopy = [...activitiesList];

    return activitiesCopy.sort((a, b) => {
      const statusA = getActivityStatus(a);
      const statusB = getActivityStatus(b);

      return statusA.activityStatus < statusB.activityStatus ? -1 : 1;
    });
  }, [activitiesList]);
};

export const useMarketingActivitiesList = (activitiesList?: MarketingActivity[]) => {
  const sortedActivities = useSortedMarketingActivities(activitiesList);

  return useMemo(() => getArrayOfUniqueObjects(sortedActivities, "type"), [sortedActivities]);
};

export const useMarketingActivityContent = (activity?: MarketingActivity) => {
  const { i18n } = useTranslation();

  return useMemo(() => {
    if (!activity) {
      return { text: "", title: "", startDate: "TBA", endDate: "TBA" };
    }

    const { translations, text, title } = activity;

    if (!translations || !translations.length) {
      return { text, title };
    }
    const projectActivityText = findTranslation(TranslationType.TEXT, translations);
    const projectActivityTitle = findTranslation(TranslationType.TITLE, translations);

    return {
      text: projectActivityText || text,
      title: projectActivityTitle || title,
      startDate: activity.startDate
        ? moment(activity.startDate).locale(i18n.language).format("DD MMMM")
        : "TBA",
      endDate: activity.endDate
        ? moment(activity.endDate).locale(i18n.language).format("DD MMMM")
        : "TBA",
    };
  }, [activity, i18n.language]);
};

export const useCurrentMarketingActivity = (
  activityType: MarketingActivityType | string | null,
  activitiesList?: MarketingActivity[]
) => {
  const sortedActivities = useSortedMarketingActivities(activitiesList);

  return useMemo(() => {
    const [currentActivity] = sortedActivities.filter(({ type }) => type === activityType);
    const currentActivityStatus = getActivityStatus(currentActivity);

    return { ...currentActivityStatus, currentActivity };
  }, [activityType, activitiesList, i18next.language, sortedActivities]);
};

export const useMarketingActivity = (
  activityType: MarketingActivityType | string | null,
  activitiesList?: MarketingActivity[]
) => {
  const currentActivityData = useCurrentMarketingActivity(activityType, activitiesList);
  const marketingActivityContent = useMarketingActivityContent(currentActivityData.currentActivity);

  return useMemo(
    () => ({ ...marketingActivityContent, ...currentActivityData }),
    [activityType, activitiesList, currentActivityData, marketingActivityContent]
  );
};
