import { useTonConnectUI } from "@tonconnect/ui-react";
import { useActions } from "app/hooks";
import { Path } from "app/pages";
import LogoutIcon from "assets/img/logout.svg";
import { ReactComponent as ProfileIcon } from "assets/img/profile-icon.svg";
import cn from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EWalletType } from "store/slice";
import "./user-menu.sass";

type TProps = {
  isUserMenuOpened: boolean;
  closeUserMenu: () => void;
};

export const UserMenuTon: FC<TProps> = ({ isUserMenuOpened, closeUserMenu }) => {
  const { setWalletType } = useActions();
  const [tonConnectUI] = useTonConnectUI();
  const { t } = useTranslation();
  const disconnect = async () => await tonConnectUI.disconnect();

  return (
    <div className={cn("user-menu", { _opened: isUserMenuOpened })}>
      <Link
        className="user-menu__item"
        to={Path.ACCOUNT}
        onClick={() => {
          setWalletType(EWalletType.TON);
          closeUserMenu();
        }}
      >
        <div className="user-menu__img">
          <ProfileIcon />
        </div>
        <div className="user-menu__title">{t("Account settings")}</div>
      </Link>

      <div className="user-menu__item" onClick={() => disconnect()}>
        <img src={LogoutIcon} alt="" className="user-menu__img" />
        <div className="user-menu__title-special">{t("Log out")}</div>
      </div>
    </div>
  );
};
