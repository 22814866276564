import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AccountObject = {
  __typename?: 'AccountObject';
  balances: Array<BalanceObjectWithTonAmba1Nfts>;
  bonusAp: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  ghostAp: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  stakingAp: Scalars['String']['output'];
  tier: Scalars['String']['output'];
  totalAp: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BalanceObject = {
  __typename?: 'BalanceObject';
  account: AccountObject;
  accountId: Scalars['Int']['output'];
  addr: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  network: Scalars['String']['output'];
  stakingAmount: Scalars['String']['output'];
  stakingMultiplier: Scalars['String']['output'];
  tonAmba1Nfts: Array<TonAmba1NftObject>;
  totalAmount: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BalanceObjectWithTonAmba1Nfts = {
  __typename?: 'BalanceObjectWithTonAmba1Nfts';
  accountId: Scalars['Int']['output'];
  addr: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  network: Scalars['String']['output'];
  stakingAmount: Scalars['String']['output'];
  stakingMultiplier: Scalars['String']['output'];
  tonAmba1Nfts: Array<TonAmba1NftObject>;
  totalAmount: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GetByIdOrAddrInput = {
  addr?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type GetCollectionInput = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type LinkBalancesInput = {
  bnbAddr: Scalars['String']['input'];
  tonAddr: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  linkBalances: BalanceObject;
  syncAccount: AccountObject;
};


export type MutationLinkBalancesArgs = {
  linkBalancesInput: LinkBalancesInput;
};


export type MutationSyncAccountArgs = {
  syncAccountInput: SyncAccountInput;
};

export type Query = {
  __typename?: 'Query';
  account: AccountObject;
  accounts: Array<AccountObject>;
  balance: BalanceObject;
  balances: Array<BalanceObject>;
  tonAmba1Nft: TonAmba1NftObject;
  tonAmba1Nfts: Array<TonAmba1NftObject>;
};


export type QueryAccountArgs = {
  getAccountInput: GetByIdOrAddrInput;
};


export type QueryAccountsArgs = {
  getAccountsInput: GetCollectionInput;
};


export type QueryBalanceArgs = {
  getBalanceInput: GetByIdOrAddrInput;
};


export type QueryBalancesArgs = {
  getBalancesInput: GetCollectionInput;
};


export type QueryTonAmba1NftArgs = {
  getTonAmba1NftInput: GetByIdOrAddrInput;
};


export type QueryTonAmba1NftsArgs = {
  getTonAmba1NftsInput: GetCollectionInput;
};

export type SyncAccountInput = {
  addr: Scalars['String']['input'];
};

export type TonAmba1NftObject = {
  __typename?: 'TonAmba1NftObject';
  addr: Scalars['String']['output'];
  balance: BalanceObject;
  balanceId: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  index: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SyncAccountMutationVariables = Exact<{
  syncAccountInput: SyncAccountInput;
}>;


export type SyncAccountMutation = { __typename?: 'Mutation', syncAccount: { __typename?: 'AccountObject', id: number } };

export type LinkBalancesMutationVariables = Exact<{
  linkBalancesInput: LinkBalancesInput;
}>;


export type LinkBalancesMutation = { __typename?: 'Mutation', linkBalances: { __typename?: 'BalanceObject', id: number } };

export type AccountQueryVariables = Exact<{
  getAccountInput: GetByIdOrAddrInput;
}>;


export type AccountQuery = { __typename?: 'Query', account: { __typename?: 'AccountObject', tier: string, totalAp: string, bonusAp: string, ghostAp: string, stakingAp: string, balances: Array<{ __typename?: 'BalanceObjectWithTonAmba1Nfts', id: number, createdAt: any, updatedAt: any, addr: string, totalAmount: string, stakingAmount: string, stakingMultiplier: string, network: string, accountId: number, tonAmba1Nfts: Array<{ __typename?: 'TonAmba1NftObject', id: number, createdAt: any, updatedAt: any, type: string, addr: string, index: number, content: string }> }> } };


export const SyncAccountDocument = gql`
    mutation SyncAccount($syncAccountInput: SyncAccountInput!) {
  syncAccount(syncAccountInput: $syncAccountInput) {
    id
  }
}
    `;
export type SyncAccountMutationFn = Apollo.MutationFunction<SyncAccountMutation, SyncAccountMutationVariables>;

/**
 * __useSyncAccountMutation__
 *
 * To run a mutation, you first call `useSyncAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncAccountMutation, { data, loading, error }] = useSyncAccountMutation({
 *   variables: {
 *      syncAccountInput: // value for 'syncAccountInput'
 *   },
 * });
 */
export function useSyncAccountMutation(baseOptions?: Apollo.MutationHookOptions<SyncAccountMutation, SyncAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncAccountMutation, SyncAccountMutationVariables>(SyncAccountDocument, options);
      }
export type SyncAccountMutationHookResult = ReturnType<typeof useSyncAccountMutation>;
export type SyncAccountMutationResult = Apollo.MutationResult<SyncAccountMutation>;
export type SyncAccountMutationOptions = Apollo.BaseMutationOptions<SyncAccountMutation, SyncAccountMutationVariables>;
export const LinkBalancesDocument = gql`
    mutation LinkBalances($linkBalancesInput: LinkBalancesInput!) {
  linkBalances(linkBalancesInput: $linkBalancesInput) {
    id
  }
}
    `;
export type LinkBalancesMutationFn = Apollo.MutationFunction<LinkBalancesMutation, LinkBalancesMutationVariables>;

/**
 * __useLinkBalancesMutation__
 *
 * To run a mutation, you first call `useLinkBalancesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkBalancesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkBalancesMutation, { data, loading, error }] = useLinkBalancesMutation({
 *   variables: {
 *      linkBalancesInput: // value for 'linkBalancesInput'
 *   },
 * });
 */
export function useLinkBalancesMutation(baseOptions?: Apollo.MutationHookOptions<LinkBalancesMutation, LinkBalancesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkBalancesMutation, LinkBalancesMutationVariables>(LinkBalancesDocument, options);
      }
export type LinkBalancesMutationHookResult = ReturnType<typeof useLinkBalancesMutation>;
export type LinkBalancesMutationResult = Apollo.MutationResult<LinkBalancesMutation>;
export type LinkBalancesMutationOptions = Apollo.BaseMutationOptions<LinkBalancesMutation, LinkBalancesMutationVariables>;
export const AccountDocument = gql`
    query Account($getAccountInput: GetByIdOrAddrInput!) {
  account(getAccountInput: $getAccountInput) {
    tier
    balances {
      id
      createdAt
      updatedAt
      addr
      totalAmount
      stakingAmount
      stakingMultiplier
      network
      accountId
      tonAmba1Nfts {
        id
        createdAt
        updatedAt
        type
        addr
        index
        content
      }
    }
    totalAp
    bonusAp
    ghostAp
    stakingAp
  }
}
    `;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      getAccountInput: // value for 'getAccountInput'
 *   },
 * });
 */
export function useAccountQuery(baseOptions: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables> & ({ variables: AccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
        }
export function useAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountSuspenseQueryHookResult = ReturnType<typeof useAccountSuspenseQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;