import cn from "classnames";
import { ReactNode } from "react";

type TProps = {
  className?: string;
  title?: string;
  items?: {
    key: string;
    value: ReactNode;
    isBig?: boolean;
  }[];
};

const KeyValueList: React.FC<TProps> = ({ className, title, items }) => {
  return (
    <div className={cn(className)}>
      {items &&
        items.map((item, i) => (
          <div
            key={i}
            className={cn("mb-2 flex items-baseline justify-between last:mb-0", {
              "flex-col": item.isBig,
              "items-center": !item.isBig,
            })}
          >
            <div className="h4 text-text_secondary">{item.key}</div>
            <div
              className={cn("text-text_primary", {
                p2: !item.isBig,
                "h3b mt-1": item.isBig,
              })}
            >
              {item.value}
            </div>
          </div>
        ))}
    </div>
  );
};

export default KeyValueList;
