import { BigNumber, BigNumberish } from 'ethers';
import {
  Address,
  Builder,
  Cell,
  Contract,
  ContractProvider,
  Dictionary,
  Sender,
  Slice,
  beginCell,
  contractAddress,
  toNano
} from 'ton-core';

enum OpCodes {
  TRANSFER_NOTIFICATION_STAKE = 0x7cf7ce5c,
  TRANSFER_NOTIFICATION_DEPOSIT_TREASURY = 0xe33ae46c,
  WITHDRAW = 0xa49b63c9,
  SET_CODE = 0xbbee66f6
}

export type LockPoolData = {
  ggrAddr: Address;
  ggrWalletCode: Cell;
  treasuryBalance: bigint;
  pools: {
    lockSeconds: bigint;
    rewardPercent: bigint;
  }[];
  stakes: {
    owner: Address;
    lockSeconds: bigint;
    updatedAt: bigint;
    amount: bigint;
  }[];
};

export class LockPool implements Contract {
  readonly address: Address;
  readonly init: { code: Cell; data: Cell };

  constructor(workchain: number, code: Cell, data: LockPoolData) {
    const poolsDict = Dictionary.empty(this.poolsDictK, this.poolsDictV);

    data.pools.forEach(pool => {
      poolsDict.set(pool.lockSeconds, pool.rewardPercent);
    });

    this.init = {
      code,
      data: beginCell()
        .storeAddress(data.ggrAddr)
        .storeRef(data.ggrWalletCode)
        .storeCoins(data.treasuryBalance)
        .storeDict(poolsDict)
        .storeBit(false)
        .endCell()
    };
    this.address = contractAddress(workchain, this.init);
  }

  async sendDeploy(
    provider: ContractProvider,
    via: Sender,
    params: {
      init: { code: Cell; data: Cell };
      body?: Cell;
      value?: bigint;
      deployValue?: bigint;
    }
  ) {
    await provider.internal(via, {
      value: params.value ?? toNano('0.1')
    });
    return contractAddress(0, params.init);
  }

  async sendWithdraw(
    provider: ContractProvider,
    via: Sender,
    params: {
      coinAmount: bigint;
    }
  ) {
    return await provider.internal(via, {
      value: params.coinAmount,
      body: beginCell().storeUint(OpCodes.WITHDRAW, 32).storeUint(0, 64).endCell()
    });
  }

  static buildTransferNotificationStakeMsgBody(lockSeconds: BigNumberish) {
    return beginCell().storeInt(OpCodes.TRANSFER_NOTIFICATION_STAKE, 32).storeInt(BigNumber.from(lockSeconds).toBigInt(), 128).endCell();
  }

  async getLockPoolData(provider: ContractProvider): Promise<LockPoolData> {
    const { stack } = await provider.get('get_lock_pool_data', []);
    const [ggrAddr, ggrWalletCode, treasuryBalance, pools, stakes] = [
      stack.readAddress(),
      stack.readCell(),
      stack.readBigNumber(),
      stack.readCellOpt(),
      stack.readCellOpt()
    ];

    const poolsDict = pools
      ? Dictionary.loadDirect(this.poolsDictK, this.poolsDictV, pools)
      : Dictionary.empty(this.poolsDictK, this.poolsDictV);

    const stakesDict = stakes
      ? Dictionary.loadDirect(this.stakesDictK, this.stakesDictV, stakes)
      : Dictionary.empty(this.stakesDictK, this.stakesDictV);

    const data = {
      ggrAddr,
      ggrWalletCode,
      treasuryBalance,
      pools: poolsDict.keys().map(key => ({
        lockSeconds: key,
        rewardPercent: poolsDict.get(key)!
      })),
      stakes: stakesDict.keys().map(key => ({
        owner: new Address(0, Buffer.from(key.toString(16), 'hex')),
        ...stakesDict.get(key)!
      }))
    };
    return data;
  }

  get poolsDictK() {
    return Dictionary.Keys.BigInt(128);
  }
  get poolsDictV() {
    return Dictionary.Values.BigInt(128);
  }
  get stakesDictK() {
    return Dictionary.Keys.BigInt(256);
  }
  get stakesDictV() {
    return {
      serialize: (src: any, buidler: Builder) => {
        buidler.storeSlice(src);
      },
      parse: (src: Slice) => {
        const lockSeconds = src.loadUintBig(128);
        const updatedAt = src.loadUintBig(128);
        const amount = src.loadCoins();
        return { lockSeconds, updatedAt, amount };
      }
    };
  }
}
