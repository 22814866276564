import { QueryParam } from "app/enums";
import { getQueryParam } from "app/utils";
import { ReactComponent as BackIcon } from "assets/img/icons/back.svg";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { Path } from "./";

const ProjectPage: FC = ({ children }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const queryParam = getQueryParam(QueryParam.MARKETING_ACTIVITY_TYPE);
    if (queryParam) return;

    animateScroll.scrollTo(170, {
      duration: 300,
    });
  }, []);

  return (
    <div className="page">
      <div className="page__inner">
        <Link to={Path.IDO_LIST} className="page__back">
          <BackIcon className="page__back-icon" />
          {t("Back")}
        </Link>
        {children}
      </div>
    </div>
  );
};

export default ProjectPage;
