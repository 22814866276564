import { checkIsGtLimit, checkIsNumeric, trim } from "app/utils";
import iConfirm from "assets/img/input-edit-confirm-idon.svg";
import iEdit from "assets/img/input-edit-icon.svg";
import cn from "classnames";
import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./input-number.sass";

type Props = {
  className?: string;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  variant?: "_outline" | "_blank";
  icon?: string;
  mask?: string;
  limit?: string;
  isEditable?: boolean;
  onEditConfirm?: () => void;
  maxDecimalsLength?: number; // 0 = no max length
};

const InputNumber: FC<Props> = ({
  className,
  value,
  setValue,
  disabled,
  placeholder,
  variant = "_outline",
  icon,
  mask,
  limit,
  isEditable,
  onEditConfirm,
  maxDecimalsLength,
}) => {
  const [editModeOn, setEditModeOn] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const setValueAfterChecking = (value: string): void => {
    let inputValue = trim(value.replace(/,/g, "."));
    const isNumeric = checkIsNumeric(inputValue, maxDecimalsLength);
    if (!isNumeric) return;
    if (limit) {
      const isGtLimit = checkIsGtLimit(inputValue, limit);
      if (isGtLimit) {
        inputValue = limit;
      }
    }

    setValue(inputValue);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setValueAfterChecking(value);
  };

  const onEdit = () => {
    setEditModeOn(false);
    if (onEditConfirm) onEditConfirm();
  };

  const onKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      onEdit();
    }
  };

  const isDisabled = useMemo(
    () => disabled && !editModeOn,
    [disabled, editModeOn]
  );

  useEffect(() => {
    if (limit) {
      setValueAfterChecking(value);
    }
  }, [limit]);

  useEffect(() => {
    if (ref.current && !isDisabled && editModeOn) {
      ref.current.select();
    }
  }, [ref, isDisabled, editModeOn]);

  return (
    <div
      className={cn("input-number__container", variant, {
        "_have-icon": !!icon,
      })}
    >
      <input
        ref={ref}
        placeholder={placeholder}
        className={cn(className, "input-number")}
        value={isDisabled && mask ? `${value} ${mask}` : value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        disabled={isDisabled}
        autoComplete="off"
        autoCorrect="off"
        type="text"
        inputMode="decimal"
        pattern="^[0-9]*[.,]?[0-9]*$"
        spellCheck="false"
      />
      {icon && (
        <img className={cn("input-number__icon", variant)} src={icon} alt="" />
      )}
      {isEditable && (
        <>
          {editModeOn ? (
            <div
              className={cn("input-number__icon", "_edit", variant)}
              onClick={onEdit}
            >
              <img src={iConfirm} alt="" />
            </div>
          ) : (
            <div
              className={cn("input-number__icon", "_edit", variant)}
              onClick={() => setEditModeOn(true)}
            >
              <img src={iEdit} alt="" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InputNumber;
