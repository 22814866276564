import { useOutsideClick, useWidth } from "app/hooks";
import { SelectItem } from "app/types/select-item";
import iArrow from "assets/img/timeline-arrow.svg";
import cn from "classnames";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectScreenWidth } from "store/slice";
import "./selector.sass";

type Props = {
  selected?: SelectItem;
  onSelect: (item: SelectItem) => void;
  list: SelectItem[];
  disabled?: boolean;
  className?: string;
  listClassName?: string;
  itemClassName?: string;
  iconClassName?: string;
  clippedInMobile?: boolean;
  showArrow?: boolean;
  showSelected?: boolean;
};

export const Selector: FC<Props> = ({
  selected,
  onSelect,
  list,
  disabled,
  className,
  listClassName,
  itemClassName,
  iconClassName,
  clippedInMobile,
  showArrow,
  showSelected,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const screenWidth = useSelector(selectScreenWidth);
  const { isMobileWidth } = useWidth();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>();

  const _disabled = useMemo(() => disabled || list.length < 2, [disabled, list.length]);

  const togglePopup = () => {
    if (_disabled) return;

    setIsOpen((state) => !state);
  };

  const onClick = (selected: SelectItem) => {
    onSelect(selected);
    setIsOpen(false);
  };

  useOutsideClick(ref, () => setIsOpen(false));

  useEffect(() => {
    if (!ref.current) return;

    const { width } = ref.current.getBoundingClientRect();
    setWidth(width);
  }, [ref, screenWidth]);

  return (
    <button type="button" className={cn("selector", className)}>
      <div
        className={cn("selector__item", itemClassName, {
          _open: isOpen,
          _clipped: clippedInMobile,
          _disabled,
        })}
        onClick={togglePopup}
        style={{ width }}
      >
        {selected?.icon && (
          <div
            className={cn("selector__item-icon", iconClassName)}
            style={{ backgroundImage: `url(${selected.icon})` }}
          />
        )}
        {clippedInMobile && isMobileWidth ? "" : selected?.renderValue || selected?.value}
      </div>
      <div ref={ref} className={cn("selector__popup", listClassName, { _open: isOpen })}>
        {list.map((item, i) => (
          <div
            key={i}
            className={cn("selector__item", itemClassName, {
              _clipped: clippedInMobile,
              _selected: showSelected && !i,
            })}
            onClick={() => onClick(item)}
          >
            {item.icon && (
              <div
                className={cn("selector__item-icon", iconClassName)}
                style={{ backgroundImage: `url(${item.icon})` }}
              />
            )}
            {clippedInMobile && isMobileWidth ? "" : item.renderValue || item.value}
          </div>
        ))}
      </div>
      {showArrow && (
        <img
          className={cn("selector__arrow", {
            _open: isOpen,
            _hidden: _disabled,
          })}
          src={iArrow}
          alt="arrow"
          width={22}
          height={22}
        />
      )}
    </button>
  );
};
