import Button from "app/components/button/button";
import Checkbox from "app/components/checkbox/checkbox";
import { REFUND_STORAGE_KEY } from "app/constants";
import { useActions } from "app/hooks";
import iGuide_2 from "assets/img/refund-guide-2.png";
import iGuide_3 from "assets/img/refund-guide-3.png";
import iGuide_4 from "assets/img/refund-guide-4.png";
import iGuide_5 from "assets/img/refund-guide-5.png";
import cn from "classnames";
import { FC, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Ido, RefundType } from "sdk";
import "./refund-modal.sass";

const [refundPoints, returnTokensPoints]: { title: string; icon?: string }[][] = [[
  {
    title:
      "Complete a short survey and click the «Confirm refund» button. The amount of the refund is filled in automatically.",
  },
  {
    title: "Sign the transaction in a crypto wallet",
    icon: iGuide_2,
  },
  {
    title: "Wait for the transaction response",
    icon: iGuide_3,
  },
  {
    title: "Your funds successfully returned, thank you for your participation",
    icon: iGuide_4,
  },
], [
  {
    title:
      "Complete a short survey and click the «Confirm refund» button. The amount of the refund is filled in automatically.",
  },
  {
    title: "Sign the transaction in a crypto wallet",
    icon: iGuide_2,
  },
  {
    title: "Wait for the transaction response",
    icon: iGuide_3,
  },
  {
    title: "Return of tokens completed successfully. Wait for the transfer of funds to the wallet",
    icon: iGuide_5,
  },
]];

type Props = { className?: string, project?: Ido };

const RefundModal: FC<Props> = ({ className = "", project }) => {
  const { t } = useTranslation();
  const { closeModal, setIsImportantModal } = useActions();
  const [checked, setChecked] = useState<boolean>(false);

  const onClick = (): void => {
    localStorage.setItem(REFUND_STORAGE_KEY, "true");
    closeModal();
  };

  const currentPoints = useMemo(() => {
    if (project?.refundType === RefundType.RETURN_PROJECT_TOKENS) {
      return returnTokensPoints
    }
    return refundPoints
  }, [project])

  return (
    <div className={cn("refund-modal", className)}>
      <div className="refund-modal__head">
        <div className="refund-modal__title">
          <Trans i18nKey="projectRefund.title">
            The <span className="_g-text-active-lighter">refund</span> stage
          </Trans>
        </div>
        <div className="refund-modal__desc">
          {t("If you want a refund, then follow these steps")}
        </div>
      </div>
      <div className="refund-modal__body">
        {currentPoints.map(({ title, icon }, i) => (
          <div className="refund-modal__row" key={i}>
            <div className="refund-modal__column">
              <div className="refund-modal__point">{++i}</div>
            </div>
            <div className="refund-modal__column">
              <div className="refund-modal__row-title">{t(title)}</div>
              {icon && <img className="refund-modal__row-icon" src={icon} alt="" />}
            </div>
          </div>
        ))}
        <div className="refund-modal__row">
          <Checkbox
            checked={checked}
            setChecked={(checked: boolean) => {
              setChecked(checked);
              setIsImportantModal(!checked);
            }}
          >
            {t("I have read the instructions")}
          </Checkbox>
        </div>
        <Button disabled={!checked} onClick={onClick}>
          {t("Continue")}
        </Button>
      </div>
    </div>
  );
};

export default RefundModal;
