import cn from "classnames";
import { FC, HTMLAttributes } from "react";
import "./badge.sass";

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, "id"> {
  title: string;
  type?: "primary" | "green" | "red-contrast" | "green-contrast";
}

const getStylesForType = (type: Props["type"]) => {
  switch (type) {
    case "primary": {
      return {
        style: " _primary",
      };
    }
    case "green": {
      return {
        style: " _green",
      };
    }
    case "red-contrast": {
      return {
        style: " _red-contrast",
      };
    }
    case "green-contrast": {
      return {
        style: " _green-contrast",
      };
    }
    default: {
      return {
        style: " _default",
      };
    }
  }
};

const Badge: FC<Props> = ({ className, title, type, onClick }) => {
  const Tag = "div";
  const styles = getStylesForType(type);
  return (
    <Tag className={cn("badge ", className, styles.style)} onClick={onClick}>
      <div className={cn("badge__title ", styles.style)}>{title.toUpperCase()}</div>
    </Tag>
  );
};

export default Badge;
