import cn from "classnames";
import "./kv-list.sass";

interface IProps {
  className?: string;
  items: { title: React.ReactNode; value: React.ReactNode; disabled?: boolean }[];
}

const KVList: React.FC<IProps> = ({ items, className }) => {
  return (
    <div className={cn(className, " kv-list ")}>
      {items.map((el, i) => (
        <div className="kv-list__content" key={`kv-list-${i}`}>
          <div className="kv-list__title ">{el.title}</div>
          <div
            className={cn(" kv-list__info ", {
              " _disabled opacity-50": el.disabled,
            })}
          >
            {el.value}
          </div>
        </div>
      ))}
    </div>
  );
};

export default KVList;
