import Button from "app/components/button/button";
import { Selector } from "app/components/selector/selector";
import { useChains } from "app/hooks";
import { Project } from "app/types";
import { SelectItem } from "app/types/select-item";
import WarningIcon from "assets/img/warning.svg";
import cn from "classnames";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChainConfig, ChainId, getChainIconSrc } from "sdk";
import { Chain } from "wagmi";
import { CheckNetworkText } from "./check-network-text";

type Props = {
  project: Project;
  className?: string;
  exact?: boolean;
  chainsList?: ChainConfig[];
};

export const CheckNetworkBody: FC<Props> = ({ project, className, exact, chainsList }) => {
  const { t } = useTranslation();
  const [selectedChain, setSelectedChain] = useState<SelectItem>();
  const { chainId, isSwitchAvailable, supportedChains, availableChains, switchNetwork } = useChains(project);

  const onClick = async () => {
    if (selectedChain?.value === chainId) return;

    const selectedChainId = selectedChain?.value || ChainId.BINANCE_MAINNET;
    await switchNetwork.switchNetworkAsync?.(+selectedChainId);
  };

  const _chainsList = useMemo(() => {
    let chains: Chain[] = []
    if (exact) {
      chains = availableChains.filter(({ id }) => id === project.network);
    }
    if (!chains.length) {
      chains = availableChains;
    }

    const list: SelectItem[] = chains.map(({ name, id }) => ({
      renderValue: name,
      icon: getChainIconSrc(id),
      value: id,
    }));

    if (!selectedChain) return list;

    return list.sort((a) => (a.value === selectedChain.value ? -1 : 1));
  }, [exact, chainsList, chainId, supportedChains, selectedChain, availableChains]);

  useEffect(() => {
    if (selectedChain) return;

    setSelectedChain(_chainsList[0]);
  }, [_chainsList, selectedChain]);

  return (
    <div className={cn("check-network", className)}>
      <div className="check-network__inner">
        <img className="check-network__icon" src={WarningIcon} alt="warning" />
        <CheckNetworkText project={project} />
      </div>
      {isSwitchAvailable && (
        <div className="check-network__actions">
          <Selector
            className="check-network__chain-selector"
            listClassName="check-network__chain-selector-list"
            selected={selectedChain}
            onSelect={setSelectedChain}
            list={_chainsList}
            showSelected
          />
          <Button onClick={onClick} disabled={selectedChain?.value === chainId}>
            {t("Switch Network")}
          </Button>
        </div>
      )}
    </div>
  );
};
