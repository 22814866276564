import { useActions } from "app/hooks";
import AccountBlockInfo from "app/ton-lk/account-block";
import { ReactComponent as IconBnb } from "assets/img/icons/icon-bnb.svg";
import { ReactComponent as IconTon } from "assets/img/icons/icon-ton.svg";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EWalletType, selectWalletType } from "store/slice";
import "./account-block.sass";
import BnbAccountBlock from "./bnb-account-block";
import TonAccountBlock from "./ton-account-block";

interface IProps {}

const walletsType = [
  { name: "BNB", key: EWalletType.BNB, icon: <IconBnb className="w-6" /> },
  { name: "TON", key: EWalletType.TON, icon: <IconTon className="w-6" /> },
] as const;

const AccountBlock: React.FC<IProps> = () => {
  const { setWalletType } = useActions();

  const walletType = useSelector(selectWalletType);

  const [activeSection, setActiveSection] =
    useState<(typeof walletsType)[number]["key"]>(walletType);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setActiveSection(walletType);
  }, [walletType]);
  return (
    <div className="account-block w-full max-lg:flex max-lg:flex-col max-lg:items-center">
      <div className="w-full flex justify-between gap-8 max-lg:flex-col max-lg:items-center">
        <div className="w-[450px]  max-lg:w-auto max-lg:max-w-[420px]">
          <AccountBlockInfo />
        </div>
        <div className="max-lg:w-full w-full">
          <div className="flex gap-2 mb-8 max-lg:justify-center max-lg:w-full">
            {walletsType.map((el, i) => (
              <div
                key={i}
                onClick={() => {
                  setWalletType(el.key);
                }}
                className={cn(
                  "px-4 py-3 text-xl font-semibold flex gap-2 items-center justify-center rounded-xl bg-bg_secondary_active cursor-pointer",
                  { "bg-blue": el.key === activeSection }
                )}
              >
                {el.icon} {el.name}
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            {activeSection === EWalletType.TON && (
              <TonAccountBlock
                className={cn("account-block__content max-lg:w-full max-lg:max-w-[420px]")}
              />
            )}

            {activeSection === EWalletType.BNB && (
              <BnbAccountBlock className={cn("account-block__content")} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountBlock;
