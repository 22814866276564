import { aftermathId, privateSwapProjects, sportzchain } from "app/constants";
import { ProjectStage } from "app/enums";
import { useSupportedChains } from "app/hooks";
import { Project } from "app/types";
import { findTranslation } from "app/utils";
import classNames from "classnames";
import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isIdo, NETWORK_NAMES_MAP, ProjectStatus, TranslationType } from "sdk";
import { useProjectNotificationsQuery } from "store/api";
import { selectProjectStage } from "store/slice";
import "./project-notification.sass";

type Props = {
  project: Project;
  notification?: string
};

export const ProjectNotification: FC<Props> = ({ project, notification }) => {
  const { t, i18n } = useTranslation();
  const projectStage = useSelector(selectProjectStage);
  const { supportedChains } = useSupportedChains(project);

  const { data } = useProjectNotificationsQuery(project.id);
  const notifications = useMemo(() => {
    if (!data || !data.length) return [];

    return data.reduce<string[]>((result, { translations }) => {
      const translation = findTranslation(TranslationType.DESCRIPTION, translations);
      if (translation) {
        result.push(translation);
      }

      return result;
    }, []);
  }, [data, i18n.language]);

  return (
    <div className="project-notification__list">
      {notification && (
        <div className="project-notification">
          <div
            className={classNames(
              "project-notification__inner",
            )}
            dangerouslySetInnerHTML={{ __html: notification }}
          >
            {/* {t(`${notification}`)} */}
          </div>
        </div>
      )}
      {isIdo(project) && project.status === ProjectStatus.FCFS && (
        <div className="project-notification">
          <div
            className={classNames(
              "project-notification__inner",
              "project-notification__inner_warning"
            )}
          >
            <Trans i18nKey="FCFSFee">
              Dear investors! <br />
              Please note that at the FCFS stage, a non-refundable fee of 5% is charged for
              participation in the IDO if you don't have any tier.{" "}
              <Link style={{ textDecoration: "underline" }} to={"/account?projectFilter=Live"}>
                Stake
              </Link>{" "}
              GGR tokens to avoid fees.
            </Trans>
          </div>
        </div>
      )}
      {!!notifications.length &&
        notifications.map((notification) => (
          <div key={notification} className="project-notification">
            <div
              className="project-notification__inner"
              dangerouslySetInnerHTML={{ __html: notification }}
            />
          </div>
        ))}
      {project.id === sportzchain && (
        <div className="project-notification">
          <div className="project-notification__inner">
            <Trans i18nKey="Sportzchain message">
              Dear investors! <br />
              GAGARIN gives a unique opportunity to Sportzchain investors. Within a week from the
              time of the claim, those investors who do not claim tokens will be credited with SPN
              at the rate of 1000% APR for 7 days!
            </Trans>
          </div>
        </div>
      )}
      {project.id === aftermathId &&
        (projectStage <= ProjectStage.whitelist || projectStage === ProjectStage.fcfs) && (
          <div className="project-notification">
            <div className="project-notification__inner">
              <Trans i18nKey="Aftermath message">
                Dear investors! <br />
                There are restrictions on the purchase of Aftermath Islands tokens, which you can
                find on the project website.
              </Trans>
            </div>
          </div>
        )}
      {((isIdo(project) && !project.whitelistFree) || privateSwapProjects.includes(project.id)) &&
        projectStage >= ProjectStage.swapGap &&
        projectStage < ProjectStage.fcfs && (
          <div className="project-notification">
            <div className="project-notification__inner">
              {t(
                "workingFCFSRound1" //FIXME 1
              )}
            </div>
          </div>
        )}
      {isIdo(project) && !project.whitelistFree && projectStage === ProjectStage.fcfs && (
        <div className="project-notification">
          <div className="project-notification__inner">
            {t(
              "workingFCFSRound2" //FIXME 2
            )}
          </div>
        </div>
      )}
      {isIdo(project) &&
        project.claimType.toLowerCase() !== "gagarin" &&
        projectStage < ProjectStage.claim && (
          <div className="project-notification">
            <div className="project-notification__inner">
              <Trans i18nKey="Claim Type message">
                Dear investors! <br />
                Please note that the distribution of {{ projectTitle: project.title }} (
                {{ tokenSymbol: project.claimCurrency?.[0].ticket || "TBA" }}) tokens will be
                carried out by the project itself.
              </Trans>
            </div>
          </div>
        )}
      {isIdo(project) &&
        supportedChains.length > 1 &&
        projectStage < ProjectStage.claim &&
        projectStage !== ProjectStage.refundPolicy && (
          <div className="project-notification">
            <div className="project-notification__inner">
              <Trans i18nKey="Crosschain message">
                Dear investors! <br />
                Pay your attention that the {{ projectTitle: project.title }} tokens (
                {{ tokenSymbol: project.claimCurrency?.[0].ticket || "TBA" }}) deposit is available
                on several blockchain networks:{" "}
                {{
                  networksList: supportedChains.map(({ title }) => title).join(", "),
                }}
                <br />
                The distribution of {{ projectTitle: project.title }} tokens (
                {{ tokenSymbol: project.claimCurrency?.[0].ticket || "TBA" }}) will be carried out
                in{" "}
                {{
                  projectNetwork:
                    project.claimType.toLowerCase() === "gagarin"
                      ? NETWORK_NAMES_MAP[project.network]
                      : project.claimType,
                }}{" "}
                only!
              </Trans>
            </div>
          </div>
        )}
    </div>
  );
};
