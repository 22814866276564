import { ChainId, RootAbi, RootV2, getHexFromId } from "sdk";
import { VoucherV2 } from "sdk/src/contracts/typechain-types/RootV2";
import { ContractService } from "./contract.service";
class RootContract extends ContractService {
  constructor() {
    super(RootAbi);
  }

  redeemIdoPopulate({
    address,
    voucher,
    chainId,
  }: {
    address: string;
    voucher: VoucherV2.RedeemIdoVoucherStruct;
    chainId: ChainId;
  }) {
    const contract = this._getContract<RootV2>(address, chainId);
    return contract.populateTransaction.redeemIdo(voucher);
  }

  claimProjectPopulate({
    address,
    id,
    chainId,
  }: {
    address: string;
    id: string;
    chainId: ChainId;
  }) {
    const hexId = getHexFromId(id);
    const contract = this._getContract<RootV2>(address, chainId);
    return contract.populateTransaction.claimProject(hexId);
  }

  refundPopulate({
    address,
    voucher,
    chainId,
  }: {
    address: string;
    voucher: any;
    chainId: ChainId;
  }) {
    const contract = this._getContract<RootV2>(address, chainId);
    return contract.populateTransaction.refund(voucher);
  }

  returnProjectTokensPopulate({
    address,
    voucher,
    chainId,
  }: {
    address: string;
    voucher: any;
    chainId: ChainId;
  }) {
    const contract = this._getContract<RootV2>(address, chainId);
    return contract.populateTransaction.returnProjectTokens(voucher);
  }
}

const rootContract = new RootContract();

export default rootContract;
