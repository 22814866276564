import { FC } from "react";
import { HashLink } from "react-router-hash-link";
import { Link as ScrollLink } from "react-scroll";

type Props = {
  isLink: boolean;
  hashId: string;
  onClick?: () => void;
  to: string;
};

const MenuScrollLink: FC<Props> = ({ isLink, hashId, onClick, to, children }) => {
  const scroll = (element: HTMLElement) => {
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <>
      {isLink ? (
        <HashLink to={to + `#${hashId}`} className="menu__item" scroll={scroll} onClick={onClick}>
          {children}
        </HashLink>
      ) : (
        <ScrollLink
          to={hashId}
          offset={-50} // header height
          smooth
          duration={600}
          className="menu__item"
          onClick={onClick}
        >
          {children}
        </ScrollLink>
      )}
    </>
  );
};

export default MenuScrollLink;
