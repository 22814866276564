import CheckAuth from "app/components/check-auth";
import RocketLoader from "app/components/loader/rocket-loader";
import {
  ClaimStage,
  useActions,
  useApplication,
  useIdoClaimStage,
  useInvestorInfo,
  useNextClaimByProject,
} from "app/hooks";
import cn from "classnames";
import { FC, useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { Ido } from "sdk";
import { CheckNetwork } from "../../project/check-network/check-network";
import ProjectLose from "../../project/project-lose";
import ProjectRefund__Success from "../../project/project-refund/project-refund__success";
import "./ido-claim.sass";
import IdoClaim__Body from "./ido-claim__body";
import IdoClaim__Button from "./ido-claim__button";

type Props = {
  className?: string;
  ido: Ido;
};

const IdoClaim: FC<Props> = ({ className, ido }) => {
  const { setIsProjectTimerRunning } = useActions();
  const { investorInfo, refetchInvestorInfo, isInvestorInfoLoading } = useInvestorInfo(ido);
  const { nextClaimDate } = useNextClaimByProject(ido);
  const { isApplicationLoading } = useApplication(ido);
  const claimStage = useIdoClaimStage(ido);

  const { restart, isRunning, days, hours, minutes, seconds } = useTimer({
    expiryTimestamp: nextClaimDate ? new Date(nextClaimDate) : new Date(),
    onExpire: () => {
      pingTimer();
    },
  });

  const pingTimer = () => {
    if (nextClaimDate) {
      restart(new Date(nextClaimDate));
    }
  };

  useEffect(() => {
    if (!investorInfo) return
    setIsProjectTimerRunning(isRunning);
    refetchInvestorInfo();
  }, [isRunning, refetchInvestorInfo]);

  useEffect(() => {
    pingTimer();
  }, [ido, isRunning, nextClaimDate]);

  return (
    <CheckAuth>
      <RocketLoader
        className="ido-claim__loader"
        isLoading={isInvestorInfoLoading || isApplicationLoading}
      >
        <CheckNetwork project={ido} exact>
          <div className={cn("ido-claim", className)}>
            {claimStage === ClaimStage.C1_NO_APP && (
              <>
                <ProjectLose type="no-application" />
              </>
            )}

            {claimStage === ClaimStage.C2_HAS_APP_NOT_REDEEM && (
              <>
                <ProjectLose type="not-redeemed_ido" />
              </>
            )}

            {claimStage === ClaimStage.C3_HAS_APP_REFUNDED && (
              <ProjectRefund__Success project={ido} />
            )}

            {claimStage === ClaimStage.C4_CLAIM && (
              <>
                <IdoClaim__Body ido={ido} />
                <IdoClaim__Button
                  ido={ido}
                  days={days}
                  hours={hours}
                  minutes={minutes}
                  seconds={seconds}
                />
              </>
            )}
          </div>
        </CheckNetwork>
      </RocketLoader>
    </CheckAuth>
  );
};

export default IdoClaim;
