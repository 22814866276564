import {
  Address as Addr,
  beginCell,
  Cell,
  Contract,
  contractAddress,
  ContractProvider,
  Sender,
  SendMode,
} from "ton-core";

export type SdlpCfg = {
  adminAddr: Addr;
  srcJAddr: Addr;
  dstJAddr: Addr;
  srcJwCode: Cell;
  dstJwCode: Cell;
};

export class Sdlp implements Contract {
  constructor(readonly address: Addr, readonly init?: { code: Cell; data: Cell }) {}

  static OpCodes = {
    SWAP: BigInt(0xb8a7a61c),
    UPGRADE: BigInt(0x74520c50),
    CHANGE_DATA: BigInt(0xd9285b94),
  };

  static Errors = {
    BAD_SENDER_JW: 1001,
    BAD_SENDER_ADMIN: 1002,
  };

  static createFromAddress(address: Addr) {
    return new Sdlp(address);
  }

  static async createFromConfig(cfg: SdlpCfg, code: Cell, workchain = 0) {
    const data = await Sdlp.buildDataCellFromCfg(cfg);
    const init = { code, data };
    return new Sdlp(contractAddress(workchain, init), init);
  }

  static async buildDataCellFromCfg(cfg: SdlpCfg): Promise<Cell> {
    return beginCell()
      .storeAddress(cfg.adminAddr)
      .storeAddress(cfg.srcJAddr)
      .storeAddress(cfg.dstJAddr)
      .storeRef(cfg.srcJwCode)
      .storeRef(cfg.dstJwCode)
      .endCell();
  }

  static buildSwapPayload() {
    return beginCell().storeUint(Sdlp.OpCodes.SWAP, 32).endCell();
  }

  static async buildChangeDataPayload({ queryId = 0, data }: { queryId?: number; data: SdlpCfg }) {
    return beginCell()
      .storeUint(Sdlp.OpCodes.CHANGE_DATA, 32)
      .storeUint(queryId, 64)
      .storeRef(await Sdlp.buildDataCellFromCfg(data))
      .endCell();
  }

  static async buildUpgradePayload({
    queryId = 0,
    code,
    data,
  }: {
    queryId?: number;
    code: Cell;
    data?: Cell;
  }) {
    const builder = beginCell()
      .storeUint(Sdlp.OpCodes.UPGRADE, 32)
      .storeUint(queryId, 64)
      .storeRef(code);
    if (data) builder.storeRef(data);
    return builder.endCell();
  }

  async sendMsg(
    provider: ContractProvider,
    via: Sender,
    params: {
      value: bigint;
      body: Cell;
    }
  ) {
    return await provider.internal(via, {
      value: params.value,
      body: params.body,
    });
  }

  async sendDeploy(provider: ContractProvider, via: Sender, value: bigint) {
    await provider.internal(via, {
      value,
      sendMode: SendMode.PAY_GAS_SEPARATELY,
      body: beginCell().endCell(),
    });
  }

  async getStoredData(provider: ContractProvider) {
    const { stack } = await provider.get("get_stored_data", []);
    const [adminAddr, srcJAddr, dstJAddr, srcJwCode, dstJwCode] = [
      stack.readAddress(),
      stack.readAddress(),
      stack.readAddress(),
      stack.readCell(),
      stack.readCell(),
    ];

    return {
      adminAddr,
      srcJAddr,
      dstJAddr,
      srcJwCode,
      dstJwCode,
    };
  }
}
