import { ONE_HOUR } from "sdk";
import tokenService from "services/token.service";
import { useAccount, useDisconnect } from "wagmi";
import { useActions } from "./use-actions";
import { useLogin } from "./use-login";

export const useConnect = () => {
  const { disconnect } = useDisconnect();
  const { closeModal, openModal } = useActions();
  const {
    loginMutation: [loginMutation, loginResponse],
    refreshMutation: [refreshMutation, refreshResponse],
    isMessageSigning,
    setIsMessageSigning,
  } = useLogin();

  useAccount({
    async onConnect() {
      try {
        if (!tokenService.tokens) {
          const isSuccess = await loginMutation();
          if (!isSuccess) {
            throw new Error("login error");
          }
          closeModal();
        } else if (tokenService.tokens.accessExpiresIn - Date.now() < ONE_HOUR) {
          await refreshMutation();
        }
      } catch (error) {
        console.error(error);
        disconnect();
        closeModal();
      }
    },
    async onDisconnect() {
      tokenService.removeToken();
      setIsMessageSigning(false);
    },
  });
  return {
    isLoginMessageSigning: isMessageSigning,
    closeModal,
    openModal,
  };
};
