import cn from 'classnames';
import { ReactNode } from 'react';

type TProps = {
  className?: string;
  title?: ReactNode;
  items?: {
    key: ReactNode;
    value?: ReactNode;
  }[];
};

const NumberedList: React.FC<TProps> = ({ className, title, items }) => {
  return (
    <div className={cn(className)}>
      {title && <div className="p2b text-text_primary mb-4 last:mb-0">{title}</div>}
      {items &&
        items.map((item, i) => (
          <div key={i} className="mb-4 last:mb-0">
            <div className="flex gap-2">
              <div className="p3 text-misty_lavender bg-soft_sky_10  flex h-6 min-w-[1.5rem] items-center justify-center rounded-full ">
                {i + 1}
              </div>
              <div>
                <div key={i} className="p3 text-soft_sky mb-2 w-full last:mb-0">
                  {item.key}
                </div>
                {item.value && <div>{item.value}</div>}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default NumberedList;
