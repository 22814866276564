import ReferrersList from "app/components/referral-program/referrers.list";
import { useWidth } from "app/hooks";
import iCoinexBackgroundLeft from "assets/img/coinex-background-left.svg";
import iCoinexBackgroundRight from "assets/img/coinex-background-right.svg";
import cn from "classnames";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./referral-program.page.sass";

const ReferalProgramPage: FC = () => {
  useTranslation();
  const { isMobileWidth, isDesktopWidth } = useWidth();

  return (
    <div className={cn("page", "referral-program")}>
      <div className="page__inner">
        <div className={cn("page__title", "referral-program__title")}>
          <Trans i18nKey="referalProgramPage.title">
            GAGARIN <span className="_g-text-active-lighter"> referral program</span>
          </Trans>
        </div>
        <ReferrersList />
      </div>
      {/* WTF */}
      {!isDesktopWidth && !isMobileWidth && (
        <>
          <img className="referral-program__background left" src={iCoinexBackgroundLeft} alt="" />
          <img className="referral-program__background right" src={iCoinexBackgroundRight} alt="" />
        </>
      )}
    </div>
  );
};

export default ReferalProgramPage;
