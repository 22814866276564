import { useTranslation } from "react-i18next";
import { Back, Button, ButtonType } from "../index";

type TProps = {
  amount: string;
  onBackClick: () => void;
  onContinueClick: () => Promise<any>;
};

const StakingWithdrawalAlertScene: React.FC<TProps> = ({
  amount,
  onBackClick,
  onContinueClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-4 flex items-baseline justify-between gap-2">
        <div className="p2b text-text_primary">{t("Withdrawal")}</div>
        <Back onClick={onBackClick} />
      </div>
      <div className="p3 text-soft_sky mb-4">
        {t(
          "Could you confirm whether you are aware that withdrawing tokens before the specified time will result in the loss of 30% of the initial stake and forfeiting any rewards?"
        )}
      </div>
      <div className="h4 text-misty_lavender mb-1">{t("You will get")}</div>
      <div className="h2 text-soft_sky mb-4">{amount} GGR</div>
      <div className="flex gap-2">
        <Button text={t("Continue")!} type={ButtonType.TRANSPARENT} onClick={onContinueClick} />
        <Button text={t("Back")!} onClick={onBackClick} />
      </div>
    </>
  );
};

export default StakingWithdrawalAlertScene;
