import { BigNumber } from "ethers";
import { useAccountQuery } from "graphql/@generated/graphql";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Ido } from "sdk";
import { selectIsProjectTimerRunning, selectProjectStage } from "store/slice";
import { useAccount } from "wagmi";
import { useApplication } from "./use-application";
import { ApplicationStatus, useApplicationStatus } from "./use-application-status";
import { useLeftToRaiseAmount } from "./use-left-to-raise";

export enum HighTierFcfsStage {
  F1_HAS_NO_APP = 1,
  F2_HAS_APP_NOT_REDEEM,
  F3_HAS_APP_REDEEM,
}

export const useIdoHighTierFcfsStage = (ido: Ido) => {
  const { application } = useApplication(ido);
  const { address } = useAccount();

  const { data: account } = useAccountQuery({
    variables: {
      getAccountInput: {
        addr: address,
      },
    },
    skip: !address,
  });
  const projectStage = useSelector(selectProjectStage);
  const isProjectTimerRunning = useSelector(selectIsProjectTimerRunning);
  const applicationStatus = useApplicationStatus(ido);
  const leftToRaiseAmount = useLeftToRaiseAmount(ido);

  return useMemo(() => {
    const isTierAccepted =
      account?.account.tier === "UNIVERSE" || account?.account.tier === "GALAXY";
    if (!isTierAccepted || applicationStatus === ApplicationStatus.rejected) {
      return HighTierFcfsStage.F1_HAS_NO_APP;
    }

    const projectFcfsMaxAmount = BigNumber.from(ido.fcfsMaxCount);
    let availableAmount = projectFcfsMaxAmount.sub(application?.fcfsAmount || 0);
    if (availableAmount.lt(0)) {
      availableAmount = BigNumber.from(0);
    }

    if (isTierAccepted && !availableAmount.isZero() && !leftToRaiseAmount.isZero()) {
      return HighTierFcfsStage.F2_HAS_APP_NOT_REDEEM;
    }

    return HighTierFcfsStage.F3_HAS_APP_REDEEM;
  }, [projectStage, applicationStatus, application, ido, isProjectTimerRunning, account]);
};
