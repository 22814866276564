import cn from 'classnames';

type TProps = {
  className?: string;
  title?: string;
  items?: {
    value: string;
  }[];
};

const SimpleList: React.FC<TProps> = ({ className, title, items }) => {
  return (
    <div className={cn('', className)}>
      {title && <div className="p2b text-text_primary mb-1 last:mb-0">{title}</div>}
      {items &&
        items.map((item, i) => (
          <div key={i} className="p3 text-text_secondary mb-1 last:mb-0">
            {item.value}
          </div>
        ))}
    </div>
  );
};

export default SimpleList;
