import DescriptionWizard from "app/components/description-wizard/description-wizard";
import IdoTimeline from "app/components/ido/ido-timeline";
import IdoWizardMobile from "app/components/ido/ido-wizard-mobile/ido-wizard-mobile";
import ProjectNews from "app/components/project/project-news/project-news";
import ProjectList from "app/components/project/project.list";
import { LocalStorageItem, ProjectStage, QueryParam } from "app/enums";
import {
  useApplication,
  useAuth,
  useBlockchainProject,
  useQuery,
  useRefundPolicy,
  useRefundPolicyTimer,
  useUpdateIdoImages,
  useUpdateIdoStage,
  useWidth,
} from "app/hooks";
import { Params, Path } from "app/pages";
import { AccountQuery, useAccountQuery } from "graphql/@generated/graphql";
import { TierTypes } from "graphql/account-query";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { Ido } from "sdk";
import tokenService from "services/token.service";
import { useCreateReferalMutation, useIdoByIdQuery, useIdosAdQuery } from "store/api";
import { selectProject } from "store/slice";
import { useAccount, useChainId } from "wagmi";
import IdoInfo from "../components/ido/ido-info";
import IdoWizard from "../components/ido/ido-wizard/ido-wizard";
import RocketLoader from "../components/loader/rocket-loader";
import ProjectHeader from "../components/project/project-header";
import RefundPolicy from "../components/project/refund-policy";
import ProjectPage from "./project.page";
import "./project.page.sass";

const IdoHooks: FC<{ ido?: Ido, account?: AccountQuery }> = ({ ido, account }) => {
  useUpdateIdoStage(ido);
  useRefundPolicy(ido, account?.account.tier as TierTypes);
  useUpdateIdoImages(ido);

  return null;
};

const IdoPage: FC = () => {
  const { idoId } = useParams<Params>();
  const { isMobileWidth } = useWidth();
  const isAuth = useAuth();
  const { projectStage } = useSelector(selectProject);
  const { address } = useAccount();
  const chainId = useChainId();

  const { queryParam } = useQuery(QueryParam.REFERAL_CODE);

  const { data: account } = useAccountQuery({
    variables: {
      getAccountInput: {
        addr: address,
      },
    },
    skip: !address,
  });
  const {
    data: ido,
    refetch: refetchIdo,
    isLoading: isIdoLoading,
    error: idoError,
  } = useIdoByIdQuery(idoId, {
    pollingInterval: 10000,
  });
  const { data: idosAd, isLoading: isIdosAdLoading } = useIdosAdQuery(ido?.title, {
    refetchOnMountOrArgChange: true,
  });
  const refundPolicyTimerProps = useRefundPolicyTimer(ido, account?.account.tier as TierTypes);
  const { application, isApplicationLoading, refetchApplication } = useApplication(ido);
  const { blockchainProject, refetchBlockchainProject } = useBlockchainProject(ido);
  const [createReferal] = useCreateReferalMutation();

  const checkReferal = useCallback(async () => {
    if (queryParam) {
      localStorage.setItem(LocalStorageItem.REFERAL_CODE, queryParam);
    }

    if (!address) return;

    const code = localStorage.getItem(LocalStorageItem.REFERAL_CODE);
    if (!code) return;

    if (ido?.id) {
      await createReferal({ code, idoId: ido.id });
    }
  }, [queryParam, address, ido?.id]);

  const isLoading = useMemo(() => {
    return isIdoLoading || isApplicationLoading || !idoId || !ido;
  }, [isIdoLoading, isApplicationLoading, idoId, ido]);

  useEffect(() => {
    console.log({ projectStage });
  }, [projectStage]);

  useEffect(() => {
    try {
      refetchIdo();
      refetchApplication();
    } catch (error) {
      console.error(error);
    }
  }, [isAuth, tokenService.tokens?.accessExpiresIn, chainId]);

  useEffect(() => {
    if (!blockchainProject) return;
    if (projectStage >= ProjectStage.claimGap && blockchainProject) {
      refetchBlockchainProject();
    }
  }, [isAuth, tokenService.tokens?.accessExpiresIn, chainId, projectStage]);

  useEffect(() => {
    checkReferal();
  }, [checkReferal]);

  return (
    <>
      <ProjectPage>
        <div className="project">
          {(idoError as any)?.status === 404 && <Redirect to={Path.MAIN} />}
          <IdoHooks ido={ido} account={account} />
          <RocketLoader className="project__loader" isLoading={isLoading}>
            {ido && (
              <>
                {isMobileWidth ? (
                  <>
                    <IdoWizardMobile ido={ido} />
                    <RefundPolicy ido={ido} {...refundPolicyTimerProps} />
                    <DescriptionWizard project={ido} />
                    <ProjectNews project={ido} />
                  </>
                ) : (
                  <ProjectHeader project={ido} />
                )}
                <IdoTimeline className="project__timeline" ido={ido} />
                <div className="project__body">
                  <div className="project__info">
                    <IdoInfo ido={ido} />
                    {!isMobileWidth && <ProjectNews project={ido} />}
                  </div>
                  {!isMobileWidth && (
                    <div className="project__wizard">
                      <IdoWizard ido={ido} />
                      <RefundPolicy ido={ido} {...refundPolicyTimerProps} />
                      <DescriptionWizard project={ido} />
                    </div>
                  )}
                </div>
              </>
            )}
          </RocketLoader>
        </div>
      </ProjectPage>
      <ProjectList
        title="Participate in ongoing token sales"
        projects={idosAd}
        isLoading={isIdosAdLoading || !idosAd}
      />
    </>
  );
};

export default IdoPage;
