import cn from "classnames";
import { FC, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import "./card.sass";

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, "id"> {
  contentPlacement?: "bottom-center" | "top-center" | "center-center" | "center-left";
  size?: "sm" | "base";
  shape?: "square";
  target?: "_blank";
  bg?: any;
  href?: string;
  onClick?: () => void;
  soon?: boolean;
  soonText?: string;
}

const Card: FC<Props> = ({
  className,
  children,
  contentPlacement = "bottom-center",
  style,
  shape,
  bg,
  href,
  target,
  size = "base",
  soon,
  soonText,
  onClick,
}) => {
  const Tag = href ? "a" : "div";
  const { t, i18n } = useTranslation();
  return (
    <Tag
      href={href!}
      target={target}
      style={{
        // backgroundImage: !bg
        //   ? "linear-gradient(180deg, rgba(15, 28, 43, 0) 0%, rgba(87, 165, 255, 0.1) 100%)"
        //   : "",
        ...style,
      }}
      onClick={onClick || (() => {})}
      className={cn(
        "ton-card w-[450px] max-lg:w-full max-lg:max-w-[420px] max-lg:mx-auto",
        className,
        {
          " _square ": shape === "square",
          " _bottom-center ": contentPlacement === "bottom-center",
          " _top-center ": contentPlacement === "top-center",
          " _center-center ": contentPlacement === "center-center",
          " _center-left ": contentPlacement === "center-left",
          " _relative": !!bg,
          " _no-bg": !bg,
          " _base": size === "base",
          " _sm": size === "sm",
          " _pointer": onClick,
        }
      )}
    >
      {bg && <img className="ton-card__img  " src={bg} alt="" />}
      {soon && (
        <div className={cn("ton-card__soon-text")}>
          {soonText} <div className="ton-card__soon-text-sub">{t("Soon")}</div>
        </div>
      )}
      <div
        className={cn(" ton-card__content ", {
          " _base": size === "base" && shape === "square",
          " _sm": size === "sm" && shape === "square",
          " _square-bc ": shape === "square" && contentPlacement === "bottom-center",
          " _square-c": shape !== "square",
          " _soon": soon,
        })}
      >
        {soon && <div className={cn("ton-card__soon")}></div>}

        {children}
      </div>
    </Tag>
  );
};

export default Card;
