import { getErc20Contract } from "app/utils/contract-helper";
import { ChainId } from "sdk";
import { HexString } from "sdk/src/types/hex-string";
import { useAccount, useChainId, useContractRead } from "wagmi";

export const useAllowanceQuery = ({ spender, token }: { spender: HexString; token: HexString }) => {
  const chainId = useChainId() as ChainId;
  const { address: account } = useAccount();
  const response = useContractRead({
    chainId,
    ...getErc20Contract(chainId, token),
    functionName: "allowance",
    enabled: Boolean(account && spender),
    args: account && spender ? [account, spender] : undefined,
    watch: true,
  });
  return response;
};
