import { NETWORK_NAMES_MAP } from "sdk";

const en = {
  "Connect Wallet": "Connect Wallet",
  About: "About",
  Projects: "Projects",
  Staking: "Staking",
  "Buy GGR": "Buy GGR",
  "Apply Project": "Apply Project",
  "Account settings": "My account",
  "Smart Investing": "Smart\u00A0Investing",
  "Log out": "Log out",
  "About GAGARIN": "About\u00A0GAGARIN",
  "Stake GGR": "Stake GGR",
  "Projects on GAGARIN": "Projects on GAGARIN",
  "Want to launch your project on the GAGARIN Launchpad?":
    "Want to launch your project on the GAGARIN\u00A0Launchpad?",
  "Leave an application for acceleration and launch a project together with our experts":
    "Leave an application for acceleration and launch a project together with our experts",
  "SOLIDITY.FINANCE AUDIT PASSED": "SOLIDITY.FINANCE AUDIT PASSED",
  "WE ARE AUDITED": "WE ARE AUDITED",
  "apply to launch": "apply to launch",
  "Apply for IDO": "Apply for IDO",
  "Apply for Incubation": "Apply for Incubation",
  "Terms of Services": "Terms of Services",
  "Privacy Policy": "Privacy Policy",
  Documentation: "Documentation",
  New: "New",
  Soon: "Soon",
  My: "My",
  All: "all",
  Live: "live",
  Closed: "closed",
  Back: "Back",
  Timeline: "Timeline",
  Upcoming: "Upcoming",
  Swap: "Swap",
  FCFS: "FCFS",
  Filled: "Filled",
  Claimable: "Claimable",
  Ended: "Ended",
  Stage: "Stage",
  Duration: "Duration",
  "Whitelist start": "Whitelist start",
  "Whitelist end": "Whitelist end",
  "Swap start": "Swap start",
  "Swap end": "Swap end",
  "FCFS start": "FCFS start",
  "FCFS end": "FCFS end",
  "High tier FCFS end": "High tier FCFS end",
  "Next claim": "Next claim",
  "Payments are over": "Payments are over",
  "Starts on": "Starts on",
  "Ends on": "Ends on",
  "Whitelist starts on": "Whitelist starts on",
  "Whitelist ends on": "Whitelist ends on",
  "Swap starts on": "Swap starts on",
  "Swap ends on": "Swap ends on",
  "FCFS ends on": "FCFS ends on",
  "Starts after": "Starts after",
  "Ends after": "Ends after",
  "Whitelist starts after": "Whitelist starts after",
  "Whitelist ends after": "Whitelist ends after",
  "Swap starts after": "Swap starts after",
  "Swap ends after": "Swap ends after",
  "FCFS ends after": "FCFS ends after",
  "Next claim after": "Next claim after",
  "Next claim at": "Next claim at",
  Refund: "Refund",
  Refunded: "Refunded",
  "You did not apply for IDO or your application was rejected":
    "You did not apply for IDO or your application was rejected",
  "You didn't win the allocation, but you can still participate in the FCFS. Follow the news":
    "You didn't win the allocation, but you can still participate in the FCFS. Follow the news",
  "You didn't win the allocation, thank you for participation":
    "You didn't win the allocation, thank you for participation",
  "Your application has been sent. The results of the lottery will be announced 24 hours before the start of IDO (Round 1).":
    "Your application has been sent. The results of the lottery will be announced 24 hours before the start of IDO (Round 1).",
  "Application has been accepted. The results will be published before the swap starts.":
    "Application has been accepted. The results will be published before the swap starts.",
  "Application was rejected. Check the correctness of social nicknames and try to apply again.":
    "Application was rejected. Check the correctness of social nicknames and try to apply again.",
  "Apply to Whitelist": "Apply to Whitelist",
  "Wallet address": "Wallet address",
  Copied: "Copied",
  "Invesment History": "Invesment History",
  "No investmens yet": "No investmens yet",
  "My Account": "My Account",
  Tomorrow: "Tomorrow",
  "My Tier": "My Tier",
  Earth: "Earth",
  Orbit: "Orbit",
  Galaxy: "Galaxy",
  Universe: "Universe",
  "Mandatory social tasks": "Mandatory social tasks",
  "Without social tasks": "Without social tasks",
  "Allocation via lottery": "Allocation via lottery",
  "Guaranteed allocation": "Guaranteed allocation",
  "Guaranteed dynamic allocation": "Guaranteed dynamic allocation",
  yes: "yes",
  no: "no",
  soon: "soon",
  Min: "Min",
  Max: "Max",
  "Tier Bonus System": "Tier Bonus System",
  Airdrop: "Airdrop",
  Commision: "Commision",
  Confirm: "Confirm",
  "Claim progress": "Claim progress",
  "Available For Claim": "Available For Claim",
  "Already Recieved": "Already Recieved",
  "Total Allocation Power": "Total Allocation Power",
  Claim: "Claim",
  Next: "Next",
  Tokens: "Tokens",
  Balance: "Balance",
  Approve: "Approve",
  Approved: "Approved",
  swapButton: "Swap",
  partialSwapButton: "Swap {{amount}}",
  "Swap all": "Swap all",
  "Insufficient balance": "Insufficient balance {{symbol}}",
  "Incorrect amount": "Incorrect amount",
  "You paid in total": "You paid in total",
  "You will receive": "You will receive",
  "There are no tokens available for redemption": "There are no tokens available for redemption.",
  "The pool is filled": "The pool is filled",
  pitchdeck: "pitchdeck",
  whitepaper: "whitepaper",
  tokenomics: "tokenomics",
  "Exchange Rate": "Exchange Rate",
  "Exchange Networks": "Swap Network",
  "Total Raise": "Total Raise",
  "Refund of funds": "Refund of funds",
  "You can return project tokens in the amount of the current claim":
    "You can return project tokens in the amount of the current claim",
  "Refund of project tokens": "Refund of project tokens",
  "Reason for refund": "Reason for refund",
  "Specify the reason for the return": "Specify the reason for the return",
  "You can only return the entire amount": "You can only return the entire amount",
  "Confirm refund": "Confirm refund",
  Receiving: "Receiving",
  Amount: "Amount",
  "successfully returned, thank you for your participation":
    "successfully returned, thank you for your participation",
  "successfully returned. The funds will be transferred to the wallet in the near future":
    "successfully returned. The funds will be transferred to the wallet in the near future",
  "The time to return the project tokens is over, wait for the fund transfer to the wallet":
    "The time to return the project tokens is over, wait for the fund transfer to the wallet",
  "View in": "View in",
  "Complete a short survey and click the «Confirm refund» button. The amount of the refund is filled in automatically.":
    "Complete a short survey and click the «Confirm refund» button. The amount of the refund is filled in automatically.",
  "Sign the transaction in a crypto wallet": "Sign the transaction in a crypto wallet",
  "Wait for the transaction response": "Wait for the transaction response",
  "Your funds successfully returned, thank you for your participation":
    "Your funds successfully returned, thank you for your participation",
  "Return of tokens completed successfully. Wait for the transfer of funds to the wallet":
    "Return of tokens completed successfully. Wait for the transfer of funds to the wallet",
  "If you want a refund, then follow these steps": "If you want a refund, then follow these steps.",
  "I have read the instructions": "I have read the instructions",
  Continue: "Continue",
  "Now you have time to prepare the funds for swap. You will be able to redeem allocation after the timer expires":
    "Now you have time to prepare the funds for swap. You will be able to redeem allocation after the timer expires.",
  "The FСFS stage is underway, have time to buy additional tokens":
    "The FСFS stage is underway, have time to buy additional tokens",
  "You have successfully redeemed tokens! You will be able to receive them according to the distribution schedule.":
    "You have successfully redeemed tokens! You will be able to receive them according to the distribution schedule.",
  "Lottery is running": "Lottery is running.",
  "Please wait for the results": "Please wait for the results.",
  "You have won the allocation": "You have won the allocation",
  "Please prepare funds for swap": "Please prepare funds for swap",
  Lottery: "Lottery",
  "Lottery results": "Lottery results",
  Wait: "Wait",
  "Project description": "Project description",
  "Raise Currency": "Raise Currency",
  "Swap Amount": "Swap Amount",
  "Claim Type": "Claim Type",
  "Claim on": "Claim on",
  "Advanced Protection System": "Advanced Protection",
  "Protection System": "Protection",
  "No Refund": "No Refund",
  Network: "Network",
  "Claim Network": "Claim Network",
  "Payment Schedule": "<0>Payment</0> Schedule",
  Requirements: "Requirements",
  "not required": "not required",
  "Tier not required": "Tier not required",
  required: "required",
  "Social activities": "Social activities",
  View: "View",
  "IDO is over. Thanks for participation": "IDO is over. Thanks for participation",
  Days: "Days",
  Hours: "Hours",
  Minutes: "Minutes",
  Seconds: "Seconds",
  "No projects at this stage": "No projects at this stage",
  "You have no active projects": "You have no active projects",
  "Applying for Whitelist": "Applying for <0>Whitelist</0>",
  "Please make sure you have completed all the tasks on":
    "Please make sure you have completed all the tasks on",
  "To apply to Whitelist you need to complete the tasks below":
    "To apply to Whitelist you need to complete the tasks below",
  "To apply to Whitelist you will need to complete the tasks below":
    "Complete the tasks and take part in the Whitelist lottery. An additional allocation of +10% will be given to those investors who have a Tier if they win the Whitelist lottery.",
  "Participate in the Whitelist lottery":
    "Participate in the Whitelist lottery and get the opportunity to take part in IDOs without commission fees. Attention! If you have a Tier on GAGARIN, you are entitled to a guaranteed allocation without participating in the Whitelist lottery. Every participant who has a Tier on GAGARIN and wins the lottery will receive +10% to the allocation given. Apply, complete the tasks, and get the opportunity to participate in IDOs with advanced capabilities!",
  "Please make sure you have completed all the tasks and entered your contact information correctly so that we can check your social activities and accept your application.":
    "Please make sure you have completed all the tasks and entered your contact information correctly so that we can check your social activities and accept your application.",
  "Complete all tasks on the link": "Complete all tasks on the link",
  "click to go to SweepWidget": "click to go to SweepWidget",
  "Send your nickname on social networks for verification":
    "Send your nickname on social networks for verification",
  "Invalid characters. Allowed characters: a-z, 0-9, underscores.":
    "Invalid characters. Allowed characters: a-z, 0-9, underscores.",
  "Subscribe to telegram accounts": "Subscribe to telegram accounts",
  "Subscribe to twitter accounts": "Subscribe to twitter accounts",
  "After Whitelist opens, you will need to enter your social nicknames to check the completion of social tasks and accepts your application. You can subscribe now to be as prepared as possible for Whitelisting.":
    "After Whitelist opens, you will need to enter your social nicknames to check the completion of social tasks and accepts your application. You can subscribe now to be as prepared as possible for Whitelisting.",
  "There are GGR left until the next tier":
    "There are {{leftToNextTier}} GGR left until the next tier",
  "My IDO": "My IDO",
  "You are at top tier! Congratulations!": "You are at top tier! Congratulations!",
  Edit: "Edit",
  "Connect Smart Investing": "Connect Smart Investing",
  "Smart Investing is a form of investment where the investor provides funds that the gagarin platform invests in all projects placed on it automatically. The investor also receives income by airdrop at the end of the IDO":
    "Smart Investing is a form of investment where the investor provides funds that the gagarin platform invests in all projects placed on it automatically. The investor also receives income by airdrop at the end of the IDO",
  "This service is available to everyone. Fill in the fields to activate the service. As a payment for auto investment,a commission will be charged":
    "This service is available to everyone. Fill in the fields to activate the service. As a payment for auto investment,a commission will be charged",
  "Enter the amount to invest in USDT or BUSD. Then you will be able to supplement the balance of both currencies":
    "Enter the amount to invest in USDT or BUSD. Then you will be able to supplement the balance of any of these currencies",
  "Participate in FCFS?": "Participate in FCFS?",
  "Enter the amount in ": "Enter the amount in ",
  "Disconnect Smart Investing": "Disconnect Smart Investing",
  Replenish: "Replenish",
  "Refund the funds?": "<0>Refund</0> the funds?",
  Notifications: "Notifications",
  "connect to telegram bot": "connect to telegram bot",
  "telegram bot": "telegram bot",
  "Replenishment of the balance": "Replenishment of the balance",
  "loading...": "loading...",
  "The possibility of partial redemption is available to you. You can redeem the desired amount within the available range.":
    "The possibility of partial redemption is available to you. You can redeem the desired amount within the available range.",
  "project tokens that you haven't claimed yet": "project tokens that you haven't claimed yet",
  "Project tokens for refund": "Project tokens for refund",
  "You can return the project tokens only once": "You can return the project tokens only once",
  "The site is under maintenance. It will be available again very soon":
    "The site is under maintenance. It will be available again very soon",
  "Participants' funds have been successfully refunded, thank you for your participation":
    "Participants' funds have been successfully refunded, thank you for your participation",
  "Swap amount limit in": "Swap amount limit in {{currencySymbol}}",
  "FCFS amount limit in": "FCFS amount limit in {{currencySymbol}}",
  "Swap Limits": "Swap Limits",
  "FCFS Limits": "FCFS Limits",
  "To description": "To description",
  "Choose Network": "Choose Network",
  "Choose Wallet": "Choose Wallet",
  "I read and accept": "I read and accept",
  and: "and",
  Accept: "Accept",
  "Refund Funds": "Refund Funds",
  "You didn't make the last claim, so you don't need to return tokens for a refund":
    "You didn't make the last claim, so you don't need to return tokens for a refund",
  "Refund Policy":
    "Within <1>two hours</1> after the first claim date, you can make a full refund of funds. If you have already claimed the project tokens, the refund will not be available. Please note that after the refund, this IDO will be closed to you.",
  "cancel and back to claim": "cancel and back to claim",
  "You can only return the full amount of tokens": "You can only return the full amount of tokens",
  "Join the private group of investors": "Join the private group of investors",
  "CoinEx Smart Chain (CSC) is a decentralized and highly efficient public blockchain, whose native $CET token has a current capitalization of over $20 million. CSC is fully compatible with the Ethereum ecosystem, can perform up to 10,000 transactions per second and has a low transaction cost (currently less than $0.005).":
    "CoinEx Smart Chain (CSC) is a decentralized and highly efficient public blockchain, whose native $CET token has a current capitalization of over $20 million. CSC is fully compatible with the Ethereum ecosystem, can perform up to 10,000 transactions per second and has a low transaction cost (currently less than $0.005).",
  Advantages: "Advantages",
  "Full compatibility with EVM": "Full compatibility with EVM",
  "DApps on Ethereum can be transferred directly to CSC.":
    "DApps on Ethereum can be transferred directly to CSC.",
  "Unique opportunities for the investors of CoinEx Smart Chain":
    "Unique opportunities for the investors of CoinEx Smart Chain",
  "The opportunity to redeem project tokens for stablecoins":
    "The opportunity to redeem project tokens for stablecoins",
  "Participate in the private round of GAGARIN and get all the bonuses":
    "Participate in the private round of GAGARIN and get all the bonuses",
  "Learn about the early investor support program":
    "Learn about the early investor support program",
  "Projects on CoinEx Smart Chain": "Projects on CoinEx Smart Chain",
  "The best projects of the CSC network will soon be available":
    "The best projects of the CSC network will soon be available",
  "Switch Network": "Switch Network",
  "You are on an unsupported network.": "You are on an unsupported network.",
  "You have not redeemed in this network.": "You have not redeemed in this network.",
  "Switch the network to continue.": "Switch the network to continue.",
  "For this IDO we need an additional information. Fill the fields below please.":
    "For this IDO we need an additional information. Fill the fields below please.",
  "Crosschain message":
    "Dear investors!<1></1>Pay your attention that the {{projectTitle}} tokens ({{tokenSymbol}}) deposit is available on several blockchain networks: {{networksList}}.<9></9> The distribution of {{projectTitle}} tokens will be carried out in {{projectNetwork}} only!",
  "Something went wrong. We are already figuring out what's going on and will immediately inform you about the results":
    "Something went wrong. We are already figuring out what's going on and will immediately inform you about the results",
  "This project has no activities yet": "This project has no activities yet 🚀",
  "Claim Type message":
    "Dear investors! <1></1> Please note that the distribution of {{projectTitle}} tokens ({{tokenSymbol}}) will be carried out by the project itself.",
  "You also have": "You also have",
  "wrong network refund message": "{{redeemed}} <2>{{symbol}}</2> left in {{networkName}}",
  "Refund confirm modal desc":
    'Pay your attention that by clicking on the "Yes" button you confirm your intention to refuse taking part in this IDO.<1></1><2>This action is irreversible.</2>',
  "Refund confirm modal title": "<0>Refund</0> the funds?",
  "Thanks for participating!": "Thanks for participating!",
  projectEnded: "You have claimed <1></1><2></2><3></3><4></4><5></5><6></6>",
  after: "after",
  "Sportzchain message":
    "Dear investors! <1></1> GAGARIN gives a unique opportunity to Sportzchain investors. Within a week from the time of the claim, those investors who do not claim tokens will be credited with SPN at the rate of 1000% APR for 7 days for the amount of the first claim!",
  "Referral program": "Referral program",
  "Referral Projects": "Referral Projects",
  Referrals: "Referrals",
  Award: "Award",
  "Your Address": "Your Address",
  "Total Refund": "Total Refund",
  Status: "Status",
  Address: "Address",
  "in proccess": "in proccess",
  paid: "paid",
  "First Name": "First Name",
  "Last Name": "Last Name",
  Country: "Country",
  "Passport Photo": "Passport Photo",
  "I consent to the provision and processing of personal data to the GAGARIN partner for the KYC procedure":
    "I consent to the provision and processing of personal data to the GAGARIN partner for the KYC procedure",
  "Aftermath message":
    "Dear investors! <1></1> There are restrictions on the purchase of Aftermath Islands tokens, which you can find on the project website.",
  "The current round is available only to GAGARIN investors":
    "The current round is available only to GAGARIN investors",
  "The current round is available only to Galaxy or Universe Tiers owners":
    "The current round is available only to Galaxy or Universe Tiers owners",
  "FCFS round for GAGARIN investors. Allocations are not guaranteed and can be repurchased.":
    "FCFS round for GAGARIN investors. Allocations are not guaranteed and can be repurchased.",
  "Boxes Count": "Boxes Count",
  "One Box": "One Box",
  "Box description": "Box description",
  "There are no boxes available for redemption": "There are no boxes available for redemption",
  "The boxes are sold": "The boxes are sold",
  "Box title": "Box title",
  Quantity: "Quantity",
  from: "from",
  "My purchases": "My purchases",
  "Enter the count": "Enter the count",
  "Go to claim": "Go to claim",
  "Dear investor, NFT claim will take place at the project site":
    "Dear investor, NFT claim will take place at the project site",
  Title: "Title",
  Left: "Left",
  Start: "Start",
  End: "End",
  Chance: "Chance",
  ClaimAction: "Claim",
  "Grab the best NFTs while you have a time!": "Grab the best NFTs while you have a time!",
  "Nft list": "Nft list",
  Price: "Price",
  "You did not apply for INO": "You did not apply for INO",
  "Participate in ongoing token sales": "Participate in ongoing token sales",
  "How to participate in IDO?": "How to participate in IDO?",
  "Join the telegram chat": "Join the telegram chat",
  referalProgramPage: {
    title: "GAGARIN <1>referral program</1>",
  },
  smartInvestingPage: {
    title: "Get income and airdrops <1></1> <2>automatically</2>",
  },
  projectRefund: {
    title: "The <1 >refund</1> stage",
    desc: "You can return the funds spent on the IDO of this project. <1></1> If you return the funds, you will <3>no longer be able</3> to participate in this IDO",
  },
  accountPage: {
    buy: "buy",
    claim: "claim",
  },
  mainPage: {
    title:
      "Expert <1></1> platform for <3>incubating</3>\u00A0<5></5><6>projects</6> and <8>launching\u00A0IDOs</8>",
    desc: "The projects that we\u00A0will launch will receive full technical expertise and marketing support. We\u00A0will not just launch a\u00A0project, we\u00A0will make it\u00A0overcome gravity!",
  },
  tags: {
    upcoming: "upcoming",
    live: "live",
    closed: "closed",
    refund: "refund",
    refunded: "refunded",
    bsc: "BNB Chain",
    polygon: "polygon",
    okc: "okc",
    coinex: "coinex",
    csc: "csc",
    solana: "solana",
    postponed: "postponed",
    expanded: "expanded",
    ...NETWORK_NAMES_MAP,
  },
  projectFill: {
    filled: "Sales are over. <1></1> Thank you for your participation.",
  },
  links: {
    whitepaper: "https://gagarin.world/GAGARIN_Whitepaper_EN.pdf",
    tgChat: "https://t.me/Gagarin_Launchpad_Chat",
  },
  coinexPage: {
    title: "GAGARIN is <1></1> integrated with <3></3> <4>CoinEx Smart Chain<4>",
    desc: "CoinEx Smart Chain (CSC) is\u00A0a\u00A0decentralized and highly efficient public blockchain, whose native $CET token has a\u00A0current capitalization of\u00A0over $20\u00A0million. CSC is\u00A0fully compatible with the Ethereum ecosystem, can perform up\u00A0to\u00A010,000 transactions per second and has a\u00A0low transaction cost (currently less than $0.005).",
    advantageDecentralization: "Full <1></1> decentralization",
    advantageDecentralizationText:
      "The CoinEx Smart Chain network <1></1> supports up\u00A0to\u00A0101 validators that are sorted and approved based on\u00A0their CET token rate.",
    advantageHighPerformanceText:
      "Blocks are generated in\u00A0seconds, allowing the network to\u00A0have high capacity and low transaction costs.",
    advantageCompatibility: "Full <1></1> compatibility with EVM",
    advantageHighPerformance: "High performance <1></1> with low fees",
    opportunityTwo: "Participation in\u00A0the private round of\u00A0GAGARIN",
    opportunityThree: "Priority participation in\u00A0the IDOs of\u00A0projects",
  },
  FCFSFee:
    "Dear investors! <1></1> Please note that at the FCFS stage, a non-refundable fee of 5% is charged for participation in the IDO if you don't have any tier. <4>Stake</4> GGR tokens to avoid fees.",
  timeline: {
    claim: "Claim",
  },

  //  new translation
  accountInfo: {
    LinkTonMyAccount: "https://ton.gagarin.world/app/dashboard",
    title: "Account info", // Информация об аккаунте
    balance: "TOTAL BALANCE", // Баланс
    networks: "IN OTHER NETWORKS", // В других сетях
    staking: "IN STAKING", // В стейкинге
    claiming: "IN VESTING", // В вестинге
    inWallet: "IN WALLET", // В кошельке
  },
  staking: {
    lockTime: "Lock time",
    title: "Staking", // Стейкинг
    till: "LOCKED TILL", // Заблокировано до
    totalStaking: "TOTAL LOCKED", //  Всего
    initialAmount: "INITIAL STAKE", //  Стейк
    rewardAmount: "REWARD AMOUNT", //  Награда
    subInfo: {
      text1: "Lock", // Заблокируйте
      text2: "GGR more to get the next next tier", // чтобы получить следующий тир
      subText: "ADD", // Добавить
    },
    balance: "Balance", // Баланс
  },
  modal: {
    confirmationTitle: "Are you sure?", // Вы уверены?
    titleSuccess: "Success", // Успех
    titleError: "Error", // Ошибка
    textError: "Something went wrong", // Что-то пошло не так
    yes: "YES",
    no: "NO",
    claimModal: {
      title: "Claim", // Получить
      text: "AVAILABLE TO CLAIM", // Доступно к получению
      textSuccess: "Done! You've successfully claimed your GGR", // Готово! Вы успешно получили свои GGR
    },
    stakingModal: {
      text: "Restake your amount and earn even more!", // Получите больше застейкав еще раз!
      subText: "Restake", // Повторить стейк
      success: "Done! You've successfully staked your GGR!", // Готово! Вы успешно застейкали свои GGR,
      emergencyWithdrawDescription:
        "If you withdraw your tokens before the release date, you will not receive a reward for staking and will also lose 30% of the initial stake.",
      youWillGet: "you will get",
    },
    claimModalPrivate: {
      title: "What you'll lose", // Чт овы теряете
      text1: "on",
      text2: "Changing the conditions for obtaining new tier", // Изменятся условия для получения тиров
      text3: "You will get less allocation on projects", // Вы будете получать меньшую аллокацию на проектах
    },
    withdrawModal: {
      text: "You can repeat staking for this amount and earn even more!", //Вы можете повторить ставку на эту сумму и заработать еще больше!
      subText: "Repeat staking", //Повторить ставку
    },
  },
  buttons: {
    buttonStake: "Stake", // Застейкать
    buttonChange: "Change", // Изменить
    buttonWithdraw: "Withdraw", // Вывести
    buttonBuy: "Buy GGR", // Купить GGR
    buttonClaim: "Claim", // Gjkexbnm
  },
  tier: {
    title: "My Tier", // Мой тир
    HowToGet: "How to obtain an investor's Tier?",
    receivedTier: "RECEIVED TIER", // Полученный тир
    nextTier: "NEXT TIER", // Следующий тир
    tierEarth: {
      name: "Earth", // Замля
      lottery: "Allocation via lottery", // Лотерея
      tasks: "Mandatory social tasks", // Обязательные социальные активности
      airdrop: "No airdrop", // Без эйрдропа
    },
    tierOrbit: {
      name: "Orbit", // Орбита
      lottery: "Guaranteed allocation", // Гарантированная аллокация
      tasks: "Mandatory social tasks", // Обязательные социальные активности
      airdrop: "No airdrop", // Без эйрдропа
    },
    tierGalaxy: {
      name: "Galaxy", // Галактика
      lottery: "Guaranteed allocation", // Гарантированная аллокация
      tasks: "Mandatory social tasks", // Обязательные социальные активности
      airdrop: "Airdrop", // Эйрдроп
    },
    tierUniverse: {
      name: "Universe", // Вселенная
      lottery: "Guaranteed allocation", // Гарантированная аллокация
      tasks: "Without social tasks", // Не обязательные социальные активности
      airdrop: "Airdrop", // Эйрдроп
    },
    sorry: "Sorry, you don't have enought GGR to get a tier", // Извините, у вас недостаточно GGR чтобы получить тир
    nft: {
      title: "Bonus NFTs",
    },
    totalAllocationPower: "Total Allocation Power",
    forTheNextTier: "for the next tier",
  },
  claim: {
    noBalance: "There are no GGR tokens available for claiming",
    title: "Vesting progress", // вестинг
    informationUpdate: "Information update",
    nextClaim: "Next claim", // Следующий разлок
    totalAmount: "Total amount", // Всего
    alreadyReceived: "Already received", // Уже получено
    privateRound: {
      title: "Private sale ", // Закрытая продажа
      text: "10% at TGE, then in equal installments every month for 12 months", // ТУТ НАДО ЕЩЕ ДОРАБОТАТЬ РАУНДЫ
    },
    Marketing2: {
      title: "Marketing #2",
      text: "100% 90 days after TGE",
    },
    Marketing3: {
      title: "Marketing #3",
      text: "100% on 24 hours after TGE",
    },
    availableForClaim: "AVAILABLE FOR CLAIM", // Доступно для получения
    moreInfo: "Details", // Подробнее
  },
  month: "M",
  year: "Y",
  week: "W",
  workingFCFSRound1:
    "A 0% commission for the redemption of tokens. It is available only for those investors who have Tier 1 to Tier 4.",
  workingFCFSRound2:
    "Available for everyone, the commission for token redemption for those investors who do not have a Tier is 5% (for Tier 1 to Tier 4 investors - 0%).",
  tonLaunchpad: "https://ton.gagarin.world/app/",
  "Applications for Whitelisting for the Paysenger Community Sale are now open. Attention! Only $GGR holders and Tier holders who have submitted an application for the whitelist are eligible to participate in the sale.":
    "Applications for Whitelisting for the Paysenger Community Sale are now open. Attention! Only $GGR holders and Tier holders who have submitted an application for the whitelist are eligible to participate in the sale.",
  signLoginMessageModalBody:
    "Please sign the Authentication Message in your crypto wallet or click the Retry button if the signature request was not received.",
  signLoginMessageModalTitle: "<0>Connect</0> wallet",
  retry: "Retry",
  cancel: "Cancel",
  "In Wallet": "In Wallet",
  "Lock Time": "Lock Time",
  "Locked Till": "Locked Till",
  "Initial Stake": "Initial Stake",
  "Restake GGR": "Restake GGR",
  Earnings: "Earnings",
  Change: "Change",
  Withdraw: "Withdraw",
  Withdrawal: "Withdrawal",
  "Could you confirm whether you are aware that withdrawing tokens before the specified time will result in the loss of 30% of the initial stake and forfeiting any rewards?":
    "Could you confirm whether you are aware that withdrawing tokens before the specified time will result in the loss of 30% of the initial stake and forfeiting any rewards?",
  "You will get": "You will get",
  "Please enter GGR amount": "Please enter GGR amount",
  "Link EVM address": "Link EVM address",
  "Enter your address": "Enter your address",
  "Linking two addresses will allow you to enjoy the benefits of GGR from either one. Allocation Power, NFT bonuses and other balances are added up, allowing you to get a bigger tier!":
    "Linking two addresses will allow you to enjoy the benefits of GGR from either one. Allocation Power, NFT bonuses and other balances are added up, allowing you to get a bigger tier!",
  "Great! You have successfully linked a EVM address!":
    "Great! You have successfully linked a EVM address!",
  "Oops! Something went wrong, try again later or contact our support.":
    "Oops! Something went wrong, try again later or contact our support.",
  "Invalid address": "Invalid address",
  "Wait a bit...": "Wait a bit...",
  "Staking AP": "Staking AP",
  "In vesting": "In vesting",
  "Bonus AP": "Bonus AP",
  "GGR In Wallets": "GGR In Wallets",
  Vesting: "Vesting",
  "Next unlock": "Next unlock",
  Received: "Received",
  Remaining: "Remaining",
  "What you will lose?": "What you will lose?",
  "Claim anyway": "Claim anyway",
  "We're grateful to our early investor for believing in our project. We've prepared bonuses for you, but you'll lose them if you claim the tokens. We value our friendship and hope to continue be together towards our goals!":
    "We're grateful to our early investor for believing in our project. We've prepared bonuses for you, but you'll lose them if you claim the tokens. We value our friendship and hope to continue be together towards our goals!",
  "If you will claim the tokens, please be aware that it will result in a loss of 15% APR on your initial deposit":
    "If you will claim the tokens, please be aware that it will result in a loss of 15% APR on your initial deposit",
  "Claiming tokens may seem like an easy win, but it comes with a high cost. If you claim the tokens, you will need more Allocation Power to receive the Investor Tier, which could cost you in the long run":
    "Claiming tokens may seem like an easy win, but it comes with a high cost. If you claim the tokens, you will need more Allocation Power to receive the Investor Tier, which could cost you in the long run",
  "Claiming tokens may result in a reduction of your allocation for projects. It may impact your ability to participate in future IDO":
    "Claiming tokens may result in a reduction of your allocation for IDO projects. It may impact your ability to participate in future IDO",
  "Unfortunately, no vesting tokens have been allocated for you":
    "Unfortunately, no vesting tokens have been allocated for you",
  title: "Account Info",
  "Total allocation power": "Total allocation power",
  "Bonus NFTs": "Bonus NFTs",
  "Only NFT that gives the biggest tier is taken into account":
    "Only NFT that gives the biggest tier is taken into account",
  "Looks like your Allocation Power is currently not enough to have a Tier. You can easily solve it your tier by purchasing and staking GGR tokens. Don't miss out opportunity to maximize your investment potential. Buy and stake GGR tokens now!":
    "Looks like your Allocation Power is currently not enough to have a Tier. You can easily solve it your tier by purchasing and staking GGR tokens. Don't miss out opportunity to maximize your investment potential. Buy and stake GGR tokens now!",
  "Garanteed allocation": "Garanteed allocation",
  "Gives you Orbit tier, 1% cashback and guaranteed allocation up to $400":
    "Gives you Orbit tier, 1% cashback and guaranteed allocation up to $400",
  "Gives you Galaxy tier, 5% cashback and guaranteed allocation up to $600":
    "Gives you Galaxy tier, 5% cashback and guaranteed allocation up to $600",
  "Your addresses are not linked. Only AP from BNB is taken into account. Please":
    "Your addresses are not linked. Only AP from BNB is taken into account. Please",
  "link your addresses": "link your addresses",
  "You have": "You have",
  "old GGR that are not taken into account when calculating the AP. You should upgrade GGR.":
    "old GGR that are not taken into account when calculating the AP. You should upgrade GGR.",
  "This staking program has been finished. To get AP, please use staking V2":
    "This staking program has been finished. To get AP, please use staking V2",
};

export default en;
