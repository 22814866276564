import Button from "app/components/button/button";
import IdoTitlePostfix from "app/components/ido-title-postfix";
import { useActiveFilter, useProjectImage } from "app/hooks";
import { Project } from "app/types";
import { KeyValueItem } from "app/types/key-value-item";
import cn from "classnames";
import { CSSProperties, FC, ReactNode, useEffect } from "react";
import { Link } from "react-router-dom";
import { ImageType, isIdo } from "sdk";
import { useSwiper } from "swiper/react";
import ProjectInfoItem from "../project-info-item";
import ProjectItemLinks from "./project-item-links";
import { ProjectItemMarketingActivityList } from "./project-item-marketing-activity-list";
import "./project.item.sass";

type Props = {
  className?: string;
  project: Project;
  items: KeyValueItem[];
  buttonText: string;
  style?: CSSProperties;
  link: string;
  tagsComponent?: ReactNode;
};

const ProjectItem: FC<Props> = ({
  className = "",
  project,
  items,
  style,
  buttonText,
  children,
  link,
  tagsComponent,
}) => {
  const mainActiveFilter = useActiveFilter();
  const banner = useProjectImage(ImageType.BANNER, project.id);

  const swiper = useSwiper();

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0);
    }
  }, [mainActiveFilter, swiper]);

  return (
    <div className={cn("project-item", className)} style={style}>
      <ProjectItemMarketingActivityList project={project} />
      <Link
        className="project-item__head"
        to={link}
        style={{ backgroundImage: `url(${banner || (isIdo(project) && project.idoBannerUrl)})` }}
      >
        {tagsComponent}
      </Link>
      <div className="project-item__body">
        <div className="project-item__row-group">
          <div className="project-item__row">
            <div className="project-item__title">
              {project.title}{" "}
              <IdoTitlePostfix projectId={project?.id} />
            </div>
            {/* {exchangeCurrencyIcon && (
              <img
                src={exchangeCurrencyIcon}
                alt={exchangeCurrencySymbol}
                className="project-item__stable"
              />
            )} */}
          </div>
          <ProjectItemLinks project={project} />
          <div className="project-item__row">
            <div className="project-item__info">
              {items.map(({ itemKey, children }) => (
                <ProjectInfoItem className="project-item__info" key={itemKey} itemKey={itemKey}>
                  {children}
                </ProjectInfoItem>
              ))}
            </div>
          </div>
        </div>
        <div className="project-item__row-group">
          {children}
          <Link className="project-item__row" to={link}>
            <Button variant="_dark">{buttonText}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
