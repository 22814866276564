import { PAYMENTS_OVER, refundedProjectsId } from "app/constants";
import { ProjectStage } from "app/enums";
import {
  useCurrency,
  useExchangeRate,
  useFcfsEnd,
  useHighTierFcfsEnd,
  useIdoStage,
  useNextClaimByProject,
  useSwapEnd,
  useSwapStart,
  useTotalRaiseAmount,
  useWhitelistEnd,
  useWhitelistStart
} from "app/hooks";
import { KeyValueItem } from "app/types/key-value-item";
import { CSSProperties, FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Ido, RefundType } from "sdk";
import ProjectItem from "../project/project-item/project.item";
import IdoClaimNetworksList from "./ido-claim-networks-list";
import IdoExchangeNetworksList from "./ido-exchange-networks-list";
import IdoFill from "./ido-fill";
import IdoTagsList from "./ido-tags.list";

type Props = {
  className?: string;
  ido: Ido;
  style?: CSSProperties;
};

const IdoItem: FC<Props> = ({ className = "", ido, style }) => {
  const { t, i18n } = useTranslation();
  const { claimCurrencySymbol, exchangeCurrencySymbol } = useCurrency(ido);
  const totalRaiseAmount = useTotalRaiseAmount(ido);
  const exchangeRate = useExchangeRate(ido, 'auto');
  const projectStage = useIdoStage(ido);
  const { nextClaimView } = useNextClaimByProject(ido);
  const whitelistStart = useWhitelistStart(ido);
  const whitelistEnd = useWhitelistEnd(ido);
  const swapStart = useSwapStart(ido);
  const swapEnd = useSwapEnd(ido);
  const fcfsEnd = useFcfsEnd(ido);
  const highTierFcfsEnd = useHighTierFcfsEnd(ido);

  const items: KeyValueItem[] = useMemo(
    () => [
      {
        itemKey: "Total Raise",
        children: (
          <>
            {totalRaiseAmount !== "TBA" && "$"}
            {totalRaiseAmount}
          </>
        ),
      },
      {
        itemKey: "Exchange Rate",
        children: (
          <>
            {exchangeRate === "TBA"
              ? exchangeRate
              : `1 ${claimCurrencySymbol} = ${exchangeRate} ${exchangeCurrencySymbol}`}
          </>
        ),
      },
      {
        itemKey: "Exchange Networks",
        children: (
          <>
            {exchangeRate === "TBA"
              ? exchangeRate
              : <IdoExchangeNetworksList project={ido} />
            }
          </>
        ),
      },
      {
        itemKey: "Claim Network",
        children: (
          <>
            <IdoClaimNetworksList project={ido} />
          </>
        ),
      },
    ],
    [totalRaiseAmount, exchangeRate, claimCurrencySymbol, exchangeCurrencySymbol]
  );

  const buttonText = useMemo(() => {
    switch (projectStage) {
      case ProjectStage.whitelistTba:
        return "TBA";
      case ProjectStage.whitelistGap:
        return `${t("Whitelist starts on")} ${whitelistStart}`;
      case ProjectStage.whitelist:
        return `${t("Whitelist ends on")} ${whitelistEnd}`;
      case ProjectStage.swapGap:
      case ProjectStage.swapGapWithResults:
        return `${t("Swap starts on")} ${swapStart}`;
      case ProjectStage.swap:
        return `${t("Swap ends on")} ${swapEnd}`;
      case ProjectStage.highTierFcfs:
        return `${t("High tier FCFS ends on")} ${highTierFcfsEnd}`;
      case ProjectStage.fcfs:
        return `${t("FCFS ends on")} ${fcfsEnd}`;
      case ProjectStage.claimGap:
      case ProjectStage.claim:
        return nextClaimView === PAYMENTS_OVER
          ? t(PAYMENTS_OVER)
          : `${t("Next claim at")} ${nextClaimView}`;

      case ProjectStage.ended:
        return t("Ended");
      case ProjectStage.refund:
        if (refundedProjectsId.includes(ido.id) || ido.refundType === RefundType.REFUNDED) {
          return t(PAYMENTS_OVER);
        }
        return t("Refund");
      default:
        return "TBA";
    }
  }, [projectStage, i18n.language]);

  return (
    <ProjectItem
      className={className}
      project={ido}
      items={items}
      buttonText={buttonText}
      link={`/ido/${ido.id}`}
      style={style}
      tagsComponent={
        <IdoTagsList className="project-item__tags" ido={ido} projectStage={projectStage} />
      }
    >
      {exchangeRate !== "TBA" && (
        <div className="project-item__row">
          <IdoFill className="project-item__progress" ido={ido} />
        </div>
      )}
    </ProjectItem>
  );
};

export default IdoItem;
