import { useProjectLinks } from "app/hooks";
import { Project } from "app/types";
import { FC } from "react";
import ProjectItemLinkDocs from "./project-item-link-docs";
import ProjectItemLinkSocial from "./project-item-link-social";

type Props = {
  className?: string;
  project: Project;
};

const ProjectItemLinks: FC<Props> = ({ className = "", project }) => {
  const [socailLinks, docsLinks] = useProjectLinks(project);

  return (
    <>
      {!!socailLinks.length && (
        <div className="project-item__row">
          <div className="project-item__links">
            {socailLinks.map((link) => (
              <ProjectItemLinkSocial key={link.id} link={link} />
            ))}
          </div>
        </div>
      )}
      {!!docsLinks.length && (
        <div className="project-item__row">
          <div className="project-item__links">
            {docsLinks.map((link) => (
              <ProjectItemLinkDocs key={link.id} link={link} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectItemLinks;
